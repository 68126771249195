import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../../components/Buttons/NextBtnSmall';
import TopInfo from './TopInfo';
import GoodsTable from './GoodsTable';
import { DialogWarning } from '../DialogWarning';
import { BtnNormal } from '../../../../../../components/Buttons/BtnNormal';
import { useTranslation } from 'react-i18next';

// #region styled-components

const IndexTableContainer = styled.div``;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
`;

const Right = styled.div`
    display: flex;
`;

//#endregion

function Page1(props) {
    //#region state initiation
    // props
    const {
        setDialogConfirmData,
        parentData,
        setParentData,
        page,
        processSteps,
        handleNextPage,
        t,
    } = props;
    //console.log(props);

    // state
    const [pageData, setPageData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [warningMessage, setWarningMessage] = useState('');
    const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');

    // axios
    const { sendRequest: axiosDataPage1 } = useAxios();

    //#endregion

    //#region variable initiation

    // table header 的名稱
    const headTitleList = [
        '#',
        '型態',
        '銷售內容',
        '銷售數量',
        '未理數量',
        '理貨數量',
        t('materialCube'),
        t('batchNo'),
        t('note'),
    ];

    // table body 的資料名稱
    const bodyTitleList = [
        'displayOrder',
        'salesCategoryCode',
        'salesCategoryontent',
        'quantitySale',
        'quantityUnpack',
        'quantity',
        'materialCube',
        'batchNo',
        'note',
    ];

    // 不同 input 的 type
    const inputProperties = [
        {},
        {},
        {},
        {},
        {},
        { type: 'number' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
    ];

    // table header 的 style
    const tableHeadStyles = [
        {
            width: '56px',
            textAlign: 'center',
        },
        {
            width: '80px',
            textAlign: 'center',
        },
        {
            width: '248px',
            textAlign: 'center',
        },
        {
            width: '80px',
            textAlign: 'center',
        },
        {
            width: '112px',
            textAlign: 'center',
        },
        {
            width: '136px',
            textAlign: 'center',
        },
        {
            width: '160px',
            textAlign: 'center',
        },
        {
            width: '160px',
            textAlign: 'center',
        },
        {
            width: '160px',
            textAlign: 'center',
        },
    ];

    // table body 的 style
    const tableBodyStyles = [
        {
            width: '56px',
            textAlign: 'center',
        },
        {
            width: '80px',
            textAlign: 'left',
        },
        {
            width: '248px',
            textAlign: 'left',
        },
        {
            width: '80px',
            textAlign: 'left',
        },
        {
            width: '112px',
            textAlign: 'right',
        },
        {
            width: '136px',
            textAlign: 'right',
        },
        {
            width: '160px',
            textAlign: 'left',
        },
        {
            width: '160px',
            textAlign: 'left',
        },
        {
            width: '160px',
            textAlign: 'left',
        },
    ];

    //#endregion

    //#region function

    // 暫存被修改的資料
    const handleTemporaryStore = () => {
        const newParentData = { ...parentData, ...pageData };
        const newUpdateData = pageData?.updateData;

        newParentData.updateData = newUpdateData;
        // 標記是否曾拿取過資料
        newParentData.isEntered = true;

        setParentData(newParentData);
    };

    // 回到page0 清空暫存資料
    const handleBackPage0 = () => {
        const newData = {
            btnName: 'goToPage0',
            isOpen: true,
            goTo: 'preStep',
            title: t('confirmRechooseOrder') + '?',
        };
        setDialogConfirmData(newData);
    };

    // 往下一頁之前的檢查
    //console.log("tableBodyData", tableBodyData);
    const handleSubmit = () => {
        let falseCount = 0;

        // 1.pageData
        // 1-1.收貨日期必填
        const newUpdateData = pageData.updateData;
        console.log(newUpdateData);
        if (!newUpdateData?.packTime) {
            newUpdateData.packTimeWarning = t(
                'lgsPurchaseOrderGoodsInvoice:fillData'
            );

            falseCount++;
        }

        // 1-2.收貨員工必填
        console.log(newUpdateData);
        if (!newUpdateData?.packGuid) {
            newUpdateData.packWarning = t(
                'lgsPurchaseOrderGoodsInvoice:fillData'
            );

            falseCount++;
        }

        // 3.tableBodyData
        if (newUpdateData.salesOrderShipmentItemList.length) {
            let newSalesOrderShipmentItemList = [
                ...newUpdateData.salesOrderShipmentItemList,
            ];

            // 2-1.理貨數量至少填一欄 (alert)
            let isQuantityReceiveLeastOne = newSalesOrderShipmentItemList.some(
                (el) => {
                    console.log(el);
                    return (
                        el['quantity'] && el['quantity'] !== '0'
                    );
                }
            );
            !isQuantityReceiveLeastOne && falseCount++;

            if (!isQuantityReceiveLeastOne) {
                setWarningMessage(
                    t(
                        'lgsPurchaseOrderGoodsInvoice:atLeastOneReceivedQuantityRequired'
                    ) + '！'
                );
                handleDialogWarning();
            }
        }

        // 驗證通過
        //console.log(falseCount);
        if (falseCount === 0) {
            handleTemporaryStore();
            handleNextPage();
        } else {
            setPageData({
                ...pageData,
                updateData: {
                    ...pageData.updateData,
                    ...newUpdateData,
                },
            });
        }
    };

    //#endregion

    //#region DialogWarning

    // 返回btn確認dialog

    const [isWarningOpen, setIsWarningOpen] = useState(false);

    // Modal open
    const handleDialogWarning = () => {
        setIsWarningOpen(true);
    };

    // console.log(parentData)
    //#endregion

    useEffect(() => {
        const isEverEnterPage = parentData.isEntered;

        if (!isEverEnterPage) {
            // axios get BE data
            const url = `api/Logistics/LgsSalesOrderPreparing/getUpdatePage2?guid=${parentData.page0.shipmentGuid}`;
            const method = 'GET';

            axiosDataPage1({ url, method }, async (res) => {

                const {
                    employeeList,
                    materialCubeList,
                    salesOrder,
                    salesOrderKitList,
                    salesOrderKitItemList,
                    salesOrderShipment,
                    salesOrderShipmentItemList,
                } = res;

                const adjustedSalesOrderShipmentItemList =
                    [...salesOrderShipmentItemList].map((el) => {
                        const { quantitySale, quantityPack } = el;
                        const cube = materialCubeList.find(
                            (cube) => el?.materialCubeGuid === cube.cubeGuid
                        );

                        if (cube) {
                            el.cubeGuid = cube.cubeGuid
                                 el.cubeName = cube.name01
                                 el.cubeCode = cube.cubeCode 
                        }

                        return {
                            ...el,
                            quantityUnpack: quantitySale - quantityPack,
                            newQuantityPack: 0,
                        };
                    });

                const {
                    employeeGuid: packGuid,
                    employeeCode: packCode,
                    fullname: packName,
                } = employeeList.find((el) => el.employeeGuid === salesOrderShipment.packGuid);

                const originalShipmentItemList =
                [...salesOrderShipmentItemList].map((el) => {
                    const { quantitySale, quantityPack } = el;
                    const cube = materialCubeList.find(
                        (cube) => el?.materialCubeGuid === cube.cubeGuid
                    );

                    if (cube) {
                        el.cubeGuid = cube.cubeGuid
                             el.cubeName = cube.name01
                             el.cubeCode = cube.cubeCode 
                    }

                    return {
                        ...el,
                        quantityUnpack: quantitySale - quantityPack,
                        newQuantityPack: 0,
                    };
                });


                const newParentData = {
                    originalShipmentItemList,
                    originalData: {
                        ...salesOrder,
                        employeeList,
                        materialCubeList,
                        salesOrderKitList,
                        salesOrderKitItemList,
                        salesOrderShipment: {
                            ...salesOrderShipment,
                            packCode,
                            packName,
                        },
                        salesOrderShipmentItemList:
                        adjustedSalesOrderShipmentItemList,
                        packGuid,
                        packCode,
                        packName,
                        isEntered: true,
                    },
                    updateData: {
                        ...salesOrder,
                        ...salesOrderShipment,
                        packCode,
                        packName,

                        employeeList,
                        materialCubeList,
                        salesOrderKitList,
                        salesOrderKitItemList,
                        salesOrderShipment: {
                            ...salesOrderShipment,
                            packCode,
                            packName,
                        },
                        salesOrderShipmentItemList:
                            adjustedSalesOrderShipmentItemList,
                        packGuid,
                        packCode,
                        packName,
                        isEntered: true,
                    },
                };

                await setPageData(newParentData);
            });
        } else {
            setPageData(parentData);
        }
    }, []);
    //#endregion

    console.log(pageData)

    return (
        <>
            {/* DialogWarning element */}
            <DialogWarning
                isWarningOpen={isWarningOpen}
                setIsWarningOpen={setIsWarningOpen}
                warningMessage={warningMessage}
            />

            <Container className="">
                <Left className="">
                    {page !== 0 && (
                        <BtnNormal
                            className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
                            key={'PrevBtn'}
                            onClick={() => {
                                handleBackPage0();
                            }}
                        >
                            {t('common:button.prevStep')}
                        </BtnNormal>
                    )}
                </Left>
                <Center className="">
                    <StepByStepBar steps={processSteps} activePage={page} />
                </Center>
                <Right className="">
                    {page < processSteps.length - 1 ? (
                        <BtnNormal
                            className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
                            key={'PrevBtn'}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit(page);
                            }}
                        >
                            {'下一步'}
                            {/* {t("common:button.nextStep")} */}
                        </BtnNormal>
                    ) : (
                        <NextBtnSmall className="d-none" />
                    )}
                </Right>
            </Container>

            {pageData?.updateData && (
                <>
                    <div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
                        <TopInfo
                            pageData={pageData}
                            setPageData={setPageData}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            modalData={modalData}
                            setModalData={setModalData}
                            setDialogBtnKeyName={setDialogBtnKeyName}
                            t={t}
                        />
                    </div>
                    <div className="position-relative">
                        <IndexTableContainer className="bg-white rounded-xxl d-flex flex-column px-2 py-3 position-relative">
                            {pageData && (
                                <GoodsTable
                                    pageData={pageData}
                                    setPageData={setPageData}
                                    salesOrderKitList={
                                        pageData?.updateData?.salesOrderKitList
                                    }
                                    salesOrderKitItemList={
                                        pageData?.updateData
                                            ?.salesOrderShipmentItemList
                                    }
                                    salesOrderShipmentItemList={
                                        pageData?.updateData
                                            ?.salesOrderShipmentItemList
                                    }
                                    headTitleList={headTitleList}
                                    bodyTitleList={bodyTitleList}
                                    tableHeadStyles={tableHeadStyles}
                                    tableBodyStyles={tableBodyStyles}
                                    inputProperties={inputProperties}
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    modalData={
                                        pageData?.updateData?.materialCubeList
                                    }
                                    setModalData={setModalData}
                                    dialogBtnKeyName={dialogBtnKeyName}
                                    setDialogBtnKeyName={setDialogBtnKeyName}
                                    t={t}
                                />
                            )}
                        </IndexTableContainer>
                    </div>
                </>
            )}
        </>
    );
}

export default Page1;
