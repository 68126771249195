import React, { useEffect, useRef, useState } from 'react';
import Input from '../../../../../../components/FormElement/Input';
import Textarea from '../../../../../../components/FormElement/Textarea';
import ThreeColLayout from '../../../../../../components/ThreeColLayout/ThreeColLayout';
import { useTranslation } from 'react-i18next';

const LgsSalesOrder_BaseInfo = ({
	register,
	getValues,
	setValue,
	detailsInfo,
}) => {
	const {
		phone1: customerPhone1,
		phone2: customerPhone2,
		address: customerAddress,
		sectorName: sectorText,
		invoiceFormatTypeName: invoiceFormatTypeText,
		taxId: taxID,
		invoiceTitle,
		noteCustomer: note_Customer,
		noteInternal: note_Internal,
	} = detailsInfo.salesOrder || {};

	const { t } = useTranslation(['common', 'lgsSalesOrder']);

	function LeftCol1() {
		const [valueShow, setValueShow] = useState('');
		return (
			<>
				<Input
					wrapperClass="mb-3"
					labelText={t('lgsSalesOrder:customerPhone1')}
					type="text"
					id="customerPhone1"
					placeholder=""
					disabled={true}
					disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register}
					value={customerPhone1 || ''}
				/>
				<Input
					wrapperClass="mb-3" // 外層class，可用來推下面margin
					labelText={t('lgsSalesOrder:customerPhone2')} // label文字
					type="tel" // input型態
					id="customerPhone2" // input id&name ; label htmlFor
					placeholder=""
					disabled={true}
					disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register}
					value={customerPhone2 || ''}
				/>
				<Input
					wrapperClass="mb-3" // 外層class，可用來推下面margin
					labelText={t('lgsSalesOrder:customerAddress')} // label文字
					type="tel" // input型態
					id="customerAddress" // input id&name ; label htmlFor
					placeholder=""
					disabled={true}
					disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register}
					value={customerAddress || ''}
				/>
			</>
		);
	}

	function CenterCol1() {
		return (
			<>
				<Input
					wrapperClass="mb-3"
					labelText={t('lgsSalesOrder:salesDepartment')}
					type="text"
					id="sectorText"
					placeholder=""
					disabled={true}
					disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register}
					value={sectorText || ''}
					onChange={() => {}}
				/>

				<Input
					wrapperClass="mb-3"
					labelText={t('lgsSalesOrder:invoiceFormatType')}
					type="text"
					id="invoiceFormatTypeText"
					placeholder=""
					disabled={true}
					disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register}
					value={invoiceFormatTypeText || ''}
					onChange={() => {}}
				/>
				<Input
					wrapperClass="mb-3" // 外層class，可用來推下面margin
					labelText={t('lgsSalesOrder:taxID')} // label文字
					type="text" // input型態
					id="taxID" // input id&name ; label htmlFor
					placeholder=""
					disabled={true}
					disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register}
					value={taxID || ''}
					onChange={() => {}}
				/>
				<Input
					wrapperClass="mb-3" // 外層class，可用來推下面margin
					labelText={t('lgsSalesOrder:invoiceTitle')} // label文字
					type="text" // input型態
					id="invoiceTitle" // input id&name ; label htmlFor
					placeholder=""
					disabled={true}
					disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register}
					value={invoiceTitle || ''}
					onChange={() => {}}
				/>
			</>
		);
	}

	function RightCol1() {
		return (
			<>
				<Textarea
					wrapperClass="mb-3" // 外層class，可用來推下面margin
					labelText={t('lgsSalesOrder:note_Customer')} // label文字
					id="note_Customer" // input id&name ; label htmlFor
					rows="4"
					cols="30"
					//   placeholder="資料顯示-靠左"
					disabled={true}
					disabledCssType={0} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register} // react-hook-form
					getValues={getValues}
					value={note_Customer || ''}
					onChange={(e) => {
						setValue('note_Customer', e.target.value);
						const note_CustomerValue = getValues('note_Customer');
						// console.log(noteValue);
					}}
				/>
				<Textarea
					wrapperClass="mb-3" // 外層class，可用來推下面margin
					labelText={t('lgsSalesOrder:note_Internal')} // label文字
					id="note_Internal" // input id&name ; label htmlFor
					rows="4"
					cols="30"
					//   placeholder="資料顯示-靠左"
					disabled={true}
					disabledCssType={0} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register} // react-hook-form
					getValues={getValues}
					value={note_Internal || ''}
					onChange={(e) => {
						setValue('note_Internal', e.target.value);
						const note_InternalValue = getValues('note_Internal');
						// console.log(noteValue);
					}}
				/>
			</>
		);
	}

	return (
		<div className="px-3 py-5">
			<ThreeColLayout
				LeftCol={<LeftCol1 />}
				CenterCol={<CenterCol1 />}
				RightCol={<RightCol1 />}
			/>
		</div>
	);
};

export default LgsSalesOrder_BaseInfo;
