import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BtnNormal } from "../../../../../components/Buttons/BtnNormal";
import { StepByStepBar } from "../../../../../components/StepByStepBar/StepByStepBar";
import useAxios from "../../../../../hooks/useAxios";
import { TabPage } from "../../../LgsPurchaseOrder/exports";
import { DialogWarning } from "../DialogWarning";
import BottomInfo from "./BottomInfo";
import AccountDocumentTab from "./TabContent/AccountDocumentTab/AccountDocumentTab";
import HistoryOverview from "./TabContent/HistoryOverview/HistoryOverview";
import PaymentHistory from "./TabContent/PaymentHistory/PaymentHistory";
import PaymentTerm from "./TabContent/PaymentTerm/PaymentTerm";
import TopInfo from "./TopInfo";
// #region styled-components

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const Left = styled.div`
  display: flex;
`;

const Center = styled.div`
  display: flex;
`;

const Right = styled.div`
  display: flex;
`;

//#endregion

function Page2(props) {
  //#region state initiation
  const { t, i18n } = useTranslation();
  const {
    setDialogConfirmData,
    parentData,
    setParentData,
    purchaseOrderData,
    page,
    processSteps,
    handleNextPage,
    dialogBtnKeyName,
    setDialogBtnKeyName,
    isOpen,
    setIsOpen,
    modalData,
    setModalData,
    warningMessage,
    setWarningMessage,
    handlePrevPage,
    CurrencyCode,
    CurrencyName,
    DecimalPlaceTl,
  } = props;
  // console.log(props);
  const [success, setSuccess] = useState(false);
  const [pageData, setPageData] = useState(parentData);
  const [accountList, setAccountList] = useState([]);

  //#endregion

  //#region variable initiation

  // 分頁title
  const tabNavList = [
    t("lgsPurchaseOrderPayment:page2.tabs.paymentDetails"),
    t("lgsPurchaseOrderPayment:page2.tabs.paymentTerms"),
    t("lgsPurchaseOrderPayment:page2.tabs.transactionHistoryOverview"),
    t("lgsPurchaseOrderPayment:page2.tabs.paymentHistory"),
    t("lgsPurchaseOrderPayment:page2.tabs.accountingVoucher"),
  ];

  // 分頁內容 component
  const tabContent = [
    <div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
      <BottomInfo
        pageData={pageData}
        setPageData={setPageData}
        topInfoData={purchaseOrderData?.topInfoData}
        purchaseOrderData={purchaseOrderData}
        dialogBtnKeyName={dialogBtnKeyName}
        setDialogBtnKeyName={setDialogBtnKeyName}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalData={modalData}
        setModalData={setModalData}
        CurrencyCode={CurrencyCode}
        CurrencyName={CurrencyName}
        DecimalPlaceTl={DecimalPlaceTl}
      />
    </div>,
    <PaymentTerm parentData={parentData} />,
    <HistoryOverview parentData={parentData} />,
    <PaymentHistory
      purchaseOrderPayment={parentData.purchaseOrderPayment}
      foreignCurrency={parentData.purchaseOrder.foreignCurrency}
    />,
    <AccountDocumentTab
      parentData={parentData}
      tab3Data={{
        // 前端畫面使用
        accountDocumentName: "會計憑證",
        accountList: accountList,

        // 後端需要的，分開3個list
        dataToBackEnd: {
          goodsReceivedNoteList: [],
          newInvoiceList: [],
          priceVarianceList: [],
        },
      }}
      DecimalPlaceTl={DecimalPlaceTl}
      foreignCurrency={parentData.purchaseOrder.foreignCurrency}
    />,
  ];

  //#endregion

  //#region function

  const { sendRequest: axiosPaymentSubmit } = useAxios();

  // 往下一頁之前的檢查
  const handleSubmit = () => {
    // handleNextPage();

    // const {purchaseOrderGuid}

    const {
      postingDate,
      employeeGuid,
      grossAmount,
      foreignGrossAmount,
      exchageRate,
      exchangeDifferenceAmount,
      note,
    } = pageData.payment;

    axiosPaymentSubmit(
      {
        url: `api/Logistics/LgsPurchaseOrderPayment/insert`,
        method: "POST",
        data: {
          purchaseOrderGuid: pageData.purchaseOrderGuid,
          postingDate,
          staffGuid: employeeGuid,
          grossAmount,
          foreignGrossAmount,
          exchageRate,
          exchangeDifferenceAmount,
          note,
          accountDocumentItem1List: [accountList[0]],
          accountDocumentItem2List: [accountList[1]],
          accountDocumentItem3List: parentData.purchaseOrder.foreignCurrency
            ? [accountList[2]]
            : null,
        },
      },
      async (res) => {
        handleNextPage();
      }
    );
  };

  // state
  // axios
  const { sendRequest: axiosPaymentTermList } = useAxios();

  //#endregion

  useEffect(() => {
    axiosPaymentTermList(
      {
        url: `api/Logistics/LgsPurchaseOrderPayment/getPage3`,
        method: "POST",
        data: {
          purchaseOrderGuid: parentData.purchaseOrderGuid,
        },
      },
      async (res) => {
        console.log(res);
        const {
          purchaseOrderHistoryOverview,
          purchaseOrderPayment,
          purchaseOrderPaymentTerm,
          entry1,
          entry2,
          priceVarianceList,
        } = res;

        console.log(priceVarianceList);

        setParentData({
          ...parentData,
          purchaseOrderPaymentTerm: purchaseOrderPaymentTerm,
          purchaseOrderPayment: purchaseOrderPayment,
          purchaseOrderHistoryOverview: purchaseOrderHistoryOverview,
        });

        entry1.amountDr = parentData.purchaseOrder.foreignCurrency
          ? Number(pageData.payment.grossAmountShouldPay).toFixed(
              DecimalPlaceTl
            )
          : Number(pageData.payment.grossAmount).toFixed(DecimalPlaceTl);

        entry2.amountCr = Number(pageData.payment.grossAmount).toFixed(
          DecimalPlaceTl
        );
        entry2.accountGuid = pageData.payment.accountGuid;
        entry2.accountNo = pageData.payment.accountNo;
        entry2.name01 = pageData.payment.accountName;

        if (parentData.purchaseOrder.foreignCurrency) {
          const moneyQQ =
            pageData.payment.exchangeDifferenceAmount > 0
              ? priceVarianceList[0]
              : priceVarianceList[1];

          moneyQQ.amountDr =
            pageData.payment.exchangeDifferenceAmount > 0
              ? pageData.payment.exchangeDifferenceAmount
              : null;

          moneyQQ.amountCr =
            pageData.payment.exchangeDifferenceAmount > 0
              ? null
              : Number(-pageData.payment.exchangeDifferenceAmount).toFixed(
                  DecimalPlaceTl
                );

          entry1.displayOrder = 1;
          entry2.displayOrder = 2;
          moneyQQ.displayOrder = 3;
          setAccountList([entry1, entry2, moneyQQ]);
        } else {
          entry1.displayOrder = 1;
          entry2.displayOrder = 2;
          setAccountList([entry1, entry2]);
        }
      }
    );
  }, []);

  /**
   * DialogWarning 返回btn確認dialog
   **/
  const [isWarningOpen, setIsWarningOpen] = useState(false);

  // DialogWarning Modal open
  const handleDialogWarning = () => {
    setIsWarningOpen(true);
  };

  const handleCancel = () => {};

  return (
    <>
      {/* DialogWarning element */}
      <DialogWarning
        isWarningOpen={isWarningOpen}
        setIsWarningOpen={setIsWarningOpen}
        warningMessage={warningMessage}
      />

      <Container className="">
        <Left className="">
          {page !== 0 && (
            <BtnNormal
              className="layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center"
              key="prevBtn"
              onClick={() => handlePrevPage()}
            >
              {t("common:button.prevStep")}
            </BtnNormal>
          )}
        </Left>
        <Center className="">
          <StepByStepBar steps={processSteps} activePage={page} />
        </Center>
        <Right className="">
          <BtnNormal
            className="layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center"
            key="nextBtn"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(page);
            }}
          >
            {t("common:button.saveButton")}
          </BtnNormal>
          <BtnNormal
            className="layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center"
            key="cancelBtn"
            onClick={() => {
              handleCancel();
            }}
          >
            {t("common:button.cancelButton")}
          </BtnNormal>
        </Right>
      </Container>

      <div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
        <TopInfo
          pageData={pageData}
          setPageData={setPageData}
          topInfoData={purchaseOrderData?.topInfoData}
          purchaseOrderData={purchaseOrderData}
          dialogBtnKeyName={dialogBtnKeyName}
          setDialogBtnKeyName={setDialogBtnKeyName}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          modalData={modalData}
          setModalData={setModalData}
          CurrencyCode={CurrencyCode}
          CurrencyName={CurrencyName}
          DecimalPlaceTl={DecimalPlaceTl}
        />
      </div>
      <TabPage
        tabNavList={tabNavList}
        tabContent={tabContent}
        defaultPage={0}
      />
    </>
  );
}

export default Page2;
