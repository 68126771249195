import { createContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/exports';

import LgsPurchaseOrderGoodsInspect from './pages/Logistics/LgsPurchaseOrderGoodsInspect/LgsPurchaseOrderGoodsInspect';
//import LgsSalesOrderPreparing from './pages/Logistics/LgsSalesOrderPreparing/LgsSalesOrderPreparing';
import LgsPurchaseOrderGoodsInvoice from './pages/Logistics/LgsPurchaseOrderGoodsInvoice/LgsPurchaseOrderGoodsInvoice';
import LgsPurchaseOrderGoodsReturn from './pages/Logistics/LgsPurchaseOrderGoodsReturn/LgsPurchaseOrderGoodsReturn';
import LgsPurchaseOrderInvoice from './pages/Logistics/LgsPurchaseOrderInvoice/LgsPurchaseOrderInvoice';
import LgsPurchaseOrderInvoiceReturn from './pages/Logistics/LgsPurchaseOrderInvoiceReturn/LgsPurchaseOrderInvoiceReturn';
import {
	GlaAccountant_Index,
	LgsPurchaseOrderAppendix_Create,
	LgsPurchaseOrderAppendix_Details,
	LgsPurchaseOrderAppendix_Edit,
	LgsPurchaseOrderAppendix_Index,
	LgsPurchaseOrderAppendix_Log,
	LgsPurchaseOrderNoRule_Create,
	LgsPurchaseOrderNoRule_Details,
	LgsPurchaseOrderNoRule_Edit,
	LgsPurchaseOrderNoRule_Index,
	LgsPurchaseOrderNoRule_Log,
	LgsDocumentNoRule_Create,
	LgsDocumentNoRule_Details,
	LgsDocumentNoRule_Edit,
	LgsDocumentNoRule_Index,
	LgsDocumentNoRule_Log,
	LgsPurchaseOrderPayment,
	LgsPurchaseOrderPaymentClose,
	LgsPurchaseOrderPaymentReturn,
	LgsPurchaseOrderType_Create,
	LgsPurchaseOrderType_Details,
	LgsPurchaseOrderType_Edit,
	LgsPurchaseOrderType_Index,
	LgsPurchaseOrderType_Log,
	LgsPurchaseOrder_Create,
	LgsPurchaseOrder_Details,
	LgsPurchaseOrder_Edit,
	LgsPurchaseOrder_Index,
	LgsSalesKitClass_Create,
	LgsSalesKitClass_Index,
	LgsSalesKitClass_Details,
	LgsSalesKitClass_Edit,
	LgsSalesKitClass_Log,
	LgsServiceClass_Create,
	LgsServiceClass_Details,
	LgsServiceClass_Edit,
	LgsServiceClass_Index,
	LgsServiceClass_Log,
	LgsServiceMaster_Create,
	LgsServiceMaster_Details,
	LgsServiceMaster_Edit,
	LgsServiceMaster_Index,
	LgsServiceMaster_Log,
	LgsSalesOrderType_Create,
	LgsSalesOrderType_Details,
	LgsSalesOrderType_Edit,
	LgsSalesOrderType_Index,
	LgsSalesOrderType_Log,
	LgsSalesKit_Index,
	LgsSalesKit_Create,
	LgsSalesKit_DetailsAndEdit,
	LgsSalesKit_Log,
	LgsSalesOrderNoRule_Index,
	LgsSalesOrderNoRule_Details,
	LgsSalesOrderNoRule_Create,
	LgsSalesOrderNoRule_Edit,
	LgsSalesOrderNoRule_Log,
	LgsSalesOrder_CreateAndEdit,
	LgsSalesOrder_Details,
	LgsSalesOrder_Index,
	LgsSalesOrderPayment,
	LgsSalesOrderPaymentReturn,
	LgsSalesOrderPaymentClose,
	LgsSalesOrderPreparing_Create,
	LgsSalesOrderPreparing_Edit,
	LgsSalesOrderGoodsInvoice_Create,
	LgsSalesOrderGoodsInvoice_Edit,
	LgsSalesOrderGoodsReturn_Create,
	LgsSalesOrderGoodsReturn_Edit,
	LgsSalesOrderGoodsInspect_Create,
	LgsSalesOrderGoodsInspect_Edit
} from './pages/Logistics/exports';

export const Context = createContext();

function App() {
	const [cookies, setCookie] = useCookies();
	const lang = cookies['_system_culture']; // IdentityServer.Cookie // /AuthIdentityServer/Auth/Login

	const isLogin = cookies['_system_identityserver'];
	// const isLogin = cookies['IdentityServer.Cookie'];

	// console.log(isLogin);

	const { i18n } = useTranslation(['common']);

	// console.log(isLogin);
	// console.log(lang);
	// 组件内部使用hook
	// const [t, i18n] = useTranslation();

	// 在事件方法中使用i18n.changeLanguage()方法
	const toggleI18n = () => {
		let locale = 'zh';

		if (lang === 'c=zh-CN|uic=zh-CN') {
			locale = 'zh-CN';
		} else if (lang === 'c=zh-TW|uic=zh-TW') {
			locale = 'zh';
		} else if (lang === 'c=en|uic=en') {
			locale = 'en';
		}

		i18n.changeLanguage(locale);
	};

	// alert(isLogin);
	if (!isLogin) {
		location.href = './AuthIdentityServer/Auth/Login';
	}
	// publishReset
	//if (!isLogin) {
	//    location.href = 'AuthIdentityServer/Auth/Login';
	//}

	useEffect(() => {
		toggleI18n();
	}, [lang]);

	return (
		<Context.Provider value={{ lang: lang }}>
			<Layout lang={lang}>
				<Routes>
					{/* // GlaAccountant */}
					<Route path={`/Logistics/GlaAccountant`}>
						<Route path="" element={<GlaAccountant_Index />} />
					</Route>
					{/* // LgsPurchaseOrderNoRule */}
					<Route path={`/Logistics/LgsPurchaseOrderNoRule`}>
						<Route
							path=""
							element={<LgsPurchaseOrderNoRule_Index />}
						/>
						<Route
							path="Create"
							element={<LgsPurchaseOrderNoRule_Create />}
						/>
						<Route
							path="Log/:logGuid"
							element={<LgsPurchaseOrderNoRule_Log />}
						/>
						<Route
							path="Log"
							element={<LgsPurchaseOrderNoRule_Log />}
						/>

						<Route
							path="Details/:noRuleGuid"
							element={<LgsPurchaseOrderNoRule_Details />}
						/>
						<Route
							path="Edit/:guid"
							element={<LgsPurchaseOrderNoRule_Edit />}
						/>
					</Route>
					{/* // 進銷存憑證編號規則 LgsDocumentNoRule */}
					<Route path={`/Logistics/LgsDocumentNoRule`}>
						<Route path="" element={<LgsDocumentNoRule_Index />} />
						<Route
							path="Create"
							element={<LgsDocumentNoRule_Create />}
						/>
						<Route
							path="Log/:documentName"
							element={<LgsDocumentNoRule_Log />}
						/>
						<Route
							path="Details/:noRuleGuid"
							element={<LgsDocumentNoRule_Details />}
						/>
						<Route
							path="Edit/:guid"
							element={<LgsDocumentNoRule_Edit />}
						/>
					</Route>
					{/* // LgsPurchaseOrder */}
					<Route path={`/Logistics/LgsPurchaseOrder`}>
						<Route path="" element={<LgsPurchaseOrder_Index />} />
						<Route
							path="Details/:purchaseOrderGuid"
							element={<LgsPurchaseOrder_Details />}
						/>
						<Route
							path="Create"
							element={<LgsPurchaseOrder_Create />}
						/>

						<Route
							path="Edit/:guid"
							element={<LgsPurchaseOrder_Edit />}
						/>
					</Route>
					{/* // LgsPurchaseOrderType */}
					<Route path={`/Logistics/LgsPurchaseOrderType`}>
						<Route
							path=""
							element={<LgsPurchaseOrderType_Index />}
						/>
						<Route
							path="Create"
							element={<LgsPurchaseOrderType_Create />}
						/>

						<Route
							path="Details/:typeGuid"
							element={<LgsPurchaseOrderType_Details />}
						/>
						<Route
							path="Edit/:guid"
							element={<LgsPurchaseOrderType_Edit />}
						/>
						<Route
							path="Log/:logGuid"
							element={<LgsPurchaseOrderType_Log />}
						/>
						<Route
							path="Log"
							element={<LgsPurchaseOrderType_Log />}
						/>
					</Route>
					{/* 採購訂單收貨與收發票 LgsPurchaseOrderGoodsInvoice */}
					<Route path={`/Logistics/lgsPurchaseOrderGoodsInvoice`}>
						<Route
							path=""
							element={<LgsPurchaseOrderGoodsInvoice />}
						/>
					</Route>
					{/* // LgsPurchaseOrderGoodsInspect */}
					<Route path={`/Logistics/LgsPurchaseOrderGoodsInspect`}>
						<Route
							path=""
							element={<LgsPurchaseOrderGoodsInspect />}
						/>
					</Route>
					{/* 採購訂單付款 lgsPurchaseOrderPayment */}
					<Route path={`/Logistics/lgsPurchaseOrderPayment`}>
						<Route path="" element={<LgsPurchaseOrderPayment />} />
					</Route>
					{/* 採購付款退回 lgsPurchaseOrderPaymentReturn */}
					<Route path={`/Logistics/lgsPurchaseOrderPaymentReturn`}>
						<Route
							path=""
							element={<LgsPurchaseOrderPaymentReturn />}
						/>
					</Route>
					{/* 採購訂單付款結清 lgsPurchaseOrderPaymentClose */}
					<Route path={`/Logistics/lgsPurchaseOrderPaymentClose`}>
						<Route
							path=""
							element={<LgsPurchaseOrderPaymentClose />}
						/>
					</Route>
					{/* 採購訂單退貨 LgsPurchaseOrderGoodsReturn */}
					<Route path={`/Logistics/LgsPurchaseOrderGoodsReturn`}>
						<Route
							path=""
							element={<LgsPurchaseOrderGoodsReturn />}
						/>
					</Route>
					{/* 採購訂單收發票 lgsPurchaseOrderInvoice */}
					<Route path={`/Logistics/lgsPurchaseOrderInvoice`}>
						<Route path="" element={<LgsPurchaseOrderInvoice />} />
					</Route>
					{/* 採購訂單退發票 lgsPurchaseOrderInvoiceReturn */}
					<Route path={`/Logistics/lgsPurchaseOrderInvoiceReturn`}>
						<Route
							path=""
							element={<LgsPurchaseOrderInvoiceReturn />}
						/>
					</Route>
					{/* 採購訂單附件分類 lgsPurchaseOrderAppendix */}
					{/* // LgsPurchaseOrderAppendix */}
					<Route path={`/Logistics/LgsPurchaseOrderAppendix`}>
						<Route
							path=""
							element={<LgsPurchaseOrderAppendix_Index />}
						/>
						<Route
							path="Create"
							element={<LgsPurchaseOrderAppendix_Create />}
						/>

						<Route
							path="Details/:typeGuid"
							element={<LgsPurchaseOrderAppendix_Details />}
						/>
						<Route
							path="Edit/:guid"
							element={<LgsPurchaseOrderAppendix_Edit />}
						/>
						<Route
							path="Log/:logGuid"
							element={<LgsPurchaseOrderAppendix_Log />}
						/>
						<Route
							path="Log"
							element={<LgsPurchaseOrderAppendix_Log />}
						/>
					</Route>

					{/* // 銷售理貨-新增 LgsSalesOrderPreparing-create*/}
					<Route path={`/Logistics/LgsSalesOrderPreparing`}>
						<Route
							path="Create"
							element={<LgsSalesOrderPreparing_Create />}
						/>
					</Route>

					{/* // 銷售理貨-修改 LgsSalesOrderPreparing-edit*/}
					<Route path={`/Logistics/LgsSalesOrderPreparing`}>
						<Route
							path="Edit"
							element={<LgsSalesOrderPreparing_Edit />}
						/>
					</Route>

					{/* // 銷售出貨-新增 LgsSalesOrderGoodsInvoice-create*/}
					<Route path={`/Logistics/LgsSalesOrderGoodsInvoice`}>
						<Route
							path="Create"
							element={<LgsSalesOrderGoodsInvoice_Create />}
						/>
					</Route>

					{/* // 銷售出貨-修改 LgsSalesOrderGoodsInvoice-edit*/}
					<Route path={`/Logistics/LgsSalesOrderGoodsInvoice`}>
						<Route
							path="Edit"
							element={<LgsSalesOrderGoodsInvoice_Edit />}
						/>
					</Route>

					{/* // 銷售退貨-新增 LgsSalesOrderGoodsReturn-create*/}
					<Route path={`/Logistics/LgsSalesOrderGoodsReturn/create`}>
						<Route
							path=""
							element={<LgsSalesOrderGoodsReturn_Create />}
						/>
					</Route>

					{/* // 銷售退貨-修改 LgsSalesOrderGoodsReturn-edit*/}
					<Route path={`/Logistics/LgsSalesOrderGoodsReturn/edit`}>
						<Route
							path=""
							element={<LgsSalesOrderGoodsReturn_Edit />}
						/>
					</Route>
					
					{/* // 銷售退貨-驗貨單新增 LgsSalesOrderGoodsInspect-create*/}
					<Route path={`/Logistics/LgsSalesOrderGoodsInspect/create`}>
						<Route
							path=""
							element={<LgsSalesOrderGoodsInspect_Create />}
						/>
					</Route>

					{/* // 銷售退貨-驗貨單修改 LgsSalesOrderGoodsInspect-edit*/}
					<Route path={`/Logistics/LgsSalesOrderGoodsInspect/edit`}>
						<Route
							path=""
							element={<LgsSalesOrderGoodsInspect_Edit />}
						/>
					</Route>

					{/* // 銷售訂單 LgsSalesOrder */}
					<Route path={`/Logistics/LgsSalesOrder`}>
						<Route path="" element={<LgsSalesOrder_Index />} />
						<Route
							path="Details/:salesOrderGuid"
							element={<LgsSalesOrder_Details />}
						/>
						<Route
							path="Create"
							element={
								<LgsSalesOrder_CreateAndEdit isEdit={false} />
							}
						/>

						<Route
							path="Edit/:salesOrderGuid"
							element={
								<LgsSalesOrder_CreateAndEdit isEdit={true} />
							}
						/>
					</Route>
					{/* // 銷售商品主檔 LgsSalesKit */}
					<Route path={`/Logistics/LgsSalesKit`}>
						<Route path="" element={<LgsSalesKit_Index />} />
						<Route path="Create" element={<LgsSalesKit_Create />} />
						<Route
							path="Details/:salesKitGuid"
							element={<LgsSalesKit_DetailsAndEdit />}
						/>
						<Route
							path="Edit/:salesKitGuid"
							element={<LgsSalesKit_DetailsAndEdit />}
						/>
						<Route
							path="Log/:logGuid"
							element={<LgsSalesKit_Log />}
						/>
						<Route path="Log" element={<LgsSalesKit_Log />} />
					</Route>
					{/*  銷售商品分類 LgsSalesKitClass */}
					<Route path={`/Logistics/LgsSalesKitClass`}>
						<Route path="" element={<LgsSalesKitClass_Index />} />
						<Route
							path="Create"
							element={<LgsSalesKitClass_Create />}
						/>
						<Route
							path="Log/:logGuid"
							element={<LgsSalesKitClass_Log />}
						/>
						<Route path="Log" element={<LgsSalesKitClass_Log />} />
						<Route
							path="Details/:noRuleGuid"
							element={<LgsSalesKitClass_Details />}
						/>
						<Route
							path="Edit/:guid"
							element={<LgsSalesKitClass_Edit />}
						/>
					</Route>
					{/*  銷售訂單類型 LgsSalesOrderType */}
					<Route path={`/Logistics/LgsSalesOrderType`}>
						<Route path="" element={<LgsSalesOrderType_Index />} />
						<Route
							path="Create"
							element={<LgsSalesOrderType_Create />}
						/>
						<Route
							path="Log/:logGuid"
							element={<LgsPurchaseOrderNoRule_Log />}
						/>
						<Route path="Log" element={<LgsSalesOrderType_Log />} />
						<Route
							path="Details/:salesKitClassGuid"
							element={<LgsSalesOrderType_Details />}
						/>
						<Route
							path="Edit/:guid"
							element={<LgsSalesOrderType_Edit />}
						/>
					</Route>
					{/*  銷售訂單編號規則 LgsSalesOrderNoRule */}
					<Route path={`/Logistics/LgsSalesOrderNoRule`}>
						<Route
							path=""
							element={<LgsSalesOrderNoRule_Index />}
						/>
						<Route
							path="Create"
							element={<LgsSalesOrderNoRule_Create />}
						/>
						<Route
							path="Log/:logGuid"
							element={<LgsSalesOrderNoRule_Log />}
						/>
						<Route
							path="Log"
							element={<LgsSalesOrderNoRule_Log />}
						/>
						<Route
							path="Details/:salesKitClassGuid"
							element={<LgsSalesOrderNoRule_Details />}
						/>
						<Route
							path="Edit/:guid"
							element={<LgsSalesOrderNoRule_Edit />}
						/>
					</Route>
					{/* 銷售訂單付款 LgsSalesOrderPayment */}
					<Route path={`/Logistics/LgsSalesOrderPayment`}>
						<Route path="" element={<LgsSalesOrderPayment />} />
					</Route>
					{/* 銷售付款退回 lgsSalesOrderPaymentReturn */}
					<Route path={`/Logistics/lgsSalesOrderPaymentReturn`}>
						<Route
							path=""
							element={<LgsSalesOrderPaymentReturn />}
						/>
					</Route>
					{/* 銷售訂單付款結清 lgsSalesOrderPaymentClose */}
					<Route path={`/Logistics/lgsSalesOrderPaymentClose`}>
						<Route
							path=""
							element={<LgsSalesOrderPaymentClose />}
						/>
					</Route>
					{/*  服務分類 LgsServiceClass */}
					<Route path={`/Logistics/LgsServiceClass`}>
						<Route path="" element={<LgsServiceClass_Index />} />
						<Route
							path="Create"
							element={<LgsServiceClass_Create />}
						/>
						<Route
							path="Log/:logGuid"
							element={<LgsServiceClass_Log />}
						/>
						<Route path="Log" element={<LgsServiceClass_Log />} />
						<Route
							path="Details/:noRuleGuid"
							element={<LgsServiceClass_Details />}
						/>
						<Route
							path="Edit/:guid"
							element={<LgsServiceClass_Edit />}
						/>
					</Route>
					{/*  服務主檔 LgsServiceMaster */}
					<Route path={`/Logistics/LgsServiceMaster`}>
						<Route path="" element={<LgsServiceMaster_Index />} />
						<Route
							path="Create"
							element={<LgsServiceMaster_Create />}
						/>
						<Route
							path="Log/:logGuid"
							element={<LgsServiceMaster_Log />}
						/>
						<Route path="Log" element={<LgsServiceMaster_Log />} />
						<Route
							path="Details/:noRuleGuid"
							element={<LgsServiceMaster_Details />}
						/>
						<Route
							path="Edit/:guid"
							element={<LgsServiceMaster_Edit />}
						/>
					</Route>
				</Routes>
			</Layout>
		</Context.Provider>
	);
}

export default App;
