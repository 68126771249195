import { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../../assets/images/icn_inputDialog.svg';
import ThreeColLayout from '../../../../../../components/ThreeColLayout/ThreeColLayout';
import { DialogCol } from '../DialogCol';
import { useTranslation } from 'react-i18next';
import StaffModal from '../../../StaffModal';

//#region styled-components
const LabelNormal = styled.label`
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;

const InputElement = styled.input`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    &:disabled {
        background-color: #eff2f5;
    }
`;

const TextareaElement = styled.textarea`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
`;

// -----DialogCol------
const DialogWrapper = styled.div`
    position: relative;
    align-items: stretch;
    width: 100%;
    --bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
    display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.div`
    flex: 1 1 auto;
    border: 1px solid var(--grey2);
    border-radius: 4px 0 0 4px;
    border-right: 0;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    pointer-events: none;
    background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 3px;
    right: 32px;
    width: 24px;
    text-align: center;
    display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
    cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 8px;
    cursor: pointer;
`; // dialog按鈕

//#endregion

const TopInfo = ({ pageData, setPageData, t }) => {
    const updateData = pageData?.updateData;
    const {
        packTime,
        packGuid,
        packCode,
        packName,
        note,
        orderNo,
        customerName,
        orderDate,
        employeeList,
        salesOrderShipment,
        materialCubeList,
        salesOrder,
        salesOrderKitList,
        salesOrderKitItemList,
        salesOrderShipmentItemList,
    } = updateData || {};

    // const { packTime, packGuid, packCode, packName , note} = salesOrderShipment;

    const [isStaffModalOpen, setIsStaffModalOpen] = useState(false);

    const handleInput = (inputName) => {
        const newUpdateData = pageData.updateData;

        // console.log(inputName)

        newUpdateData[inputName] = event.target.value;
        newUpdateData[`${inputName}Warning`] = ''; // 清空錯誤訊息

        // console.log(newUpdateData)

        setPageData({
            ...pageData,
            updateData: {
                ...pageData.updateData,
                ...newUpdateData,
            },
        });
    };

    // console.log(updateData)

    function LeftCol1() {
        //#region Dialog Col

        // DialogCol input 黃色按鈕取消
        const handleCancelClick = () => {
            const newUpdateData = pageData.updateData;
            newUpdateData.employeeGuid = null;
            newUpdateData.employeeCode = null;
            newUpdateData.employeeName = null;

            setPageData({
                ...pageData,
                updateData: {
                    ...pageData.updateData,
                    ...newUpdateData,
                },
            });
        };

        const handleUpdateOutboundStaff = (guid) => {
            const newUpdateData = pageData.updateData;
            // console.log(guid)

            if (guid) {
                const { fullname, employeeCode, employeeGuid } =
                    employeeList.find(
                        (employee) => employee.employeeGuid === guid
                    );
                // console.log( { fullname, employeeCode, employeeGuid })
                newUpdateData.packGuid = employeeGuid;
                newUpdateData.packCode = employeeCode;
                newUpdateData.packName = fullname;
            } else {
                newUpdateData.packGuid = null;
                newUpdateData.packCode = null;
                newUpdateData.packName = null;
            }

            setPageData({
                ...pageData,
                updateData: {
                    ...pageData.updateData,
                    ...newUpdateData,
                },
            });
        };

        //#endregion

        return (
            <>
                <div className="row">
                    <div className="col-lg-12 ">
                        <div className="d-flex flex-column">
                            <LabelNormal htmlFor={`packTime`}>
                                {t('postingDate')}
                            </LabelNormal>
                            <InputElement
                                type="date"
                                id={`packTime`}
                                className="mb-2"
                                defaultValue={packTime?.split('T')[0]}
                                onChange={() => handleInput('packTime')}
                            />

                            <span className="text-danger ms-1 my-1">
                                {pageData?.updateData?.packTimeWarning}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column mb-3">
                    <LabelNormal htmlFor={`pack`}>
                        {t('pack')}
                    </LabelNormal>

                    <DialogWrapper className="row flex-nowrap">
                        <InputDialogElementView
                            style={{
                                minWidth: '85px',
                                cursor: 'auto',
                            }}
                        >
                            {packGuid ? `${packCode} ${packName}` : ''}
                        </InputDialogElementView>
                        <CancelInputBtn
                            isValueExist={packGuid || ''}
                            onClick={() => handleUpdateOutboundStaff(null)}
                        >
                            <Icn_InputYellowCancel />
                        </CancelInputBtn>

                        <DialogBtn
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => setIsStaffModalOpen(true)}
                        >
                            <Icn_InputDialog />
                        </DialogBtn>
                    </DialogWrapper>

                    <span className="text-danger ms-1 mt-1">
                        {pageData?.updateData?.packWarning || ''}
                    </span>
                    {isStaffModalOpen && (
                        <StaffModal
                            t={t}
                            isModalOpen={isStaffModalOpen}
                            setIsModalOpen={setIsStaffModalOpen}
                            listData={employeeList}
                            targetKey="employeeGuid"
                            targetValue={packGuid}
                            handleSubmit={handleUpdateOutboundStaff}
                        />
                    )}
                </div>
            </>
        );
    }

    function CenterCol1() {
        return (
            <>
                <div className={`wrapper d-flex flex-column mb-3`}>
                    <LabelNormal htmlFor={'note'}>
                        {t('lgsPurchaseOrderGoodsInvoice:note2')}
                    </LabelNormal>
                    <TextareaElement
                        //style={elementStyle}
                        id={'note'}
                        rows={'4'}
                        cols={'30'}
                        defaultValue={note}
                        onBlur={() => handleInput('note')}
                        disabled={false}
                    />
                </div>
            </>
        );
    }

    function RightCol1() {
        return (
            <>
                <div className="d-flex flex-column">
                    <LabelNormal htmlFor={`vendor`}>{'客戶'}</LabelNormal>

                    <InputElement
                        type="text"
                        id={`vendor`}
                        className="mb-3"
                        disabled
                        defaultValue={customerName}
                    />
                </div>

                <div className="row">
                    <div className="col-lg-7">
                        <div className="d-flex flex-column">
                            <LabelNormal htmlFor={`orderNo`}>
                                {'銷售訂單編號'}
                            </LabelNormal>
                            <InputElement
                                type="text"
                                id={`orderNo`}
                                className="mb-3"
                                disabled
                                defaultValue={orderNo}
                            />
                        </div>
                    </div>

                    <div className="col-lg-5 ps-0">
                        <div className="d-flex flex-column">
                            <LabelNormal htmlFor={`orderDate`}>
                                {'銷售日期'}
                            </LabelNormal>
                            <InputElement
                                type="date"
                                id={`orderDate`}
                                className="mb-3"
                                disabled
                                defaultValue={orderDate?.split('T')[0]}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <ThreeColLayout
            LeftCol={<LeftCol1 />}
            CenterCol={<CenterCol1 />}
            RightCol={<RightCol1 />}
        />
    );
};

export default TopInfo;
