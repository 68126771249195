import React, { useState } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { ReactComponent as Icn_chevronTop } from '../../../../../assets/images/icn_chevron-top.svg';
import { ReactComponent as Icn_Trash } from '../../../../../assets/images/icn_Trash.svg';
import { useTranslation } from 'react-i18next';
import showAlert from '../../../../../utils/swal';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const TR = styled.tr`
	height: 40px;

	&:hover {
		background-color: transparent !important;
	}
	&:hover:nth-of-type(0) {
		background-color: var(--primaryllt) !important;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: none !important;
	background-color: ${(prop) =>
		prop.grandPaOutside ? '#EEF4FD !important' : '#f7f8fc !important'};
	text-align: left !important;
	font-weight: bold !important;

	&:nth-of-type(1) {
		width: 48px;
	}

	&:nth-of-type(2) {
		/* width: 96px; */
	}

	&:nth-of-type(3) {
		/* width: 336px; */
		padding: 0 !important;
		/* padding-right: 56px !important; */
	}
	&:nth-of-type(6) {
		text-align: right !important;
	}
`;

const ChildTD = styled.td`
	height: 40px;
	text-align: center;

	&:first-of-type {
		width: 144px;
	}
`;

const OrderItemImg = styled.img`
	max-width: 96px;
	max-height: 88px;
	object-fit: cover;
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;

//#endregion

function Page1_Item(props) {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsSalesOrder']);

	// prop
	const {
		dialogDataFromBE,
		parentData,
		newSalesOrderData,
		setNewSalesOrderData,
		localDecimalPlaceTl,
		localDecimalPlaceUp,
		convertToFloat,
		reCalculateTopInfo,
	} = props;

	const { outputTaxRate, vendorGuid, orderDate, currencyCode } =
		parentData.page0;

	console.log('newSalesOrderData', newSalesOrderData);

	// axios
	const { sendRequest: axiosGetVendorPrice } = useAxios(); // call api 計算 價格差異

	//#endregion

	//#region handle input change
	const handleInputChange = (keyName, rowIndex, value, decimalPlace) => {
		// console.log(keyName, rowIndex, value, decimalPlace);

		// 1.更新newSalesOrderData
		const newData = [...newSalesOrderData];

		if (decimalPlace) {
			// 如果此input有decimalPlace限制
			const regex = new RegExp(`^(\\d+(\\.\\d{0,${decimalPlace}})?|)$`); // 只允許數字，並且小數點後最多decimalPlace位
			if (regex.test(value)) newData[rowIndex][keyName] = value;
			newData[rowIndex][`${keyName}Warning`] = '';
			setNewSalesOrderData(newData);
		} else {
			// 一般的input
			newData[rowIndex][keyName] = value; // input值
			newData[rowIndex][`${keyName}Warning`] = ''; // 清空錯誤訊息
			setNewSalesOrderData(newData);
		}

		// 2.重新計算topInfo數值
		reCalculateTopInfo(newData);
	};

	// 當改變input的內容時更新父層action
	const handleUpdateAction = (action) => {
		return action === 'origin' || action === 'update' ? 'update' : 'insert'; // 更新子項目時，同步只更新父層的action
	};

	const handleQuantityChange = (rowIndex, quantity) => {
		// 0.限制使用者輸入的數字不能為0且不能為文字
		if (!/^\d+$/.test(quantity) || quantity === '0') {
			quantity = 1;
		}

		const newData = [...newSalesOrderData];
		console.log(newData);

		// 1 判斷是否有符合的price
		const priceData =
			newData[rowIndex]['priceData'].salesKitPriceList.length !== 0
				? newData[rowIndex]['priceData'].salesKitPriceList
				: newData[rowIndex]['priceData'].salesKitSetPriceList.length !==
				  0
				? newData[rowIndex]['priceData'].salesKitSetPriceList
				: [{}];

		console.log(priceData);

		const findPriceByQuantity = (quantity, priceList) => {
			// 使用 find 函數尋找符合條件的物件
			const foundPrice = priceList.find((item) => {
				return (
					quantity >= item.quantityFrom && quantity <= item.quantityTo
				);
			});

			// 如果找到符合條件的物件，返回該物件，否則返回 null
			return foundPrice || null;
		};

		const foundPriceInfo = findPriceByQuantity(quantity, priceData);
		console.log('foundPriceInfo', foundPriceInfo);

		if (foundPriceInfo) {
			const prevQuantity =
				newData[rowIndex]['kitSetMainInfo']['quantity']; // 上一狀態數量
			const newUnitPrice =
				(newData[rowIndex]['kitSetMainInfo']['newUnitPrice'] /
					prevQuantity) *
				quantity;
			newData[rowIndex]['kitSetMainInfo']['newUnitPrice'] = newUnitPrice; // 更新組合總價

			newData[rowIndex]['kitSetItemInfoList'].forEach((el) => {
				el.newQuantity =
					((el.newQuantity * quantity) / prevQuantity) * 1; // 更新品項數量
				el.netAmount = (newUnitPrice * el.percentage) / 100; // 更新品項總價
				el.netPrice = el.netAmount / el.newQuantity; // 更新品項單價
			});

			newData[rowIndex]['kitSetMainInfo']['quantity'] = quantity * 1; // 更新組合數量

			newData[rowIndex]['kitSetMainInfo']['action'] = handleUpdateAction(
				newData[rowIndex]['kitSetMainInfo']['action']
			); // 更新action

			console.log('更新後newSalesOrderData', newData);
		} else {
			showAlert({
				html: `<div class="mt-4 text-danger fw-bold">商品未設定符合此數量的價格</div>`,
				htmlSvg: 'danger',
				size: 'small',
				confirmButtonText: t('button.confirmButton'),
				showCloseButton: false,
				allowOutsideClick: true,
			});
		}

		// 更新newSalesOrderData
		setNewSalesOrderData(newData);

		// 重新計算topInfo數值
		reCalculateTopInfo(newData);

		// 清空錯誤訊息
		newData[rowIndex]['kitSetMainInfo'][`quantityWarning`] = '';
	};

	const handleUnitPriceChange = (rowIndex, newUnitPrice) => {
		// 1.更新newSalesOrderData
		const newData = [...newSalesOrderData];

		// 1.1 更新kitSetMainInfo的unitPrice
		newData[rowIndex]['kitSetMainInfo']['newUnitPrice'] = newUnitPrice;

		newData[rowIndex]['kitSetItemInfoList'].forEach((el) => {
			el.netAmount = (newUnitPrice * 1 * el.percentage) / 100;
			el.netPrice = el.netAmount / el.newQuantity;
		});

		newData[rowIndex]['kitSetMainInfo']['action'] = handleUpdateAction(
			newData[rowIndex]['kitSetMainInfo']['action']
		); // 更新action

		setNewSalesOrderData(newData);

		// 2.重新計算topInfo數值
		reCalculateTopInfo(newData);

		// 3.清空錯誤訊息
		newData[rowIndex]['kitSetMainInfo'][`quantityWarning`] = '';
	};

	const handleDeliverDateKitSetChange = (rowIndex, deliverDateKitSet) => {
		// 1.更新newSalesOrderData
		const newData = [...newSalesOrderData];

		newData[rowIndex]['kitSetMainInfo']['deliverDateKitSet'] =
			deliverDateKitSet;
		newData[rowIndex]['kitSetMainInfo'][`deliverDateKitSetWarning`] = ''; // 清空錯誤訊息

		newData[rowIndex]['kitSetMainInfo']['action'] = handleUpdateAction(
			newData[rowIndex]['kitSetMainInfo']['action']
		); // 更新action

		newData[rowIndex]['kitSetItemInfoList'].forEach((el) => {
			el.action = el.action === 'origin' ? 'update' : 'insert';
			el.deliverDate = deliverDateKitSet;
			el[`deliverDateWarning`] = ''; // 清空錯誤訊息
		}); // 同步item 的 deliverDate

		setNewSalesOrderData(newData);
	};

	const handleDeliverDateChange = (
		kitSetIndex,
		kitSetItemIndex,
		deliverDate
	) => {
		// 1.更新newSalesOrderData
		const newData = [...newSalesOrderData];

		newData[kitSetIndex]['kitSetItemInfoList'].forEach((el, index) => {
			if (index === kitSetItemIndex) {
				el.deliverDate = deliverDate;
				el[`deliverDateWarning`] = '';
			} // 清空錯誤訊息
		});

		newData[kitSetIndex]['kitSetMainInfo']['action'] = handleUpdateAction(
			newData[kitSetIndex]['kitSetMainInfo']['action']
		); // 更新action

		setNewSalesOrderData(newData);
	};

	const handleNoteChange = (kitSetIndex, kitSetItemIndex, note) => {
		// 1.更新newSalesOrderData
		const newData = [...newSalesOrderData];

		newData[kitSetIndex]['kitSetItemInfoList'].forEach((el, index) => {
			if (index === kitSetItemIndex) el.note = note;
		});

		newData[kitSetIndex]['kitSetMainInfo']['action'] = handleUpdateAction(
			newData[kitSetIndex]['kitSetMainInfo']['action']
		); // 更新action

		setNewSalesOrderData(newData);
	};

	//#endregion

	//#region handle delete card
	const handleDeleteChange = (rowIndex) => {
		// 1.更新newSalesOrderData
		const newData = [...newSalesOrderData];
		const action = newData[rowIndex]['kitSetMainInfo']['action'];

		if (action === 'update' || action === 'origin') {
			newData[rowIndex]['kitSetMainInfo']['action'] = 'delete';
		} else if (action === 'insert') {
			newData.splice(rowIndex, 1);
			console.log('刪除後newSalesOrderData', newData);
		}

		setNewSalesOrderData(newData);
		// 2.重新計算topInfo數值
		reCalculateTopInfo(newData);
	};

	//#endregion

	//#region Dialog Two Col
	const [isOpen, setIsOpen] = useState(false);
	const [indexDialogTwoCol, setIndexDialogTwoCol] = useState('');

	// DialogTwoCol input 黃色按鈕取消
	const handleCancelClick = (index) => {
		// 更新newSalesOrderData
		const newData = [...newSalesOrderData];
		newData[index]['uomCode'] = '';
		newData[index]['uomName01'] = '';
		newData[index]['uomGuid'] = '';
		setNewSalesOrderData(newData);
	};

	// DialogTwoCol Modal open
	const handleOpen = (index) => {
		setIsOpen(true);
		setIndexDialogTwoCol(index); // 獲取真實的index，傳給DialogTwoCol
	};

	//#endregion

	//#region 計算 "價格差異"
	const handleKitSetItemQuantityBlur = async (
		e,
		materialGuid,
		index,
		netPrice
	) => {
		try {
			const url = `api/Logistics/LgsSalesOrder/getMaterialMasterVendorPrice?MaterialGuid=${materialGuid}&VendorGuid=${vendorGuid}&OrderDate=${orderDate}&Quantity=${e.target.value}&CurrencyCode=${currencyCode}`;

			axiosGetVendorPrice({ url }, (res) => {
				console.log('materialMasterVendorPrice', res);

				handleInputChange(`materialMasterVendorPrice`, index, res);

				// 假使先填"單價"再填"採購數量"，更新價格差異
				if (res !== 0) {
					netPrice &&
						handleInputChange(
							`diffPrice`,
							index,
							((netPrice - res) / res) * 100
						);
				}
			});
		} catch (error) {
			console.error(error);
		}
	};

	//#endregion

	const handleGrandPaArrowBtn = () => {
		const trFatherElements = Array.from(
			event.target.closest('tbody').querySelectorAll('tr.list-item')
		);
		trFatherElements.shift();

		const trElements = event.target
			.closest('tbody')
			.querySelectorAll('tr:not(.list-item)');

		let svg =
			event.target.closest('svg') || event.target.querySelector('svg');

		// console.log(trFatherElements);
		// console.log(trElements);
		// console.log(event.target.closest('svg').style.transform);

		// 當改變父層方向時，同步改變子層方向
		trFatherElements.forEach((tr) => {
			console.log(tr.querySelector('td svg'));
			console.log(svg.style.transform);

			svg.style.transform === ''
				? (tr.querySelector('td svg').style.transform = 'rotate(1turn)')
				: (tr.querySelector('td svg').style.transform =
						'rotate(0.5turn)');
		});

		// 將找到的 tr 元素添加 'd-none' 的 className
		trElements.forEach((tr) => {
			console.log(tr);

			if (svg) {
				svg.style.transform === ''
					? tr.classList.remove('d-none')
					: tr.classList.add('d-none');
			}
		});
	};

	let cardDisplayNum = 0;

	return (
		<>
			{newSalesOrderData
				// .filter((el) => el.kitSetMainInfo.action !== 'delete')
				?.map((allList, kitSetIndex) => {
					allList.kitSetMainInfo.action !== 'delete' &&
						cardDisplayNum++;

					return (
						<div
							className={`position-relative rounded-xxl ${
								kitSetIndex !== 0 ? 'mt-4' : ''
							} ${
								allList.kitSetMainInfo.action === 'delete' &&
								'd-none'
							}`}
							style={{
								backgroundColor: '#EEF4FD',
								border: '1px solid var(--grey1)',
							}}
							key={`"allList"${kitSetIndex}`}
						>
							<IndexTableContainer className="d-flex flex-column p-2 pb-4 position-relative">
								<IndexTable className="table SalesOrderCardWrapper">
									<TBody>
										{/* 阿公層 */}
										<TR className="list-item table-item">
											<TD grandPaOutside={true}>
												{cardDisplayNum}
											</TD>
											<TD
												grandPaOutside={true}
												className={`text-left p-2 text-center`}
												colSpan={2}
											>
												{
													allList
														.kitSetItemInfoList[0]
														.cardName
												}
											</TD>
											<TD
												grandPaOutside={true}
												className={`text-left p-2 text-center`}
											>
												<div
													className={`d-flex align-items-center justify-content-between`}
												>
													<div>
														{
															allList
																.kitSetMainInfo
																.specSetName
														}
													</div>

													<div className="d-flex justify-content-end align-items-center">
														<div>
															<LabelNormal
																htmlFor={`deliverDateKitSet${kitSetIndex}`}
																className={`me-2`}
															>
																{t(
																	'lgsSalesOrder:deliveryDate2'
																)}
															</LabelNormal>
															<InputElement
																style={{
																	maxWidth:
																		'115px',
																	maxHeight:
																		'32px',
																}}
																className="flex-fill"
																type="date"
																id={`deliverDateKitSet${kitSetIndex}`}
																value={
																	allList
																		.kitSetMainInfo
																		.deliverDateKitSet
																}
																onChange={(
																	e
																) => {
																	handleDeliverDateKitSetChange(
																		kitSetIndex,
																		e.target
																			.value
																	);
																}}
															/>
															<span className="d-block text-danger ms-1 mt-1">
																{
																	allList
																		.kitSetMainInfo
																		.deliverDateKitSetWarning
																}
															</span>
														</div>
													</div>
												</div>
											</TD>

											<TD
												grandPaOutside={true}
												className={`px-0`}
											>
												<div className="text-end">
													<LabelNormal
														htmlFor={`kitSetQuantity${kitSetIndex}`}
														className="me-2"
													>
														{t(
															'lgsSalesOrder:quantity'
														)}
													</LabelNormal>
													<InputElement
														style={{
															maxWidth: '60px',
														}}
														className="flex-fill"
														type="number"
														min="1"
														id={`kitSetQuantity${kitSetIndex}`}
														value={
															allList
																.kitSetMainInfo
																.quantity
														}
														onChange={(e) => {
															handleQuantityChange(
																kitSetIndex,
																e.target.value
															);

															// handleInputChange(
															// 	`kitSetQuantity`,
															// 	kitSetIndex,
															// 	e.target.value
															// );
															// // TODO: netPrice改為set總價
															// handleInputChange(
															// 	`netAmount`,
															// 	kitSetIndex,
															// 	convertToFloat(
															// 		allList
															// 			.kitSetItemInfoList
															// 			.netPrice *
															// 			allList
															// 				.kitSetItemInfoList
															// 				.percentage *
															// 			e.target
															// 				.value,
															// 		localDecimalPlaceTl
															// 	),
															// 	localDecimalPlaceTl
															// );
															// // TODO: netPrice改為set總價
															// handleInputChange(
															// 	`taxAmount`,
															// 	kitSetIndex,
															// 	convertToFloat(
															// 		(allList
															// 			.kitSetItemInfoList
															// 			.netPrice *
															// 			allList
															// 				.kitSetItemInfoList
															// 				.percentage *
															// 			e.target
															// 				.value *
															// 			outputTaxRate) /
															// 			100,
															// 		localDecimalPlaceTl
															// 	),
															// 	localDecimalPlaceTl
															// );
														}}
														// onBlur={(e) =>
														// 	handleKitSetQuantityBlur(
														// 		e,
														// 		allList.kitSetItemInfoList.materialGuid,
														// 		kitSetIndex,
														// 		allList.kitSetItemInfoList.netPrice
														// 	)
														// }
													/>
													<span className="text-danger ms-1 mt-1">
														{
															allList
																.kitSetItemInfoList
																.quantityWarning
														}
													</span>
												</div>
											</TD>
											{/* <TD grandPaOutside={true}></TD> */}

											<TD
												grandPaOutside={true}
												className="d-flex align-item-center justify-content-end"
											>
												<div className="d-flex align-items-center ps-3 me-auto">
													<div>
														<LabelNormal
															htmlFor={`netAmount${kitSetIndex}`}
															className={`me-2`}
														>
															{t(
																'lgsSalesOrder:amountExcludingTax'
															)}
														</LabelNormal>
														<InputElement
															style={{
																maxWidth:
																	'120px',
																maxHeight:
																	'32px',
															}}
															className="flex-fill"
															type="number"
															id={`netAmount${kitSetIndex}`}
															value={
																allList
																	.kitSetMainInfo
																	.newUnitPrice
															}
															onChange={(e) => {
																handleUnitPriceChange(
																	kitSetIndex,
																	e.target
																		.value
																);

																// handleInputChange(
																// 	`netAmount`,
																// 	kitSetIndex,
																// 	e.target.value,
																// 	localDecimalPlaceTl
																// );
																// handleInputChange(
																// 	`netPrice`,
																// 	kitSetIndex,
																// 	convertToFloat(
																// 		e.target
																// 			.value /
																// 			allList
																// 				.kitSetItemInfoList
																// 				.quantitySales,
																// 		localDecimalPlaceTl
																// 	),
																// 	localDecimalPlaceUp
																// );
																// handleInputChange(
																// 	`taxAmount`,
																// 	kitSetIndex,
																// 	convertToFloat(
																// 		(e.target
																// 			.value *
																// 			outputTaxRate) /
																// 			100,
																// 		localDecimalPlaceTl
																// 	),
																// 	localDecimalPlaceTl
																// );
															}}
															// onBlur={
															// 	// 假如是物料，並且單價改值，計算價格差異
															// 	(e) => {
															// 		allList.kitSetItemInfoList.materialMasterVendorPrice &&
															// 			handleInputChange(
															// 				`diffPrice`,
															// 				kitSetIndex,
															// 				((allList.kitSetItemInfoList.netPrice -
															// 					allList.kitSetItemInfoList.materialMasterVendorPrice) /
															// 					allList.kitSetItemInfoList.materialMasterVendorPrice) *
															// 					100
															// 			);
															// 	}
															// }
														/>
														<span className="text-danger ms-1 mt-1">
															{
																allList
																	.kitSetItemInfoList
																	.netAmountWarning
															}
														</span>
													</div>
													{/* <span className="flex-grow-1 collapsedItem">
													{
														allList
															.kitSetPriceList[0]
															.unitPrice
													}
												</span>
												<span className="flex-grow-1 collapsedItem">
													{
														allList
															.kitSetPriceList[0]
															.unitPrice
													}
												</span> */}
												</div>

												{/* 刪除垃圾桶 */}
												<div
													className="d-inline-block ps-2"
													style={{
														cursor: 'pointer',
													}}
													onClick={(e) =>
														handleDeleteChange(
															kitSetIndex,
															e
														)
													}
												>
													<Icn_Trash
														style={{
															marginTop: '14px',
														}}
													/>
												</div>

												{/* 展開按鈕 */}
												<div
													className={`d-inline-block text-left p-2 ps-0 text-center`}
													style={{
														cursor: 'pointer',
													}}
													onClick={(e) => {
														// icon箭頭方向
														// console.log(
														// 	e.target.closest(
														// 		'svg'
														// 	) ||
														// 		e.target.querySelector(
														// 			'svg'
														// 		)
														// );

														let svg =
															e.target.closest(
																'svg'
															) ||
															e.target.querySelector(
																'svg'
															);

														if (svg) {
															svg.style
																.transform ===
															''
																? (svg.style.transform =
																		'rotate(.5turn)')
																: (svg.style.transform =
																		'');
														}

														handleGrandPaArrowBtn();
													}}
												>
													<Icn_chevronTop className="iconChevron" />
												</div>
											</TD>
										</TR>

										{/* 品項 */}
										{allList?.kitSetItemInfoList?.map(
											(data, index) => {
												return (
													<React.Fragment
														key={`"salesOrderDataItem"${index}`}
													>
														{/* 父層 */}
														<TR className="list-item table-item">
															<TD className="ps-4">
																{index + 1}
															</TD>
															<TD
																className={`text-left p-2 text-center`}
															>
																{/* 資料顯示轉換 -型態  */}
																{data.salesCategoryCode ===
																'P'
																	? t(
																			'lgsSalesOrder:material'
																	  )
																	: data.salesCategoryCode ===
																	  'S'
																	? t(
																			'lgsSalesOrder:service'
																	  )
																	: data.salesCategoryCode}
															</TD>

															<TD
																className={`text-left p-2 text-center`}
															>
																{
																	data.cardChildName
																}
															</TD>

															<TD
																className={`px-0`}
															>
																<div className="d-flex">
																	<span className="flex-grow-1 collapsedItem">
																		{
																			data.quantitySales
																		}
																	</span>
																	<span className="flex-grow-1 collapsedItem">
																		{
																			data.uomName01
																		}
																	</span>
																</div>
															</TD>
															<TD></TD>

															<TD className="text-end pe-4">
																<span className="me-5 collapsedItem">
																	{
																		data.netAmount
																	}
																</span>

																{/* 刪除垃圾桶 */}
																{/* <div
																className="d-inline-block"
																style={{
																	cursor: 'pointer',
																}}
																onClick={(e) =>
																	handleDeleteChange(
																		index,
																		e
																	)
																}
															>
																<Icn_Trash />
															</div> */}

																{/* 展開按鈕 */}
																<div
																	className={`d-inline-block text-left p-2 text-center`}
																	style={{
																		cursor: 'pointer',
																	}}
																	onClick={(
																		e
																	) => {
																		// console.log(
																		// 	e.target.closest(
																		// 		'svg'
																		// 	)
																		// );

																		let svg =
																			e.target.closest(
																				'svg'
																			) ||
																			e.target.querySelector(
																				'svg'
																			);

																		if (
																			svg
																		) {
																			// icon箭頭方向
																			svg
																				.style
																				.transform ===
																			''
																				? (svg.style.transform =
																						'rotate(.5turn)')
																				: (svg.style.transform =
																						'');
																		}

																		// 是否顯示子層
																		e.target
																			.closest(
																				'tr'
																			)
																			.nextElementSibling.classList.contains(
																				'd-none'
																			)
																			? e.target
																					.closest(
																						'tr'
																					)
																					.nextElementSibling.classList.remove(
																						'd-none'
																					) &&
																			  alert(
																					1
																			  )
																			: e.target
																					.closest(
																						'tr'
																					)
																					.nextElementSibling.classList.add(
																						'd-none'
																					);

																		if (
																			e.target.closest(
																				'svg'
																			)
																		) {
																			e.target.closest(
																				'svg'
																			).style.transform =
																				'rotate("0.5turn")';
																		}
																	}}
																>
																	<Icn_chevronTop className="iconChevron" />
																</div>
															</TD>
														</TR>

														{/* 子層 */}
														<tr
															// 1.第一個預設開啟
															// className={`${index !== 0 && 'd-none'} childInfoTr rounded-xxl`}
															// 2.全部預設開啟
															className={`childInfoTr rounded-xxl`}
														>
															{data.length !==
																0 && (
																<>
																	<ChildTD
																		colSpan={
																			2
																		}
																		className="p-0 ps-3 border-0"
																		style={{
																			backgroundColor:
																				'#F7F8FC',
																		}}
																	>
																		<div
																			className="h-100 p-4"
																			style={{
																				backgroundColor:
																					'white',
																			}}
																		>
																			<OrderItemImg
																				className="rounded-xxl"
																				src={
																					location.origin +
																					(data.materialAppendixUrl ||
																						data.serviceAppendixUrl)
																				}
																				alt="OrderItemImg"
																			/>
																		</div>
																	</ChildTD>
																	<ChildTD className="ps-0 py-0 border-0">
																		<div className="py-4 text-align-left text-grey4">
																			<span>
																				{
																					data.cardChildName
																				}
																			</span>
																			<br />

																			{data?.cardChildName2 ? (
																				<span>
																					{
																						data?.cardChildName2
																					}
																				</span>
																			) : (
																				<span className="invisible">
																					123
																				</span>
																			)}
																		</div>
																		<div className="pt-2 pb-4 pe-8 text-align-left">
																			<div
																				className={`wrapper d-flex flex-column "mb-3`}
																			>
																				<TextareaElement
																					style={{
																						minHeight:
																							'0px',
																					}}
																					id={`note${index}`}
																					rows={
																						2
																					}
																					value={
																						data.note ||
																						''
																					}
																					onChange={(
																						e
																					) => {
																						handleNoteChange(
																							kitSetIndex,
																							index,
																							e
																								.target
																								.value
																						);
																						// handleInputChange(
																						// 	`note`,
																						// 	index,
																						// 	e
																						// 		.target
																						// 		.value
																						// );
																					}}
																				/>
																			</div>
																		</div>
																	</ChildTD>

																	<ChildTD className="ps-0 pe-6 py-0 border-0">
																		<div className="pt-4 d-flex text-align-left text-grey4">
																			<div
																				className={`wrapper row flex-column mb-3 pe-5 flex-fill`}
																			>
																				<LabelNormal
																					htmlFor={`kitSetItemQuantity${index}`}
																				>
																					{t(
																						'lgsSalesOrder:salesQuantity'
																					)}
																				</LabelNormal>
																				<InputElement
																					style={{
																						minWidth:
																							'120px',
																					}}
																					className="flex-fill"
																					type="number"
																					min="1"
																					id={`kitSetItemQuantity${index}`}
																					disabled
																					value={
																						data.newQuantity
																					}
																					onChange={(
																						e
																					) => {
																						handleInputChange(
																							`kitSetItemQuantity`,
																							index,
																							e
																								.target
																								.value
																						);
																						// TODO: netPrice改為set總價
																						handleInputChange(
																							`netAmount`,
																							index,
																							convertToFloat(
																								data.netPrice *
																									data.percentage *
																									e
																										.target
																										.value,
																								localDecimalPlaceTl
																							),
																							localDecimalPlaceTl
																						);
																						// TODO: netPrice改為set總價
																						handleInputChange(
																							`taxAmount`,
																							index,
																							convertToFloat(
																								(data.netPrice *
																									data.percentage *
																									e
																										.target
																										.value *
																									outputTaxRate) /
																									100,
																								localDecimalPlaceTl
																							),
																							localDecimalPlaceTl
																						);
																					}}
																					// onBlur={(e) =>
																					// 	handleKitSetItemQuantityBlur(
																					// 		e,
																					// 		data.materialGuid,
																					// 		index,
																					// 		data.netPrice
																					// 	)
																					// }
																				/>
																				<span className="text-danger ms-1 mt-1">
																					{
																						data.kitSetItemQuantityWarning
																					}
																				</span>
																			</div>

																			<div
																				className={`wrapper d-flex flex-column mb-3 flex-fill`}
																			>
																				<LabelNormal
																					htmlFor={`deliverDate${index}`}
																				>
																					{t(
																						'lgsSalesOrder:deliveryDate'
																					)}
																				</LabelNormal>
																				<InputElement
																					style={{
																						minWidth:
																							'135px',
																					}}
																					className="flex-fill"
																					type="date"
																					id={`deliverDate${index}`}
																					value={
																						data.deliverDate
																					}
																					onChange={(
																						e
																					) => {
																						handleDeliverDateChange(
																							kitSetIndex,
																							index,
																							e
																								.target
																								.value
																						);

																						// handleInputChange(
																						// 	`deliverDate`,
																						// 	index,
																						// 	e
																						// 		.target
																						// 		.value
																						// );
																					}}
																				/>
																				<span className="text-danger ms-1 mt-1">
																					{
																						data.deliverDateWarning
																					}
																				</span>
																			</div>
																		</div>
																	</ChildTD>

																	<ChildTD
																		colSpan="2"
																		className="ps-0 pe-3 py-0 border-0"
																		style={{
																			backgroundColor:
																				'#F7F8FC',
																		}}
																	>
																		<div
																			className="h-100 pt-4 d-flex text-align-left text-grey4"
																			style={{
																				backgroundColor:
																					'white',
																			}}
																		>
																			<div
																				className={`d-flex flex-column pe-4 mb-3`}
																			>
																				<LabelNormal
																					htmlFor={`netPrice${index}`}
																				>
																					{t(
																						'lgsSalesOrder:unitPriceExcludingTax'
																					)}
																				</LabelNormal>
																				<InputElement
																					style={{
																						minWidth:
																							'120px',
																						maxHeight:
																							'32px',
																					}}
																					className="flex-fill"
																					type="number"
																					id={`netPrice${index}`}
																					disabled
																					value={
																						data.netPrice
																					}
																					// onChange={(
																					// 	e
																					// ) => {
																					// 	handleInputChange(
																					// 		`netPrice`,
																					// 		index,
																					// 		e
																					// 			.target
																					// 			.value,
																					// 		localDecimalPlaceUp
																					// 	);
																					// 	handleInputChange(
																					// 		`netAmount`,
																					// 		index,
																					// 		convertToFloat(
																					// 			e
																					// 				.target
																					// 				.value *
																					// 				data.quantitySales,
																					// 			localDecimalPlaceTl
																					// 		),
																					// 		localDecimalPlaceTl
																					// 	);
																					// 	handleInputChange(
																					// 		`taxAmount`,
																					// 		index,
																					// 		convertToFloat(
																					// 			(e
																					// 				.target
																					// 				.value *
																					// 				data.quantitySales *
																					// 				outputTaxRate) /
																					// 				100,
																					// 			localDecimalPlaceTl
																					// 		),
																					// 		localDecimalPlaceTl
																					// 	);
																					// }}
																					// onBlur={
																					// 	// 假如是物料，並且單價改值，計算價格差異
																					// 	(e) => {
																					// 		data.materialMasterVendorPrice &&
																					// 			handleInputChange(
																					// 				`diffPrice`,
																					// 				index,
																					// 				(
																					// 					((e
																					// 						.target
																					// 						.value -
																					// 						data.materialMasterVendorPrice) /
																					// 						data.materialMasterVendorPrice) *
																					// 					100
																					// 				).toFixed(
																					// 					localDecimalPlaceUp
																					// 				)
																					// 			);
																					// 	}
																					// }
																				/>
																				<span className="text-danger ms-1 mt-1">
																					{
																						data.netPriceWarning
																					}
																				</span>
																			</div>

																			<div
																				className={`d-flex flex-column pe-3 mb-3`}
																			>
																				<LabelNormal
																					htmlFor={`netAmount${index}`}
																				>
																					{t(
																						'lgsSalesOrder:amountExcludingTax'
																					)}
																				</LabelNormal>
																				<InputElement
																					style={{
																						minWidth:
																							'120px',
																						maxHeight:
																							'32px',
																					}}
																					className="flex-fill"
																					type="number"
																					id={`netAmount${index}`}
																					disabled
																					value={
																						data.netAmount
																					}
																					// onChange={(
																					// 	e
																					// ) => {
																					// 	handleInputChange(
																					// 		`netAmount`,
																					// 		index,
																					// 		e
																					// 			.target
																					// 			.value,
																					// 		localDecimalPlaceTl
																					// 	);
																					// 	handleInputChange(
																					// 		`netPrice`,
																					// 		index,
																					// 		convertToFloat(
																					// 			e
																					// 				.target
																					// 				.value /
																					// 				data.quantitySales,
																					// 			localDecimalPlaceTl
																					// 		),
																					// 		localDecimalPlaceUp
																					// 	);
																					// 	handleInputChange(
																					// 		`taxAmount`,
																					// 		index,
																					// 		convertToFloat(
																					// 			(e
																					// 				.target
																					// 				.value *
																					// 				outputTaxRate) /
																					// 				100,
																					// 			localDecimalPlaceTl
																					// 		),
																					// 		localDecimalPlaceTl
																					// 	);
																					// }}
																					// onBlur={
																					// 	// 假如是物料，並且單價改值，計算價格差異
																					// 	(e) => {
																					// 		data.materialMasterVendorPrice &&
																					// 			handleInputChange(
																					// 				`diffPrice`,
																					// 				index,
																					// 				((data.netPrice -
																					// 					data.materialMasterVendorPrice) /
																					// 					data.materialMasterVendorPrice) *
																					// 					100
																					// 			);
																					// 	}
																					// }
																				/>
																				<span className="text-danger ms-1 mt-1">
																					{
																						data.netAmountWarning
																					}
																				</span>
																			</div>
																		</div>
																	</ChildTD>
																</>
															)}
														</tr>
													</React.Fragment>
												);
											}
										)}
										<tr>
											<td
												colSpan={6}
												style={{
													backgroundColor: '#F7F8FC',
													height: '16px',
													border: 'none',
												}}
											></td>
										</tr>
									</TBody>
								</IndexTable>
							</IndexTableContainer>
						</div>
					);
				})}
		</>
	);
}

export default Page1_Item;
