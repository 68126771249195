import dayjs from 'dayjs';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Icn_deleted } from '../../../assets/images/icn_DeleteCross.svg';
import { BtnNormal } from '../../../components/Buttons/BtnNormal';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
    margin-bottom: 0;
    color: var(--grey4);
    background-color: #fff;
`;

const THead = styled.thead`
    height: 40px;
`;

const TR = styled.tr`
    height: 40px;
    /* &:hover {
        cursor: ${(hoverEffect) =>
        hoverEffect === 'true' ? 'pointer' : 'default'};
        background-color: ${(hoverEffect) =>
        hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
    } */
    /* td:last-child {
        display: none !important;
    }

    &:hover {
        td:last-child {
            display: block !important;
        }
    } */
`;

const TH = styled.th`
    vertical-align: middle;
    border: 1px solid var(--grey1) !important;
    /* &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-right: none !important;
    } */
`;

const NextToLast = styled(TH)`
    border-right: none !important;
`;

const LastTH = styled(TH)`
    border-left: none !important;
`;

const TBody = styled.tbody``;

const TD = styled.td`
    cursor: auto;
    border: 1px solid var(--grey1) !important;

    /* text-align: ${(prop) => prop.align}; */

    /* &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-left: none !important;
        border-right: none !important;
    }

    background-color: ${(prop) =>
        prop.active ? '#fbfcfd !important' : '#fff'};

    position: relative;
    padding: 0; */
`;

const TD_Input = styled(TD)`
    &&& {
        background-color: #f7f8fb;
    }
`;

const TFoot = styled.tfoot`
    border: none;
`;

const FootTR = styled.tr`
    border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
    border: none !important;
`;

const Container = styled.div`
    display: flex;
    justify-content: start;
    margin: 16px 0;
`;

const Cell = styled.div`
    border: ${(prop) => (prop.active ? '1px solid #e3e8ee' : 'none')};
    border-radius: 4px;
`;

const Menu = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    will-change: transform;
    padding: 0;
    margin-top: 8px;
    min-width: 64px;
    background-color: #ffffff;
    box-shadow: 2px 4px 12px -1px rgb(61 61 61 / 51%);
    border-radius: 0.25rem;
    color: var(--danger);

    &:hover,
    &:focus {
        color: var(--danger);
    }
`;

const Button = styled.div`
    //active-submit dropdownItem d-flex align-items-center pe-2 tex-left px-3 py-2
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 8px 16px;
    text-align: left;
    min-width: 64px;
    height: 32px;
    justify-content: center;
    svg {
        margin-right: 8px;
    }
`;

const Input = styled.input`
    border: 1px solid #e3e8ee;
    border-radius: 4px;
    min-width: 24px;
    width: 100%;
    outline: 'none;
`;

const Select = styled.select`
    border: 1px solid #e3e8ee;
    border-radius: 4px;
    min-width: 24px;
    width: 100%;
    outline: 'none;
`;

//#endregion
export default function ServiceEstimatedPrice({
    currencyList,
    view,
    data,
    setData,
    error,
}) {
    const { t } = useTranslation(['common', 'lgsServiceMaster']);
    const [editingRowIndex, setEditingRowIndex] = useState(-1);

    const [cookies, setCookie] = useCookies();
    const _defaultCurrencyCode = cookies['_company_currency_code'];
    const _defaultCurrencyName = cookies['_company_currency_name'];

    //#region variable initiation

    // table header 的名稱
    const headTitleList = [
        '#',
        // t('lgsPurchaseOrder:paymentDateBasis'),
        // t('lgsPurchaseOrder:paymentDateDays'),
        // t('lgsPurchaseOrder:paymentDate'),
        // t('lgsPurchaseOrder:paymentAmount'),
        // t('lgsPurchaseOrder:paymentPercentage'),
        // t('lgsPurchaseOrder:annotation'),
        '幣別',
        '起始日期',
        '截止日期',
        '數量下限',
        '數量上限',
        '未稅單價',
        '備註',
        '',
    ];

    // table body 的資料名稱
    const bodyTitleList = [
        'displayOrder',
        'currencyCode',
        'startDate',
        'endDate',
        'quantityFrom',
        'quantityTo',
        'unitPrice',
        'note',
    ];

    // 不同 input 有沒有小數點的要求
    const toFloatList = ['', '', '', '', 'decimalPlace', 'decimalPlace', ''];

    // 不同 input 的 type
    const inputProperties = [
        { type: 'number' },
        { type: 'text' },
        {
            type: 'number',
            min: 1,
        },
        { type: 'date' },
        {
            type: 'number',
            // onBlur: () =>
            //     handleCalculateAmount('changingAmount', tableBodyData),
        },
        {
            type: 'number',
            // onBlur: () =>
            //     handleCalculateAmount('changingPercentage', tableBodyData),
        },
        { type: 'text' },
        { type: 'text' },
    ];

    // table header 的 style
    const tableHeadStyles = [
        {
            width: '48px',
            textAlign: 'center',
        },
        {
            width: '128px',
            textAlign: 'center',
        },
        {
            width: '128px',
            textAlign: 'center',
        },
        {
            width: '128px',
            textAlign: 'center',
        },
        {
            textAlign: 'center',
        },
        {
            textAlign: 'center',
        },
        {
            textAlign: 'center',
        },
        {
            width: '248px',
            textAlign: 'center',
        },
        {
            width: '48px',
            textAlign: 'center',
        },
    ];

    // table body 的 style
    const tableBodyStyles = [
        {
            width: '48px',
            textAlign: 'center',
        },
        {
            width: '128px',
            textAlign: 'left',
        },
        {
            width: '128px',
            textAlign: 'left',
        },
        {
            width: '128px',
            textAlign: 'left',
        },
        {
            textAlign: 'right',
        },
        {
            textAlign: 'right',
        },
        {
            textAlign: 'right',
        },
        {
            width: '248px',
            textAlign: 'left',
        },
        {
            width: '48px',
            textAlign: 'center',
        },
    ];

    //#endregion

    const handleAddServiceEstimatedPriceList = () => {
        const newNumber = data.serviceEstimatedPriceList.length + 1;

        const newItem = {
            _uuid: _uuid(),
            action: 'insert',
            displayOrder: newNumber,
            currencyCode: _defaultCurrencyCode,
            currencyName: _defaultCurrencyName,
            startDate: '',
            endDate: '',
            note: '',
            priceGuid: '',
            quantityFrom: 1,
            quantityTo: 2,
            rowStamp: '',
            serviceGuid: data?.serviceGuid,
            unitPrice: 0,
            action: 'insert',
        };

        const newList = [...data.serviceEstimatedPriceList, newItem];

        setData({
            ...data,
            serviceEstimatedPriceList: newList,
        });
    };

    const handleDeleteEstimatedPrice = (e, priceIndex) => {
        e.stopPropagation();

        const newServiceEstimatedPriceList = [];

        data?.serviceEstimatedPriceList.map((el, index) => {
            if (priceIndex === index) {
                console.log(el);
                if (el.action === 'origin') {
                    newServiceEstimatedPriceList.push({
                        ...el,
                        action: 'delete',
                    });
                }
            } else {
                newServiceEstimatedPriceList.push(el);
            }
        });

        console.log(newServiceEstimatedPriceList);

        setData({
            ...data,
            serviceEstimatedPriceList: newServiceEstimatedPriceList,
        });
    };

    const handleDeletePaymentTerm = (e, data) => {
        e.stopPropagation();
        const oldList = [...tableBodyData];

        // 只有在 EDIT 裡才會有被刪除的付款條件
        if (oldList[data.displayOrder - 1].action === 'update') {
            oldList[data.displayOrder - 1].action = 'delete';
            deletedTermList &&
                setDeleteTermList([
                    ...deletedTermList,
                    oldList[data.displayOrder - 1],
                ]);
        }

        // remove the item from the array
        oldList.splice(data.displayOrder - 1, 1);
        // update the displayOrder property of the remaining items
        const newList = oldList.map((item, index) => ({
            ...item,
            displayOrder: index + 1,
        }));

        setTableBodyData(newList);
        // console.log(newList);
    };

    const handleEditTable = (e, rowIndex, el) => {
        // console.log(e.target, rowIndex, el);

        const targetIndex = rowIndex;
        setEditingRowIndex(targetIndex);
    };

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        const name = e.target.name;

        const serviceEstimatedPriceList = [...data.serviceEstimatedPriceList];
        serviceEstimatedPriceList[index][name] = value;
        if (serviceEstimatedPriceList[index].action === 'origin') {
            serviceEstimatedPriceList[index].action = 'update';
        }

        setData({ ...data, serviceEstimatedPriceList });
    };

    function _uuid() {
        var d = Date.now();
        if (
            typeof performance !== 'undefined' &&
            typeof performance.now === 'function'
        ) {
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
    }

    // console.log(data);

    return (
        <>
            <div className="position-relative">
                <div className="bg-white d-flex flex-column p-4 position-relative">
                    <div className="px-2 py-3 d-flex align-item-center">
                        <div
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#1278E8',
                                lineHeight: '2',
                            }}
                        >
                            {t('lgsServiceMaster:estimatedPrice.paymentTerm')}
                        </div>
                        {view !== 'details' && (
                            <BtnNormal
                                className={'btn btn-primary text-white ms-4'}
                                key={'ActivateBtnNormal'}
                                // disabled={!disabled}
                                // onClick={
                                //     handleClick
                                //         ? handleClick
                                //         : () => navigate('Activate')
                                // }
                                onClick={handleAddServiceEstimatedPriceList}
                            >
                                {t('button.createButton')}
                            </BtnNormal>
                        )}
                    </div>
                    <IndexTable className="table">
                        <THead className="table-head">
                            <TR>
                                <TH
                                    style={{
                                        width: '48px',
                                        textAlign: 'center',
                                    }}
                                >
                                    #
                                </TH>
                                <TH
                                    style={{
                                        width: '128px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {t(
                                        'lgsServiceMaster:estimatedPrice.currency'
                                    )}
                                </TH>
                                <TH
                                    style={{
                                        width: '128px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {t(
                                        'lgsServiceMaster:estimatedPrice.startDate'
                                    )}
                                </TH>
                                <TH
                                    style={{
                                        width: '128px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {t(
                                        'lgsServiceMaster:estimatedPrice.endDate'
                                    )}
                                </TH>
                                <TH
                                    style={{
                                        width: '160px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {t(
                                        'lgsServiceMaster:estimatedPrice.quantityFrom'
                                    )}
                                </TH>
                                <TH
                                    style={{
                                        width: '160px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {t(
                                        'lgsServiceMaster:estimatedPrice.quantityTo'
                                    )}
                                </TH>
                                <TH
                                    style={{
                                        width: '160px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {t(
                                        'lgsServiceMaster:estimatedPrice.unitPrice'
                                    )}
                                </TH>
                                <NextToLast
                                    style={{
                                        width: '232px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('lgsServiceMaster:estimatedPrice.note')}
                                </NextToLast>
                                <LastTH
                                    style={{
                                        width: '48px',
                                        textAlign: 'center',
                                    }}
                                ></LastTH>
                            </TR>
                        </THead>
                        <TBody className="">
                            {data?.serviceEstimatedPriceList
                                ?.sort(
                                    (a, b) => a.displayOrder - b.displayOrder
                                )
                                .map(
                                    (el, rowIndex) =>
                                        el.action !== 'delete' &&
                                        (editingRowIndex !== rowIndex ? (
                                            <TR
                                                key={el?.priceGuid || el?._uuid}
                                                id={el?._uuid}
                                                onClick={(e) =>
                                                    view !== 'details' &&
                                                    handleEditTable(
                                                        e,
                                                        rowIndex,
                                                        el
                                                    )
                                                }
                                            >
                                                <TD className="text-center">
                                                    {rowIndex + 1}
                                                </TD>
                                                <TD className="text-left">
                                                    {el.currencyCode
                                                        ? `${el?.currencyCode} ${el?.currencyName}`
                                                        : ''}
                                                </TD>
                                                <TD className="text-left">
                                                    {el?.startDate
                                                        ? dayjs(
                                                              el?.startDate
                                                          ).format('YYYY-MM-DD')
                                                        : ''}
                                                    <div className="mt-xl-1 text-danger text-start">
                                                        {error?.serviceEstimatedPriceList &&
                                                            error
                                                                ?.serviceEstimatedPriceList[
                                                                el._uuid
                                                            ]?.startDate
                                                                ?.message}
                                                    </div>
                                                </TD>
                                                <TD className="text-left">
                                                    {el?.endDate
                                                        ? dayjs(
                                                              el?.endDate
                                                          ).format('YYYY-MM-DD')
                                                        : ''}
                                                </TD>
                                                <TD className="text-end">
                                                    {el?.quantityFrom || 1}
                                                    <div className="mt-xl-1 text-danger text-start">
                                                        {error?.serviceEstimatedPriceList &&
                                                            error
                                                                ?.serviceEstimatedPriceList[
                                                                el._uuid
                                                            ]?.quantityFrom
                                                                ?.message}
                                                    </div>
                                                </TD>
                                                <TD className="text-end">
                                                    {el?.quantityTo || 2}
                                                    <div className="mt-xl-1 text-danger text-start">
                                                        {error?.serviceEstimatedPriceList &&
                                                            error
                                                                ?.serviceEstimatedPriceList[
                                                                el._uuid
                                                            ]?.quantityTo
                                                                ?.message}
                                                    </div>
                                                </TD>
                                                <TD className="text-end">
                                                    {el?.unitPrice}
                                                    <div className="mt-xl-1 text-danger text-start">
                                                        {error?.serviceEstimatedPriceList &&
                                                            error
                                                                ?.serviceEstimatedPriceList[
                                                                el._uuid
                                                            ]?.unitPrice
                                                                ?.message}
                                                    </div>
                                                </TD>
                                                <NextToLast className="text-left">
                                                    {el?.note}
                                                </NextToLast>
                                                <LastTH className="text-left"></LastTH>
                                            </TR>
                                        ) : (
                                            <TR
                                                key={
                                                    el?.priceGuid ||
                                                    el.displayOrder
                                                }
                                                onClick={(e) =>
                                                    view !== 'details' &&
                                                    handleEditTable(
                                                        e,
                                                        rowIndex,
                                                        el
                                                    )
                                                }
                                            >
                                                <TD_Input
                                                    className="text-center"
                                                    style={{ color: '#1278E8' }}
                                                >
                                                    {rowIndex + 1}
                                                </TD_Input>
                                                <TD_Input className="text-left">
                                                    <Select
                                                        name="currencyCode"
                                                        value={el?.currencyCode}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                rowIndex
                                                            )
                                                        }
                                                    >
                                                        {currencyList?.map(
                                                            (currency) => (
                                                                <option
                                                                    key={
                                                                        currency.currencyCode
                                                                    }
                                                                    value={
                                                                        currency?.currencyCode
                                                                    }
                                                                >
                                                                    {`${currency.currencyCode} ${currency.name01}`}
                                                                </option>
                                                            )
                                                        )}
                                                    </Select>
                                                </TD_Input>
                                                <TD_Input className="text-left">
                                                    <Input
                                                        name="startDate"
                                                        value={
                                                            el?.startDate
                                                                ? dayjs(
                                                                      el?.startDate
                                                                  ).format(
                                                                      'YYYY-MM-DD'
                                                                  )
                                                                : ''
                                                        }
                                                        active={el?.active}
                                                        type="date"
                                                        min="0"
                                                        onChange={(e) => {
                                                            handleInputChange(
                                                                e,
                                                                rowIndex
                                                            );
                                                        }}
                                                    />
                                                    <div className="mt-xl-1 text-danger text-start">
                                                        {error?.serviceEstimatedPriceList &&
                                                            error
                                                                ?.serviceEstimatedPriceList[
                                                                el._uuid
                                                            ]?.startDate
                                                                ?.message}
                                                    </div>
                                                </TD_Input>
                                                <TD_Input className="text-left">
                                                    <Input
                                                        name="endDate"
                                                        value={dayjs(
                                                            el?.endDate
                                                        ).format('YYYY-MM-DD')}
                                                        active={el?.active}
                                                        type="date"
                                                        min="0"
                                                        onChange={(e) => {
                                                            handleInputChange(
                                                                e,
                                                                rowIndex
                                                            );
                                                        }}
                                                    />
                                                </TD_Input>
                                                <TD_Input className="text-end">
                                                    <Input
                                                        name="quantityFrom"
                                                        value={
                                                            el?.quantityFrom ||
                                                            1
                                                        }
                                                        active={el?.active}
                                                        type="number"
                                                        min="1"
                                                        onChange={(e) => {
                                                            handleInputChange(
                                                                e,
                                                                rowIndex
                                                            );
                                                        }}
                                                    />
                                                    <div className="mt-xl-1 text-danger text-start">
                                                        {error?.serviceEstimatedPriceList &&
                                                            error
                                                                ?.serviceEstimatedPriceList[
                                                                el._uuid
                                                            ]?.quantityFrom
                                                                ?.message}
                                                    </div>
                                                </TD_Input>
                                                <TD_Input className="text-end">
                                                    <Input
                                                        name="quantityTo"
                                                        value={
                                                            el?.quantityTo || 2
                                                        }
                                                        active={el?.active}
                                                        type="number"
                                                        min="0"
                                                        onChange={(e) => {
                                                            handleInputChange(
                                                                e,
                                                                rowIndex
                                                            );
                                                        }}
                                                    />
                                                    <div className="mt-xl-1 text-danger text-start">
                                                        {error?.serviceEstimatedPriceList &&
                                                            error
                                                                ?.serviceEstimatedPriceList[
                                                                el._uuid
                                                            ]?.quantityTo
                                                                ?.message}
                                                    </div>
                                                </TD_Input>
                                                <TD_Input className="text-end">
                                                    <Input
                                                        name="unitPrice"
                                                        value={el?.unitPrice}
                                                        active={el?.active}
                                                        type="number"
                                                        min="0"
                                                        onChange={(e) => {
                                                            handleInputChange(
                                                                e,
                                                                rowIndex
                                                            );
                                                        }}
                                                    />
                                                    <div className="mt-xl-1 text-danger text-start">
                                                        {error?.serviceEstimatedPriceList &&
                                                            error
                                                                ?.serviceEstimatedPriceList[
                                                                el._uuid
                                                            ]?.unitPrice
                                                                ?.message}
                                                    </div>
                                                </TD_Input>
                                                <TD_Input className="text-left">
                                                    <Input
                                                        name="note"
                                                        value={el?.note}
                                                        active={el?.active}
                                                        type="text"
                                                        onChange={(e) => {
                                                            handleInputChange(
                                                                e,
                                                                rowIndex
                                                            );
                                                        }}
                                                    />
                                                </TD_Input>
                                                <TD_Input className="text-left position-relative">
                                                    <div
                                                        className="position-absolute top-50 start-50 translate-middle cursor"
                                                        role="button"
                                                        onClick={(e) =>
                                                            handleDeleteEstimatedPrice(
                                                                e,
                                                                rowIndex
                                                            )
                                                        }
                                                    >
                                                        <Icn_deleted />
                                                    </div>
                                                </TD_Input>
                                            </TR>
                                        ))
                                )}
                        </TBody>
                    </IndexTable>
                </div>
            </div>
        </>
    );
}
