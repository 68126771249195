import axios from 'axios';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ActivateBtnNormal } from '../../../src/components/Buttons/ActivateBtnNormal';
import { ReactComponent as Icn_SidebarEnt } from '../../assets/images/icn_SidebarEnt.svg';
import { ReactComponent as Icn_SidebarGla } from '../../assets/images/icn_SidebarGla.svg';
import { ReactComponent as Icn_SidebarLgs } from '../../assets/images/icn_SidebarLgs.svg';
import { ReactComponent as Icn_chevronLeft } from '../../assets/images/icn_chevron-left.svg';
import { ReactComponent as Icn_chevronRight } from '../../assets/images/icn_chevron-right.svg';
import { ReactComponent as SynerterLogo } from '../../assets/images/synerterLogo.svg';
import { ReactComponent as SynerterLogoSmall } from '../../assets/images/synerterLogoSmall.svg';
import { ReactComponent as TriangleCursor } from '../../assets/images/triangle_cursor.svg';

// #region styled-components
const SidebarContainer = styled.div`
	width: ${(prop) => (prop.isSidebarWrap ? '56px' : '200px')};
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	z-index: 999;
	background: var(--primary);
	color: #ffffff;
`;

const SidebarHeader = styled.div`
	padding: 1rem 0.5rem;
	text-align: center;
	cursor: pointer;
`;

const SidebarHeaderShrinkBtn = styled.div`
	cursor: pointer;
	&:hover {
		background-color: var(--primaryhover);
	}
`;

const SidebarCollapseBtn = styled.div`
	padding: 9px 20px;
	line-height: 22px;
	display: block;
`;

const SidebarListContainer = styled.div``;

const sidebarBtn = styled.div`
	display: flex;
	padding: 9.5px 20px;
	list-style: none;
	align-items: center;
	font-size: 0.875rem;
	cursor: pointer;
	&:hover {
		background-color: var(--primaryhover);

		svg {
			opacity: 1;
		}
	}

	height: ${(prop) => prop.isSidebarWrap && '40px'};
`;

const GmnSidebar = styled(sidebarBtn)`
	background-color: ${(props) =>
		props.layer2Show === '0' && 'var(--primaryhover)'};

	&:hover svg {
		path:last-child {
			fill: var(--warning);
		}
	}
`;

const EntSidebar = styled(sidebarBtn)`
	background-color: ${(props) =>
		props.layer2Show === '0' && 'var(--primaryhover)'};

	&:hover {
		svg {
			path:last-child {
				fill: var(--warning);
			}
		}
	}
`;
const GlaSidebar = styled(sidebarBtn)`
	background-color: ${(props) =>
		props.layer2Show === '1' && 'var(--primaryhover)'};

	&:hover svg {
		path:first-child {
			fill: var(--warning);
		}
	}
`;
const LgsSidebar = styled(sidebarBtn)`
	background-color: ${(props) =>
		props.layer2Show === '2' && 'var(--primaryhover)'};

	&:hover svg {
		#Rectangle_3550 {
			fill: var(--warning);
		}
	}
`;
const ProSidebar = styled(sidebarBtn)`
	background-color: ${(props) =>
		props.layer2Show === '3' && 'var(--primaryhover)'};

	&:hover svg {
		path:nth-of-type(2) {
			fill: var(--warning);
		}
	}
`;

const Layer2 = styled.div`
	position: absolute;
	top: 64px;
	left: ${(prop) => (prop.isSidebarWrap ? '64px' : '200px')};
	min-height: 500px;
	max-height: calc(100vh - 100px); /* 設置最大高度為視窗高度減去頂部的距離 */
	width: calc(100vw - 200px);
	max-width: 1248px;
	padding: 24px;
	z-index: 10000;
	background-color: #ffffff;
	box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.1);
	cursor: auto;
	overflow-y: hidden; /* 顯示垂直卷軸，但一開始內容未溢出時隱藏 */

	/* 隱藏瀏覽器預設的滾動條樣式 */
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;

	/* 自訂滾動條樣式 */
	::-webkit-scrollbar {
		width: 8px; /* 調整滾動條寬度 */
	}

	::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.3); /* 滾動條拖動部分顏色 */
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: rgba(0, 0, 0, 0.5); /* 滑鼠懸停時的顏色 */
	}

	&:hover {
		overflow-y: auto; /* 懸停時顯示垂直卷軸 */
	}
`;

const Layer2Head = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 16px 0 16px;
`;

const Hr = styled.hr`
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.4);
`;

const SubjectIcon = styled.div`
	svg {
		width: 24px;
		height: 24px;

		//ent, gla
		path:first-child {
			fill: var(--warning);
		}

		path:last-child {
			fill: var(--primary) !important;
		}

		//lgs
		#Rectangle_3551,
		#Rectangle_3552 {
			fill: var(--primary) !important;
		}

		//pro
		#Path_1553,
		#Rectangle_3555 {
			fill: var(--primary) !important;
		}
	}
`;

const SubjectText = styled.div`
	font-weight: bold;
	font-size: 20px;
	color: var(--primary);
	margin-left: 8px;
`;

const Layer2Body = styled.div`
	padding: 16px;
	max-width: 100vw;
	max-heigh: 100vh;
	color: #0c2556;
	display: flex;
	flex-wrap: wrap;
`;

const InnerSubject = styled.div`
	width: 232px;
`;

const InnerSubjectTitle = styled.div`
	width: 200px;
	height: 32px;
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 24px;
	background-color: #f7f8fc;
	line-height: 32px;
`;

const InnerSubjectContent = styled.div`
	font-size: 14px;
`;

const InnerSubjectItems = styled.div`
	width: 232px;
	margin-bottom: 12px;
`;

const InnerSubjectSecondTitle = styled.div`
	margin-bottom: 12px;
`;

const InnerSubjectItem = styled.div`
	margin-bottom: 12px;
	padding: 0 0 0 8px !important;
`;

const InnerSubjectText = styled.span`
	padding: 0 0 0 0px !important;
	font-size: 14px;
	cursor: ${(prop) => (prop.ispermission ? 'pointer' : 'auto')};
	color: ${(prop) => (prop.ispermission ? 'var(--grey5)' : 'var(--grey3)')};
`;

const StyledLink = styled(Link)`
	padding: 0 0 0 0px !important;
	color: ${(prop) =>
		prop.ispermission === 'true' ? 'var(--grey5)' : 'var(--grey3)'};
	pointer-events: ${(prop) =>
		prop.ispermission === 'true' ? 'auto' : 'none'};
	text-decoration: none;
`;
//#endregion

const Sidebar = ({ isSidebarWrap, setSidebarWrap }) => {
	const { t } = useTranslation(['common', 'shdSidebar']);

	const mvcLocalhost = 'https://localhost:7014/'; // publishReset 前改為下方
	//const mvcLocalhost = "/";

	const [userPermission, setUserPermission] = useState([]);
	const [layer2Show, setLayer2Show] = useState('');
	const [cookies, setCookie] = useCookies();
	const [showScrollbar, setShowScrollbar] = useState(false);
	// console.log(cookies);

	const getUserPermission = async () => {
		await axios
			.get(
				`api/Auth/UserPermission/SideBar?orgType=${cookies._system_organization_type}`
			)
			.then((res) => {
				setUserPermission(res.data.authData);
				// console.log(res.data);
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		getUserPermission();

		// 定義一個處理點擊事件的函數
		function handleOutsideClick(event) {
			const layer2Element = document.querySelector('.layer2');

			// 檢查點擊事件的目標是否位於 layer2 元素內
			if (layer2Element && !layer2Element.contains(event.target)) {
				// 檢查目標是否具有 'sidebarBtn' 類別，目的使得click btn不觸發setLayer2Show('')
				if (event.target.classList.contains('sidebarBtn')) {
					return;
				} else {
					// 如果不是 'sidebarBtn' 的子元素，才執行 setLayer2Show('')
					setLayer2Show('');
				}
			}
		}

		// 在組件掛載時添加事件監聽器
		document.addEventListener('click', handleOutsideClick);

		// 在組件卸載時移除事件監聽器以避免內存洩漏
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	// console.log(userPermission);

	return (
		<SidebarContainer isSidebarWrap={isSidebarWrap}>
			<SidebarHeader onClick={() => (window.location.href = '/')}>
				{isSidebarWrap ? <SynerterLogoSmall /> : <SynerterLogo />}
			</SidebarHeader>
			<SidebarHeaderShrinkBtn
				onClick={() => setSidebarWrap(!isSidebarWrap)}
			>
				<SidebarCollapseBtn>
					{isSidebarWrap ? <Icn_chevronRight /> : <Icn_chevronLeft />}
				</SidebarCollapseBtn>
			</SidebarHeaderShrinkBtn>

			{cookies._group_guid && (
				<SidebarListContainer>
					<GmnSidebar
						layer2Show={layer2Show}
						className="sidebarBtn"
						isSidebarWrap={isSidebarWrap}
						onClick={() => {
							if (layer2Show === '0') {
								setLayer2Show('');
							} else {
								setLayer2Show('0');
							}
						}}
					>
						<Icn_SidebarEnt className={!isSidebarWrap && 'me-3'} />
						{!isSidebarWrap && t('shdSidebar:groupManagement')}
					</GmnSidebar>
					{layer2Show === '0' && (
						<Layer2
							isSidebarWrap={isSidebarWrap}
							className="layer2"
						>
							<Layer2Head>
								<SubjectIcon>
									<Icn_SidebarEnt />
								</SubjectIcon>
								<SubjectText>
									{t('shdSidebar:groupManagement')}
								</SubjectText>
							</Layer2Head>
							<Hr />
							<Layer2Body>
								<InnerSubject>
									<InnerSubjectTitle>
										{t('shdSidebar:group')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											{/* <InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'entGroupHierarchy'
														)}
														onClick={() => {
															userPermission.includes(
																'entGroupHierarchy'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntGroupHierarchy`);
														}}
													>
														{t(
															'shdSidebar:entGroupHierarchy'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'entGroup'
														)}
														onClick={() => {
															userPermission.includes(
																'entGroup'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntGroup/Details/${cookies['_group_guid']}`);
														}}
													>
														{t(
															'shdSidebar:entGroup'
														)}
													</InnerSubjectText>
												</InnerSubjectItem> */}
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'gmnCompanyCreate'
													)}
													onClick={() => {
														userPermission.includes(
															'gmnCompanyCreate'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntGroup/Details/${atob(
																cookies._group_guid
															)}`);
													}}
												>
													{t('shdSidebar:entCompany')}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'gmnCompanyCreate'
													)}
													onClick={() => {
														userPermission.includes(
															'gmnCompanyCreate'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntCompany/Create`);
													}}
												>
													{t(
														'shdSidebar:createNewCompany'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
								<InnerSubject>
									<InnerSubjectTitle>
										{t('shdSidebar:groupUser')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'gmnAuthorization'
													)}
													onClick={() => {
														userPermission.includes(
															'gmnAuthorization'
														) &&
															(window.location.href = `${mvcLocalhost}User/GmnAuthorization`);
													}}
												>
													{t(
														'shdSidebar:permissionSetting'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'??'
													)}
													onClick={() => {
														userPermission.includes(
															'??'
														) &&
															(window.location.href = `${mvcLocalhost}User/Home/Development`);
													}}
												>
													{t(
														'shdSidebar:permissionAssignment'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'gmnGroupUser'
													)}
													onClick={() => {
														userPermission.includes(
															'gmnGroupUser'
														) &&
															(window.location.href = `${mvcLocalhost}User/GmnGroupUser`);
													}}
												>
													{t(
														'shdSidebar:userManagement'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
							</Layer2Body>
						</Layer2>
					)}
				</SidebarListContainer>
			)}

			{cookies._company_guid && (
				<SidebarListContainer>
					<EntSidebar
						layer2Show={layer2Show}
						className="sidebarBtn"
						isSidebarWrap={isSidebarWrap}
						onClick={() => {
							if (layer2Show === '0') {
								setLayer2Show('');
							} else {
								setLayer2Show('0');
							}
						}}
					>
						<Icn_SidebarEnt className={!isSidebarWrap && 'me-3'} />
						{!isSidebarWrap &&
							t('shdSidebar:organizationalStructure')}
					</EntSidebar>
					{layer2Show === '0' && (
						<Layer2
							isSidebarWrap={isSidebarWrap}
							className="layer2"
						>
							<Layer2Head>
								<SubjectIcon>
									<Icn_SidebarEnt />
								</SubjectIcon>

								{/* 網頁標題-組織架構 */}
								<SubjectText>
									{t('shdSidebar:organizationalStructure')}
								</SubjectText>
							</Layer2Head>
							<Hr />
							<Layer2Body>
								<InnerSubject>
									{/* 大標題-客戶 */}
									<InnerSubjectTitle>
										{t('shdSidebar:customer')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entCompanyUser'
													)}
													onClick={() => {
														userPermission.includes(
															'entCompanyUser'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntCustomer`);
													}}
												>
													{t(
														'shdSidebar:customerInfo'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entCustomerClass'
													)}
													onClick={() => {
														userPermission.includes(
															'entCustomerClass'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntCustomerClass`);
													}}
												>
													{t(
														'shdSidebar:customerClass'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entCustomerNoRule'
													)}
													onClick={() => {
														userPermission.includes(
															'entCustomerNoRule'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntCustomerNoRule`);
													}}
												>
													{t(
														'shdSidebar:customerNoRule'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
								<InnerSubject>
									{/* 大標題-廠商 */}
									<InnerSubjectTitle>
										{t('shdSidebar:vendorGuid')}
									</InnerSubjectTitle>

									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entVendor'
													)}
													onClick={() => {
														userPermission.includes(
															'entVendor'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntVendor`);
													}}
												>
													{t('shdSidebar:vendor')}
												</InnerSubjectText>
											</InnerSubjectItem>

											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entVendorClass'
													)}
													onClick={() => {
														userPermission.includes(
															'entVendorClass'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntVendorClass`);
													}}
												>
													{t(
														'shdSidebar:vendorClass'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entVendorNoRule'
													)}
													onClick={() => {
														userPermission.includes(
															'entVendorNoRule'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntVendorNoRule`);
													}}
												>
													{t(
														'shdSidebar:vendorNoRule'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>

								<InnerSubject>
									{/* 大標題-員工 */}
									<InnerSubjectTitle>
										{t('shdSidebar:employee')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											{/* 員工基本資料 */}
											<InnerSubjectItem>
												<TriangleCursor />
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entEmployee'
													)}
													onClick={() => {
														userPermission.includes(
															'entEmployee'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntEmployee`);
													}}
												>
													{t(
														'shdSidebar:entEmployee'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 員工分類 */}
											<InnerSubjectItem>
												<TriangleCursor />
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entEmployeeClass'
													)}
													onClick={() => {
														userPermission.includes(
															'entEmployeeClass'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntEmployeeClass`);
													}}
												>
													{t(
														'shdSidebar:employeeClass'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 員工編號規則 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entEmployeeNoRule'
													)}
													onClick={() => {
														userPermission.includes(
															'entEmployeeNoRule'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntEmployeeNoRule`);
													}}
												>
													{t(
														'shdSidebar:employeeNoRule'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>

								<InnerSubject>
									{/* 大標題-權限/帳號 */}
									<InnerSubjectTitle>
										{t('shdSidebar:PermissionAccount')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											{/* 使用者管理 */}
											<InnerSubjectItem>
												<TriangleCursor />
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entCompanyUser'
													)}
													onClick={() => {
														userPermission.includes(
															'entCompanyUser'
														) &&
															(window.location.href = `${mvcLocalhost}User/EntCompanyUser`);
													}}
												>
													{t(
														'shdSidebar:userManagement'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 權限設定 */}
											<InnerSubjectItem>
												<TriangleCursor />
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entAuthorization'
													)}
													onClick={() => {
														userPermission.includes(
															'entAuthorization'
														) &&
															(window.location.href = `${mvcLocalhost}User/entAuthorization`);
													}}
												>
													{t(
														'shdSidebar:permissionSetting'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											{/* 
			權限指派-未做 
			<InnerSubjectItem>
				<InnerSubjectText
					ispermission={userPermission.includes(
						'erpFunctionAuthorization'
					)}
					onClick={() => {
						userPermission.includes(
							'erpFunctionAuthorization'
						) &&
							(window.location.href = `${mvcLocalhost}User/Home/development`);
					}}
				>
					{t(
						'shdSidebar:permissionAssignment'
					)}
				</InnerSubjectText>
			</InnerSubjectItem> */}
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>

								<InnerSubject>
									<InnerSubjectTitle>
										{t('shdSidebar:companySetting')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											{/* 公司基本資料 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entCompany'
													)}
													onClick={() => {
														window.location.href = `${mvcLocalhost}Enterprise/EntCompany/Details/${cookies['_company_guid']}`;
													}}
												>
													{t('shdSidebar:entCompany')}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 部門基本資料 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entSector'
													)}
													onClick={() => {
														userPermission.includes(
															'entSector'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/entSector`);
													}}
												>
													{t('shdSidebar:entSector')}
												</InnerSubjectText>
												{/* 部門分類 */}
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entSectorClass'
													)}
													onClick={() => {
														userPermission.includes(
															'entSectorClass'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntSectorClass`);
													}}
												>
													{t(
														'shdSidebar:sectorClass'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 交易貨幣 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entCurrency'
													)}
													onClick={() => {
														userPermission.includes(
															'entCurrency'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntCurrency`);
													}}
												>
													{t('shdSidebar:currency')}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 計量單位 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={userPermission.includes(
														'entUOM'
													)}
													onClick={() => {
														userPermission.includes(
															'entUOM'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntUom`);
													}}
												>
													{t('shdSidebar:uomguid')}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
							</Layer2Body>
						</Layer2>
					)}
					<GlaSidebar
						layer2Show={layer2Show}
						className="sidebarBtn"
						isSidebarWrap={isSidebarWrap}
						onClick={() => {
							if (layer2Show === '1') {
								setLayer2Show('');
							} else {
								setLayer2Show('1');
							}
						}}
					>
						<Icn_SidebarGla className={!isSidebarWrap && 'me-3'} />
						{!isSidebarWrap && t('shdSidebar:generalLedger')}
					</GlaSidebar>
					{layer2Show === '1' && (
						<Layer2
							isSidebarWrap={isSidebarWrap}
							className="layer2"
						>
							<Layer2Head>
								<SubjectIcon>
									<Icn_SidebarGla />
								</SubjectIcon>
								{/* <SubjectText>{"TEST"}</SubjectText> */}
								<SubjectText>
									{t('shdSidebar:generalLedger')}
								</SubjectText>

								{/* 總帳啟用前，啟用總帳按鈕 */}
								{cookies._company_setting_status === '0' &&
									userPermission.includes(
										'glaCompanySetting'
									) && (
										<ActivateBtnNormal
											word={t('button.activateButton')}
											handleClick={() => {
												window.location.href = `${mvcLocalhost}GeneralAccount/GlaCompanySetting/Activate`;
											}}
										/>
									)}

								{/* 啟用總帳
                {cookies._company_setting_status === "0" && (
                  <InnerSubjectItem>
                    <TriangleCursor />
                    <InnerSubjectText
                      className="ps-2"
                      ispermission={
                        cookies._company_setting_status === "0"
                          ? userPermission.includes("glaCompanySetting")
                          : false
                      }
                      onClick={() => {
                        cookies._company_setting_status === "0" &&
                          userPermission.includes("glaCompanySetting") &&
                          (window.location.href = `${mvcLocalhost}GeneralAccount/GlaCompanySetting/Activate`);
                      }}
                    >
                      {t("shdSidebar:enableGeneralLedger")}
                    </InnerSubjectText>
                  </InnerSubjectItem>
                )} */}
							</Layer2Head>
							<Hr />
							<Layer2Body>
								<InnerSubject>
									{/* 大標題-會計過帳 */}
									<InnerSubjectTitle>
										{t('shdSidebar:accountingPosting')}
									</InnerSubjectTitle>
									<InnerSubjectSecondTitle>
										{t('shdSidebar:accountDocument')}
									</InnerSubjectSecondTitle>
									{/* 會計憑證登錄*/}
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaAccountDocumentCreate'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaAccountDocumentCreate'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccountDocument/Create`);
													}}
												>
													{t('shdSidebar:create')}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 會計憑證*/}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaLedgersDocumentList'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaLedgersDocumentList'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccountDocument`);
													}}
												>
													{t('shdSidebar:edit')}
													{/* {t("shdSidebar:accountDocument")} */}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
								<InnerSubject>
									{/* 大標題-會計報表 */}
									<InnerSubjectTitle>
										{t('shdSidebar:financialStatements')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectSecondTitle>
												{t('shdSidebar:voucherList')}
											</InnerSubjectSecondTitle>

											{/* 會計憑證列表 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaLedgersDocumentList'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaLedgersDocumentList'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaLedgersDocumentList`);
													}}
												>
													{t(
														'shdSidebar:listOfAccountingDocuments'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 日記帳 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaLedgersJournal'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaLedgersJournal'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaLedgersJournal`);
													}}
												>
													{t('shdSidebar:journal')}
												</InnerSubjectText>
											</InnerSubjectItem>

											<InnerSubjectSecondTitle>
												{t('shdSidebar:accountReports')}
											</InnerSubjectSecondTitle>

											{/* 分類帳 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaLedgersGeneral'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaLedgersGeneral'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaLedgersGeneral`);
													}}
												>
													{t(
														'shdSidebar:generalLedger2'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 明細帳 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaLedgersOpen'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaLedgersOpen'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaLedgersOpen`);
													}}
												>
													{t(
														'shdSidebar:detailedLedger'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 會計科目餘額 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaAccountBalance'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaAccountBalance'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaLedgersBalance`);
													}}
												>
													{t(
														'shdSidebar:accountBalance'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 會計科目餘額總覽 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaAccountBalanceTable'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaAccountBalanceTable'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaLedgersBalanceTable`);
													}}
												>
													{t(
														'shdSidebar:accountBalanceOverview'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											<InnerSubjectSecondTitle>
												{t(
													'shdSidebar:financialReports'
												)}
											</InnerSubjectSecondTitle>

											{/* 試算表 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaReportTrialBalance'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaReportTrialBalance'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaReportTrialBalance`);
													}}
												>
													{t(
														'shdSidebar:trialBalance'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 損益表 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaReportIncomeStatement'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaReportIncomeStatement'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaReportIncomeStatement`);
													}}
												>
													{t(
														'shdSidebar:incomeStatement'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 資產負債表 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaReportBalanceSheet'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaReportBalanceSheet'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaReportBalanceSheet`);
													}}
												>
													{t(
														'shdSidebar:balanceSheet'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 會計報表格式 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaReportFormat'
													)}
													onClick={() => {
														userPermission.includes(
															'glaReportFormat'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaReportFormat`);
													}}
												>
													{t(
														'shdSidebar:reportFormat'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
								<InnerSubject>
									{/* 大標題-其他帳戶管理 */}
									<InnerSubjectTitle>
										{t('shdSidebar:otherAccountManagement')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectSecondTitle>
												{t(
													'shdSidebar:assetManagement'
												)}
											</InnerSubjectSecondTitle>
											{/* 資產主檔 */}
											<InnerSubjectItem>
												<TriangleCursor />
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaAssets'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaAssets'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAssets`);
													}}
												>
													{t(
														'shdSidebar:assetMaster'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											{/* 資產過帳 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaAssetsPosting'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaAssetsPosting'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAssetsPosting`);
													}}
												>
													{t(
														'shdSidebar:assetPosting'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											{/* 資產折舊提列 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaAssetsDepreciationRun'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaAssetsDepreciationRun'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAssetsDepreciationRun`);
													}}
												>
													{t(
														'shdSidebar:assetDepreciationBooking'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											{/* 資產編號規則 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaAssetsNoRule'
													)}
													onClick={() => {
														userPermission.includes(
															'glaAssetsNoRule'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAssetsNoRule`);
													}}
												>
													{t(
														'shdSidebar:assetNumberRule'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												{/* 資產分類 */}
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaAssetsClass'
													)}
													onClick={() => {
														userPermission.includes(
															'glaAssetsClass'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAssetsClass`);
													}}
												>
													{t(
														'shdSidebar:assetClassification'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											{/* 舊有資產匯入 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaAssetsLegacyConversion'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaAssetsLegacyConversion'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAssetsLegacyConversion`);
													}}
												>
													{t(
														'shdSidebar:importExistingAssets'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											<InnerSubjectSecondTitle>
												{t('shdSidebar:fundManagement')}
											</InnerSubjectSecondTitle>
											{/* 資金帳戶 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaBankingAccount'
													)}
													onClick={() => {
														userPermission.includes(
															'glaBankingAccount'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaBankingAccount`);
													}}
												>
													{t(
														'shdSidebar:fundAccount'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											{/* 資金分類 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaBankingClass'
													)}
													onClick={() => {
														userPermission.includes(
															'glaBankingClass'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaBankingClass`);
													}}
												>
													{t(
														'shdSidebar:fundCategory'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectSecondTitle>
												{t('shdSidebar:classCode')}
											</InnerSubjectSecondTitle>
											{/* 費用分類 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaExpenseClass'
													)}
													onClick={() => {
														userPermission.includes(
															'glaExpenseClass'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaExpenseClass`);
													}}
												>
													{t(
														'shdSidebar:expenseClassCode'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 收入分類 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaRevenueClass'
													)}
													onClick={() => {
														userPermission.includes(
															'glaRevenueClass'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaRevenueClass`);
													}}
												>
													{t(
														'shdSidebar:revenueCategory'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
								<InnerSubject>
									<InnerSubjectTitle>
										{t('shdSidebar:generalPostingSettings')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectSecondTitle>
												{t('shdSidebar:account')}
											</InnerSubjectSecondTitle>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaAccount'
													)}
													onClick={() => {
														userPermission.includes(
															'glaAccount'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccount`);
													}}
												>
													{t('shdSidebar:account')}
												</InnerSubjectText>
											</InnerSubjectItem>

											<InnerSubjectSecondTitle>
												{t(
													'shdSidebar:accountDocument'
												)}
											</InnerSubjectSecondTitle>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaAccountDocument'
													)}
													onClick={() => {
														userPermission.includes(
															'glaAccountDocument'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccountDocument`);
													}}
												>
													{t(
														'shdSidebar:accountDocument'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaAccountingDocumentType'
													)}
													onClick={() => {
														userPermission.includes(
															'glaAccountingDocumentType'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccountDocumentType`);
													}}
												>
													{t(
														'shdSidebar:accountDocumentType'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaAccountDocumentNoRule'
													)}
													onClick={() => {
														userPermission.includes(
															'glaAccountDocumentNoRule'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccountDocumentNoRule`);
													}}
												>
													{t(
														'shdSidebar:accountingCertificateNumberRule'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaInvoiceFormatType'
													)}
													onClick={() => {
														userPermission.includes(
															'glaInvoiceFormatType'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaInvoiceFormatType`);
													}}
												>
													{t(
														'shdSidebar:invoiceType'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaInvoiceTaxRateType'
													)}
													onClick={() => {
														userPermission.includes(
															'glaInvoiceTaxRateType'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaInvoiceTaxRateType`);
													}}
												>
													{t(
														'shdSidebar:invoiceTaxType'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaAppendixClass'
													)}
													onClick={() => {
														userPermission.includes(
															'glaAppendixClass'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAppendixClass`);
													}}
												>
													{t(
														'shdSidebar:attachmentCategory'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											<InnerSubjectSecondTitle>
												{t(
													'shdSidebar:accountingBasicSettings'
												)}
											</InnerSubjectSecondTitle>

											{/* 會計人員 */}

											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/GlaAccountant"
													ispermission={userPermission
														.includes(
															'glaAccountant'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{'會計人員'}
												</StyledLink>
											</InnerSubjectItem>

											{/* 會計開帳期間 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaPostingPeriod'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaPostingPeriod'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaPostingPeriod`);
													}}
												>
													{t(
														'shdSidebar:accountingOpenPeriod'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 匯率表 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaExchangeTable'
													)}
													onClick={() => {
														userPermission.includes(
															'glaExchangeTable'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaExchangeTable/Details`);
													}}
												>
													{t(
														'shdSidebar:exchangeTable'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 會計年度結轉 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={
														cookies._company_setting_status ===
														'0'
															? false
															: userPermission.includes(
																	'glaCarryForward'
															  )
													}
													onClick={() => {
														userPermission.includes(
															'glaCarryForward'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaCarryForward`);
													}}
												>
													{t(
														'shdSidebar:accountingYearEndTransfer'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaCompanySetting'
													)}
													onClick={() => {
														userPermission.includes(
															'glaCompanySetting'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaCompanySetting/Details/${cookies['_company_guid']}`);
													}}
												>
													{t(
														'shdSidebar:companyBasicSetting'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 總帳交易設定 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'glaTransaction'
													)}
													onClick={() => {
														userPermission.includes(
															'glaTransaction'
														) &&
															(window.location.href = `${mvcLocalhost}GeneralAccount/GlaTransaction/Details/${cookies['_company_guid']}`);
													}}
												>
													{t(
														'shdSidebar:transaction'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
							</Layer2Body>
						</Layer2>
					)}
					<LgsSidebar
						layer2Show={layer2Show}
						className="sidebarBtn"
						isSidebarWrap={isSidebarWrap}
						onClick={() => {
							if (layer2Show === '2') {
								setLayer2Show('');
							} else {
								setLayer2Show('2');
							}
						}}
					>
						<Icn_SidebarLgs className={!isSidebarWrap && 'me-3'} />
						{!isSidebarWrap && t('shdSidebar:inventory')}
					</LgsSidebar>
					{layer2Show === '2' && (
						<Layer2
							isSidebarWrap={isSidebarWrap}
							className="layer2"
						>
							<Layer2Head>
								<SubjectIcon>
									<Icn_SidebarLgs />
								</SubjectIcon>
								<SubjectText>
									{t('shdSidebar:inventoryManagement')}
								</SubjectText>
							</Layer2Head>
							<Hr />
							<Layer2Body>
								<InnerSubject>
									<InnerSubjectTitle>
										{t('shdSidebar:purchaseTransactions')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectSecondTitle>
												{t('shdSidebar:purchaseOrders')}
											</InnerSubjectSecondTitle>

											{/* 採購訂單-新增 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/lgsPurchaseOrder/Create"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrder'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t('shdSidebar:create')}
												</StyledLink>
											</InnerSubjectItem>

											{/* 採購訂單-修改 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/lgsPurchaseOrder"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrder'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t('shdSidebar:edit')}
												</StyledLink>
											</InnerSubjectItem>

											<InnerSubjectSecondTitle>
												{t(
													'shdSidebar:purchaseOrderReceiptAndInvoice'
												)}
											</InnerSubjectSecondTitle>

											{/* 收貨與收發票 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsPurchaseOrderGoodsInvoice"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderGoodsInvoice'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:purchaseOrderReceiptAndInvoice'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 採購訂單退貨 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsPurchaseOrderGoodsReturn"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderGoodsReturn'
														)
														.toString()}
													lgsPurchaseOrderInvoice
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:purchaseOrderReturn'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 採購訂單收發票 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsPurchaseOrderInvoice"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderInvoice'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:purchaseOrderInvoicing'
													)}
												</StyledLink>
											</InnerSubjectItem>
											{/* 採購訂單退發票 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsPurchaseOrderInvoiceReturn"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderInvoiceReturn'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:purchaseOrderInvoiceReturn'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 收貨檢驗 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsPurchaseOrderGoodsInspect"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderGoodsInspect'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:purchaseOrderInspection'
													)}
												</StyledLink>
											</InnerSubjectItem>

											<InnerSubjectSecondTitle>
												{t(
													'shdSidebar:purchasePaymentAndSettlement'
												)}
											</InnerSubjectSecondTitle>
											{/* 採購訂單付款 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsPurchaseOrderPayment"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderPayment'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:purchaseOrderPayment'
													)}
												</StyledLink>
											</InnerSubjectItem>
											{/* 採購付款退回 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsPurchaseOrderPaymentReturn"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderPaymentReturn'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:purchasePaymentRefund'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 採購訂單付款結清 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsPurchaseOrderPaymentClose"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderPaymentClose'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:purchaseOrderPaymentSettlement'
													)}
												</StyledLink>
											</InnerSubjectItem>
											<InnerSubjectSecondTitle>
												{t('shdSidebar:settings')}
											</InnerSubjectSecondTitle>
											{/* 採購訂單類型 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsPurchaseOrderType"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderType'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:purchaseOrderTypes'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 採購訂單編號規則 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsPurchaseOrderNoRule"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderNoRule'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:purchaseOrderNumberRules'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 進銷存憑證編號規則 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsDocumentNoRule"
													ispermission={(
														userPermission.includes(
															'lgsPurchaseOrderGoodsNoRule'
														) ||
														userPermission.includes(
															'lgsPurchaseOrderGoodsReturnNoRule'
														) ||
														userPermission.includes(
															'lgsPurchaseOrderGoodsInspectNoRule'
														) ||
														userPermission.includes(
															'lgsSalesOrderIssueNoRule'
														) ||
														userPermission.includes(
															'lgsSalesOrderIssueReturnNoRule'
														)
													).toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													進銷存憑證編號規則
												</StyledLink>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
								<InnerSubject>
									<InnerSubjectTitle>
										{t('shdSidebar:salesTransaction')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectSecondTitle>
												{t('shdSidebar:salesOrder')}
											</InnerSubjectSecondTitle>
											{/* 銷售訂單新增 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/lgsSalesOrder/Create"
													ispermission={userPermission
														.includes(
															'lgsSalesOrder'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t('shdSidebar:create')}
												</StyledLink>
											</InnerSubjectItem>
											{/* 銷售訂單修改 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/lgsSalesOrder"
													ispermission={userPermission
														.includes(
															'lgsSalesOrder'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t('shdSidebar:edit')}
												</StyledLink>
											</InnerSubjectItem>

											<InnerSubjectSecondTitle>
												{t(
													'shdSidebar:salesDeliveryAndInvoicing'
												)}
											</InnerSubjectSecondTitle>

											{/* 銷售理貨-新增 */}

											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsSalesOrderPreparing/Create"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderGoodsInvoice'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesPreparingCreate'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 銷售理貨-修改 */}

											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsSalesOrderPreparing/Edit"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderGoodsInvoice'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesPreparingEdit'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 銷售出貨-新增 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsSalesOrderGoodsInvoice/create"
													ispermission={userPermission
														.includes(
															'lgsSalesOrderGoodsInvoice'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesDeliveryCreate'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 銷售出貨-修改 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsSalesOrderGoodsInvoice/edit"
													ispermission={userPermission
														.includes(
															'lgsSalesOrderGoodsInvoice'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesDeliveryEdit'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 銷售退貨-新增 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsSalesOrderGoodsReturn/create"
													ispermission={userPermission
														.includes(
															'lgsSalesOrderGoodsReturn'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesReturnCreate'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 銷售退貨-修改 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													ispermission={userPermission
														.includes(
															'lgsSalesOrderGoodsReturn'
														)
														.toString()}
													to="/Logistics/LgsSalesOrderGoodsReturn/edit"
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesReturnEdit'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 銷售退貨-驗貨單新增 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsSalesOrderGoodsInspect'
													).toString()}
													to="/Logistics/LgsSalesOrderGoodsInspect/create"
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesInspectCreate'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 銷售退貨-驗貨單修改 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsSalesOrderGoodsInspect'
													).toString()}
													to="/Logistics/LgsSalesOrderGoodsInspect/edit"
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesInspectEdit'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 銷售補開發票 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'XXX'
													)}
													onClick={() => {
														userPermission.includes(
															'XXX'
														) &&
															(window.location.href = `/User/Home/Development`);
													}}
												>
													{t(
														'shdSidebar:salesSupplementInvoice'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											{/* 銷售退發票 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'XXX'
													)}
													onClick={() => {
														userPermission.includes(
															'XXX'
														) &&
															(window.location.href = `/User/Home/Development`);
													}}
												>
													{t(
														'shdSidebar:salesReturnInvoice'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectSecondTitle>
												{t(
													'shdSidebar:salesReceiptAndSettlement'
												)}
											</InnerSubjectSecondTitle>
											{/* 銷售收款 */}
											<InnerSubjectItem>
												<TriangleCursor />
												<StyledLink
													className="ps-2"
													to="/Logistics/lgsSalesOrderPayment"
													ispermission={userPermission
														.includes(
															'lgsSalesOrderPayment'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesReceipt'
													)}
												</StyledLink>
											</InnerSubjectItem>
											{/* 銷售收款退回 */}
											<InnerSubjectItem>
												<TriangleCursor />
												<StyledLink
													className="ps-2"
													to="/Logistics/lgsSalesOrderPaymentReturn"
													ispermission={userPermission
														.includes(
															'lgsSalesOrderPaymentReturn'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesReceiptReturn'
													)}
												</StyledLink>
											</InnerSubjectItem>
											{/* 銷售差異結清 */}
											<InnerSubjectItem>
												<TriangleCursor />
												<StyledLink
													className="ps-2"
													to="/Logistics/lgsSalesOrderPaymentClose"
													ispermission={userPermission
														.includes(
															'lgsSalesOrderPaymentClose'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesDifferenceSettlement'
													)}
												</StyledLink>
											</InnerSubjectItem>
											<InnerSubjectSecondTitle>
												{t('shdSidebar:settings')}
											</InnerSubjectSecondTitle>

											{/* 銷售商品主檔 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsSalesKit"
													// TODO: 權限要改為 LgsSalesKit
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderPayment'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t('shdSidebar:salesKit')}
												</StyledLink>
											</InnerSubjectItem>
											{/* 銷售商品分類 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsSalesKitClass"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderPayment'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesKitClass'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 銷售訂單類型 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsSalesOrderType"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderPayment'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesOrderType'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 銷售訂單編號規則 */}

											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsSalesOrderNoRule"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderPayment'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:salesOrderNumberRule'
													)}
												</StyledLink>
											</InnerSubjectItem>

											<InnerSubjectSecondTitle>
												{t('shdSidebar:settings')}
											</InnerSubjectSecondTitle>

											{/* 服務分類 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsServiceClass"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderPayment'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:serviceClass'
													)}
												</StyledLink>
											</InnerSubjectItem>
											{/* 服務主檔 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsServiceMaster"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderPayment'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:serviceMaster'
													)}
												</StyledLink>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
								<InnerSubject>
									<InnerSubjectTitle>
										{t('shdSidebar:inventorySalesReport')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectSecondTitle>
												{t('shdSidebar:purchaseReport')}
											</InnerSubjectSecondTitle>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'XXX'
													)}
													onClick={() => {
														userPermission.includes(
															'XXX'
														) &&
															(window.location.href = `/User/Home/Development`);
													}}
												>
													{t(
														'shdSidebar:purchaseOrderList'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'XXX'
													)}
													onClick={() => {
														userPermission.includes(
															'XXX'
														) &&
															(window.location.href = `/User/Home/Development`);
													}}
												>
													{t(
														'shdSidebar:purchaseReceiptList'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'XXX'
													)}
													onClick={() => {
														userPermission.includes(
															'XXX'
														) &&
															(window.location.href = `/User/Home/Development`);
													}}
												>
													{t(
														'shdSidebar:purchaseInspectionList'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'XXX'
													)}
													onClick={() => {
														userPermission.includes(
															'XXX'
														) &&
															(window.location.href = `/User/Home/Development`);
													}}
												>
													{t(
														'shdSidebar:purchasePaymentList'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											<InnerSubjectSecondTitle>
												{t('shdSidebar:salesReport')}
											</InnerSubjectSecondTitle>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'XXX'
													)}
													onClick={() => {
														userPermission.includes(
															'XXX'
														) &&
															(window.location.href = `/User/Home/Development`);
													}}
												>
													{t(
														'shdSidebar:salesOrderList'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'XXX'
													)}
													onClick={() => {
														userPermission.includes(
															'XXX'
														) &&
															(window.location.href = `/User/Home/Development`);
													}}
												>
													{t(
														'shdSidebar:salesShipmentList'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'XXX'
													)}
													onClick={() => {
														userPermission.includes(
															'XXX'
														) &&
															(window.location.href = `/User/Home/Development`);
													}}
												>
													{t(
														'shdSidebar:salesReceiptList'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											<InnerSubjectSecondTitle>
												{t(
													'shdSidebar:inventoryReport'
												)}
											</InnerSubjectSecondTitle>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'LgsMaterialMonthlyQuantity'
													)}
													onClick={() => {
														userPermission.includes(
															'LgsMaterialMonthlyQuantity'
														) &&
															(window.location.href = `/Logistics/LgsMaterialMonthlyQuantity`);
													}}
												>
													{t(
														'shdSidebar:monthlyQuantityOfMaterials'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsMaterialMonthlyStandardCost'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsMaterialMonthlyStandardCost'
														) &&
															(window.location.href = `/Logistics/lgsMaterialMonthlyStandardCost`);
													}}
												>
													{t(
														'shdSidebar:totalStandardCostOfMonthlyMaterials'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>

								{/* 大標題-物料與廠房 */}
								<InnerSubject>
									<InnerSubjectTitle>
										{t('shdSidebar:materialsAndFactory')}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectSecondTitle>
												{t(
													'shdSidebar:basicMasterData'
												)}
											</InnerSubjectSecondTitle>

											{/* 廠房主檔 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsPlant'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsPlant'
														) &&
															(window.location.href = `${mvcLocalhost}Logistics/LgsPlant`);
													}}
												>
													{t(
														'shdSidebar:factoryMasterData'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 採購訂單附件分類 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<StyledLink
													className="ps-2"
													to="/Logistics/LgsPurchaseOrderAppendix"
													ispermission={userPermission
														.includes(
															'lgsPurchaseOrderType'
														)
														.toString()}
													onClick={() =>
														setLayer2Show('')
													}
												>
													{t(
														'shdSidebar:purchaseOrderattachmentCategory'
													)}
												</StyledLink>
											</InnerSubjectItem>

											{/* 物料主檔 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsMaterialMaster'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsMaterialMaster'
														) &&
															(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialMaster`);
													}}
												>
													{t(
														'shdSidebar:materialMasterData'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 物料的廠房設定 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsMaterialMasterPlant'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsMaterialMasterPlant'
														) &&
															(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialMasterPlant`);
													}}
												>
													{t(
														'shdSidebar:materialPlantSettings'
													)}
												</InnerSubjectText>
												{/* 物料供貨廠商 */}
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsMaterialMasterVendor'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsMaterialMasterVendor'
														) &&
															(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialMasterVendor`);
													}}
												>
													{t(
														'shdSidebar:materialSupplier'
													)}
												</InnerSubjectText>
												{/* 物料倉位 */}
											</InnerSubjectItem>
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsMaterialCube'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsMaterialCube'
														) &&
															(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialCube`);
													}}
												>
													{t(
														'shdSidebar:materialLocation'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 物料分類 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsMaterialClass'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsMaterialClass'
														) &&
															(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialClass`);
													}}
												>
													{t(
														'shdSidebar:materialCategory'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 物料用途 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsMaterialUsage'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsMaterialUsage'
														) &&
															(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialUsage`);
													}}
												>
													{t(
														'shdSidebar:materialUsage'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectSecondTitle>
												{t(
													'shdSidebar:standardCostOfMaterial'
												)}
											</InnerSubjectSecondTitle>

											{/* 未來標準成本的設定 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsStandardCostFuture'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsStandardCostFuture'
														) &&
															(window.location.href = `${mvcLocalhost}Logistics/LgsFutureStandardPrice`);
													}}
												>
													{t(
														'shdSidebar:futureStandardCostSetting'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 當月標準成本的變更 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsStandardCostCurrent'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsStandardCostCurrent'
														) &&
															(window.location.href = `${mvcLocalhost}Logistics/LgsCurrentStandardPrice`);
													}}
												>
													{t(
														'shdSidebar:monthlyStandardCostChange'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectSecondTitle>
												{t(
													'shdSidebar:materialVoucher'
												)}
											</InnerSubjectSecondTitle>

											{/* 物料憑證類型 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsMaterialDocumentType'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsMaterialDocumentType'
														) &&
															(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialDocumentType`);
													}}
												>
													{t(
														'shdSidebar:materialVoucherType'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 物料憑證編號規則 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsMaterialDocumentTypeNoRule'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsMaterialDocumentTypeNoRule'
														) &&
															(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialDocumentNoRule`);
													}}
												>
													{t(
														'shdSidebar:materialVoucherNumberRule'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>

								{/* 大標題-進銷存設定 */}
								<InnerSubject>
									<InnerSubjectTitle>
										{t(
											'shdSidebar:inventoryPurchasingAndSalesSettings'
										)}
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectSecondTitle>
												{t('shdSidebar:otherSettings')}
											</InnerSubjectSecondTitle>

											{/* 進銷存基本設定 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'XXX'
													)}
													onClick={() => {
														userPermission.includes(
															'XXX'
														) &&
															(window.location.href = `/User/Home/Development`);
													}}
												>
													{t(
														'shdSidebar:inventoryPurchaseSalesSettings'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>

											{/* 進銷存交易設定 */}
											<InnerSubjectItem>
												<TriangleCursor />{' '}
												<InnerSubjectText
													className="ps-2"
													ispermission={userPermission.includes(
														'lgsTransaction'
													)}
													onClick={() => {
														userPermission.includes(
															'lgsTransaction'
														) &&
															(window.location.href = `${mvcLocalhost}Logistics/LgsTransaction`);
													}}
												>
													{t(
														'shdSidebar:inventoryPurchaseSalesTransactionSettings'
													)}
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
							</Layer2Body>
						</Layer2>
					)}
					{/* <ProSidebar
					isSidebarWrap={isSidebarWrap}
					onMouseEnter={() => setLayer2Show('3')}
					onMouseLeave={() => setLayer2Show('')}
				>
					<Icn_SidebarPro className={!isSidebarWrap && 'me-3'} />
					{!isSidebarWrap && '生產管理'}

					{layer2Show === '3' && (
						<Layer2
							isSidebarWrap={isSidebarWrap}
							className="layer2"
						>
							<Layer2Head>
								<SubjectIcon>
									<Icn_SidebarPro />
								</SubjectIcon>
								<SubjectText>生產管理</SubjectText>
							</Layer2Head>
							<Hr />
							<Layer2Body>
								<InnerSubject>
									<InnerSubjectTitle>集團</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'EntGroupHierarchy'
													)}
													onClick={() => {
														userPermission.includes(
															'EntGroupHierarchy'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntGroupHierarchy`);
													}}
												>
													集團層級分類
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'EntGroup'
													)}
													onClick={() => {
														userPermission.includes(
															'EntGroup'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntGroup/Details/${cookies['Auth.COOKIES_CONTROLGROUPGUID']}`);
													}}
												>
													集團基本資料
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'ErpCompany'
													)}
													onClick={() => {
														userPermission.includes(
															'ErpCompany'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntCompany`);
													}}
												>
													公司基本資料
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'EntCompany'
													)}
													onClick={() => {
														userPermission.includes(
															'EntCompany'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntCompany/Create`);
													}}
												>
													建立新公司
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
								<InnerSubject>
									<InnerSubjectTitle>
										集團使用者
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'GmnAuthorization'
													)}
													onClick={() => {
														userPermission.includes(
															'GmnAuthorization'
														) &&
															(window.location.href = `/User/GmnAuthorization`);
													}}
												>
													權限設定
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'XXX'
													)}
													onClick={() => {
														userPermission.includes(
															'XXX'
														) &&
															(window.location.href = `/User/Home/Development`);
													}}
												>
													權限指派
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'GmnGroupUser'
													)}
													onClick={() => {
														userPermission.includes(
															'GmnGroupUser'
														) &&
															(window.location.href = `/User/GmnGroupUser`);
													}}
												>
													使用者管理
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
							</Layer2Body>
						</Layer2>
					)}
				</ProSidebar> */}
				</SidebarListContainer>
			)}
		</SidebarContainer>
	);
};

export default Sidebar;
