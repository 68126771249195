import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EditIcon } from '../../assets/images/icn_Edit.svg';
import { BtnNormal } from './BtnNormal';
import { useNavigate } from 'react-router-dom';

export const EditBtnNormal = ({ className, to, guid }) => {
	// console.log(className, to);
	const navigate = useNavigate();
	console.log(guid);
	return (
		<BtnNormal
			className={'btn btn-primary'}
			key={'EditBtnNormal'}
			onClick={() => navigate(`../Edit/${guid}`)}
		>
			<EditIcon />
			<span>修改</span>
		</BtnNormal>
	);
};
