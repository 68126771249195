import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BtnNormal2 } from "../../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN";
import { ReactComponent as CopyPage } from "../../../../../../assets/images/copyPage.svg";
import Page0Table from "./Page0Table";
import { StepByStepBar } from "../../../../../../components/StepByStepBar/StepByStepBar";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

// #region styled-components

const Wrapper = styled.div`
  min-height: 720px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const Left = styled.div`
  display: flex;
`;

const Center = styled.div`
  display: flex;
`;

const Right = styled.div`
  display: flex;
`;

//#endregion

function Page0(props) {
  //#region state initialization
  // i18n
  const { t } = useTranslation(["common", "lgsSalesOrderPreparing"]);
  const [cookies] = useCookies();
  const lang = cookies["_system_culture"];

  const { parentData, setParentData, page, processSteps, handleNextPage } =
    props;
  const [orderGuid, setOrderGuid] = useState("");
  const [advancedSearchShow, setAdvancedSearchShow] = useState(false);
  const [buttonName, setButtonName] = useState(
    t("common:button.advancedSearch")
  );

  //#endregion

  const handlePurchaseOrderNext = () => {
    //console.log(orderGuid);

    const newParentData = { ...parentData };
    newParentData.orderGuid = orderGuid;
    setParentData(newParentData);
    handleNextPage();
  };

  // 每次回到page0清空parentData
  useEffect(() => {
    const newParentData = {
      ...parentData,
      isEverEnterPage: {
        page1: false,
        page2: false,
      },
      page1: {},
      page2: {},
    };

    setParentData(newParentData);
  }, []);

  useEffect(() => {
    setButtonName(t("common:button.advancedSearch"));
  }, [lang, t("common:button.advancedSearch")]);

  return (
    <>
      {/* 上下步驟 */}
      <Container>
        <Left>
          <BtnNormal2
            className="btn-primary d-inline-block me-2 mediumSmallBtn"
            keyName="advancedSearchCancel"
            word={buttonName}
            handleClick={() => {
              setAdvancedSearchShow(true);
              // setButtonName(t('common:button.advancedSearch'));
            }}
          >
            <CopyPage className="me-2 mb-1" />
          </BtnNormal2>
        </Left>
        <Center>
          <StepByStepBar steps={processSteps} activePage={page} />
        </Center>
        <Right>
          {page < processSteps.length - 1 ? (
            <BtnNormal2
              className="btn-primary d-inline-block me-2 mediumBtn"
              keyName="advancedSearchCancel"
              word={t("common:button.nextStep")}
              handleClick={() => {
                handlePurchaseOrderNext(orderGuid);
              }}
              disabled={!orderGuid}
            />
          ) : (
            <BtnNormal2 className="d-none" />
          )}
        </Right>
      </Container>

      <div className="position-relative">
        <Wrapper className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
          <Page0Table
            singleChecked={orderGuid}
            setSingleChecked={setOrderGuid}
            advancedSearchShow={advancedSearchShow}
            setAdvancedSearchShow={setAdvancedSearchShow}
          />
        </Wrapper>
      </div>
    </>
  );
}

export default Page0;
