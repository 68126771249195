import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Icn_CheckAndCircle } from "../../../../../../assets/images/icn_CheckAndCircle.svg";
import { PrevBtnSmall } from "../../../../../../components/Buttons/PrevBtnSmall";
import { NextBtnSmall } from "../../../../../../components/Buttons/NextBtnSmall";
import { StepByStepBar } from "../../../../../../components/StepByStepBar/StepByStepBar";
import { SaveSubmitBtn } from "../../../../../../components/Buttons/SaveSubmitBtn";
import { CancelSubmitBtn } from "../../../../../../components/Buttons/CancelSubmitBtn";
import useAxios from "../../../../../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// #region styled-components

// 換上下頁元件
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const Left = styled.div`
  display: flex;
`;

const Center = styled.div`
  display: flex;
`;

const Right = styled.div`
  display: flex;
`;

// dialog
const CardWrapper = styled.div`
  width: 384px;
  height: 408px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const CardTitle = styled.h1`
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 16px;
  text-align: center;
`;
const CardText = styled.h2`
  font-size: 14px;
  text-align: center;
`;

const CardText2 = styled.h2`
  font-size: 14px;
  text-align: center;
  color: red;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 40px;
`;

const ButtonLeft = styled.button`
  width: 136px;
  height: 32px;
  margin-top: 88px;
`;
const ButtonRight = styled.button`
  width: 136px;
  height: 32px;
  margin-top: 88px;
`;

//#endregion

function Page3(props) {
  const {
    parentData,
    page,
    setPage,
    processSteps,
    handlePrevPage,
    setParentData,
    emptyPaymentData,
  } = props;
  const [success, setSuccess] = useState(false);
  console.log(props);
  const isDelivery = parentData.status;
  const navigate = useNavigate();
  const handleCreateNewPayment = () => {
    // 清空parent Data
    // setParentData(emptyPaymentData);
    // setPage(0);
    location.reload();
  };
  const { t } = useTranslation(["common", "lgsSalesOrderPreparing"]);

  //  console.log("enter page3,", parentData.isDelivery);

  return (
    <>
      <Container>
        <Left></Left>
        <Center className="">
          <StepByStepBar steps={processSteps} activePage={2} />
        </Center>
        <Right></Right>
      </Container>
      <CardWrapper className="d-flex flex-column border-grey3 rounded-xxl bg-white mx-auto mt-5">
        <Icn_CheckAndCircle className="mx-auto my-6" />
        <CardTitle>
          {`${t("lgsSalesOrderPreparing:page3.orderNo")} ${
            // props.parentData.page2.goodsItemInfo.orderNo
            props.parentData.updateData.orderNo
          }`}
        </CardTitle>
        <CardTitle>
          {`${t("lgsSalesOrderPreparing:page3.shipmentNo")} ${
            props.parentData.res
          }`}
        </CardTitle>
        {isDelivery  === "1" ? (
          <CardText>{t("lgsSalesOrderPreparing:page3.finished")}</CardText>
        ) : (
          <CardText2>{t("lgsSalesOrderPreparing:page3.undone")}</CardText2>
        )}

        <ButtonWrapper className="d-flex justify-content-center">
          <ButtonLeft
            className="btn btn-outline-primary mx-2"
            onClick={() => navigate("/")}
          >
            {t("lgsSalesOrderPreparing:page3.backToHome")}
          </ButtonLeft>

          <ButtonRight
            className="btn btn-primary mx-2"
            onClick={() => {
              handleCreateNewPayment();
            }}
          >
            {t("lgsSalesOrderPreparing:page3.newPaymentClose")}
          </ButtonRight>
        </ButtonWrapper>
      </CardWrapper>
    </>
  );
}

export default Page3;
