import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PrevIcon } from '../../assets/images/icn_Prev.svg';
import { BtnSmall } from './BtnSmall';

const IconDiv = styled.div`
	width: 35px;
	height: 35px;
	padding-block: 6px;
	&:hover {
		svg path {
			fill: #ffffff;
		}
	}
`;

export const PrevBtnSmall = ({ className, handleClick }) => {
	// console.log(className, to);
	return (
		<BtnSmall
			className="layout-title-searchBtn layout-title-item btn btn-outline-primary d-flex justify-content-center align-items-center p-0"
			key={'PrevBtn'}
			onClick={() => handleClick()}
		>
			<IconDiv>
				<PrevIcon />
			</IconDiv>
		</BtnSmall>
	);
};
