import React from 'react';
import { ReactComponent as PrintIcon } from '../../assets/images/icn_Print.svg';
import { BtnNormal } from './BtnNormal';

export const PrintBtnNormal = ({ className, to, handleClick }) => {
	// console.log(className, to);
	return (
		<BtnNormal
			className={'btn btn-primary'}
			to={''}
			key={'PrintBtn'}
			onClick={(e) => handleClick(e)}
		>
			<PrintIcon />
			<span>列印</span>
		</BtnNormal>
	);
};
