import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import useAxios from '../../../../../hooks/useAxios';
import { TabPage } from '../../../LgsSalesOrder/exports';
import { DialogWarning } from '../DialogWarning';
import BottomInfo from './BottomInfo';
import AccountDocumentTab from './TabContent/AccountDocumentTab/AccountDocumentTab';
import HistoryOverview from './TabContent/HistoryOverview/HistoryOverview';
import PaymentHistory from './TabContent/PaymentHistory/PaymentHistory';
import PaymentTerm from './TabContent/PaymentTerm/PaymentTerm';
import TopInfo from './TopInfo';

// #region styled-components

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
`;

const Right = styled.div`
    display: flex;
`;

//#endregion
/**
 * Page2 組件，用於顯示銷售訂單支付相關頁面的第二頁內容。
 *
 * 主要功能：
 * 1. 顯示分頁標題、步驟條和內容。
 * 2. 包含子組件 TopInfo、BottomInfo、PaymentTerm、PaymentHistory 和 AccountDocumentTab。
 * 3. 提供上一步、下一步、保存和取消按鈕的點擊事件處理。
 * 4. 使用 react-i18next 實現多語言支持，提供 t 函數。
 *
 * @param {Object} props - 組件的屬性
 * @param {Object} props.parentData - 父組件傳遞的數據
 * @param {Function} props.setParentData - 更新父組件數據的函數
 * @param {number} props.page - 當前頁面數
 * @param {Function} props.setPage - 更新當前頁面數的函數
 * @param {Array} props.processSteps - 頁面步驟列表
 * @param {Function} props.handlePrevPage - 上一步按鈕點擊事件處理函數
 * @param {Function} props.handleNextPage - 下一步按鈕點擊事件處理函數
 * @param {Object} props.salesOrderData - 銷售訂單相關數據
 * @param {Function} props.setSalesOrderData - 更新銷售訂單相關數據的函數
 * @param {Object} props.dialogConfirmData - 確認對話框相關數據
 * @param {Function} props.setDialogConfirmData - 更新確認對話框相關數據的函數
 * @param {string} props.dialogBtnKeyName - 對話框按鈕名稱
 * @param {Function} props.setDialogBtnKeyName - 更新對話框按鈕名稱的函數
 * @param {boolean} props.isOpen - 模態框是否打開
 * @param {Function} props.setIsOpen - 更新模態框打開狀態的函數
 * @param {Object} props.modalData - 模態框相關數據
 * @param {Function} props.setModalData - 更新模態框相關數據的函數
 * @param {string} props.warningMessage - 警告消息
 * @param {Function} props.setWarningMessage - 更新警告消息的函數
 * @param {string} props.CurrencyCode - 公司貨幣代碼
 * @param {string} props.CurrencyName - 公司貨幣名稱
 * @param {number} props.DecimalPlaceTl - 貨幣小數位數
 * @param {Object} props.t - react-i18next 提供的多語言函數
 *
 * @returns {JSX.Element} Page2 組件的 JSX 元素
 */
function Page2({
    parentData,
    setParentData,
    page,
    setPage,
    processSteps,
    handlePrevPage,
    handleNextPage,
    salesOrderData,
    setSalesOrderData,
    dialogConfirmData,
    setDialogConfirmData,
    dialogBtnKeyName,
    setDialogBtnKeyName,
    isOpen,
    setIsOpen,
    modalData,
    setModalData,
    warningMessage,
    setWarningMessage,
    CurrencyCode,
    CurrencyName,
    DecimalPlaceTl,
    t,
}) {
    //#region state initiation
    const [success, setSuccess] = useState(false);
    const [pageData, setPageData] = useState(parentData);
    const [accountList, setAccountList] = useState([]);
    //#endregion

    //#region variable initiation

    // 分頁title
    const tabNavList = [
        t('page2.tabs.paymentDetails'),
        t('page2.tabs.paymentTerms'),
        t('page2.tabs.paymentHistory'),
        t('page2.tabs.accountingVoucher'),
        // t('page2.tabs.transactionHistoryOverview'),
    ];

    // 分頁內容 component
    const tabContent = [
        <div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
            <BottomInfo
                pageData={pageData}
                setPageData={setPageData}
                topInfoData={salesOrderData?.topInfoData}
                salesOrderData={salesOrderData}
                dialogBtnKeyName={dialogBtnKeyName}
                setDialogBtnKeyName={setDialogBtnKeyName}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                modalData={modalData}
                setModalData={setModalData}
                CurrencyCode={CurrencyCode}
                CurrencyName={CurrencyName}
                DecimalPlaceTl={DecimalPlaceTl}
                t={t}
            />
        </div>,
        <PaymentTerm
            salesOrderPaymentTermList={parentData.salesOrderPaymentTermList}
            foreignCurrency={parentData.salesOrder.foreignCurrency}
            t={t}
        />,
        // <HistoryOverview parentData={parentData}
        // t={t}/>,
        <PaymentHistory
            salesOrderPayment={parentData.salesOrderPaymentList}
            foreignCurrency={parentData.salesOrder.foreignCurrency}
            t={t}
        />,
        <AccountDocumentTab
            parentData={parentData}
            tab3Data={{
                // 前端畫面使用
                accountDocumentName: '會計憑證',
                accountList: accountList,

                // 後端需要的，分開3個list
                dataToBackEnd: {
                    goodsReceivedNoteList: [],
                    newInvoiceList: [],
                    entry3: [],
                },
            }}
            DecimalPlaceTl={DecimalPlaceTl}
            foreignCurrency={parentData.salesOrder.foreignCurrency}
            t={t}
        />,
    ];

    //#endregion

    //#region function

    const { sendRequest: axiosPaymentSubmit } = useAxios();

    // 往下一頁之前的檢查
    const handleSubmit = () => {
        // handleNextPage();

        console.log(parentData);
        console.log(pageData);

        const {
            postingDate,
            staffGuid,
            grossAmount,
            foreignGrossAmount,
            exchangeRate,
            exchangeDifferenceAmount,
            note,
        } = pageData.receive;

        const data = {
            salesOrderGuid: pageData.salesOrderGuid,
            postingDate,
            staffGuid,
            accountDocumentGuid: pageData.accountGuid,
            grossAmount,
            foreignGrossAmount,
            exchangeRate,
            exchangeDifferenceAmount,
            note,
            accountDocumentItem1List: [accountList[0]],
            accountDocumentItem2List: [accountList[1]],
            accountDocumentItem3List: parentData.salesOrder.foreignCurrency
                ? [accountList[2]]
                : null,
        };

        console.log(data);

        axiosPaymentSubmit(
            {
                url: `api/Logistics/LgsSalesOrderPayment/insert`,
                method: 'POST',
                data,
            },
            async (res) => {
                handleNextPage();
            }
        );
    };

    // state
    // axios
    const { sendRequest: axiosSalesTermList } = useAxios();

    //#endregion

    useEffect(() => {
        axiosSalesTermList(
            {
                url: `api/Logistics/LgsSalesOrderPayment/getPage3?orderGuid=${parentData.salesOrder.orderGuid}`,
            },
            async (res) => {
                console.log(res);
                console.log(parentData);
                const {
                    accountDocumentName,
                    salesOrderPaymentTermList,
                    salesOrderPaymentList,
                    entry1,
                    entry2,
                    entry3,
                } = res;

                // entry1.amountDr = parentData.salesOrder.foreignCurrency
                //     ? Number(pageData.receive.grossAmountPayment).toFixed(
                //           DecimalPlaceTl
                //       )
                //     : Number(pageData.receive.grossAmountPayment).toFixed(
                //           DecimalPlaceTl
                //       );

                // entry2.amountCr = Number(pageData.receive.grossAmount).toFixed(
                //     DecimalPlaceTl
                // );
                // entry2.accountGuid = pageData.receive.accountGuid;
                // entry2.accountNo = pageData.receive.accountNo;
                // entry2.name01 = pageData.receive.accountName;

                if (parentData.salesOrder.foreignCurrency) {
                    // const moneyQQ =
                    //     pageData.receive.exchangeDifferenceAmount > 0
                    //         ? entry3[0]
                    //         : entry3[0];

                    // moneyQQ.amountDr =
                    //     pageData.receive.exchangeDifferenceAmount > 0
                    //         ? pageData.receive.exchangeDifferenceAmount
                    //         : null;

                    // moneyQQ.amountCr = pageData
                    //     ? null
                    //     : Number(
                    //           -pageData.receive.exchangeDifferenceAmount
                    //       ).toFixed(DecimalPlaceTl);

                    entry1.displayOrder = 1;
                    entry2.displayOrder = 2;
                    entry3.displayOrder = 3;
                    setAccountList([...entry1, ...entry2, ...entry3]);
                } else {
                    entry1.displayOrder = 1;
                    entry2.displayOrder = 2;
                    setAccountList([...entry1, ...entry2]);
                }

                
                setParentData({
                    ...parentData,
                    accountDocumentName,
                    salesOrderPaymentTermList,
                    salesOrderPaymentList,
                    entry1,
                    entry2,
                    entry3,
                });
            }
        );
    }, []);

    /**
     * DialogWarning 返回btn確認dialog
     **/
    const [isWarningOpen, setIsWarningOpen] = useState(false);

    // DialogWarning Modal open
    const handleDialogWarning = () => {
        setIsWarningOpen(true);
    };

    const handleCancel = () => {};
    console.log(parentData);

    return (
        <>
            {/* DialogWarning element */}
            <DialogWarning
                isWarningOpen={isWarningOpen}
                setIsWarningOpen={setIsWarningOpen}
                warningMessage={warningMessage}
            />

            <Container className="">
                <Left className="">
                    {page !== 0 && (
                        <BtnNormal
                            className="layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center"
                            key="prevBtn"
                            onClick={() => handlePrevPage()}
                        >
                            {t('common:button.prevStep')}
                        </BtnNormal>
                    )}
                </Left>
                <Center className="">
                    <StepByStepBar steps={processSteps} activePage={page} />
                </Center>
                <Right className="">
                    <BtnNormal
                        className="layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center"
                        key="nextBtn"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit(page);
                        }}
                    >
                        {t('common:button.saveButton')}
                    </BtnNormal>
                    <BtnNormal
                        className="layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center"
                        key="cancelBtn"
                        onClick={() => {
                            handleCancel();
                        }}
                    >
                        {t('common:button.cancelButton')}
                    </BtnNormal>
                </Right>
            </Container>

            <div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
                <TopInfo
                    pageData={pageData}
                    setPageData={setPageData}
                    topInfoData={salesOrderData?.topInfoData}
                    salesOrderData={salesOrderData}
                    dialogBtnKeyName={dialogBtnKeyName}
                    setDialogBtnKeyName={setDialogBtnKeyName}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    modalData={modalData}
                    setModalData={setModalData}
                    CurrencyCode={CurrencyCode}
                    CurrencyName={CurrencyName}
                    DecimalPlaceTl={DecimalPlaceTl}
                    t={t}
                />
            </div>
            <TabPage
                tabNavList={tabNavList}
                tabContent={tabContent}
                defaultPage={0}
            />
        </>
    );
}

export default Page2;
