import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../assets/images/icn_inputDialog.svg';
import ThreeColLayout from '../../../../components/ThreeColLayout/ThreeColLayout';
import { DialogCol } from '../Dialog/DialogCol';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icn_OpenModal } from '../../../../assets/images/icn_OpenModal.svg';
import UomModal from '../Dialog/UomModal';
import axios from 'axios';
import Attachment from './Attachment';
import PriceArea from './PriceArea';
import { DialogCustomerClass } from '../Dialog/DialogCustomerClass';
import { DialogCustomerClassDeleteConfirm } from '../Dialog/DialogCustomerClassDeleteConfirm';

//#region styled-components
const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const InputElement = styled.input`
	border: ${(props) => (props.isDetailRoute ? '0' : '1')}px solid var(--grey2);
	border-bottom-width: 1px;

	border-radius: ${(props) => (props.isDetailRoute ? '0' : '4')}px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #fff;
	}
`;

const TextareaElement = styled.textarea`
	border: ${(props) => (props.isDetailRoute ? '0' : '1')}px solid var(--grey2);
	border-bottom-width: 1px;
	border-radius: ${(props) => (props.isDetailRoute ? '0' : '4')}px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #fff;
	}
`;

const Select = styled.select`
	border: ${(props) => (props.isDetailRoute ? '0' : '1')}px solid var(--grey2);
	border-bottom-width: 1px;
	border-radius: 4px;
	min-width: 24px;
	padding: 6px 8px;
	margin-bottom: 12px;
	height: 32px;
	width: 100%;
	outline: none;
	cursor: ${(props) => (props.isDetailRoute ? 'default' : 'pointer')};

	/* 下拉箭頭圖案 */
	background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
		linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
	background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
	background-size: 6px 6px, 6px 6px;
	background-repeat: no-repeat;
	-webkit-appearance: none;
`;

// -----DialogCol------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	--bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: ${(props) => (props.isDetailRoute ? '0' : '1')}px solid var(--grey2);
	border-bottom-width: 1px;
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) =>
		props.isValueExist && !props.isDetailRoute ? 'inline' : 'none'};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: ${(props) => (props.isDetailRoute ? '0' : '1')}px solid var(--grey2);
	border-bottom-width: 1px;
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: ${(props) => (props.isDetailRoute ? 'default' : 'pointer')};
`; // dialog按鈕

//#endregion

const TopInfo = ({
	pageData,
	setPageData,
	dialogBtnKeyName,
	setDialogBtnKeyName,
	isOpen,
	setIsOpen,
	modalData,
	setModalData,
	baseInfoList,
	langList,
	isDetailRoute,
	isEditRoute,
	isCreateRoute,
	selectedCustomerClassList,
	error,
	tableBodyData,
	setTableBodyData,
	setSelectedCustomerClassList,
}) => {
	// i18n
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	const [uomDialogOpen, setUomDialogOpen] = useState(false); // 計量單位dialog

	console.log('pageData', pageData);

	// 輸入框
	const handleInput = (inputName, event) => {
		let newPageData = { ...pageData };
		newPageData.goodsItemInfo[inputName] = event.target.value;
		newPageData.goodsItemInfo[`${inputName}Warning`] = ''; // 清空錯誤訊息

		setPageData(newPageData);
	};

	// 驗證商品代碼是否重複
	const checkSalesKitCode = async (salesKitCode) => {
		await axios
			.get(
				`api/Logistics/LgsSalesKit/verifyCode?SalesKitGuid=${
					pageData.goodsItemInfo.salesKitGuid ?? ''
				}&SalesKitCode=${salesKitCode}`
			)
			.then((res) => {
				console.log(res.data);
				if (res.data.status === '000') {
					setPageData({
						...pageData,
						goodsItemInfo: {
							...pageData.goodsItemInfo,
							salesKitCodeWarning: '',
						},
					});
				} else {
					setPageData({
						...pageData,
						goodsItemInfo: {
							...pageData.goodsItemInfo,
							salesKitCodeWarning: res.data.message, // '此代碼已被使用'
						},
					});
				}
			});
	};

	// 黃色取消按鈕
	const handleCancelClick = (btnKeyName) => {
		const newData = { ...pageData };

		newData.goodsItemInfo[`${btnKeyName}Guid`] = '';
		newData.goodsItemInfo[`${btnKeyName}Name`] = '';
		newData.goodsItemInfo[`${btnKeyName}Code`] = '';

		setPageData(newData);
	};

	// 打開選單dialog
	const handleOpen = (btnKeyName, listName) => {
		setDialogBtnKeyName(btnKeyName);
		setModalData(baseInfoList[listName]);
		setIsOpen(true);
	};

	// 計量單位dialog (此處的dialog與其他不同因此另外控制)
	const handleUomDialogOpen = () => {
		setUomDialogOpen(!uomDialogOpen);
	};

	// 以下都是 shared price相關 (其內容是從bottomInfo移植過來)

	// 客戶分類List
	const [isDialogCustomerClassOpen, setIsDialogCustomerClassOpen] =
		useState(false); // 客戶分類dialog
	const [customerClassBtnActive, setCustomerClassBtnActive] = useState(''); //客戶分類BTN 預設為空

	const handleCustomerClassBtnClick = (customerClassGuid) => {
		setCustomerClassBtnActive(customerClassGuid);
	};

	const [dialogCustomerClassDeleteData, setDialogCustomerClassDeleteData] =
		useState({
			isOpen: false,
			title: t('lgsSalesKit:deleteCustomerCategory'), // 刪除客戶分類
			customerClassGuid: '',
		}); // 確認"刪除"客戶分類

	const [dialogCopyConfirmData, setDialogCopyConfirmData] = useState({
		isOpen: false,
		title: '',
	}); // 確認"複製"規格或顏色視窗

	// 客戶分類dialog
	const handleOpenDialogCustomerClass = (btnKeyName, listName) => {
		setDialogBtnKeyName(btnKeyName);

		// 已選擇的客戶類別GUID
		const selectedCustomerClassGuidList = selectedCustomerClassList.map(
			(el) => el.customerClassGuid
		);

		// baseInfoList[listName]的customerClassGuid不包含selectedCustomerClassGuidList已經有的guid
		// 剩下的就是可以選擇的
		const temp = baseInfoList[listName].filter(
			(el) =>
				!selectedCustomerClassGuidList.includes(el.customerClassGuid)
		);
		// console.log(temp);

		setModalData(temp);
		setIsDialogCustomerClassOpen(true);
	};

	const [copyFunctionName, setCopyFunctionName] = useState(''); // "複製"的名稱 (copyAppendix copySetItem copySetPrice)

	const [isCustomerClassDeleteClicked, setIsCustomerClassDeleteClicked] =
		useState(false);
	const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);

	useEffect(() => {
		if (isConfirmBtnClicked) {
			handleSpecSelected(
				dialogConfirmData.confirmInfo.guid,
				dialogConfirmData.confirmInfo.specName
			);
			setIsConfirmBtnClicked(false);
		}

		if (isCustomerClassDeleteClicked) {
			console.log(dialogCustomerClassDeleteData);

			// 刪除左欄客戶分類
			setSelectedCustomerClassList(
				selectedCustomerClassList.filter((el) => {
					return (
						el.customerClassGuid !==
						dialogCustomerClassDeleteData.customerClassGuid
					);
				})
			);

			// 刪除價格條件
			const newData = { ...pageData };

			const newPriceDetailsList = newData.priceDetailsList.map((el) => {
				if (
					el.customerClassGuid ===
					dialogCustomerClassDeleteData.customerClassGuid
				) {
					if (
						el.action === 'native' ||
						el.action === 'update' ||
						el.action === 'delete'
					) {
						return { ...el, action: 'delete' };
					} else return el;
				} else {
					return el;
				}
			});

			// 將 'insert' 的元素從陣列中刪除
			const filteredPriceDetailsList = newPriceDetailsList.filter(
				(el) => el.action !== 'insert'
			);

			console.log(filteredPriceDetailsList);

			setPageData({
				...newData,
				priceDetailsList: [...filteredPriceDetailsList],
			});

			setIsCustomerClassDeleteClicked(false);
		}
	}, [isConfirmBtnClicked, isCustomerClassDeleteClicked]);

	return (
		<>
			<ThreeColLayout
				LeftCol={
					<>
						<div className="d-flex flex-column mb-2">
							<LabelNormal htmlFor={`salesKitCode`}>
								{t('lgsSalesKit:productCode')}
								{/* 商品代碼 */}
							</LabelNormal>
							<InputElement
								type="text"
								id={`salesKitCode`}
								className=""
								value={pageData.goodsItemInfo.salesKitCode}
								onChange={(event) =>
									handleInput('salesKitCode', event)
								}
								onBlur={(e) =>
									e.target.value &&
									checkSalesKitCode(e.target.value)
								}
								isDetailRoute={isDetailRoute} // 由於detail與edit共用此欄位, 所以要判斷
								disabled={isDetailRoute}
							/>
							<span className="text-danger ms-1 my-1">
								{pageData.goodsItemInfo.salesKitCodeWarning}
							</span>
						</div>
						<div className="d-flex flex-column mb-2">
							<LabelNormal htmlFor={`name01`}>
								{t('lgsSalesKit:name')}
								{/* 名稱 */}
							</LabelNormal>
							<InputElement
								type="text"
								id={`name01`}
								className=""
								defaultValue={pageData.goodsItemInfo.name01}
								onChange={(event) =>
									handleInput('name01', event)
								}
								isDetailRoute={isDetailRoute}
								disabled={isDetailRoute}
							/>
							<span className="text-danger ms-1 my-1">
								{pageData.goodsItemInfo.name01Warning}
							</span>
						</div>

						<div className={`d-flex flex-column mb-2`}>
							<LabelNormal>
								{t('lgsSalesKit:unitOfMeasure')}
								{/* 計量單位 */}
							</LabelNormal>
							<div className="input-group">
								<InputElement
									className="form-control border-grey2"
									placeholder=""
									disabled={true}
									value={
										pageData.goodsItemInfo
											?.uomDisplayName || ''
									}
									onChange={() => {}}
									type="text"
									id="uomCode"
									name="uomCode"
									style={{
										backgroundColor: '#fff',
										borderRight: 'none',
									}}
									isDetailRoute={isDetailRoute}
								/>
								<DialogBtn
									style={{
										backgroundColor: '#fff',
									}}
									onClick={() =>
										isDetailRoute
											? {}
											: handleUomDialogOpen()
									}
									isDetailRoute={isDetailRoute}
								>
									<Icn_OpenModal />
								</DialogBtn>
							</div>
							<span className="text-danger ms-1 mt-1">
								{pageData.goodsItemInfo?.uomCodeWarning}
							</span>
						</div>
						{Object.keys(baseInfoList).length > 0 && (
							<UomModal
								modalOpen={uomDialogOpen}
								handleClose={() => setUomDialogOpen(false)}
								uomList={baseInfoList.uomList}
								uomCategoryList={baseInfoList.uomcategoryList}
								pageData={pageData}
								setPageData={setPageData}
							/>
						)}

						<LabelNormal>全部規格價格相同</LabelNormal>
						<Select
							id="samePrice"
							name="samePrice"
							type="select"
							value={pageData.goodsItemInfo?.samePrice || '0'}
							defaultValue={'0'} // 設置預設值
							onChange={(event) => {
								handleInput('samePrice', event);
							}}
							isDetailRoute={isDetailRoute}
							disabled={isDetailRoute}
						>
							<option value={'0'}>否</option>
							<option value={'1'}>是</option>
						</Select>

						{/* <div className="row">
						<div className="col-lg-5 pe-lg-2">
							<div className="d-flex flex-column mb-2">
								<LabelNormal htmlFor={`currencyGuid`}>
									{
										t('lgsSalesKit:unitPriceExcludingTax')
										//  未稅單價
									}
								</LabelNormal>
								<DialogWrapper className="row flex-nowrap">
									<InputDialogElementData
										type="text"
										value={
											pageData.goodsItemInfo
												.currencyGuid || ''
										}
										onChange={() => {}}
									/>
									<InputDialogElementView
										style={{
											// minWidth: '85px',
											cursor: 'auto',
										}}
										value={
											pageData.goodsItemInfo
												.currencyName || ''
										}
										onChange={() => {}}
										isDetailRoute={isDetailRoute}
										disabled={isDetailRoute}
									/>

									<CancelInputBtn
										onClick={() => {
											handleCancelClick('currency');
										}}
										isValueExist={
											pageData.goodsItemInfo.currencyName
										}
										isDetailRoute={isDetailRoute}
									>
										<Icn_InputYellowCancel />
									</CancelInputBtn>

									<DialogBtn
										onClick={() => {
											isDetailRoute
												? {}
												: handleOpen(
														'currency',
														'currencyList'
												  );
										}}
										isDetailRoute={isDetailRoute}
										disabled={isDetailRoute}
									>
										<Icn_InputDialog />
									</DialogBtn>
								</DialogWrapper>
								<span className="text-danger ms-1 my-1">
									{pageData.goodsItemInfo.currencyGuidWarning}
								</span>
							</div>
						</div>
						<div className="col-lg-7 ps-lg-2">
							<div className="d-flex flex-column">
								<LabelNormal
									htmlFor={`listPrice`}
									className="mb-4"
								></LabelNormal>
								<InputElement
									type="text"
									id={`listPrice`}
									className=""
									defaultValue={
										pageData.goodsItemInfo.listPrice
									}
									onChange={(event) =>
										handleInput('listPrice', event)
									}
									isDetailRoute={isDetailRoute}
									disabled={isDetailRoute}
								/>
								<span className="text-danger ms-1 my-1">
									{pageData.goodsItemInfo.listPriceWarning}
								</span>
							</div>
						</div>
					</div> */}

						<div className="row">
							<div className="col-lg-6 pe-lg-2">
								<div className="d-flex flex-column">
									<LabelNormal htmlFor={`startDate`}>
										{t('lgsSalesKit:salesStartDate')}
										{/* 銷售起始日期 */}
									</LabelNormal>
									<InputElement
										type="date"
										id={`startDate`}
										className=""
										defaultValue={
											pageData.goodsItemInfo.startDate
										}
										onChange={(event) =>
											handleInput('startDate', event)
										}
										isDetailRoute={isDetailRoute}
										disabled={isDetailRoute}
									/>
									<span className="text-danger ms-1 my-1">
										{
											pageData.goodsItemInfo
												.startDateWarning
										}
									</span>
								</div>
							</div>

							<div className="col-lg-6 ps-lg-2">
								<div className="d-flex flex-column">
									<LabelNormal htmlFor={`endDate`}>
										{t('lgsSalesKit:salesEndDate')}
										{/* 銷售截止日期 */}
									</LabelNormal>
									<InputElement
										type="date"
										id={`endDate`}
										className=""
										defaultValue={
											pageData.goodsItemInfo.endDate
										}
										onChange={(event) =>
											handleInput('endDate', event)
										}
										isDetailRoute={isDetailRoute}
										disabled={isDetailRoute}
									/>
									<span className="text-danger ms-1 my-1"></span>
								</div>
							</div>
						</div>
					</>
				}
				CenterCol={
					<>
						{/* DialogCol element */}
						<DialogCol
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							modalData={modalData}
							setModalData={setModalData}
							btnKeyName={dialogBtnKeyName}
							pageData={pageData}
							setPageData={setPageData}
						/>
						<div className="d-flex flex-column mb-2">
							<LabelNormal htmlFor={`salesKitClassGuid`}>
								{t('lgsSalesKit:productCategory')}
								{/* 商品分類 */}
							</LabelNormal>
							<DialogWrapper className="row flex-nowrap">
								<InputDialogElementData
									type="text"
									value={
										pageData.goodsItemInfo
											.salesKitClassGuid || ''
									}
									onChange={() => {}}
								/>
								<InputDialogElementView
									style={{
										minWidth: '85px',
										cursor: 'auto',
									}}
									value={
										pageData.goodsItemInfo
											.salesKitClassName || ''
									}
									onChange={() => {}}
									isDetailRoute={
										isDetailRoute ||
										pageData.goodsItemInfo.status === '1' // 啟用後不可編輯
									}
									disabled={
										isDetailRoute ||
										pageData.goodsItemInfo.status === '1'
									}
								/>

								<CancelInputBtn
									onClick={() => {
										handleCancelClick('salesKitClass');
									}}
									isValueExist={
										pageData.goodsItemInfo.salesKitClassName
									}
									isDetailRoute={
										isDetailRoute ||
										pageData.goodsItemInfo.status === '1'
									}
								>
									<Icn_InputYellowCancel />
								</CancelInputBtn>

								<DialogBtn
									onClick={() => {
										isDetailRoute ||
										pageData.goodsItemInfo.status === '1'
											? {}
											: handleOpen(
													'salesKitClass',
													'salesKitClassList'
											  );
									}}
									isDetailRoute={
										isDetailRoute ||
										pageData.goodsItemInfo.status === '1'
									}
									disabled={
										isDetailRoute ||
										pageData.goodsItemInfo.status === '1'
									}
								>
									<Icn_InputDialog />
								</DialogBtn>
							</DialogWrapper>
							<span className="text-danger ms-1 my-1">
								{
									pageData.goodsItemInfo
										.salesKitClassGuidWarning
								}
							</span>
						</div>
						{langList[1] !== '' && (
							<div className="d-flex flex-column mb-2">
								<LabelNormal htmlFor={`name02`}>
									{`${t('lgsSalesKit:name')}( ${
										langList[1]
									} )`}
								</LabelNormal>
								<InputElement
									type="text"
									id={`name02`}
									className=""
									defaultValue={pageData.goodsItemInfo.name02}
									onBlur={(event) =>
										handleInput('name02', event)
									}
									isDetailRoute={isDetailRoute}
									disabled={isDetailRoute}
								/>
								<span className="text-danger ms-1 my-1"></span>
							</div>
						)}
						<div
							className={`wrapper d-flex flex-column ${
								langList[1] === '' && 'mt-9'
							} mb-2`}
						>
							<LabelNormal
								htmlFor={'content'}
								className={`${langList[2] === '' && 'mt-3'} `}
							>
								商品內容
								{/* 商品內容 */}
							</LabelNormal>
							<TextareaElement
								//style={elementStyle}
								id={'content'}
								rows={'4'}
								cols={'30'}
								defaultValue={pageData.goodsItemInfo.content}
								onChange={(event) =>
									handleInput('content', event)
								}
								isDetailRoute={isDetailRoute}
								disabled={isDetailRoute}
							/>
						</div>
					</>
				}
				RightCol={
					<>
						{langList[2] !== '' && (
							<div className="d-flex flex-column mt-lg-10 mb-2">
								<LabelNormal htmlFor={`name03`}>
									{`${t('lgsSalesKit:name')}( ${
										langList[2]
									} )`}
									{/* 名稱 XXX */}
								</LabelNormal>
								<InputElement
									type="text"
									id={`name03`}
									className=""
									defaultValue={pageData.goodsItemInfo.name03}
									onChange={(event) =>
										handleInput('name03', event)
									}
									isDetailRoute={isDetailRoute}
									disabled={isDetailRoute}
								/>
								<span className="text-danger ms-1 my-1"></span>
							</div>
						)}
						<div
							className={`wrapper d-flex flex-column ${
								langList[2] === '' && 'mt-13'
							} mb-2`}
						>
							<LabelNormal
								htmlFor={'note'}
								className={`${langList[2] === '' && 'mt-3'} `}
							>
								{t('lgsSalesKit:note')}
								{/* 註記 */}
							</LabelNormal>
							<TextareaElement
								//style={elementStyle}
								id={'note'}
								rows={'4'}
								cols={'30'}
								defaultValue={pageData.goodsItemInfo.note}
								onChange={(event) => handleInput('note', event)}
								isDetailRoute={isDetailRoute}
								disabled={isDetailRoute}
							/>
						</div>
					</>
				}
			/>

			{/* 單一價格 */}
			{(isDetailRoute || isEditRoute) &&
				pageData.goodsItemInfo?.samePrice === '1' && (
					<>
						<div className="border-bottom border-grey1 w-100 my-4"></div>

						<div className="d-flex">
							<span className="fontSize125Rem fw-bold">
								銷售商品價格
							</span>
						</div>

						<PriceArea
							isSharedPrice={true}
							// itemDisplayList={itemDisplayList}
							// setItemDisplayList={setItemDisplayList}
							isEditRoute={isEditRoute}
							isDetailRoute={isDetailRoute}
							customerClassBtnActive={customerClassBtnActive}
							selectedCustomerClassList={
								selectedCustomerClassList
							}
							pageData={pageData}
							setPageData={setPageData}
							error={error}
							baseInfoList={baseInfoList}
							handleCustomerClassBtnClick={
								handleCustomerClassBtnClick
							}
							setDialogCustomerClassDeleteData={
								setDialogCustomerClassDeleteData
							}
							setDialogCopyConfirmData={setDialogCopyConfirmData}
							handleOpenDialogCustomerClass={
								handleOpenDialogCustomerClass
							}
							setCopyFunctionName={setCopyFunctionName}
						/>

						<DialogCustomerClass
							isDialogCustomerClassOpen={
								isDialogCustomerClassOpen
							}
							setIsDialogCustomerClassOpen={
								setIsDialogCustomerClassOpen
							}
							modalData={modalData}
							setModalData={setModalData}
							btnKeyName={dialogBtnKeyName}
							pageData={pageData}
							setPageData={setPageData}
							tableBodyData={tableBodyData}
							setTableBodyData={setTableBodyData}
							selectedCustomerClassList={
								selectedCustomerClassList
							}
							setSelectedCustomerClassList={
								setSelectedCustomerClassList
							}
						/>

						<DialogCustomerClassDeleteConfirm
							dialogCustomerClassDeleteData={
								dialogCustomerClassDeleteData
							}
							setDialogCustomerClassDeleteData={
								setDialogCustomerClassDeleteData
							}
							setIsCustomerClassDeleteClicked={
								setIsCustomerClassDeleteClicked
							}
						/>
					</>
				)}

			{/* 銷售商品的照片 */}
			{(isDetailRoute || isEditRoute) && (
				<>
					<div className="border-bottom border-grey1 w-100 my-4"></div>

					<div className="imgArea">
						<div className="d-flex">
							<span className="fontSize125Rem fw-bold">
								銷售商品照片
							</span>
						</div>

						<Attachment
							data={pageData}
							setData={setPageData}
							isDetailRoute={isDetailRoute}
							isSalesKitAppendixList={true}
						/>
					</div>
				</>
			)}
		</>
	);
};

export default TopInfo;
