import { useState, useEffect } from 'react';
import useAxios from '../../../../../../hooks/useAxios';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Table } from '../../../../../../components/exports';
import { useTranslation } from 'react-i18next';

function LgsSalesOrder_InvoiceList({
	register,
	errors,
	getValues,
	setValue,
	detailsInfo,
}) {
	const { t } = useTranslation(['common', 'lgsSalesOrder']);
	const [sortBy, setSortBy] = useState('invoiceDate');
	const [data, setData] = useState({});
	const { isLoading, error, sendRequest: axiosInvoice } = useAxios();
	const guid = useLocation().pathname.split('/').pop();

	const headTitleList = [
		'#',
		t('lgsSalesOrder:invoice'),
		t('lgsSalesOrder:invoiceFormatCertificateFormat'),
		t('lgsSalesOrder:return'),
		t('lgsSalesOrder:certificateDate'),
		t('lgsSalesOrder:invoiceNumberCertificateNumber'),
		t('lgsSalesOrder:taxRate'),
		t('lgsSalesOrder:taxableAmount'),
		t('lgsSalesOrder:netAmount2'),
		t('lgsSalesOrder:taxAmount3'),
	];
	const bodyTitleList = [
		'displayOrder',
		'governmentInvoice',
		'format',
		'invoiceReturn',
		'invoiceDate',
		'invoiceDocumentNo',
		'vatrate',
		'netAmount',
		'taxAmount',
		'taxableAmount',
	];
	const tableHeadStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			textAlign: 'center',
		},
		{
			textAlign: 'center',
		},
		{
			textAlign: 'center',
		},
		{
			textAlign: 'center',
		},
		{
			textAlign: 'center',
		},
	];
	const tableBodyStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'left',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'left',
		},
		{
			textAlign: 'left',
		},
		{
			textAlign: 'center',
		},
		{
			textAlign: 'right',
		},
		{
			textAlign: 'right',
		},
		{
			textAlign: 'right',
		},
	];

	useEffect(() => {
		const url = `api/Logistics/LgsSalesOrder/salesOrderInvoice?guid=${guid}`;
		axiosInvoice({ url }, (res) => {
			setData(res.data);
		});
	}, []);

	if (error) {
		console.log('error !', error);
	}

	// const {
	// 	purchaseOrderInvoiceList,
	// 	totalNetAmount,
	// 	totalTaxAmount,
	// 	totalTaxableAmount,
	// } = data;

	return (
		<>
			{data?.purchaseOrderInvoiceList && (
				<Table
					guidName="invoiceGuid"
					sortBy={sortBy}
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableData={purchaseOrderInvoiceList}
					tableHoverEffect={false}
					totalNetAmount={data?.totalNetAmount}
					totalTaxAmount={data?.totalTaxAmount}
					totalTaxableAmount={data?.totalTaxableAmount}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					register={register}
					errors={errors}
					setValue={setValue}
					getValues={getValues}
					detailsInfo={detailsInfo}
				/>
			)}
		</>
	);
}

export default LgsSalesOrder_InvoiceList;
