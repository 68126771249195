import { useState, useEffect } from 'react';
import useAxios from '../../../../../../hooks/useAxios';
import { useLocation } from 'react-router-dom';
import { Table } from '../../../../../../components/exports';
import { useTranslation } from 'react-i18next';

function LgsSalesOrder_PaymentHistory(props) {
	const { t } = useTranslation(['common', 'lgsSalesOrder']);
	const { foreignCurrency } = props.detailsInfo.salesOrder;
	const [tableData, setTableData] = useState([]);
	const [tableFooter, setTableFooter] = useState([]);

	let headTitleList = [];
	let bodyTitleList = [];
	let tableHeadStyles = [];
	let tableBodyStyles = [];

	useEffect(() => {
		let totalExchangeDifferenceAmount = 0;
		let totalForeignGrossAmount = 0;
		let totalGrossAmount = 0;

		const newSalesOrderPaymentList = [
			...props.detailsInfo.salesOrderPaymentList,
		].map((el, index) => {
			totalExchangeDifferenceAmount += el.exchangeDifferenceAmount;
			totalForeignGrossAmount += el.foreignGrossAmount;
			totalGrossAmount += el.grossAmount;

			return {
				...el,
				postingDate: el.postingDate.split('T')[0],
				displayOrder: index + 1,
			};
		});

		setTableData(newSalesOrderPaymentList);

		if (foreignCurrency) {
			setTableFooter([
				'',
				'',
				'',
				'',
				'',
				t('lgsSalesOrder:totalAmount'),
				totalGrossAmount,
				totalForeignGrossAmount,
				totalExchangeDifferenceAmount,
				'',
			]);
		} else {
			setTableFooter([
				'',
				'',
				'',
				'',
				'',
				t('lgsSalesOrder:totalAmount'),
				totalGrossAmount,
				'',
			]);
		}
	}, []);

	if (foreignCurrency) {
		headTitleList = [
			'#',
			'收款日期',
			'收款人員',
			'會計憑證編號',
			'退回 ',
			'幣別',
			'本幣收款金額',
			'外幣收款金額',
			'匯差金額',
			'註記',
		];

		bodyTitleList = [
			'displayOrder',
			'postingDate',
			'staffName',
			'documentNo',
			'isReturn',
			'currencyCode',
			'grossAmount',
			'foreignGrossAmount',
			'exchangeDifferenceAmount',
			'note',
		];

		tableHeadStyles = [
			{
				width: '48px',
				textAlign: 'center',
			},
			{
				width: '112px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '160px',
				textAlign: 'center',
			},
			{
				width: '80px',
				textAlign: 'center',
			},
			{
				width: '96px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '200px',
				textAlign: 'center',
			},
		];

		tableBodyStyles = [
			{
				width: '48px',
				textAlign: 'center',
			},
			{
				width: '112px',
				textAlign: 'left',
			},
			{
				width: '136px',
				textAlign: 'left',
			},
			{
				width: '160px',
				textAlign: 'left',
			},
			{
				width: '80px',
				textAlign: 'center',
			},
			{
				width: '96px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'right',
			},
			{
				width: '120px',
				textAlign: 'right',
			},
			{
				width: '120px',
				textAlign: 'right',
			},
			{
				width: '200px',
				textAlign: 'left',
			},
		];
	} else {
		headTitleList = [
			'#',
			'收款日期',
			'收款人員',
			'會計憑證編號',
			'退回 ',
			'幣別',
			'收款金額',
			'註記',
		];

		bodyTitleList = [
			'displayOrder',
			'postingDate',
			'staffName',
			'documentNo',
			'isReturn',
			'currencyCode',
			'grossAmount',
			'note',
		];

		tableHeadStyles = [
			{
				width: '48px',
				textAlign: 'center',
			},
			{
				width: '112px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '160px',
				textAlign: 'center',
			},
			{
				width: '80px',
				textAlign: 'center',
			},
			{
				width: '96px',
				textAlign: 'center',
			},
			{
				width: '160px',
				textAlign: 'center',
			},
			{
				//     width: '120px',
				textAlign: 'center',
			},
		];

		tableBodyStyles = [
			{
				width: '48px',
				textAlign: 'center',
			},
			{
				width: '112px',
				textAlign: 'left',
			},
			{
				width: '136px',
				textAlign: 'left',
			},
			{
				width: '160px',
				textAlign: 'left',
			},
			{
				width: '80px',
				textAlign: 'center',
			},
			{
				width: '96px',
				textAlign: 'center',
			},
			{
				width: '160px',
				textAlign: 'right',
			},
			{
				// width: '120px',
				textAlign: 'right',
			},
		];
	}

	return (
		<>
			{tableData && (
				<Table
					guidName="paymentGuid"
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableHoverEffect={false}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					foreignCurrency={foreignCurrency}
					tableData={tableData}
					tableFooter={tableFooter}
				/>
			)}
		</>
	);
}

export default LgsSalesOrder_PaymentHistory;
