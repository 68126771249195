import styled from 'styled-components';
import { ReactComponent as Icn_chevronTop } from '../../../../assets/images/icn_chevron-top.svg';
import { BtnNormal2 } from '../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as CreateIcon } from '../../../../assets/images/icn_Create.svg';
import { ReactComponent as Icn_deleted } from '../../../../assets/images/icn_DeleteCross.svg';
import PriceDetails from './PriceDetails';
import { useTranslation } from 'react-i18next';

// #region styled-components

const CustomerClassList = styled.div`
	height: 280px;
	overflow-y: scroll;
	border-radius: 0;
	border: 1px solid var(--grey1);
`;

const BtnCreate = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	cursor: pointer;
`;

//#endregion

const PriceArea = (props) => {
	const { t } = useTranslation(['common', 'lgsSalesKit']);
	const {
		isSharedPrice,
		itemDisplayList,
		setItemDisplayList,
		isEditRoute,
		isDetailRoute,
		customerClassBtnActive,
		selectedCustomerClassList,
		pageData,
		setPageData,
		error,
		baseInfoList,
		handleCustomerClassBtnClick,
		setDialogCustomerClassDeleteData,
		setDialogCopyConfirmData,
		handleOpenDialogCustomerClass,
		setCopyFunctionName,
	} = props;

	return (
		<div className="priceArea">
			{!isSharedPrice && (
				<div className="d-flex">
					<button
						className="border-0 bg-transparent fw-bold fontSize125Rem"
						onClick={() =>
							setItemDisplayList({
								...itemDisplayList,
								priceArea: !itemDisplayList.priceArea,
							})
						}
					>
						<span className="me-3">商品組合價格</span>
						{/* 價格 */}
						<Icn_chevronTop
							className="iconChevron"
							style={{
								transform: itemDisplayList.priceArea
									? ''
									: 'rotate(.5turn)',
							}}
						/>
					</button>
					{isEditRoute && (
						<BtnNormal2
							className={`btn-warning smallBtn ms-3`}
							key={'salesDetailCopyBtn'}
							keyName={'salesDetailCopyBtn'}
							elementStyle={{
								width: '56px',
							}}
							handleClick={() => {
								isDetailRoute
									? {}
									: setDialogCopyConfirmData({
											isOpen: true,
											title: t(
												'lgsSalesKit:beforeSelectingCopyData'
											),
											// 選擇複製資料前
									  });
								setCopyFunctionName('copySetPrice');
							}}
							word={t('lgsSalesKit:copy')}
							// 複製
						></BtnNormal2>
					)}
				</div>
			)}

			{(itemDisplayList?.priceArea || isSharedPrice) && (
				<div className="d-flex">
					<section className="customerClassArea col-2 ps-0 pe-1">
						<div className="d-flex justify-content-between my-2">
							<div
								className={`d-flex align-items-end ${
									isDetailRoute && 'mt-2'
								}`}
								style={{
									fontSize: '16px',
									fontWeight: 'bold',
									marginLeft: '8px',
								}}
							>
								{t('lgsSalesKit:customer')}
							</div>
							{/* 客戶 */}
							{!isDetailRoute && (
								<BtnCreate
									className={`mt-1 me-1 border-0 bg-primary`}
									onClick={() =>
										handleOpenDialogCustomerClass(
											'customerClass',
											'customerClassList',
											isSharedPrice
										)
									}
								>
									<CreateIcon />
								</BtnCreate>
							)}
						</div>
						<div className="">
							<div
								className="authTitleWrapper d-flex justify-content-center"
								style={{
									borderRadius: '.75rem .75rem 0 0',
									backgroundColor: 'var(--grey1)',
								}}
							>
								<h5
									className="fontSize875Rem fw-bold text-left text-secondary m-0 "
									style={{
										lineHeight: '40px',
									}}
								>
									{t('lgsSalesKit:category')}
								</h5>
								{/* 分類 */}
							</div>

							<CustomerClassList className="list-group  menubar-list">
								{/* 適用全部客戶 (客戶分類==="") */}
								<div
									className="list-group-item list-group-item-action py-2 border-0"
									onClick={() =>
										handleCustomerClassBtnClick('')
									}
									style={{
										cursor: 'pointer',
										minHeight: '40px',
										backgroundColor:
											customerClassBtnActive === ''
												? 'var(--grey1)'
												: 'transparent',
									}}
								>
									<div className="d-flex w-100 justify-content-between">
										<span>
											{t(
												'lgsSalesKit:applyToAllCustomers'
											)}
										</span>
										{/* 適用全部客戶 */}
									</div>
								</div>

								{/* 客戶分類 */}
								{selectedCustomerClassList.map((el) => {
									return (
										<div
											key={el.customerClassGuid}
											className="list-group-item list-group-item-action py-2 border-0"
											onClick={() =>
												handleCustomerClassBtnClick(
													el.customerClassGuid
												)
											}
											style={{
												cursor: 'pointer',
												minHeight: '40px',
												backgroundColor:
													customerClassBtnActive ===
													el.customerClassGuid
														? 'var(--grey1)'
														: 'transparent',
											}}
										>
											<div className="d-flex w-100 justify-content-between">
												<span>{el.name01}</span>

												{isEditRoute &&
													customerClassBtnActive ===
														el.customerClassGuid && (
														<span
															className="ps-2"
															onClick={() =>
																setDialogCustomerClassDeleteData(
																	{
																		isOpen: true,
																		title: t(
																			'lgsSalesKit:deleteCustomerCategory'
																		), // 刪除客戶分類
																		customerClassGuid:
																			el.customerClassGuid,
																	}
																)
															}
														>
															<Icn_deleted />
														</span>
													)}
											</div>
										</div>
									);
								})}
							</CustomerClassList>
						</div>
					</section>
					<section className=" priceDetailsArea col-10 ps-1 pe-0">
						<PriceDetails
							// view="create"
							isSharedPrice={isSharedPrice}
							data={pageData}
							setData={setPageData}
							error={error}
							baseInfoList={baseInfoList}
							customerClassActiveGuid={customerClassBtnActive}
							isDetailRoute={isDetailRoute}
							isEditRoute={isEditRoute}
						/>
					</section>
				</div>
			)}
		</div>
	);
};

export default PriceArea;
