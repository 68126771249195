import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icn_chevronCircleDown } from '../../../../../../assets/images/icn_chevronCircle-down.svg';
import { ReactComponent as Icn_chevronTop } from '../../../../../../assets/images/icn_chevron-top.svg';

//#region styled-components
const Wrapper = styled.div`
	border: 1px solid var(--grey1);
`;

const ToggleBtn = styled.h6`
	font-size: 14px;
	color: var(--primary);
	margin-top: 16px;
	margin-bottom: 0;
	cursor: pointer;
`;

const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const TRFirst = styled.tr`
	background-color: #f7f8fc !important;
`;

const TDFirstRow = styled.td`
	background-color: #f7f8fc !important;
	border: 1px solid var(--grey1) !important;
`;

const TR = styled.tr`
	height: 40px;

	&:hover {
		background-color: transparent !important;
	}
	&:hover:nth-of-type(0) {
		background-color: var(--primaryllt) !important;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	/* background-color: #f7f8fc !important; */
	text-align: left !important;
	font-weight: bold !important;

	&:nth-of-type(1) {
		text-align: center !important;
	}
`;

const ChildTD = styled.td`
	height: 40px;
	text-align: center;
`;

//#endregion

function GoodsHistory(props) {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsSalesOrder']);

	const [goodsHistoryList, setGoodsHistoryList] = useState([]);
	const [toggleBtnAllOpen, setToggleBtnAllOpen] = useState(true); // 展開/收合全部 btn
	//#endregion

	const handleDeliveryMethod = (method) => {
		console.log(method);
		switch (method) {
			case '0':
				return '自取/自行送回';
			case '1':
				return '公司配送/取回';
			case '2':
				return '物流業者';

			default:
				break;
		}
	};

	//#region useEffect
	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = props.detailsInfo.salesOrderGoodsInvoiceList;
				// console.log(data);
				setGoodsHistoryList([...data]);
			} catch (error) {
				console.error('獲取數據時出錯：', error);
			}
		};

		fetchData();
	}, [props.detailsInfo.salesOrderGoodsInvoiceList]);
	//#endregion
	console.log(goodsHistoryList);

	return (
		<div id="indexView">
			{goodsHistoryList.length !== 0 && (
				<>
					{/* button row */}
					<div className="position-relative d-flex mb-0 px-4 py-3">
						{/* 展開/收折全部 BTN */}
						<div className="ms-auto">
							{toggleBtnAllOpen ? (
								<ToggleBtn
									onClick={() => {
										// 箭頭btn向下
										document
											.querySelectorAll('.iconChevron')
											.forEach(
												(el) =>
													(el.style.transform =
														'rotate(0.5turn)')
											);

										// 隱藏全部子層
										document
											.querySelectorAll('.childInfoTr')
											.forEach((item) =>
												item.classList.add('d-none')
											);
										setToggleBtnAllOpen(false);
									}}
								>
									{t('lgsSalesOrder:foldAll')}
									{/* 箭頭btn向上 */}
									<Icn_chevronCircleDown
										className="ms-2 mb-1"
										style={{
											transform: 'rotate(0.5turn)',
										}}
									/>
								</ToggleBtn>
							) : (
								<ToggleBtn
									onClick={() => {
										// 箭頭btn向上
										document
											.querySelectorAll('.iconChevron')
											.forEach(
												(el) =>
													(el.style.transform = '')
											);

										// 顯示子層
										document
											.querySelectorAll('.childInfoTr')
											.forEach((item) =>
												item.classList.remove('d-none')
											);
										setToggleBtnAllOpen(true);
									}}
								>
									{t('lgsSalesOrder:expandAll')}
									{/* 箭頭btn向下 */}
									<Icn_chevronCircleDown className="ms-2 mb-1" />
								</ToggleBtn>
							)}
						</div>
					</div>
					{/* card */}
					<Wrapper className="row rounded-xxl">
						<div className="position-relative">
							<IndexTableContainer className="firstRowMinHeight d-flex flex-column p-4 position-relative">
								<IndexTable className="table PurchaseOrderCardWrapper">
									<TBody>
										<TRFirst className="list-item table-item ">
											<TDFirstRow>{'#'}</TDFirstRow>
											<TDFirstRow>
												{'出貨單編號'}
											</TDFirstRow>
											<TDFirstRow>
												{'理貨日期'}
											</TDFirstRow>
											<TDFirstRow>
												{'理貨人員'}
											</TDFirstRow>
											<TDFirstRow>
												{'出貨日期'}
											</TDFirstRow>
											<TDFirstRow>
												{'出貨人員'}
											</TDFirstRow>
											<TDFirstRow>
												{'配送方式'}
											</TDFirstRow>
											<TDFirstRow>
												{'物流收件時間'}
											</TDFirstRow>
											<TDFirstRow>
												{'物流送達時間'}
											</TDFirstRow>
											<TDFirstRow>{''}</TDFirstRow>
										</TRFirst>

										{goodsHistoryList?.map(
											(data, index) => {
												return (
													<React.Fragment
														key={`"purchaseOrderDataItem"${index}`}
													>
														{/* 父層 */}
														<TR className="list-item table-item">
															<TD>{index + 1}</TD>
															<TD
																className={`text-left p-2 text-center`}
															>
																{
																	data.documentNo
																}
															</TD>

															<TD
																className={`text-left p-2 text-center`}
															>
																{data.packTime
																	?.split(
																		'+'
																	)[0]
																	.replace(
																		'T',
																		' '
																	)}
															</TD>

															<TD>
																{data.packName}
															</TD>
															<TD>
																{data.outboundDate
																	?.split(
																		'+'
																	)[0]
																	.replace(
																		'T',
																		' '
																	)}
															</TD>
															<TD>
																{
																	data.outboundStaffName
																}
															</TD>
															<TD>
																{handleDeliveryMethod(
																	data.deliveryMethod
																)}
															</TD>
															<TD>
																{data.logisticReceivedAt
																	?.split(
																		'+'
																	)[0]
																	.replace(
																		'T',
																		' '
																	)}
															</TD>
															<TD>
																{data.logisticShippedAt
																	?.split(
																		'+'
																	)[0]
																	.replace(
																		'T',
																		' '
																	)}
															</TD>

															<TD
																className="text-end position-relative"
																style={{
																	width: '16px',
																}}
															>
																{/* <span className="me-5 collapsedItem">
																	{
																		data.netAmount
																	}
																</span> */}

																{/* 展開按鈕 */}
																<div
																	className={`d-inline-block text-left p-2 text-center ms-auto`}
																	style={{
																		cursor: 'pointer',
																	}}
																	onClick={(
																		e
																	) => {
																		// icon箭頭方向
																		if (
																			e.target.closest(
																				'svg'
																			)
																		) {
																			e.target.closest(
																				'svg'
																			)
																				.style
																				.transform ===
																			''
																				? (e.target.closest(
																						'svg'
																				  ).style.transform =
																						'rotate(.5turn)')
																				: (e.target.closest(
																						'svg'
																				  ).style.transform =
																						'');
																		}

																		// 是否顯示子層
																		e.target
																			.closest(
																				'tr'
																			)
																			.nextElementSibling.classList.contains(
																				'd-none'
																			)
																			? e.target
																					.closest(
																						'tr'
																					)
																					.nextElementSibling.classList.remove(
																						'd-none'
																					) &&
																			  alert(
																					1
																			  )
																			: e.target
																					.closest(
																						'tr'
																					)
																					.nextElementSibling.classList.add(
																						'd-none'
																					);

																		if (
																			e.target.closest(
																				'svg'
																			)
																		) {
																			e.target.closest(
																				'svg'
																			).style.transform =
																				'rotate("0.5turn")';
																		}
																	}}
																>
																	<Icn_chevronTop
																		className="iconChevron"
																		style={{
																			transform:
																				index ===
																				0
																					? ''
																					: 'rotate(.5turn)',
																		}}
																	/>
																</div>
															</TD>
														</TR>

														{/* 子層 */}
														<tr
															className={`childInfoTr rounded-xxl`}
														>
															{data
																.salesOrderShipmentItemList
																.length !==
																0 && (
																<>
																	<ChildTD
																		colSpan={
																			10
																		}
																		className="p-0 border-0"
																	>
																		<div
																			className="h-100 p-4"
																			style={{
																				backgroundColor:
																					'#f7f8fc',
																			}}
																		>
																			<table className="w-100 border border-1 bg-white">
																				<thead>
																					<tr>
																						<th
																							className="text-center fw-bold border border-1"
																							style={{
																								width: '80px',
																							}}
																						>
																							項次
																						</th>
																						<th
																							className="text-center fw-bold border border-1"
																							style={{
																								width: '360px',
																							}}
																						>
																							銷售內容
																						</th>
																						<th
																							className="text-center fw-bold border border-1"
																							style={{
																								width: '160px',
																							}}
																						>
																							出貨數量
																						</th>
																						<th
																							className="text-center fw-bold border border-1"
																							style={{
																								width: '240px',
																							}}
																						>
																							註記
																						</th>
																					</tr>
																				</thead>
																				<tbody>
																					{data.salesOrderShipmentItemList.map(
																						(
																							el,
																							index
																						) => {
																							return (
																								<tr className="text-center  border border-1">
																									<td className="px-3 text-center border border-1">
																										{index +
																											1}
																									</td>
																									<td className="px-3 text-start border border-1">
																										{el.materialName01 ||
																											el.serviceName01}
																									</td>
																									<td className="px-3 text-end border border-1">
																										{
																											el.quantity
																										}
																									</td>
																									<td className="px-3 text-start border border-1">
																										{
																											el.note
																										}
																									</td>
																								</tr>
																							);
																						}
																					)}
																				</tbody>
																			</table>
																		</div>
																	</ChildTD>
																</>
															)}
														</tr>
													</React.Fragment>
												);
											}
										)}
									</TBody>
								</IndexTable>
							</IndexTableContainer>
						</div>
					</Wrapper>
				</>
			)}
		</div>
	);
}

export default GoodsHistory;
