import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EyesIcon } from '../../assets/images/icn_Eyes.svg';
import { ReactComponent as ReOpenIcon } from '../../assets/images/icn_ReOpen.svg';
import { BtnNormal } from './BtnNormal';

export const OrderStatusBtn = ({ status, handleClick }) => {
	// console.log(className, to);
	return (
		<BtnNormal
			className={'btn btn-primary'}
			key={'OrderStatusBtn'}
			onClick={(e) => handleClick(e)}
		>
			{status === '0' ? (
				<>
					<EyesIcon />
					<span>訂單關閉</span>
				</>
			) : status === '1' ? (
				<>
					<span>完成訂單</span>
				</>
			) : status === '2' ? (
				<>
					<ReOpenIcon />
					<span>訂單重啟</span>
				</>
			) : (
				<></>
			)}
		</BtnNormal>
	);
};
