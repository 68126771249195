import React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { BtnNormal2 } from '../../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as CreateIcon } from '../../../../../../assets/images/icn_Create.svg';
import { ReactComponent as Icn_check } from '../../../../../../assets/images/icn_Check-r.svg';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../../assets/images/icn_inputDialog.svg';
import { DialogInvoice } from '../DialogInvoice';
import { DialogConfirm } from '../DialogConfirm';
import { useTranslation } from 'react-i18next';
//import Input from "../../../../../../components/FormElement/Input";
import Textarea from '../../../../../../components/FormElement/Textarea';
import { DialogCol } from '../DialogCol';
import ThreeColLayout from '../../../../../../components/ThreeColLayout/ThreeColLayout';

// #region styled-components

const Input = styled.input`
    padding-inline: 0px;
    border: ${(prop) => (prop.active ? '1px solid #e3e8ee' : 'none')};
    border-radius: 4px;

    /* 去除 type="number" 上下按鈕 */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::placeholder {
        color: var(--grey3);
    }

    &:disabled {
        border: none;
        background-color: transparent;
    }
`;

const TR1 = styled.tr`
    height: 5px;
    background-color: #00ff00 !important;
`;

const TR2 = styled.tr`
    height: 40px;
    background-color: #eef4fd;
`;

const THStyled = styled.th`
    width: 248px;
    text-align: left;
    padding-left: 15px;
    /* Add any other styles specific to TH here */
`;
const LabelNormal = styled.label`
    font-size: 12px;
    height: 18px;
    color: var(--grey4);
    margin-bottom: 6px;
`;

const InputElement = styled.input`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;

    &:focus-visible {
        outline: 0;
    }
    &:disabled {
        border: none;
        padding: 6px 8px;
        border-bottom: 1px solid #e3e8ee;
        border-radius: 0px;
        background-color: transparent;
    }
`;

const TextareaElement = styled.textarea`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
`;

// -----DialogCol------
const DialogWrapper = styled.div`
    position: relative;
    align-items: stretch;
    width: 100%;
    --bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
    display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
    flex: 1 1 auto;
    border: 1px solid var(--grey2);
    border-radius: 4px 0 0 4px;
    border-right: 0;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    pointer-events: none;
    background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 3px;
    right: 32px;
    width: 24px;
    text-align: center;
    display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
    pointer-events: none;
`; // 取消按鈕

const DialogBtn = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 8px;
    pointer-events: none;
`; // dialog按鈕

const IndexTable = styled.table`
    margin-bottom: 0;
    color: var(--grey4);
    background-color: #fff;
`;

const THead = styled.thead`
    height: 40px;
`;

const TR = styled.tr`
    height: 40px;
`;

const TH = styled.th`
    vertical-align: middle;
    border: 1px solid var(--grey1) !important;
    &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-right: none !important;
    }
`;

const TBody = styled.tbody`
    & tr:hover .deleteBtn {
        display: block !important;
    }
`;

const TD = styled.td`
    cursor: auto;
    border: 1px solid var(--grey1) !important;
    &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-left: none !important;
        border-right: none !important;
    }

    position: relative;
    padding: 0;
`;

const TFoot = styled.tfoot`
    border: none;
`;

const FootTR = styled.tr`
    border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
    border: none !important;
`;

const Container = styled.div`
    display: flex;
    justify-content: start;
    margin: 16px 0;
`;

const Menu = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    will-change: transform;
    padding: 0;
    margin-top: 3px;
    min-width: 64px;
    background-color: #ffffff;
    box-shadow: 2px 2px 6px -2px rgb(61 61 61 / 51%);
    border-radius: 0.25rem;
    color: var(--danger);

    &:hover,
    &:focus {
        color: var(--danger);
    }
`;

const Button = styled.div`
    //active-submit dropdownItem d-flex align-items-center pe-2 tex-left px-3 py-2
    display: flex;
    pointer-events: none;
    align-items: center;
    padding: 8px 16px;
    text-align: left;
    min-width: 64px;
    height: 32px;
    justify-content: center;
    svg {
        margin-right: 8px;
    }
`;
//#endregion

function InvoiceTable(props) {
    //#region state initiation
    const { t } = useTranslation(['common', 'lgsSalesOrderPreparing']); // i18n

    const {
      salesOrderKitList ,
        salesOrderShipmentItemList,
        backEndData,
        parentData,
        setParentData,
        pageData,
        setPageData,
        headTitleList,
        bodyTitleList,
        tableHeadStyles,
        tableBodyStyles,
        tableBodyData,
        page,
        setPage,
    } = props;
    // console.log(props);

    const updateData = pageData?.updateData;
    const { packTime, packName, note, customerName, orderNo, orderDate } =
        updateData;
    console.log(updateData);
    console.log(pageData);

    //const { invoiceFormatTypeList, invoiceTaxRateTypeList } = backEndData;

    // cookies
    const [cookies] = useCookies();
    const CountryCode = cookies._company_country_code;

    //console.log("pageData", pageData);
    //#endregion

    //#region function

    // 銷售商品型態 code轉換文字
    const handleSalesCategoryName = (salesCategoryCode) => {
        switch (salesCategoryCode) {
            case 'P':
                return '產品';
            case 'S':
                return '服務';
        }
    };

    // 理貨內容 串字串
    const handlePurchaseContent = (data) => {
        const {
            salesCategoryCode,
            materialCode,
            materialName,
            serviceCode,
            serviceName,
        } = data;

        switch (salesCategoryCode) {
            case 'P':
                return `${materialCode} : ${materialName}`;
            case 'S':
                return `${serviceCode} : ${serviceName}`;
        }
    };

    //#endregion

    //#region DialogInvoice

    // state
    const [firstDialogName, setFirstDialogName] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    //console.log("firstDialogName", firstDialogName);

    //#endregion

    //#region DialogConfirm

    // DialogConfirm 確認dialog

    const [dialogConfirmData, setDialogConfirmData] = useState({
        btnName: '',
        isOpen: false,
        goTo: '',
        title: '',
    });

    const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);

    // DialogConfirm Modal open
    const handleDialogConfirm = () => {
        const newData = {
            btnName: 'toPage3',
            isOpen: true,
            goTo: 'nextStep',
            title:
                t('lgsPurchaseOrderGoodsInvoice:invoiceNoInvoiceConfirm') + '!',
        };
        setDialogConfirmData(newData);
    };

    //console.log(isConfirmBtnClicked);
    if (isConfirmBtnClicked) {
        //console.log(123);
        const newPageData = {
            backEndData: pageData.backEndData,
            invoiceItemList: [],
            allAmount: { totalAmount: 0, taxAmount: 0, netAmount: 0 },
        };

        const newParentData = { ...parentData };
        newParentData.page2 = newPageData;
        // 標記是否曾拿取過資料
        newParentData.isEverEnterPage.page2 = true;

        setParentData(newParentData);

        // 換頁
        setPage(page + 1);
    }
    //#endregion

    return (
        <div className="position-relative">
            <h1
                style={{
                    color: '#282828',
                    fontSize: '28px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}
            >
                {t('lgsSalesOrderPreparing:basicInformation')}
            </h1>

            <div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
                <div className="col-lg-4 mb-5">
                    <div className="row">
                        <div className="d-flex flex-column">
                            <LabelNormal htmlFor={`packTime`}>
                                {t('lgsSalesOrderPreparing:postingDate')}
                            </LabelNormal>

                            <InputElement
                                type="date"
                                id={`packTime`}
                                className="mb-2"
                                disabled
                                defaultValue={packTime.split('T')[0]}
                                onChange={() => handleInput('packTime')}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="d-flex flex-column">
                            <LabelNormal htmlFor={`packTime`}>
                                {'理貨人員'}
                            </LabelNormal>

                            <InputElement
                                type="text"
                                id={`packTime`}
                                className="mb-2"
                                disabled
                                defaultValue={packName}
                                onChange={() => handleInput('packTime')}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 ">
                    <div className="row">
                        <div className="d-flex flex-column">
                            <LabelNormal htmlFor={`packTime`}>
                                {'註記'}
                            </LabelNormal>

                            <TextareaElement
                                //style={elementStyle}
                                id={'note'}
                                rows={'4'}
                                cols={'30'}
                                defaultValue={note}
                                // onBlur={() => handleInput("note")}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 ">
                    <div className="row">
                        <div className="d-flex flex-column">
                            <LabelNormal htmlFor={`packTime`}>
                                {'客戶'}
                            </LabelNormal>

                            <InputElement
                                type="text"
                                id={`packTime`}
                                className="mb-2"
                                disabled
                                defaultValue={customerName}
                                onChange={() => handleInput('packTime')}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-7 ">
                            <div className="d-flex flex-column">
                                <LabelNormal htmlFor={`packTime`}>
                                    {'銷售訂單編號'}
                                </LabelNormal>

                                <InputElement
                                    type="text"
                                    id={`packTime`}
                                    className="mb-2"
                                    disabled
                                    defaultValue={orderNo}
                                    onChange={() => handleInput('packTime')}
                                />
                            </div>
                        </div>

                        <div className="col-lg-5 ">
                            <div className="d-flex flex-column">
                                <LabelNormal htmlFor={`packTime`}>
                                    {'銷售日期'}
                                </LabelNormal>

                                <InputElement
                                    type="date"
                                    id={`packTime`}
                                    className="mb-2"
                                    disabled
                                    defaultValue={orderDate?.split('T')[0]}
                                    onChange={() => handleInput('packTime')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* DialogInvoice element */}
            {/* <DialogInvoice
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalData={modalData}
        setModalData={setModalData}
        firstDialogName={firstDialogName}
        backEndData={backEndData}
        parentData={parentData}
        pageData={pageData}
        setPageData={setPageData}
        tableBodyData={tableBodyData}
        //setTableBodyData={setTableBodyData}
        //evlAllAmount={evlAllAmount}
        //decimalPlaceTl={decimalPlaceTl}
      /> */}

            {/* DialogConfirm element */}
            <DialogConfirm
                dialogConfirmData={dialogConfirmData}
                setDialogConfirmData={setDialogConfirmData}
                page={page}
                setPage={setPage}
                setIsConfirmBtnClicked={setIsConfirmBtnClicked}
            />

            <div
                style={{ minHeight: '75vh' }}
                className="bg-white d-flex flex-column position-relative"
            >
                <IndexTable className="table">
                    <THead className="table-head">
                        <TR>
                            {headTitleList?.map((el) => (
                                <TH
                                    key={`tableHead${headTitleList?.indexOf(
                                        el
                                    )}`}
                                    style={
                                        tableHeadStyles[
                                            headTitleList?.indexOf(el)
                                        ]
                                    }
                                >
                                    {el}
                                </TH>
                            ))}
                        </TR>
                    </THead>

                    <>
                        <TR1></TR1>
                        {salesOrderKitList?.map((parent, parentIndex) => (
                            <React.Fragment key={`ParentRow${parentIndex}`}>
                                {/* TR2 */}
                                <TR2>
                                    <TH></TH> <TH></TH>
                                    <THStyled>{parent.salesKitName}</THStyled>
                                    <THStyled>{parent.quantitySales}</THStyled>
                                    <TH></TH> <TH></TH> <TH></TH> <TH></TH>{' '}
                                    <TH></TH>
                                </TR2>
                                {/* TR1 */}
                                <TR1></TR1>
                                {/* TBody */}
                                <TBody className="" key={`TBody${parentIndex}`}>
                                    {tableBodyData
                                        .filter(
                                            (data) =>
                                                data.kitGuid === parent.kitGuid
                                        )
                                        .map((data, rowIndex) => (
                                            <TR
                                                key={`GoodsTableRow${rowIndex}`}
                                            >
                                                {bodyTitleList?.map(
                                                    (el, inputIndex) => {
                                                        // 不可編輯欄位(不為input)
                                                        if (
                                                            [
                                                                'displayOrder',
                                                                'salesCategoryCode',
                                                                'salesCategoryontent',
                                                                'quantitySale', //銷售數量
                                                                'quantityReceive',
                                                                'quantityUnreceived', //未理數量
                                                                'note',
                                                            ].includes(el)
                                                        )
                                                            return (
                                                                <TD
                                                                    style={
                                                                        tableBodyStyles[
                                                                            bodyTitleList?.indexOf(
                                                                                el
                                                                            )
                                                                        ]
                                                                    }
                                                                    className={`border`}
                                                                    
                                                                    key={`${bodyTitleList?.indexOf(
                                                                        el
                                                                    )}`}
                                                                >
                                                                    <div className="px-2">
                                                                        {el ===
                                                                        'salesCategoryCode'
                                                                            ? handleSalesCategoryName(
                                                                                  data[
                                                                                      el
                                                                                  ]
                                                                              )
                                                                            : data[
                                                                                  el
                                                                              ]}
                                                                        {el ===
                                                                            'salesCategoryontent' &&
                                                                            handlePurchaseContent(
                                                                                data
                                                                            )}
                                                                    </div>
                                                                </TD>
                                                            );

                                                        // 理貨數量
                                                        if (
                                                            el ===
                                                            'newQuantityPack'
                                                        ) {
                                                            return (
                                                                <TD
                                                                    style={
                                                                        tableBodyStyles[
                                                                            bodyTitleList?.indexOf(
                                                                                el
                                                                            )
                                                                        ]
                                                                    }
                                                                    className={`border`}
                                                                    
                                                                    key={`${bodyTitleList?.indexOf(
                                                                        el
                                                                    )}`}
                                                                >
                                                                    <Input
                                                                        style={{
                                                                            minWidth:
                                                                                '24px',
                                                                            width: '100%',
                                                                            outline:
                                                                                'none',
                                                                        }}
                                                                        className={`${'text-end'}`}
                                                                        value={
                                                                            data?.quantity ||
                                                                            0
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            handleInputChange(
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                                rowIndex,
                                                                                inputIndex
                                                                            );
                                                                        }}
                                                                        onDoubleClick={() =>
                                                                            handleDoubleClick(
                                                                                rowIndex
                                                                            )
                                                                        }
                                                                        // onBlur={(e) =>
                                                                        //   handleInputBlur(
                                                                        //     e.target.value,
                                                                        //     rowIndex,
                                                                        //     inputIndex,
                                                                        //     el
                                                                        //   )
                                                                        // }
                                                                        disabled
                                                                    />
                                                                </TD>
                                                            );
                                                        }

                                                        // 物料倉位
                                                        if (
                                                            data.purchaseCategoryCode ===
                                                                'M' &&
                                                            el ===
                                                                'materialCube'
                                                        ) {
                                                            return (
                                                                <TD
                                                                    style={
                                                                        tableBodyStyles[
                                                                            bodyTitleList?.indexOf(
                                                                                el
                                                                            )
                                                                        ]
                                                                    }
                                                                    className={`border`}
                                                                    
                                                                    key={`${bodyTitleList?.indexOf(
                                                                        el
                                                                    )}`}
                                                                >
                                                                    <DialogWrapper className="row flex-nowrap">
                                                                        <InputDialogElementData
                                                                            type="text"
                                                                            value={
                                                                                data.cubeGuid ||
                                                                                ''
                                                                            }
                                                                            onChange={() => {}}
                                                                        />
                                                                        <InputDialogElementView
                                                                            style={{
                                                                                minWidth:
                                                                                    '85px',
                                                                                cursor: 'auto',
                                                                            }}
                                                                            value={
                                                                                data.cubeName ||
                                                                                ''
                                                                            }
                                                                            onChange={() => {}}
                                                                        />
                                                                        <CancelInputBtn
                                                                            onClick={() => {
                                                                                handleCancelClick(
                                                                                    rowIndex
                                                                                );
                                                                            }}
                                                                            isValueExist={
                                                                                data.cubeGuid
                                                                            }
                                                                        >
                                                                            <Icn_InputYellowCancel />
                                                                        </CancelInputBtn>

                                                                        <DialogBtn
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleOpen(
                                                                                    rowIndex
                                                                                )
                                                                            }
                                                                        >
                                                                            <Icn_InputDialog />
                                                                        </DialogBtn>
                                                                    </DialogWrapper>
                                                                    {data[
                                                                        'cubeGuidWarning'
                                                                    ] && (
                                                                        <div className="mt-xl-1">
                                                                            <span className="text-danger ms-1">
                                                                                {
                                                                                    data[
                                                                                        'cubeGuidWarning'
                                                                                    ]
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </TD>
                                                            );
                                                        } else if (
                                                            el ===
                                                            'materialCube'
                                                        ) {
                                                            // 不為資產與費用時，不可編輯
                                                            return (
                                                                <TD
                                                                    style={
                                                                        tableBodyStyles[
                                                                            bodyTitleList?.indexOf(
                                                                                el
                                                                            )
                                                                        ]
                                                                    }
                                                                    className={`border`}
                                                                    
                                                                    key={`${bodyTitleList?.indexOf(
                                                                        el
                                                                    )}`}
                                                                ></TD>
                                                            );
                                                        }

                                                        // 批次編號
                                                        if (el === 'batchNo') {
                                                            return (
                                                                <TD
                                                                    style={
                                                                        tableBodyStyles[
                                                                            bodyTitleList?.indexOf(
                                                                                el
                                                                            )
                                                                        ]
                                                                    }
                                                                    className={`border`}
                                                                    
                                                                    key={`${bodyTitleList?.indexOf(
                                                                        el
                                                                    )}`}
                                                                >
                                                                    <Input
                                                                        style={{
                                                                            minWidth:
                                                                                '24px',
                                                                            width: '100%',
                                                                            outline:
                                                                                'none',
                                                                        }}
                                                                        className={`${'text-end'}`}
                                                                        value={
                                                                            data[
                                                                                'batchNo'
                                                                            ] ||
                                                                            ''
                                                                        }
                                                                        disabled
                                                                        active={
                                                                            data.active
                                                                        }
                                                                        placeholder={
                                                                            data[
                                                                                'batchNoAuto'
                                                                            ] ===
                                                                                '0' &&
                                                                            data.batchNumberingFull
                                                                                ? `${t(
                                                                                      'lgsPurchaseOrderGoodsInvoice:requiredAndMustBeFullWidth'
                                                                                  )}(${
                                                                                      data.batchNumberingLength
                                                                                  }${t(
                                                                                      'lgsPurchaseOrderGoodsInvoice:code'
                                                                                  )})`
                                                                                : ''
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            handleInputChange(
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                                rowIndex,
                                                                                inputIndex
                                                                            );
                                                                        }}
                                                                        // onBlur={(e) =>
                                                                        //   handleInputBlur(
                                                                        //     e.target.value,
                                                                        //     rowIndex,
                                                                        //     inputIndex,
                                                                        //     el
                                                                        //   )
                                                                        // }
                                                                    />
                                                                    {data[
                                                                        'batchNoWarning'
                                                                    ] && (
                                                                        <div className="mt-xl-1">
                                                                            <span className="text-danger ms-1">
                                                                                {
                                                                                    data[
                                                                                        'batchNoWarning'
                                                                                    ]
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </TD>
                                                            );
                                                        }

                                                        return (
                                                            <TD
                                                                style={
                                                                    tableBodyStyles[
                                                                        bodyTitleList?.indexOf(
                                                                            el
                                                                        )
                                                                    ]
                                                                }
                                                                className={`border`}
                                                                active={
                                                                    data.active
                                                                }
                                                                key={`${bodyTitleList?.indexOf(
                                                                    el
                                                                )}`}
                                                            >
                                                                <Input
                                                                    style={{
                                                                        minWidth:
                                                                            '24px',
                                                                        width: '100%',
                                                                        outline:
                                                                            'none',
                                                                    }}
                                                                    className={``}
                                                                    value={
                                                                        data[
                                                                            el
                                                                        ] || ''
                                                                    }
                                                                    
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        handleInputChange(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            rowIndex,
                                                                            inputIndex
                                                                        );
                                                                    }}
                                                                    disabled
                                                                />
                                                            </TD>
                                                        );
                                                    }
                                                )}
                                            </TR>
                                        ))}
                                </TBody>
                            </React.Fragment>
                        ))}
                    </>
                </IndexTable>
            </div>
        </div>
    );
}

export default InvoiceTable;
