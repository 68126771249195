import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import { MaterialCubeModal } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { DialogCol } from '../DialogCol';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
    margin-bottom: 0;
    color: var(--grey4);
    background-color: #fff;
    table-layout: fixed;
`;

const THead = styled.thead`
    height: 40px;
`;

const TR = styled.tr`
    height: 40px;
    &:hover {
        cursor: ${(hoverEffect) =>
            hoverEffect === 'true' ? 'pointer' : 'default'};
        background-color: ${(hoverEffect) =>
            hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
    }
`;

const TR1 = styled.tr`
    height: 5px;
    background-color: #00ff00 !important;
`;

const TR2 = styled.tr`
    height: 40px;
    background-color: #eef4fd;
`;

const THStyled = styled.th`
    width: 248px;
    text-align: left;
    padding-left: 15px;
    /* Add any other styles specific to TH here */
`;

const TH = styled.th`
    vertical-align: middle;
    border: 1px solid var(--grey1) !important;
    &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-right: none !important;
    }
`;

const TBody = styled.tbody``;

const TD = styled.td`
    cursor: auto;
    border: 1px solid var(--grey1) !important;
    &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-right: none !important;
    }

    background-color: ${(prop) =>
        prop.active ? '#fbfcfd !important' : '#fff'};

    position: relative;
    padding: 7px 8px !important;
    max-height: 40px;
`;

const Container = styled.div`
    display: flex;
    justify-content: start;
    margin: 16px 0;
`;

const Input = styled.input`
    padding-inline: 8px;
    border: ${(prop) => (prop.active ? '1px solid #e3e8ee' : 'none')};
    border-radius: 4px;

    /* 去除 type="number" 上下按鈕 */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::placeholder {
        color: var(--grey3);
    }

    &:disabled {
        border: none;
        background-color: transparent;
    }
`;

// -----Dialog------
const DialogWrapper = styled.div`
    position: relative;
    align-items: stretch;
    width: 100%;
    height: 25px;
    --bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
    display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
    flex: 1 1 auto;
    border: 1px solid var(--grey2);
    border-radius: 4px 0 0 4px;
    border-right: 0;
    padding: 6px 8px;
    height: 25px;
    &:focus-visible {
        outline: 0;
    }
    pointer-events: none;
    background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
    display: inline-block;
    position: absolute;
    height: 100%;
    top: -1px;
    right: 24px;
    width: 24px;
    text-align: center;
    display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
    cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 8px;
    cursor: pointer;
`; // dialog按鈕

//#endregion

function GoodsTable({
    salesOrderKitList,
    salesOrderShipmentItemList,
    pageData,
    setPageData,
    tableBodyData,
    setTableBodyData,
    backEndData,
    dialogBtnKeyName,
    setDialogBtnKeyName,
    modalData,
    setModalData,
    t,
}) {
    //#region state initiation
    const headTitleList = [
        '#',
        t('salesCategoryCode'),
        t('salesCategoryContent'),
        t('quantitySale'),
        t('quantityPack'),
        t('materialCube'),
        t('batchNo'),
        t('note'),
    ];

    // table body 的資料名稱
    const bodyTitleList = [
        'displayOrder',
        'salesCategoryCode',
        'salesCategoryontent',
        'quantitySale',
        'quantity',
        'materialCube',
        'batchNo',
        'note',
    ];

    // 不同 input 的 type
    const inputProperties = [
        {},
        {},
        {},
        {},
        {},
        { type: 'number' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
    ];

    // table header 的 style
    const tableHeadStyles = [
        {
            width: '5%',
            textAlign: 'center',
        },
        {
            width: '5%',
            textAlign: 'center',
        },
        {
            width: '20%',
            textAlign: 'center',
        },
        {
            width: '5%',
            textAlign: 'center',
        },
        {
            width: '5%',
            textAlign: 'center',
        },
        {
            width: '10%',
            textAlign: 'center',
        },
        {
            width: '25%',
            textAlign: 'center',
        },
        {
            width: '25%',
            textAlign: 'center',
        },
    ];

    // table body 的 style
    const tableBodyStyles = [
        {
            width: '5%',
            textAlign: 'center',
        },
        {
            width: '5%',
            textAlign: 'center',
        },
        {
            width: '20%',
            textAlign: 'center',
        },
        {
            width: '5%',
            textAlign: 'center',
        },
        {
            width: '5%',
            textAlign: 'center',
        },
        {
            width: '10%',
            textAlign: 'center',
        },
        {
            width: '25%',
            textAlign: 'center',
        },
        {
            width: '25%',
            textAlign: 'center',
        },
    ];

    const [newSalesOrderKitItemList, setNewSalesOrderKitItemList] = useState(
        salesOrderShipmentItemList 
    );

    const [isModalOpen, setIsModalOpen] = useState(false);
    //#endregion

    //#region function
    // useEffect(() => {
    //     // 計算未理貨數量 20240126--------------
    //     // 銷售set 組數 x 品項數量 - 已理貨數量

    //     // 創建一個 Map 來映射 salesOrderKitList 的 kitGuid 到對應的 quantitySales
    //     const quantitySalesMap = new Map();
    //     salesOrderShipmentItemList.forEach((item) => {
    //         // // console.log(item);
    //         quantitySalesMap.set(item.kitGuid, item.quantitySale);
    //     });

    //     // 在 salesOrderShipmentItemList 中新增 quantityUnreceived 屬性
    //     newSalesOrderKitItemList.forEach((item) => {
    //         const kitGuid = item.kitGuid;
    //         const quantitySale = item.quantitySale || 0;
    //         const quantityPack = item.quantityPack || 0;
    //         // console.log(item);

    //         // 計算 quantityUnreceived
    //         const quantityUnreceived =
    //             quantitySalesMap.get(kitGuid) * quantitySale - quantityPack;

    //         // 將計算結果設定到 item 中
    //         item.quantityUnreceived = quantityUnreceived;
    //         // console.log(item);
    //     });

    //     // console.log(newSalesOrderKitItemList);

    //     setNewSalesOrderKitItemList(newSalesOrderKitItemList);
    // }, []);

    // 輸出更新後的 salesOrderShipmentItemList
    //// console.log(backEndData.salesOrderShipmentItemList);

    //------------------------

    //全部理貨
    const handleReceiveAll = () => {
        const newData = [...tableBodyData];
        newData.forEach((el) => {
            el.quantityReceive = el.quantityUnreceived;
        });

        setTableBodyData([...newData]);
        const newPageData = { ...pageData };
        newPageData.salesOrderShipmentItemList = [...newData];
        setPageData({ ...newPageData });
    };
    // // console.log('pageData', pageData);

    //雙擊裡貨
    const handleDoubleClick = (value, kitGuid, rowIndex, inputIndex) => {
        // console.log(rowIndex)
        const newData = [...newSalesOrderKitItemList];
        const targetRow = newData.filter((el) => el.kitGuid === kitGuid);
        // console.log(targetRow, rowIndex);
        // console.log(targetRow[rowIndex]);

        targetRow[rowIndex][bodyTitleList[inputIndex]] =
            targetRow[rowIndex].quantityUnreceived;

        //newData[rowIndex].quantityReceive = newData[rowIndex].quantityUnreceived;
        setTableBodyData([...newData]);

        const newPageData = { ...pageData };
        newPageData.salesOrderShipmentItemList = [...newData];
        setPageData({ ...newPageData });

        setNewSalesOrderKitItemList([...newData]);
    };

    const handleEditTable = (e, index) => {
        const newList = [...tableBodyData];

        newList.forEach((el) => delete el?.active);

        newList[index].active = true;
        setTableBodyData(newList);
    };

    // input改變時
    const handleInputChange = (
        value,
        kitGuid,
        rowIndex,
        inputIndex
        //toFloat,
        //data
    ) => {
        console.log({
            value,
            kitGuid,
            rowIndex,
            inputIndex,
        });

        const newData = [...tableBodyData];
        // 1. Use filter() to find the target kitSet
        // 2. Use the [rowIndex] the find the target row of the target kitSet
        const targetRow = newData.filter((el) => el.kitGuid === kitGuid);
        // // console.log(targetRow);
        // // console.log(newData);
        // // console.log(value);
        // // console.log(inputIndex);
        // // console.log(targetRow[newData[inputIndex]]);
        // // console.log(newData[inputIndex]);

        // if (toFloat) {
        //   // 如果此input有decimalPlace限制
        //   const regex = new RegExp(`^(\\d+(\\.\\d{0,${decimalPlace}})?|)$`); // 只允許數字，並且小數點後最多decimalPlace位
        //   if (regex.test(value)) targetRow[bodyTitleList[inputIndex]] = value;
        //   setTableBodyData(newData);
        // } else {

        // console.log(targetRow, rowIndex);

        targetRow[rowIndex][bodyTitleList[inputIndex]] = value;

        setTableBodyData(newData);
        //// console.log(bodyTitleList);
        //// console.log(tableBodyData);

        //   const newPageData = { ...pageData };
        //   newPageData.salesOrderShipmentItemList = [...newData];
        //   setPageData({ ...newPageData });
        // }
    };

    const handleInputBlur = (
        value,
        kitGuid,
        rowIndex,
        inputIndex,
        inputName
    ) => {
        const newData = [...tableBodyData];
        const targetRow = newData.filter((el) => el.kitGuid === kitGuid)[
            rowIndex
        ];
        //// console.log(targetRow);
        //// console.log(newData[rowIndex]);

        if (inputName === 'quantityReceive') {
            //const quantityUnreceived = newData[rowIndex]["quantityUnreceived"]; //這是錯的資料列
            const quantityUnreceived = targetRow.quantityUnreceived;
            //// console.log(quantityUnreceived);

            // 收貨數量 不大於 未收數量
            if (Number(value) > Number(quantityUnreceived)) {
                //newData[rowIndex]["quantityReceive"] = quantityUnreceived;
                targetRow[bodyTitleList[inputIndex]] = quantityUnreceived;
                setTableBodyData(newData);
            } else if (Number(value) < 0) {
                // 未收數量不為負數
                targetRow['quantityReceive'] = 0;
                // console.log('newData', newData);

                // console.log(targetRow[bodyTitleList[inputIndex]]);
                setTableBodyData(newData);
            }

            const newPageData = { ...pageData };
            newPageData.salesOrderShipmentItemList = [...newData];
            setPageData(newPageData);
        }

        // 手動批次編號，判斷是否滿碼
        if (inputName === 'batchNo' && newData[rowIndex].batchNoAuto === '0') {
            const { batchNumberingFull, batchNumberingLength } =
                newData[rowIndex];
            const inputValueLength = value.length;

            // // console.log(
            // 	batchNumberingFull,
            // 	batchNumberingLength,
            // 	inputValueLength
            // );

            if (
                batchNumberingFull &&
                inputValueLength !== batchNumberingLength
            ) {
                newData[rowIndex][inputName] = '';
            } else {
                newData[rowIndex][`${inputName}Warning`] = '';
            }

            setTableBodyData(newData);

            const newPageData = { ...pageData };
            newPageData.salesOrderShipmentItemList = [...newData];
            setPageData({ ...newPageData });
        }
    };

    // 採購型態 code轉換文字
    // const handlePurchaseCategoryName = (purchaseCategoryCode) => {
    //   switch (purchaseCategoryCode) {
    //     case "M":
    //       return "產品";
    //     case "E":
    //       return "服務";
    //     case "A":
    //       return "資產";
    //   }
    // };

    // 銷售商品型態 code轉換文字
    const handleSalesCategoryName = (salesCategoryCode) => {
        switch (salesCategoryCode) {
            case 'P':
                return '產品';
            case 'S':
                return '服務';
        }
    };

    // 理貨內容 串字串
    const handlePurchaseContent = (data) => {
        const {
            salesCategoryCode,
            materialCode,
            materialName,
            serviceCode,
            serviceName,
        } = data;

        switch (salesCategoryCode) {
            case 'P':
                return `${materialCode} : ${materialName}`;
            case 'S':
                return `${serviceCode} : ${serviceName}`;
        }
    };

    //#endregion

    //#region DialogCol
    const [targetRow, setTargetRow] = useState(null);
    const [indexDialog, setIndexDialog] = useState('');

    // DialogCol input 黃色按鈕取消
    const handleCancelClick = (kitGuid, rowIndex) => {
        const newData = { ...pageData };

        const newTargetRow = newData.salesOrderShipmentItemList.filter(
            (el) => el.kitGuid === kitGuid
        )[rowIndex];

        newTargetRow.cubeGuid = '';
        newTargetRow.cubeName = '';

        // newData.salesOrderShipmentItemList[rowIndex].cubeGuid = "";
        // newData.salesOrderShipmentItemList[rowIndex].cubeName = "";
        //// console.log(newData);

        setPageData(newData);
    };

    // DialogCol Modal open
    const handleOpen = (kitGuid, rowIndex, inputIndex) => {
        //// console.log(kitGuid, rowIndex, inputIndex);

        const newData = [...tableBodyData];
        // 1. Use filter() to find the target kitSet
        // 2. Use the [rowIndex] the find the target row of the target kitSet
        const newTargetRow = newData.filter((el) => el.kitGuid === kitGuid)[
            rowIndex
        ];

        setTargetRow(newTargetRow);

        setDialogBtnKeyName('cube');
        setModalData(pageData.materialCubeList);
        setIndexDialog(rowIndex); // 獲取真實的index，傳給DialogCol
        setIsOpen(true);
    };

    //#endregion

    return (
        <div className="position-relative">
            {/* DialogCol element */}
            {/* {isModalOpen && <MaterialCubeModal
                t={t}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                listData={materialCubeList}
                targetKey="materialCubeList"
                targetValue={
                    preUpdateData?.outboundStaffGuid
                }
                handleSubmit={handleUpdateOutboundStaff}
            />} */}

            <IndexTableContainer className="bg-white d-flex flex-column position-relative">
                {/* <Container>
                    <BtnNormal2
                        className="btn-primary ms-3 smallBtn"
                        keyName="advance-search"
                        handleClick={handleReceiveAll}
                        word={t('page1.outboundAll')}
                    ></BtnNormal2>
                </Container> */}

                <IndexTable className="table">
                    <THead className="table-head">
                        <TR>
                            {headTitleList?.map((el) => (
                                <TH
                                    key={`tableHead${headTitleList?.indexOf(
                                        el
                                    )}`}
                                    style={{
                                        ...tableHeadStyles[
                                            headTitleList?.indexOf(el)
                                        ],
                                        backgroundColor: '#F7F8FC',
                                        color: '#616E83',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {el}
                                </TH>
                            ))}
                        </TR>
                    </THead>

                    <TR1></TR1>
                    {salesOrderKitList?.map((parent, parentIndex) => (
                        <React.Fragment key={`ParentRow${parentIndex}`}>
                            {/* TR2 */}
                            <TR2>
                                <TH></TH> <TH></TH>
                                <THStyled>{parent.salesKitName}</THStyled>
                                <THStyled>{parent.quantitySales}</THStyled>
                                <TH></TH> <TH></TH> <TH></TH> <TH></TH>
                            </TR2>

                            {/* TR1 */}
                            <TR1></TR1>

                            {/* TBody */}
                            <TBody className="" key={`TBody${parentIndex}`}>
                                {tableBodyData
                                    .filter(
                                        (data) =>
                                            data.kitGuid === parent.kitGuid
                                    )
                                    .map((data, rowIndex) => {
                                        // // console.log("data", data);
                                        return (
                                            <TR
                                                key={`GoodsTableRow${rowIndex}`}
                                                // onClick={(e) =>
                                                //     handleEditTable(e, rowIndex)
                                                // }
                                            >
                                                {bodyTitleList?.map(
                                                    (el, inputIndex) => (
                                                        <TD
                                                            style={
                                                                tableBodyStyles[
                                                                    bodyTitleList?.indexOf(
                                                                        el
                                                                    )
                                                                ]
                                                            }
                                                            className={`border`}
                                                            key={`${bodyTitleList?.indexOf(
                                                                el
                                                            )}`}
                                                        >
                                                            <div className="px-2">
                                                                {/* {el ===
                                                                'salesCategoryCode'
                                                                    ? handleSalesCategoryName(
                                                                          data[
                                                                              el
                                                                          ]
                                                                      )
                                                                    : "data[el]"}
                                                                {el ===
                                                                    'salesCategoryontent' &&
                                                                    handlePurchaseContent(
                                                                        data
                                                                    )}
                                                                {el ===
                                                                    'quantitySale' &&
                                                                    ''} */}

                                                                {el ===
                                                                'salesCategoryCode'
                                                                    ? handleSalesCategoryName(
                                                                          data[
                                                                              el
                                                                          ]
                                                                      )
                                                                    : el ===
                                                                      'salesCategoryontent'
                                                                    ? handlePurchaseContent(
                                                                          data
                                                                      )
                                                                    : el ===
                                                                      'quantitySale'
                                                                    ? '' :
                                                                    el === 'quantity' ?  (data[el] || 0)
                                                                    : data[el]}
                                                            </div>
                                                        </TD>
                                                    )
                                                )}
                                            </TR>
                                        );
                                    })}
                            </TBody>
                        </React.Fragment>
                    ))}
                </IndexTable>
            </IndexTableContainer>
        </div>
    );
}

export default GoodsTable;
