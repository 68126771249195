import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import { DialogCol } from '../DialogCol';
import StaffModal from '../StaffModal';

//#region styled-components

const SelectElement = styled.select`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
`;

const TopTable = styled.table`
    font-size: 12px;
    color: #616e83;
    background-color: #f7f8fc;
    width: 100% !important;
    height: 128px !important;
    border: 1px solid var(--grey1) !important;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
`;

const TBody = styled.tbody``;

const TR = styled.tr`
    height: 32px;
    border: 2px solid var(--grey1) !important;
`;
const TH1 = styled.th`
    padding: 0px;
    height: 32px;
    width: 23% !important;
    text-align: center !important;
`;
const TH = styled.th`
    padding: 0px;
    height: 32px;
    width: 38.5% !important;
    text-align: center !important;
`;
const TD1 = styled.td`
    padding: 0px;
    height: 32px;
    width: 23% !important;
    border: 1px solid var(--grey1) !important;
    text-align: center !important;
`;
const TD = styled.td`
    padding-right: 8px;
    height: 32px;
    width: 38.5% !important;
    border: 1px solid var(--grey1) !important;

    text-align: right !important;
`;

const LabelNormal = styled.label`
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;

const InputElement = styled.input`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    &:disabled {
        background-color: #eff2f5;
    }
`;

const InputElement2 = styled.input`
    border: 1px solid var(--grey2);
    border-right: none;
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    &:disabled {
        background-color: #eff2f5;
    }
`;

const TextareaElement = styled.textarea`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
`;

// -----DialogCol------
const DialogWrapper = styled.div`
    position: relative;
    align-items: stretch;
    width: 100%;
    --bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
    display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.div`
    flex: 1 1 auto;
    border: 1px solid var(--grey2);
    border-radius: 4px 0 0 4px;
    border-right: 0;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    pointer-events: none;
    background-color: transparent;
    minwidth: 85px;
    cursor: auto;
`; // input顯示欄位

const CancelInputBtn = styled.span`
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 3px;
    right: 32px;
    width: 24px;
    text-align: center;
    display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
    cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 8px;
    cursor: pointer;
`; // dialog按鈕

//#endregion

const TopInfo = ({
    pageData,
    setPageData,
    page,
    DecimalPlaceTl,
    t,
    statusList,
}) => {
    console.log(pageData)
    const { preUpdateData, employeeList, salesOrderShipment  } = pageData;
    const {
        outboundStaffCode,
        outboundStaffGuid,
        outboundStaffName,
    } = preUpdateData;


    const [isStaffModalOpen, setIsStaffModalOpen] = useState(false);

    const handleInputChange = (e) => {
        const newInvoice = { ...preUpdateData };
        newInvoice[e.target.name] = e.target.value;

        setPageData({
            ...pageData,
            preUpdateData: newInvoice,
        });
    };

    const handleUpdateOutboundStaff = (guid) => {
        const newPreUpdateData = { ...preUpdateData };

        if (guid) {
            const { fullname, employeeCode, employeeGuid } = employeeList.find(
                (employee) => employee.employeeGuid === guid
            );

            newPreUpdateData.outboundStaffGuid = employeeGuid;
            newPreUpdateData.outboundStaffCode = employeeCode;
            newPreUpdateData.outboundStaffName = fullname;
        } else {
            newPreUpdateData.outboundStaffGuid = null;
            newPreUpdateData.outboundStaffCode = null;
            newPreUpdateData.outboundStaffName = null;
        }

        setPageData({
            ...pageData,
            preUpdateData: newPreUpdateData,
        });
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="row">
                    {/* LEFT */}
                    <div className="col-lg-4 px-4 pr-xl-6">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex flex-column mb-3">
                                    <LabelNormal>
                                        {t('page1.outboundDate')}
                                    </LabelNormal>
                                    <InputElement
                                        type="datetime-local"
                                        step="1"
                                        id="outboundDate"
                                        name="outboundDate"
                                        disabled={page !== 1}
                                        value={
                                            pageData?.preUpdateData
                                                ?.outboundDate
                                        }
                                        onChange={handleInputChange}
                                    />
                                    <span className="text-danger ms-1 mt-1">
                                        {salesOrderShipment?.outboundDateWarning}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex flex-column">
                                    <LabelNormal>
                                        {t('page1.documentNo')}
                                    </LabelNormal>
                                    <InputElement
                                        type="text"
                                        id="documentNo"
                                        name="documentNo"
                                        className="mb-3"
                                        value={preUpdateData.documentNo}
                                        onChange={handleInputChange}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex flex-column mb-3">
                                    <LabelNormal htmlFor={`outboundStaff`}>
                                        {t('page1.outboundStaff')}
                                    </LabelNormal>

                                    <DialogWrapper className="row flex-nowrap">
                                        <InputDialogElementView
                                            style={{
                                                minWidth: '85px',
                                                cursor: 'auto',
                                            }}
                                        >
                                            {outboundStaffGuid
                                                ? `${outboundStaffCode} ${outboundStaffName}`
                                                : ''}
                                        </InputDialogElementView>
                                        <CancelInputBtn
                                            isValueExist={
                                                pageData?.preUpdateData
                                                    ?.outboundStaffGuid || ''
                                            }
                                            onClick={() =>
                                                handleUpdateOutboundStaff(null)
                                            }
                                        >
                                            <Icn_InputYellowCancel />
                                        </CancelInputBtn>

                                        <DialogBtn
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => setIsStaffModalOpen(true)}
                                        >
                                            <Icn_InputDialog />
                                        </DialogBtn>
                                    </DialogWrapper>
                                    
                                    <span className="text-danger ms-1 mt-1">
                                        {salesOrderShipment.outboundStaffGuidWarning}
                                    </span>
                                    {isStaffModalOpen && (
                                        <StaffModal
                                            t={t}
                                            isModalOpen={isStaffModalOpen}
                                            setIsModalOpen={setIsStaffModalOpen}
                                            listData={employeeList}
                                            targetKey="employeeGuid"
                                            targetValue={
                                                preUpdateData?.outboundStaffGuid
                                            }
                                            handleSubmit={
                                                handleUpdateOutboundStaff
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex flex-column">
                                    <LabelNormal>
                                        {t('page1.status')}
                                    </LabelNormal>
                                    <SelectElement
                                        id="status"
                                        name="status"
                                        value={
                                            pageData?.preUpdateData?.status || 0
                                        }
                                        onChange={handleInputChange}
                                    >
                                        {statusList?.map(({ key, value }) => (
                                            <option
                                                key={key}
                                                id={key}
                                                value={key}
                                            >
                                                {value}
                                            </option>
                                        ))}
                                    </SelectElement>
                                    <span className="text-danger ms-1 mt-1">
                                        {
                                            pageData?.preUpdateData
                                                ?.bankAccountGuidWarning
                                        }
                                    </span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* Center */}
                    <div className="col-lg-4 px-4 px-xl-5">
                        <div className={`wrapper d-flex flex-column mb-3`}>
                            <LabelNormal htmlFor="note">
                                {t('note2')}
                            </LabelNormal>
                            <TextareaElement
                                //style={elementStyle}
                                id="note"
                                name="note"
                                rows="4"
                                cols="30"
                                value={pageData?.preUpdateData?.note}
                                onChange={handleInputChange}
                                disabled={false}
                            />
                        </div>
                    </div>
                    {/* RIGHT */}
                    <div className="col-lg-4 px-4 pl-xl-6">
                        <div className="d-flex flex-column">
                            <LabelNormal htmlFor="customer">
                                {t('page1.customerName')}
                            </LabelNormal>
                            <InputElement
                                type="text"
                                id="customer"
                                className="mb-3"
                                disabled
                                value={pageData?.preUpdateData?.customerName}
                            />
                        </div>
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="d-flex flex-column">
                                    <LabelNormal htmlFor={`orderNo`}>
                                        銷售訂單編號
                                    </LabelNormal>
                                    <InputElement
                                        type="text"
                                        id={`orderNo`}
                                        className="mb-3"
                                        disabled
                                        value={pageData?.preUpdateData?.orderNo}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-5 ps-0">
                                <div className="d-flex flex-column">
                                    <LabelNormal htmlFor="orderDate">
                                        銷售日期
                                    </LabelNormal>
                                    <InputElement
                                        type="orderDate"
                                        className="mb-3"
                                        disabled
                                        value={
                                            pageData?.preUpdateData?.orderDate?.split(
                                                'T'
                                            )[0]
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopInfo;
