import { useState, useEffect } from 'react';
import useAxios from '../../../../../../hooks/useAxios';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import HistoryOverviewTable from './HistoryOverviewTable';
import { useTranslation } from 'react-i18next';

function LgsSalesOrder_HistoryOverview(props) {
	// console.log(props);
	const { t } = useTranslation(['common', 'lgsSalesOrder']);
	const { foreignCurrency } = props.detailsInfo;
	const [sortBy, setSortBy] = useState('orderDate');
	const { isLoading, error, sendRequest: axiosHistoryOverview } = useAxios();
	const guid = useLocation().pathname.split('/').pop();

	const [data, setData] = useState({});
	let purchaseOrderStatus = [];
	let headTitleList = [];
	let bodyTitleList = [];
	let tableHeadStyles = [];
	let tableBodyStyles = [];

	// TODO: change it to version 2
	useEffect(() => {
		const url = `api/Logistics/LgsSalesOrder/historyOverview?guid=${guid}`;
		axiosHistoryOverview({ url }, (res) => {
			// console.log(res);
			setData(res.data);
		});
	}, []);

	if (error) {
		console.log('error !', error);
	}

	// const {
	// 	foreignGoodsReceiveAmount,
	// 	foreignInvoiceGrossAmount,
	// 	foreignInvoiceTaxAmount,
	// 	foreignPayableAmount,
	// 	goodsReceiveAmount,
	// 	historyOverviewList,
	// 	invoiceGrossAmount,
	// 	invoiceTaxAmount,
	// 	orderDate,
	// 	payableAmount,
	// 	property,
	// 	totalForeignGoodsReceiveAmount,
	// 	totalForeignInvoiceGrossAmount,
	// 	totalForeignInvoiceTaxAmount,
	// 	totalForeignPayableAmount,
	// 	totalGoodsReceiveAmount,
	// 	totalInvoiceGrossAmount,
	// 	totalInvoiceTaxAmount,
	// 	totalPayableAmount,
	// } = data;

	// alter table content with foreignCurrency (是否外幣交易)
	if (foreignCurrency) {
		purchaseOrderStatus = [
			'',
			'orderDate',
			'property',
			'foreignGoodsReceiveAmount',
			'foreignInvoiceTaxAmount',
			'foreignInvoiceGrossAmount',
			'foreignPayableAmount',
			'payableAmount',
		];
		headTitleList = [
			'#',
			t('lgsSalesOrder:date'),
			t('lgsSalesOrder:nature'),
			t('lgsSalesOrder:foreignCurrencyNetReceivingAmount'),
			t('lgsSalesOrder:foreignCurrencyInvoiceTaxAmount'),
			t('lgsSalesOrder:foreignCurrencyInvoiceTotalAmount'),
			t('lgsSalesOrder:foreignCurrencyPayableAmount'),
			t('lgsSalesOrder:localCurrencyPayableAmount'),
		];
		bodyTitleList = [
			'displayOrder',
			'orderDate',
			'property',
			'foreignGoodsReceiveAmount',
			'foreignInvoiceTaxAmount',
			'foreignInvoiceGrossAmount',
			'foreignPayableAmount',
			'payableAmount',
		];
		tableHeadStyles = [
			{
				width: '56px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '336px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
		];
		tableBodyStyles = [
			{
				width: '56px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'left',
			},
			{
				width: '336px',
				textAlign: 'left',
			},
			{
				width: '136px',
				textAlign: 'right',
			},
			{
				width: '136px',
				textAlign: 'right',
			},
			{
				width: '136px',
				textAlign: 'right',
			},
			{
				width: '136px',
				textAlign: 'right',
			},
			{
				width: '136px',
				textAlign: 'right',
			},
		];
	} else {
		purchaseOrderStatus = [
			'',
			'orderDate',
			'property',
			'goodsReceiveAmount',
			'invoiceTaxAmount',
			'invoiceGrossAmount',
			'payableAmount',
		];
		headTitleList = [
			'#',
			t('lgsSalesOrder:date'),
			t('lgsSalesOrder:nature'),
			t('lgsSalesOrder:netReceivingAmount'),
			t('lgsSalesOrder:invoiceTaxAmount'),
			t('lgsSalesOrder:invoiceTotalAmount'),
			t('lgsSalesOrder:payableAmount'),
		];
		bodyTitleList = [
			'displayOrder',
			'orderDate',
			'property',
			'goodsReceiveAmount',
			'invoiceTaxAmount',
			'invoiceGrossAmount',
			'payableAmount',
		];
		tableHeadStyles = [
			{
				width: '56px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '336px',
				textAlign: 'center',
			},
			{
				width: '168px',
				textAlign: 'center',
			},
			{
				width: '168px',
				textAlign: 'center',
			},
			{
				width: '168px',
				textAlign: 'center',
			},
			{
				width: '176px',
				textAlign: 'center',
			},
		];
		tableBodyStyles = [
			{
				width: '56px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'left',
			},
			{
				width: '336px',
				textAlign: 'left',
			},
			{
				width: '168px',
				textAlign: 'right',
			},
			{
				width: '168px',
				textAlign: 'right',
			},
			{
				width: '168px',
				textAlign: 'right',
			},
			{
				width: '176px',
				textAlign: 'right',
			},
		];
	}

	return (
		<>
			{/* {historyOverviewList && (
				<HistoryOverviewTable
					guidName="historyOverviewGuid"
					sortBy={sortBy}
					tableHoverEffect={false}
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					purchaseOrderStatus={purchaseOrderStatus}
					indexData={historyOverviewList}
					property={property}
					orderDate={orderDate}
					foreignGoodsReceiveAmount={foreignGoodsReceiveAmount}
					foreignInvoiceGrossAmount={foreignInvoiceGrossAmount}
					foreignInvoiceTaxAmount={foreignInvoiceTaxAmount}
					foreignPayableAmount={foreignPayableAmount}
					goodsReceiveAmount={goodsReceiveAmount}
					invoiceGrossAmount={invoiceGrossAmount}
					invoiceTaxAmount={invoiceTaxAmount}
					payableAmount={payableAmount}
					totalForeignGoodsReceiveAmount={
						totalForeignGoodsReceiveAmount
					}
					totalForeignInvoiceGrossAmount={
						totalForeignInvoiceGrossAmount
					}
					totalForeignInvoiceTaxAmount={totalForeignInvoiceTaxAmount}
					totalForeignPayableAmount={totalForeignPayableAmount}
					totalGoodsReceiveAmount={totalGoodsReceiveAmount}
					totalInvoiceGrossAmount={totalInvoiceGrossAmount}
					totalInvoiceTaxAmount={totalInvoiceTaxAmount}
					totalPayableAmount={totalPayableAmount}
					foreignCurrency={foreignCurrency}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
				/>
			)} */}
		</>
	);
}

export default LgsSalesOrder_HistoryOverview;
