import axios from 'axios';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { generateUpdateLogContent } from '../../../../utils';
import { BtnNormal2 } from '../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as Icn_Activate } from '../../../../assets/images/icn_Activate.svg';
import { ReactComponent as Icn_Edit } from '../../../../assets/images/icn_Edit.svg';
import { ReactComponent as Icn_Lock } from '../../../../assets/images/icn_Lock.svg';
import { ReactComponent as LogIcon } from '../../../../assets/images/icn_Log.svg';
import { ReactComponent as Icn_Trash } from '../../../../assets/images/icn_Trash.svg';
import { BtnNormal } from '../../../../components/Buttons/BtnNormal';
import { CancelSubmitBtn } from '../../../../components/Buttons/CancelSubmitBtn';
import PageTitleBar from '../../../../components/PageTitleBar/PageTitleBar';
import StatusBar from '../../../../components/StatusBar/StatusBarNormal';
import BottomInfo from '../Component/BottomInfo';
import TopInfo from '../Component/TopInfo';
import { DialogStatusConfirm } from '../Dialog/DialogStatusConfirm';
import { DropdownMenuArea } from '../Dialog/DropdownMenuArea';
import { SubmitConfirm } from '../Dialog/SubmitConfirm';
import showAlert from '../../../../utils/swal';

// #region styled-components

const IndexTableContainer = styled.div``;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function LgsSalesKit_DetailsAndEdit() {
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	const pageTitle = t('lgsSalesKit:salesProductMasterFile'); //'銷售商品主檔'
	const param = useParams('salesKitGuid');
	const isDetailRoute = useMatch(
		'/Logistics/LgsSalesKit/Details/:salesKitGuid'
	);
	const isEditRoute = useMatch('/Logistics/LgsSalesKit/Edit/:salesKitGuid');
	// console.log(param);
	// console.log(isDetailRoute);

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
	const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
	const [twoOrThreeBtn, setTwoOrThreeBtn] = useState(''); // 開啟兩按鈕或三按鈕的dialog

	const [isOpen, setIsOpen] = useState(false);
	const [tableBodyData, setTableBodyData] = useState([]);
	const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');
	const [modalData, setModalData] = useState([]);
	const [salesKitGuid, setSalesKitGuid] = useState(param.salesKitGuid);
	const [isBaseInfoCreateSuccess, setIsBaseInfoCreateSuccess] =
		useState(true);
	const [selectedCustomerClassList, setSelectedCustomerClassList] = useState(
		[]
	);
	const [specSelectedList, setSpecSelectedList] = useState([]); // 選擇specGuid [guid, guid, guid]

	const [originalDataForLog, setOriginalDataForLog] = useState({});
	const [pageData, setPageData] = useState({
		goodsItemInfo: {
			salesKitGuid: salesKitGuid,
			salesKitCode: '',
			salesKitCodeWarning: '',
			name01: '',
			name02: '',
			name03: '',
			currencyCode: '',
			listPrice: '',
			startDate: '',
			endDate: '',
			salesKitClassGuid: '',
			uomCode: '',
			samePrice: '',
			content: '',
			note: '',
		},
		salesKitSet: {
			kitSetGuid: '',
			spec1Guid: '',
			spec2Guid: '',
			spec3Guid: '',
			status: '1', // 不可銷售
			rowStamp: '',
		},
		salesKitAppendixList: [],
		salesKitPriceList: [],

		salesKitSetAppendixList: [],
		salesDetailsList: [],
		salesKitSetPriceList: [],
	});
	const [cookies, setCookie] = useCookies();
	const cookieLangList = [
		cookies['_system_language01'],
		cookies['_system_language02'] ? cookies['_system_language02'] : '',
		cookies['_system_language03'] ? cookies['_system_language03'] : '',
	];
	const defaultCurrencyCode = cookies['_company_currency_code']; // 公司幣別
	const [langList, setLangList] = useState(cookieLangList);
	const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);
	const [specList, setSpecList] = useState([]);
	const [baseInfoList, setBaseInfoList] = useState({}); // 基礎的所有選單list

	const [featureList, setFeatureList] = useState({});
	console.log('featureList', featureList);

	const navigate = useNavigate();

	// 上一頁
	const handleDialogConfirm = () => {
		isEditRoute
			? navigate('/Logistics/LgsSalesKit/Details/' + salesKitGuid)
			: navigate('/Logistics/LgsSalesKit');
	};

	function _uuid() {
		var d = Date.now();
		if (
			typeof performance !== 'undefined' &&
			typeof performance.now === 'function'
		) {
			d += performance.now(); //use high-precision timer if available
		}
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
			/[xy]/g,
			function (c) {
				var r = (d + Math.random() * 16) % 16 | 0;
				d = Math.floor(d / 16);
				return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
			}
		);
	}

	// 必須要有至少一個feature，並且至少有一個對應的spec是啟動的狀態，才顯示1.可銷售2.照片3.銷售明細4.價格
	const handleIsDisplayKitSetMoreData = () => {
		const {
			featureQuantity,
			feature1Name01,
			feature2Name01,
			feature3Name01,
		} = pageData.goodsItemInfo;

		if (
			featureQuantity === 3 &&
			feature1Name01 &&
			specList.feature1?.some((el) => el.status === '0') &&
			feature2Name01 &&
			specList.feature2?.some((el) => el.status === '0') &&
			feature3Name01 &&
			specList.feature3?.some((el) => el.status === '0')
		) {
			return true;
		} else if (
			featureQuantity === 2 &&
			feature1Name01 &&
			specList.feature1?.some((el) => el.status === '0') &&
			feature2Name01 &&
			specList.feature2?.some((el) => el.status === '0')
		) {
			return true;
		} else if (
			featureQuantity === 1 &&
			feature1Name01 &&
			specList.feature1?.some((el) => el.status === '0')
		) {
			return true;
		} else {
			return false;
		}
	};

	//啟用
	const handleActivate = () => {
		const newData = {
			...pageData.goodsItemInfo,
			status: '1',

			// log
			updateContent: t('common:log.activate', {
				value: pageData.goodsItemInfo.name01,
			}),
		};

		const formData = new FormData();
		Object.keys(newData).forEach((key) => {
			formData.append(`SalesKit.${key}`, newData[key]);
		});

		axios
			.put(`api/Logistics/LgsSalesKit/activate`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => {
				if (res.data) {
					setIsActivateDialogOpen(false);
					getDetails(
						specSelectedList[0],
						specSelectedList[1],
						specSelectedList[2]
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//停用
	const handleInActivate = () => {
		const newData = {
			...pageData.goodsItemInfo,
			status: '2',

			// log
			updateContent: t('common:log.lock', {
				value: pageData.goodsItemInfo.name01,
			}),
		};

		const formData = new FormData();
		Object.keys(newData).forEach((key) => {
			formData.append(`SalesKit.${key}`, newData[key]);
		});

		axios
			.put(`api/Logistics/LgsSalesKit/lock`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => {
				if (res.data) {
					setIsInActivateDialogOpen(false);
					getDetails(
						specSelectedList[0],
						specSelectedList[1],
						specSelectedList[2]
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//刪除
	const handleDelete = () => {
		const newData = {
			...pageData.goodsItemInfo,
			status: '4',

			// log
			updateContent: t('common:log.delete', {
				value: pageData.goodsItemInfo.name01,
			}),
		};

		const formData = new FormData();
		Object.keys(newData).forEach((key) => {
			formData.append(`SalesKit.${key}`, newData[key]);
		});

		axios
			.put(`api/Logistics/LgsSalesKit/delete`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => {
				if (res.data) {
					navigate('/Logistics/LgsSalesKit');
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getPageInfoBaseList = async () => {
		await axios
			.post(`api/Logistics/LgsSalesKit/getPageInfo`)
			.then((res) => {
				setBaseInfoList({ ...res.data.result });
			});
	};
	console.log(baseInfoList);

	const getSpecList = async () => {
		const specListTemp = {
			feature1: [],
			feature2: [],
			feature3: [],
		};

		await axios
			.post(
				`api/Logistics/LgsSalesKitSpec/getList`,
				JSON.stringify(salesKitGuid), // 銷售主檔guid
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			.then((res) => {
				// console.log(res.data.indexData);

				// 依照displayOrder排序&篩選狀態為0的資料
				const sortAndFilterSpecList = res.data.indexData.sort(
					(a, b) => {
						return a.displayOrder - b.displayOrder;
					}
				);

				const getFilterFeature = (num) => {
					return sortAndFilterSpecList.filter((item) => {
						return item.feature === num;
					});
				};

				specListTemp.feature1.push(...getFilterFeature(1));
				specListTemp.feature2.push(...getFilterFeature(2));
				specListTemp.feature3.push(...getFilterFeature(3));
			});

		getPageInfoBaseList();
		setSpecList(specListTemp);
	};

	const getDetails = (
		feature1SpecGuid,
		feature2SpecGuid,
		feature3SpecGuid
	) => {
		const params = {};

		if (feature1SpecGuid) {
			params.Spec1Guid = feature1SpecGuid;
		}
		if (feature2SpecGuid) {
			params.Spec2Guid = feature2SpecGuid;
		}
		if (feature3SpecGuid) {
			params.Spec3Guid = feature3SpecGuid;
		}

		axios
			.get(`api/Logistics/LgsSalesKit/getDetails/${salesKitGuid}`, {
				params,
			})
			.then((res) => {
				console.log(res.data);
				console.log(baseInfoList);

				// salesKit
				const currencyInfo = baseInfoList?.currencyList?.find(
					(el) => el.currencyCode === res.data.salesKit.currencyCode
				);

				const salesKitClassInfo = baseInfoList?.salesKitClassList?.find(
					(el) =>
						el.salesKitClassGuid ===
						res.data.salesKit.salesKitClassGuid
				);

				const uomInfo = baseInfoList?.uomList?.find(
					(el) => el.uomcode === res.data.salesKit.uomcode
				);

				// function calculateFeatureQuantity(
				// 	feature1SpecGuid,
				// 	feature2SpecGuid,
				// 	feature3SpecGuid
				// ) {
				// 	const hasValueCount = [
				// 		feature1SpecGuid,
				// 		feature2SpecGuid,
				// 		feature3SpecGuid,
				// 	].filter(Boolean).length;

				// 	if (hasValueCount === 3) {
				// 		return '3';
				// 	} else if (hasValueCount === 2) {
				// 		return '2';
				// 	} else if (hasValueCount === 1) {
				// 		return '1';
				// 	} else {
				// 		return '';
				// 	}
				// }

				// const featureQuantity = calculateFeatureQuantity(
				// 	feature1SpecGuid,
				// 	feature2SpecGuid,
				// 	feature3SpecGuid
				// );
				// console.log('Feature Quantity:', featureQuantity);

				// salesKitAppendixList
				const newSalesKitAppendixList = res.data.salesKitAppendixList
					? [...res.data.salesKitAppendixList].map((el) => {
							return {
								action: 'native',
								// appendixFile: '',
								// appendixType: 'jpg',
								appendixName: el.appendixName,
								appendixUrl: el.appendixUrl,
								displayOrder: el.displayOrder,
								appendixGuid: el.appendixGuid,
								rowStamp: el.rowStamp,
							};
					  })
					: [];

				// salesKitSetAppendixList
				const newSalesKitSetAppendixList = res.data
					.salesKitSetAppendixList
					? [...res.data.salesKitSetAppendixList].map((el) => {
							return {
								action: 'native',
								// appendixFile: '',
								// appendixType: 'jpg',
								appendixName: el.appendixName,
								appendixUrl: el.appendixUrl,
								displayOrder: el.displayOrder,
								appendixGuid: el.appendixGuid,
								rowStamp: el.rowStamp,
							};
					  })
					: [];

				// salesDetailsList
				const newSalesDetailsList = res.data.salesKitSetItemList
					? [...res.data.salesKitSetItemList].map((el) => {
							const materialInfo =
								baseInfoList?.materialMasterList?.find(
									(item) =>
										item.materialGuid === el.materialGuid
								);

							const serviceInfo =
								baseInfoList?.serviceMasterList?.find(
									(item) =>
										item.serviceGuid === el.serviceGuid
								);

							return {
								action: 'native',
								kitSetGuid:
									res.data.salesKitSet.kitSetGuid ?? '',
								displayOrder: el.displayOrder,
								itemGuid: el.itemGuid,
								id: el.itemGuid,
								materialGuid: el.materialGuid ?? '',
								materialName: materialInfo?.name01 ?? '',
								note: el.note ?? '',
								percentage: el.percentage,
								quantity: el.quantity,
								salesCategoryCode: el.salesCategoryCode,
								salesCategoryName:
									el.salesCategoryCode === 'P'
										? t('lgsSalesKit:material') //'物料'
										: el.salesCategoryCode === 'S'
										? t('lgsSalesKit:service') //'服務'
										: '',
								serviceGuid: el.serviceGuid ?? '',
								serviceName: serviceInfo?.name01 ?? '',
								rowStamp: el.rowStamp,
							};
					  })
					: [];

				// salesKitPriceList
				const newSalesKitPriceList =
					res.data.salesKitPriceList &&
					res.data.salesKitPriceList?.length !== 0
						? [...res.data.salesKitPriceList].map((el) => {
								const currencyInfo =
									baseInfoList?.currencyList?.find(
										(item) =>
											item.currencyCode ===
											el.currencyCode
									);

								return {
									action: 'native',
									currencyCode: el.currencyCode,
									currencyName: currencyInfo?.name01,
									customerClassGuid:
										el.customerClassGuid ?? '', // null 轉為 ""
									displayOrder: el.displayOrder,
									endDate: el.endDate?.split('T')[0] ?? '',
									id: el.priceGuid,
									priceGuid: el.priceGuid,
									note: el.note ?? '',
									quantityFrom: el.quantityFrom,
									quantityTo: el.quantityTo,
									rowStamp: el.rowStamp,
									startDate: el.startDate?.split('T')[0],
									unitPrice: el.unitPrice,
									rowStamp: el.rowStamp,
								};
						  })
						: [
								// 預設 適用全部客戶-有一筆必填的 無截止日期的價格條件
								{
									action: 'insert',
									displayOrder: 1,
									customerClassGuid: '',
									id: _uuid(),
									startDate: '',
									endDate: '',
									note: '',
									quantityFrom: 1,
									quantityTo: 1,
									rowStamp: '',
									unitPrice: 999999999,
								},
						  ];
				// salesKitSetPriceList
				const newSalesKitSetPriceList =
					res.data.salesKitSetPriceList &&
					res.data.salesKitSetPriceList?.length !== 0
						? [...res.data.salesKitSetPriceList].map((el) => {
								const currencyInfo =
									baseInfoList?.currencyList?.find(
										(item) =>
											item.currencyCode ===
											el.currencyCode
									);

								return {
									action: 'native',
									kitSetGuid:
										res.data.salesKitSet.kitSetGuid ?? '',
									currencyCode: el.currencyCode,
									currencyName: currencyInfo?.name01,
									customerClassGuid:
										el.customerClassGuid ?? '', // null 轉為 ""
									displayOrder: el.displayOrder,
									endDate: el.endDate?.split('T')[0] ?? '',
									id: el.priceGuid,
									priceGuid: el.priceGuid,
									note: el.note ?? '',
									quantityFrom: el.quantityFrom,
									quantityTo: el.quantityTo,
									rowStamp: el.rowStamp,
									startDate: el.startDate?.split('T')[0],
									unitPrice: el.unitPrice,
									rowStamp: el.rowStamp,
								};
						  })
						: [
								// 預設 適用全部客戶-有一筆必填的 無截止日期的價格條件
								{
									action: 'insert',
									displayOrder: 1,
									customerClassGuid: '',
									id: _uuid(),
									startDate: '',
									endDate: '',
									note: '',
									quantityFrom: 1,
									quantityTo: 2,
									rowStamp: '',
									unitPrice: 999999999,
								},
						  ];

				res.data.salesKitSetCustomerClassList &&
					setSelectedCustomerClassList([
						...res.data.salesKitSetCustomerClassList,
					]);

				setFeatureList({
					feature1Name01: res.data.salesKit.feature1Name01 ?? '',
					feature1Name02: res.data.salesKit.feature1Name02 ?? '',
					feature1Name03: res.data.salesKit.feature1Name03 ?? '',
					feature2Name01: res.data.salesKit.feature2Name01 ?? '',
					feature2Name02: res.data.salesKit.feature2Name02 ?? '',
					feature2Name03: res.data.salesKit.feature2Name03 ?? '',
					feature3Name01: res.data.salesKit.feature3Name01 ?? '',
					feature3Name02: res.data.salesKit.feature3Name02 ?? '',
					feature3Name03: res.data.salesKit.feature3Name03 ?? '',
				});

				setPageData({
					goodsItemInfo: {
						...res.data.salesKit,
						feature1SpecGuid:
							feature1SpecGuid ??
							pageData.goodsItemInfo.feature1SpecGuid ??
							'00000000-0000-0000-0000-000000000000',
						feature2SpecGuid:
							feature2SpecGuid ??
							pageData.goodsItemInfo.feature2SpecGuid ??
							'00000000-0000-0000-0000-000000000000',
						feature3SpecGuid:
							feature3SpecGuid ??
							pageData.goodsItemInfo.feature3SpecGuid ??
							'00000000-0000-0000-0000-000000000000',
						feature1SpecGuidWarning: '',
						feature2SpecGuidWarning: '',
						feature3SpecGuidWarning: '',

						feature1Name01: res.data.salesKit.feature1Name01 ?? '',
						feature1Name02: res.data.salesKit.feature1Name02 ?? '',
						feature1Name03: res.data.salesKit.feature1Name03 ?? '',
						feature2Name01: res.data.salesKit.feature2Name01 ?? '',
						feature2Name02: res.data.salesKit.feature2Name02 ?? '',
						feature2Name03: res.data.salesKit.feature2Name03 ?? '',
						feature3Name01: res.data.salesKit.feature3Name01 ?? '',
						feature3Name02: res.data.salesKit.feature3Name02 ?? '',
						feature3Name03: res.data.salesKit.feature3Name03 ?? '',

						startDate: res.data.salesKit.startDate.split('T')[0],
						endDate: res.data.salesKit.endDate?.split('T')[0] ?? '',

						currencyCode: res.data.salesKit.currencyCode,
						currencyGuid: currencyInfo?.currencyGuid,
						currencyName: currencyInfo?.name01,
						currencyGuidWarning: '',

						salesKitClassGuid: res.data.salesKit.salesKitClassGuid,
						salesKitClassCode: salesKitClassInfo?.salesKitClassCode,
						salesKitClassName: salesKitClassInfo?.name01,
						salesKitClassGuidWarning: '',

						uomCode: res.data.salesKit.uomcode,
						uomDisplayName: `${res.data.salesKit.uomcode} ${uomInfo?.name01}`,
						uomGuid: uomInfo?.uomguid,
						uomName: uomInfo?.name01,
						uomCodeWarning: '',

						content: res.data.salesKit.content ?? '',
						samePrice: res.data.salesKit.samePrice ?? '0',

						salesKitCodeWarning: '',

						rowStamp: res.data.salesKit.rowStamp,
					},
					salesKitSet: {
						kitSetGuid: res.data.salesKitSet?.kitSetGuid ?? '',
						spec1Guid: feature1SpecGuid ?? '',
						spec2Guid: feature2SpecGuid ?? '',
						spec3Guid: feature3SpecGuid ?? '',
						status: res.data.salesKitSet?.status ?? '1',
						rowStamp: res.data.salesKitSet?.rowStamp ?? '',
					},
					salesKitAppendixList: [...newSalesKitAppendixList],
					salesKitPriceList: [...newSalesKitPriceList],

					salesKitSetAppendixList: [...newSalesKitSetAppendixList],
					salesDetailsList: [...newSalesDetailsList],
					salesKitSetPriceList: [...newSalesKitSetPriceList],
				});

				// 保留原本資料用來LOG比對用
				setOriginalDataForLog({
					goodsItemInfo: {
						...res.data.salesKit,
						feature1SpecGuid:
							pageData.goodsItemInfo.feature1SpecGuid,
						feature2SpecGuid:
							pageData.goodsItemInfo.feature2SpecGuid,
						feature3SpecGuid:
							pageData.goodsItemInfo.feature3SpecGuid,

						startDate: res.data.salesKit.startDate.split('T')[0],
						endDate: res.data.salesKit.endDate?.split('T')[0] ?? '',

						currencyCode: res.data.salesKit.currencyCode,
						currencyGuid: currencyInfo?.currencyGuid,
						currencyName: currencyInfo?.name01,
						currencyGuidWarning: '',

						salesKitClassGuid: res.data.salesKit.salesKitClassGuid,
						salesKitClassCode: salesKitClassInfo?.salesKitClassCode,
						salesKitClassName: salesKitClassInfo?.name01,
						salesKitClassGuidWarning: '',

						uomCode: res.data.salesKit.uomcode,
						uomDisplayName: `${res.data.salesKit.uomcode} ${uomInfo?.name01}`,
						uomGuid: uomInfo?.uomguid,
						uomName: uomInfo?.name01,
						uomCodeWarning: '',

						rowStamp: res.data.salesKit.rowStamp,
					},
					salesKitAppendixList: [...newSalesKitAppendixList],
					salesKitPriceList: [...newSalesKitPriceList],

					salesKitSetAppendixList: [...newSalesKitSetAppendixList],
					salesDetailsList: [...newSalesDetailsList],
					salesKitSetPriceList: [...newSalesKitSetPriceList],
				});

				// 依狀態判斷儲存要用兩個或三個欄位
				if (res.data.salesKit.status === '1') {
					setTwoOrThreeBtn('2');
				} else if (
					res.data.salesKit.status === '0' ||
					res.data.salesKit.status === '2'
				) {
					setTwoOrThreeBtn('3');
				}
			});
	};

	console.log(selectedCustomerClassList);

	useEffect(() => {
		getSpecList();
	}, []);

	useEffect(() => {
		getDetails(
			specSelectedList[0],
			specSelectedList[1],
			specSelectedList[2]
		);
	}, [baseInfoList, isDetailRoute, isEditRoute, specSelectedList]);

	console.log(specSelectedList);

	// salesDetails相關
	const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
		useState(false);
	const [error, setError] = useState({});

	const checkEstimatedPriceList = async () => {
		const newErrorState = {
			salesDetailsList: {}, // 初始化 salesDetailsList 对象
			salesKitSetPriceList: {}, // 初始化 salesKitSetPriceList 对象

			salesKitPriceList: {}, // 初始化 salesKitPriceList 对象
		};

		// 銷售明細
		const keysToCheck = [
			'materialGuid', // materialGuid與serviceGuid，擇一必填
			'serviceGuid', // materialGuid與serviceGuid，擇一必填
			'quantity',
			'percentage',
		];

		for (const checkKey of keysToCheck) {
			for (const element of pageData?.salesDetailsList || []) {
				const uuid = element.id; // uuid 存在于 element id 中

				if (!newErrorState['salesDetailsList'][uuid]) {
					newErrorState['salesDetailsList'][uuid] = {};
				}

				if (
					(!element || !element[checkKey]) &&
					element.action !== 'delete'
				) {
					// materialGuid與serviceGuid，擇一必填即可
					if (
						(element['serviceGuid'] &&
							checkKey === 'materialGuid') ||
						(element['materialGuid'] && checkKey === 'serviceGuid')
					) {
						console.log(
							'已填入 materialGuid 或 serviceGuid，擇一必填'
						);
					} else {
						newErrorState['salesDetailsList'][uuid][checkKey] = {
							isError: true,
							message: `${t('common:errMsg.mustFill')}`,
						};
					}
				}

				// 銷售明細-價格拆分比例加總必須為100
				let total = 0;
				pageData.salesDetailsList
					.filter((item) => item.action !== 'delete')
					.forEach((element) => {
						total += parseInt(element.percentage);
					});

				console.log(total);

				if (total !== 100 && element.action !== 'delete') {
					newErrorState['salesDetailsList'][uuid]['percentage'] = {
						isError: true,
						message: t('lgsSalesKit:percentageSumMustBe100'),
					};
				}
			}
		}

		// 價格條件
		const keysToCheck2 = ['startDate', 'currencyCode', 'unitPrice'];

		let kindPriceList =
			pageData.goodsItemInfo.samePrice === '1'
				? 'salesKitPriceList' // 單一價格(是)，驗證
				: 'salesKitSetPriceList'; // 單一價格(否)，驗證

		// 必須要有至少一個feature，並且至少有一個對應的spec是啟動的狀態，才顯示1.可銷售2.照片3.銷售明細4.價格
		// 此時才需要做價格條件的驗證
		if (handleIsDisplayKitSetMoreData()) {
			for (const checkKey of keysToCheck2) {
				for (const element of pageData[kindPriceList] || []) {
					const uuid = element.id; // uuid 存在于 element id 中

					if (!newErrorState[kindPriceList][uuid]) {
						newErrorState[kindPriceList][uuid] = {};
					}

					if (
						(!element || !element[checkKey]) &&
						element.action !== 'delete'
					) {
						newErrorState[kindPriceList][uuid][checkKey] = {
							isError: true,
							message: `${t('common:errMsg.mustFill')}`,
						};
					}
				}
			}

			for (const element of pageData[kindPriceList] || []) {
				const uuid = element.id; // uuid 存在于 element id 中

				if (!newErrorState[kindPriceList][uuid]) {
					newErrorState[kindPriceList][uuid] = {};
				}

				if (
					element['quantityTo'] < element['quantityFrom'] &&
					element.action !== 'delete'
				) {
					newErrorState[kindPriceList][uuid]['quantityTo'] = {
						isError: true,
						message: t(
							'lgsSalesKit:quantityUpperLimitCannotBeLessThanLowerLimit'
						), //`數量上限不能小於數量下限`,
					};
				}
			}
		}

		console.log(newErrorState);
		return newErrorState;
	};

	const checkInputs = async () => {
		const estimatedPriceListError = await checkEstimatedPriceList();

		const newErrors = {
			...estimatedPriceListError,
		};

		setError(newErrors);
		return newErrors;
	};

	// 递归检查是否有子对象中的"isError"为true
	const checkIsErrorTrue = async (obj) => {
		if (obj && typeof obj === 'object' && Object.keys(obj).length > 0) {
			for (const prop in obj) {
				if (obj.hasOwnProperty(prop)) {
					const property = obj[prop];

					if (typeof property === 'object') {
						if (
							Object.keys(property).length > 0 &&
							checkIsErrorTrue(property)
						) {
							return true;
						}
					} else if (prop === 'isError' && property === true) {
						return true;
					}
				}
			}
			// 所有子属性都为 false，返回 false
			return false;
		} else {
			return false;
		}
	};

	const checkIsAnyErrors = async () => {
		const finalError = await checkInputs();
		console.log(finalError);

		// 遍历List每个子对象
		for (const key in finalError) {
			if (finalError.hasOwnProperty(key)) {
				const childObject = finalError[key];

				// 检查子对象中的"isError"是否为true
				if (await checkIsErrorTrue(childObject)) {
					return true;
				}
			}
		}

		let falseCount = 0;
		let newPageData = { ...pageData };

		// topInfo and spec
		const fillDataVerifyList = [
			'salesKitClassGuid',
			'name01',
			'uomCode',
			'startDate',

			'feature1SpecGuid',
			'feature2SpecGuid',
			'feature3SpecGuid',
		];

		fillDataVerifyList.forEach((el) => {
			if (!pageData.goodsItemInfo[el]) {
				newPageData.goodsItemInfo[el + 'Warning'] = t(
					'common:errMsg.mustFill'
				);
				falseCount++;
			}
		});

		// 驗證商品代碼不重複
		if (pageData.goodsItemInfo.salesKitCodeWarning !== '') falseCount++;

		console.log(falseCount);
		if (falseCount !== 0) {
			setPageData({ ...newPageData });
			return true;
		}

		// 如果所有子对象中的"isError"都为false，进行相应处理
		return false;
	};

	const confirmSubmit = async (
		isCopyItemSave,
		statusAction,
		isFeatureItemSave
	) => {
		// 驗證欄位
		const isValid = !(await checkIsAnyErrors());

		console.log(isValid);

		if (isValid) {
			setIsSubmitConfirmDialogOpen(true);

			// 計算特徵數量FeatureQuantity
			const handleFeatureQuantity = () => {
				if (
					pageData.goodsItemInfo.feature1Name01 !== '' &&
					pageData.goodsItemInfo.feature2Name01 !== '' &&
					pageData.goodsItemInfo.feature3Name01 !== ''
				) {
					return '3';
				} else if (
					pageData.goodsItemInfo.feature1Name01 !== '' &&
					pageData.goodsItemInfo.feature2Name01 !== ''
				) {
					return '2';
				} else if (pageData.goodsItemInfo.feature1Name01 !== '') {
					return '1';
				} else {
					return '';
				}
			};
			// console.log(handleFeatureQuantity());

			const newData = {
				...pageData.goodsItemInfo,
				status:
					statusAction === 'active'
						? '1'
						: statusAction === 'inActive'
						? '2'
						: pageData.goodsItemInfo.status,
				updateContent: generateUpdateLogContent(
					'salesKit',
					t,
					{ ...pageData },
					originalDataForLog
				),
				featureQuantity: handleFeatureQuantity(),

				// log
				// updateContent: t('log.edit', {
				// 	value: pageData.goodsItemInfo.name01,
				// }),
			};
			console.log(newData);

			// SalesKit
			const formData = new FormData();
			Object.keys(newData).forEach((key) => {
				formData.append(`SalesKit.${key}`, newData[key]);
			});

			// SalesKitSet
			const salesKitSetData = { ...pageData.salesKitSet };
			Object.keys(salesKitSetData).forEach((key) => {
				formData.append(`SalesKitSet.${key}`, salesKitSetData[key]);
			});

			// SalesKitAppendixList
			const addToFormData0 = (data, index, formData) => {
				Object.keys(data).forEach((key) => {
					if (key !== 'appendixUrl') {
						formData.append(
							`SalesKitAppendixList[${index}].${key}`,
							data[key]
						);
					}
				});
			};

			pageData.salesKitAppendixList.forEach((item, index) => {
				addToFormData0(item, index, formData);
			});

			// SalesKitSetAppendixList
			const addToFormData1 = (data, index, formData) => {
				Object.keys(data).forEach((key) => {
					if (key !== 'appendixUrl') {
						formData.append(
							`SalesKitSetAppendixList[${index}].${key}`,
							data[key]
						);
					}
				});
			};

			pageData.salesKitSetAppendixList.forEach((item, index) => {
				addToFormData1(item, index, formData);
			});

			// SalesKitSetItemList
			const addToFormData2 = (data, index, formData) => {
				Object.keys(data).forEach((key) => {
					formData.append(
						`SalesKitSetItemList[${index}].${key}`,
						data[key]
					);
				});
			};

			pageData.salesDetailsList.forEach((item, index) => {
				addToFormData2(item, index, formData);
			});
			// SalesKitSetPriceList
			const addToFormData3 = (data, index, formData) => {
				Object.keys(data).forEach((key) => {
					formData.append(
						`SalesKitSetPriceList[${index}].${key}`,
						data[key]
					);
					salesKitGuid;
				});
			};

			if (pageData.goodsItemInfo.samePrice === '0') {
				if (handleIsDisplayKitSetMoreData()) {
					pageData.salesKitSetPriceList.forEach((item, index) => {
						addToFormData3(item, index, formData);
					});
				} else {
					[].forEach((item, index) => {
						addToFormData3(item, index, formData);
					});
				}
			} else if (pageData.goodsItemInfo.samePrice === '1') {
				// 單一價格(是)，並且從來都沒有建立過salesKitSetPriceList資料(此時salesKitSetPriceList會是一筆預設適用全部客戶陣列)
				const isBaseInsertData =
					originalDataForLog.salesKitSetPriceList.find((el) => {
						return (
							el.customerClassGuid === '' &&
							el.displayOrder === 1 &&
							el.action === 'insert'
						);
					})
						? true
						: false;

				// console.log(
				// 	originalDataForLog.salesKitSetPriceList.find((el) => {
				// 		return (
				// 			el.customerClassGuid === '' &&
				// 			el.displayOrder === 1 &&
				// 			el.action === 'insert'
				// 		);
				// 	})
				// );

				if (isBaseInsertData || !handleIsDisplayKitSetMoreData()) {
					// 從未建立過資料，因此無須保留資料(將salesKitSetPriceList當成[]，，因此可以不要將pageData預設的salesKitSetPriceList往後端送)
					[].forEach((item, index) => {
						addToFormData3(item, index, formData);
					});
				} else {
					// 已建立過資料，則保留資料
					originalDataForLog.salesKitSetPriceList.forEach(
						(item, index) => {
							addToFormData3(item, index, formData);
						}
					);
				}
			}

			// SalesKitPriceList
			const addToFormData4 = (data, index, formData) => {
				Object.keys(data).forEach((key) => {
					formData.append(
						`SalesKitPriceList[${index}].${key}`,
						data[key]
					);
					salesKitGuid;
				});
			};

			if (pageData.goodsItemInfo.samePrice === '1') {
				pageData.salesKitPriceList.forEach((item, index) => {
					addToFormData4(item, index, formData);
				});
			} else if (pageData.goodsItemInfo.samePrice === '0') {
				// 單一價格(否)，並且從來都沒有建立過salesKitPriceList資料(此時salesKitPriceList會是一筆預設適用全部客戶陣列)
				const isBaseInsertData =
					originalDataForLog.salesKitPriceList.find((el) => {
						return (
							el.customerClassGuid === '' &&
							el.displayOrder === 1 &&
							el.action === 'insert'
						);
					})
						? true
						: false;

				// console.log(
				// 	originalDataForLog.salesKitPriceList.find((el) => {
				// 		return (
				// 			el.customerClassGuid === '' &&
				// 			el.displayOrder === 1 &&
				// 			el.action === 'insert'
				// 		);
				// 	})
				// );
				console.log(isBaseInsertData);
				console.log(!handleIsDisplayKitSetMoreData());

				if (isBaseInsertData || !handleIsDisplayKitSetMoreData()) {
					// 從未建立過資料，因此無須保留資料(將salesKitPriceList當成[]，因此可以不要將pageData預設的salesKitPriceList往後端送)
					[].forEach((item, index) => {
						addToFormData4(item, index, formData);
					});
				} else {
					// 已建立過資料，則保留資料
					originalDataForLog.salesKitPriceList.forEach(
						(item, index) => {
							addToFormData4(item, index, formData);
						}
					);
				}
			}

			axios
				.put(`api/Logistics/LgsSalesKit/update`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((res) => {
					console.log(res);

					if (isCopyItemSave) {
						setIsCopyDialogOpen(true);
					} else if (isFeatureItemSave) {
						getDetails(
							specSelectedList[0],
							specSelectedList[1],
							specSelectedList[2]
						);
					} else {
						navigate(`../Details/${salesKitGuid}`);
						getDetails(
							specSelectedList[0],
							specSelectedList[1],
							specSelectedList[2]
						);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}

		// 如果是"複製"或＂特徵改變＂的時候執行更新，則彈出提示框，提醒使用者有資料未填，所以無法開啟"複製"的dialog
		if ((!isValid && isCopyItemSave) || (!isValid && isFeatureItemSave)) {
			showAlert({
				html: `<div class="mt-4 text-danger fw-bold">請先確認表單訊息，再複製</div>`,
				htmlSvg: 'danger',
				size: 'small',
				confirmButtonText: t('button.confirmButton'),
				showCloseButton: false,
				allowOutsideClick: true,
			});
		}

		setIsSubmitConfirmDialogOpen(false);
		setIsActivateDialogOpen(false);
		setIsInActivateDialogOpen(false);
	};

	const handleFinalSubmit = (
		isCopyItemSave,
		statusAction,
		isFeatureItemSave
	) => {
		confirmSubmit(isCopyItemSave, statusAction, isFeatureItemSave);
	};

	return (
		<>
			{/* DialogStatusConfirm element */}
			<DialogStatusConfirm
				t={t}
				isOpen={isActivateDialogOpen}
				setIsOpen={setIsActivateDialogOpen}
				handleClick={() => handleFinalSubmit(false, 'active', false)}
				message={t('common:dialog.activeConfirm')}
			/>
			<DialogStatusConfirm
				t={t}
				isOpen={isInActivateDialogOpen}
				setIsOpen={setIsInActivateDialogOpen}
				handleClick={() => handleFinalSubmit(false, 'inActive', false)}
				message={t('common:dialog.lockConfirm')}
			/>
			<DialogStatusConfirm
				t={t}
				isOpen={isDeleteDialogOpen}
				setIsOpen={setIsDeleteDialogOpen}
				handleClick={() => handleDelete()}
				message={t('common:dialog.deleteConfirm')}
			/>

			<SubmitConfirm
				t={t}
				isOpen={isSubmitConfirmDialogOpen}
				setIsOpen={setIsSubmitConfirmDialogOpen}
				handleClickInactive={() =>
					handleFinalSubmit(false, 'notChange')
				} // isCopyItemSave是否是來自於"複製"的儲存, status維持本來狀態
				handleClickActive={() => handleFinalSubmit(false, 'active')} // isCopyItemSave是否是來自於"複製"的儲存, status啟動
				message={t('dialog.saveConfirm')}
				twoOrThreeBtn={twoOrThreeBtn}
			/>

			<div id="detailsView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={true}
					backTo={-1}
					isDialogConfirm={true}
					handleDialogConfirm={handleDialogConfirm}
				/>

				<>
					<Container className="">
						<Left className="">
							{isDetailRoute && (
								<>
									<BtnNormal2
										permission={[]}
										salesKitGuid={salesKitGuid}
										className="btn-primary smallBtn"
										to="Create"
										key="EditBtnDetails"
										handleClick={(e) =>
											navigate(`../Edit/${salesKitGuid}`)
										}
										word={t('common:button.editButton')}
									>
										<Icn_Edit />
									</BtnNormal2>

									{/* 當 status = 0 or 2 時，顯示"啟用"按鈕 */}
									{(pageData.goodsItemInfo?.status === '0' ||
										pageData.goodsItemInfo?.status ===
											'2') && (
										<BtnNormal2
											className="btn-primary smallBtn"
											key="EnableBtnDetails"
											handleClick={() =>
												setIsActivateDialogOpen(true)
											}
											word={t(
												'common:button.activateButton'
											)}
										>
											<Icn_Activate />
										</BtnNormal2>
									)}
								</>
							)}
						</Left>
						<Center className=""></Center>
						<Right className="">
							{isEditRoute && (
								<>
									<BtnNormal
										className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
										key={'finalSaveBtn'}
										onClick={async (e) => {
											e.preventDefault();

											// 先判斷有沒有錯誤，再開啟儲存框
											if (
												(await checkIsAnyErrors()) ===
												false
											) {
												setIsSubmitConfirmDialogOpen(
													true
												);
											}
										}}
									>
										{/* 儲存 */}
										{t('lgsSalesKit:save')}
									</BtnNormal>

									<CancelSubmitBtn
										className={'btn-outline-primary'}
									/>
								</>
							)}

							{isDetailRoute && (
								<DropdownMenuArea
									permission={
										pageData?.goodsItemInfo?.permission
									}
								>
									{/* 先判斷 status 是不是 1 ；不是的話再看是不是0。*/}
									{pageData?.goodsItemInfo?.status === '1'
										? [
												<div
													className="d-flex justify-content-start align-items-center w-100"
													key={'changeRecord'}
													onClick={() =>
														navigate(
															`../Log/${salesKitGuid}`
														)
													}
												>
													<LogIcon />
													<span>
														{t(
															'common:button.logButton'
														)}
													</span>
												</div>,
												<div
													className="d-flex justify-content-start align-items-center w-100"
													key={'disabled'}
													onClick={() =>
														setIsInActivateDialogOpen(
															true
														)
													}
												>
													<Icn_Lock />
													<span>
														{t(
															'common:button.lockButton'
														)}
													</span>
												</div>,
										  ]
										: pageData?.goodsItemInfo?.status ===
										  '0'
										? [
												<div
													className="d-flex justify-content-start align-items-center w-100"
													key={'changeRecord'}
													onClick={() =>
														navigate(
															`../Log/${salesKitGuid}`
														)
													}
												>
													<LogIcon />
													<span>
														{t(
															'common:button.logButton'
														)}
													</span>
												</div>,
												<div
													className="d-flex justify-content-start align-items-center w-100"
													onClick={() =>
														setIsDeleteDialogOpen(
															true
														)
													}
												>
													<Icn_Trash />
													<span>
														{t(
															'common:button.deleteButton'
														)}
													</span>
												</div>,
										  ]
										: [
												<div
													className="d-flex justify-content-center align-items-center"
													key={'changeRecord'}
													onClick={() =>
														navigate(
															`../Log/${salesKitGuid}`
														)
													}
												>
													<LogIcon />
													<span>
														{t('button.logButton')}
													</span>
												</div>,
										  ]}
								</DropdownMenuArea>
							)}
						</Right>
					</Container>

					<div className="position-relative">
						{pageData.goodsItemInfo?.status !== '1' && (
							<StatusBar
								status={pageData.goodsItemInfo?.status}
							/>
						)}

						<div
							className="bg-white d-flex flex-column p-4 position-relative mb-3"
							style={{
								// .75rem .75rem 0 0
								borderRadius: `${
									pageData.goodsItemInfo?.status !== '1'
										? '0 0 .75rem .75rem'
										: '.75rem'
								}`,
							}}
						>
							<TopInfo
								pageData={pageData}
								setPageData={setPageData}
								dialogBtnKeyName={dialogBtnKeyName}
								setDialogBtnKeyName={setDialogBtnKeyName}
								isOpen={isOpen}
								setIsOpen={setIsOpen}
								modalData={modalData}
								setModalData={setModalData}
								baseInfoList={baseInfoList}
								langList={langList}
								isDetailRoute={isDetailRoute}
								isEditRoute={isEditRoute}
								selectedCustomerClassList={
									selectedCustomerClassList
								}
								error={error}
								tableBodyData={tableBodyData}
								setTableBodyData={setTableBodyData}
								setSelectedCustomerClassList={
									setSelectedCustomerClassList
								}
							/>
						</div>
						{isBaseInfoCreateSuccess && (
							<div className="position-relative">
								<IndexTableContainer className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
									<BottomInfo
										pageData={pageData}
										setPageData={setPageData}
										tableBodyData={tableBodyData}
										setTableBodyData={setTableBodyData}
										dialogBtnKeyName={dialogBtnKeyName}
										setDialogBtnKeyName={
											setDialogBtnKeyName
										}
										isOpen={isOpen}
										setIsOpen={setIsOpen}
										modalData={modalData}
										setModalData={setModalData}
										langList={langList}
										getSpecList={getSpecList}
										specList={specList}
										salesKitGuid={salesKitGuid}
										handleFinalSubmit={handleFinalSubmit}
										error={error}
										setError={setError}
										baseInfoList={baseInfoList}
										selectedCustomerClassList={
											selectedCustomerClassList
										}
										setSelectedCustomerClassList={
											setSelectedCustomerClassList
										}
										isDetailRoute={isDetailRoute}
										isEditRoute={isEditRoute}
										getDetails={getDetails}
										isCopyDialogOpen={isCopyDialogOpen}
										setIsCopyDialogOpen={
											setIsCopyDialogOpen
										}
										featureList={featureList}
										setFeatureList={setFeatureList}
										specSelectedList={specSelectedList}
										setSpecSelectedList={
											setSpecSelectedList
										}
										handleIsDisplayKitSetMoreData={
											handleIsDisplayKitSetMoreData
										}
									/>
								</IndexTableContainer>
							</div>
						)}
					</div>
				</>
			</div>
		</>
	);
}

export default LgsSalesKit_DetailsAndEdit;
