import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_Search2 } from '../../../../assets/images/icn_Search2.svg';
import { ReactComponent as Icn_Cancel } from '../../../../assets/images/icn_Cancel.svg';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../assets/images/icn_InputYellowCancel.svg';
import useSortableData from '../../../../hooks/useSortableData';
import { useTranslation } from 'react-i18next';

// #region styled-components
const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	width: 100%;
	height: 100%;
`;

const ModalDialog = styled.div`
	max-width: 600px !important;
	min-height: calc(100% - 3.5rem);
`;

const ModalThead = styled.thead`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalTh = styled.th`
	padding: 0 0.75rem;
	cursor: pointer !important;
	border: none !important;
`;
const ModalLabel = styled.label`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalInput = styled.input`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;

// #endregion

export const DialogCol = ({
	indexDialog,
	isOpen,
	setIsOpen,
	modalData,
	setModalData,
	btnKeyName,
	pageData,
	setPageData,
	invoiceDialogData,
	setInvoiceDialogData,
	tableBodyData,
	setTableBodyData,
	isEditRoute,
}) => {
	//#region state initialization

	const { t } = useTranslation(['common', 'lgsSalesKit']); // i18n

	// state
	const [isCancelSearchModalBtn, setIsCancelSearchModalBtn] = useState(false); // cancel search btn
	const [oneCheck, setOneCheck] = useState('');

	// ref
	const searchModalInput = useRef('');

	console.log(modalData);
	//#endregion

	//#region Modal儲存
	function modalSave() {
		// 選取哪些checkbox
		const handleSelectedData = () => {
			const selectedId = [];
			const newPageData = { ...pageData };

			document
				.querySelectorAll('.modal-content [type=checkbox]')
				.forEach((el) => {
					el.checked && selectedId.push(el.id);
				});

			// 1.salesKitClass
			if (btnKeyName === 'salesKitClass') {
				newPageData.goodsItemInfo.salesKitClassGuid = selectedId[0];

				let item = modalData.find((el) => {
					return el[`salesKitClassGuid`] === selectedId[0];
				});

				newPageData.goodsItemInfo.salesKitClassCode =
					item.salesKitClassCode;
				newPageData.goodsItemInfo.salesKitClassName = item.name01;

				// 清除錯誤提示 TODO
				newPageData.goodsItemInfo.salesKitClassGuidWarning = '';
			}

			// 2.currency
			if (btnKeyName === 'currency') {
				newPageData.goodsItemInfo.currencyGuid = selectedId[0];

				let item = modalData.find((el) => {
					return el[`currencyGuid`] === selectedId[0];
				});

				newPageData.goodsItemInfo.currencyCode = item.currencyCode;
				newPageData.goodsItemInfo.currencyName = item.name01;

				// 清除錯誤提示 TODO
				newPageData.goodsItemInfo.currencyGuidWarning = '';
			}

			// 3.material / service
			if (btnKeyName === 'material') {
				console.log(selectedId[0]);
				console.log(newPageData.salesDetailsList[indexDialog]);
				newPageData.salesDetailsList[indexDialog].materialGuid =
					selectedId[0];

				let item = modalData.find((el) => {
					return el[`materialGuid`] === selectedId[0];
				});

				newPageData.salesDetailsList[indexDialog].materialName =
					item.name01;

				if (
					isEditRoute &&
					newPageData.salesDetailsList[indexDialog].action !==
						'insert'
				)
					newPageData.salesDetailsList[indexDialog].action = 'update';
				// 清除錯誤提示
				// const newTableBodyData = [...tableBodyData];
				// newTableBodyData.forEach((el) => {
				// 	if (
				// 		newPageData.salesDetailsList[indexDialog]
				// 			.salesDetailsGuid === el.salesDetailsGuid
				// 	) {
				// 		el.materialGuidWarning = '';
				// 	}
				// });
				// setTableBodyData(newTableBodyData);
			} else if (btnKeyName === 'service') {
				console.log(selectedId[0]);
				console.log(newPageData.salesDetailsList[indexDialog]);
				newPageData.salesDetailsList[indexDialog].serviceGuid =
					selectedId[0];

				let item = modalData.find((el) => {
					return el[`serviceGuid`] === selectedId[0];
				});

				newPageData.salesDetailsList[indexDialog].serviceName =
					item.name01;

				if (
					isEditRoute &&
					newPageData.salesDetailsList[indexDialog].action !==
						'insert'
				)
					newPageData.salesDetailsList[indexDialog].action = 'update';

				// 清除錯誤提示
				// const newTableBodyData = [...tableBodyData];
				// newTableBodyData.forEach((el) => {
				// 	if (
				// 		newPageData.salesDetailsList[indexDialog]
				// 			.salesDetailsGuid === el.salesDetailsGuid
				// 	) {
				// 		el.serviceGuidWarning = '';
				// 	}
				// });
				// setTableBodyData(newTableBodyData);
			}
			setPageData(newPageData);
		};

		oneCheck.length !== 0 && handleSelectedData();
		console.log('pageData', pageData);

		handleClose();
	}
	//#endregion

	// Modal取消(關閉)
	const handleClose = () => {
		setIsOpen(false);
	};

	//#region Modal搜尋

	// 搜尋
	const searchModalBtn = () => {
		//取得搜尋文字
		let searchModalInputText = searchModalInput.current
			? searchModalInput.current.value.toUpperCase()
			: searchModal2Input.current.value.toUpperCase();

		let modalTrList = document.querySelectorAll('.modalTr');

		if (searchModalInputText !== '') {
			//隱藏全部欄位
			modalTrList.forEach((item) => {
				item.classList.add('d-none');
			});

			// 搜尋每個Tr中的每個Td欄位
			modalTrList.forEach((item) => {
				// console.log(item.children);

				[...item.children].forEach((td) => {
					if (
						td.textContent
							.toUpperCase()
							.includes(searchModalInputText)
					) {
						td.parentElement.classList.remove('d-none');
					}
				});
			});

			//顯示取消搜尋btn
			setIsCancelSearchModalBtn(true);
		}
	};

	// 取消搜尋
	const cancelSearchModalBtn = (i) => {
		// 顯示全部欄位
		document.querySelectorAll('.modalTr').forEach((item) => {
			item.classList.remove('d-none');
		});

		// 清空搜尋欄位
		if (searchModalInput.current) searchModalInput.current.value = '';

		// 隱藏取消搜尋btn
		setIsCancelSearchModalBtn(false);
	};

	//#endregion

	//#region Modal clickTableRow(只能選一個)

	function clickTableRow(e, guid) {
		// console.log(guid);
		setOneCheck(guid);

		//點選tr 使checkbox被選取
		let majorCheck =
			e.target.parentElement.children[0].querySelector('input');

		//避免input無法直接點擊
		if (e.nodeName != 'INPUT' && e.nodeName != 'LABEL') {
			majorCheck.checked = !majorCheck.checked;
		}
	}

	//#endregion

	//#region Modal 排序

	let headTitleList = [];
	let bodyTitleList = [];

	if (btnKeyName === 'salesKitClass') {
		headTitleList = [
			t('lgsSalesKit:salesProductCategoryCode'),
			t('lgsSalesKit:name'),
		]; //['銷售商品分類代碼', '名稱']
		bodyTitleList = ['salesKitClassCode', 'name01'];
	} else if (btnKeyName === 'currency') {
		headTitleList = [t('lgsSalesKit:currencyCode'), t('lgsSalesKit:name')]; //['幣別代碼', '名稱'];
		bodyTitleList = ['currencyCode', 'name01'];
	} else if (btnKeyName === 'material') {
		headTitleList = [t('lgsSalesKit:number'), t('lgsSalesKit:name')]; // ['編號', '名稱'];
		bodyTitleList = ['materialNo', 'number'];
	} else if (btnKeyName === 'service') {
		headTitleList = [t('lgsSalesKit:number'), t('lgsSalesKit:name')]; // ['編號', '名稱'];
		bodyTitleList = ['serviceNo', 'name01'];
	}

	useEffect(() => {
		setUISort(
			btnKeyName === 'salesKitClass'
				? 'salesKitClassCode'
				: btnKeyName === 'currency'
				? 'currencyCode'
				: btnKeyName === 'material'
				? 'materialNo'
				: btnKeyName === 'service'
				? 'serviceNo'
				: ''
		);
	}, [btnKeyName]);

	const [orderBy, setOrderBy] = useState('asc'); // 排序方式
	const [UISort, setUISort] = useState(''); // 排序欄位
	const {
		items: items,
		requestSort: requestSort,
		sortConfig: sortConfig,
	} = useSortableData(modalData, bodyTitleList[0], 'ascending'); // 排序

	// console.log(modalData);

	const handleSortChange = (newTarget) => {
		const oldTarget = UISort;
		setUISort(newTarget);

		if (oldTarget === newTarget) {
			setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
		} else {
			setOrderBy('asc');
		}

		setModalData(items);
	};

	//#endregion

	// 每次打開dialog，清空所選擇的項目
	useEffect(() => {
		setOneCheck('');
	}, [isOpen]);

	return (
		<>
			{isOpen && (
				<ModalWrapper onClick={handleClose}>
					<ModalDialog
						className="modal-dialog d-flex align-items-center"
						onClick={(e) => e.stopPropagation()}
					>
						<div
							className="modal-content border-0 bg-white"
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
								borderRadius: '12px',
							}}
						>
							<div className="modal-header px-4 pt-4 pb-3">
								<h5 className="text-primary fw-bold fontSize125Rem mb-0">
									{btnKeyName === 'salesKitClass'
										? t('lgsSalesKit:productCategory') //'商品分類'
										: btnKeyName === 'currency'
										? t('lgsSalesKit:currency') //'幣別'
										: btnKeyName === 'material'
										? t('lgsSalesKit:material') //'物料'
										: btnKeyName === 'service'
										? t('lgsSalesKit:service') //'服務'
										: ''}
								</h5>
								<button
									style={{ top: '12px', right: '12px' }}
									type="button"
									className="close position-absolute btn"
									onClick={handleClose}
								>
									<Icn_Cancel />
								</button>
							</div>

							<div className="modal-body text-center px-7 py-0">
								{/* 搜尋 */}
								<div className="row">
									<div className="position-relative col-12 col-md-6 p-0 pr-3 mt-6 mb-3">
										<input
											type="text"
											id="uomSearchInput"
											name="searchModalInput"
											className="form-control searchModalInput fontSize875Rem"
											ref={searchModalInput}
											placeholder={t(
												'lgsSalesKit:dialogCol.quickSearch'
											)}
											style={{
												height: '32px',
												textTransform: 'uppercase',
											}}
										/>
										<button
											type="button"
											className="searchModalBtn position-absolute border-0 btn btn-primaryllt px-3 py-0"
											onClick={() => searchModalBtn()}
											style={{
												top: '1px',
												right: '1px',
												height: '30px',
												borderRadius:
													'0 0.25rem 0.25rem 0',
											}}
										>
											<Icn_Search2 />
										</button>
										{isCancelSearchModalBtn && (
											<a
												id="cancelSearchUomModal"
												className={`cancelSearchModalBtn position-absolute ${
													searchModalInput.current
														?.value
														? ''
														: 'd-none'
												}`}
												onClick={() =>
													cancelSearchModalBtn()
												}
												style={{
													top: '3px',
													right: '-24px',
													cursor: 'pointer',
												}}
											>
												<Icn_InputYellowCancel />
											</a>
										)}
									</div>
								</div>

								<div
									className="form-group pe-0 mb-0 row"
									style={{
										height: '500px',
										overflowY: 'scroll',
									}}
								>
									<table
										className="table tableModal"
										style={{ height: 'fit-content' }}
									>
										<ModalThead
											className="table-head position-sticky"
											style={{ top: '0' }}
											items={items}
											requestSort={requestSort}
											sortConfig={sortConfig}
										>
											<tr>
												<ModalTh className="p-0">
													<ModalLabel className="m-0 w-100 text-center">
														<ModalInput
															className="totalCheckBtn d-none"
															type="checkbox"
														/>
														<span></span>
													</ModalLabel>
												</ModalTh>
												{headTitleList?.map(
													(el, index) => {
														return (
															<th
																key={
																	bodyTitleList[
																		index
																	] + index
																}
																className="text-start"
																onClick={() => {
																	requestSort(
																		bodyTitleList[
																			headTitleList.indexOf(
																				el
																			)
																		]
																	);
																	handleSortChange(
																		bodyTitleList[
																			headTitleList.indexOf(
																				el
																			)
																		]
																	);
																}}
															>
																{el}
																{bodyTitleList[
																	headTitleList.indexOf(
																		el
																	)
																] === UISort &&
																	(orderBy ===
																	'desc' ? (
																		<span className="ml-1">
																			▼
																		</span>
																	) : (
																		<span className="ml-1">
																			▲
																		</span>
																	))}
															</th>
														);
													}
												)}
											</tr>
										</ModalThead>
										<tbody>
											{items.map((item) => {
												return (
													<tr
														key={
															item[
																`${btnKeyName}Guid`
															]
														}
														className="modalTr list-item table-item tableData"
														onClick={(e) =>
															clickTableRow(
																e,
																item[
																	`${btnKeyName}Guid`
																]
															)
														}
													>
														<td className="text-center p-0">
															<label className="m-0 w-100 px-2">
																<input
																	type="checkbox"
																	id={
																		item[
																			`${btnKeyName}Guid`
																		]
																	}
																	value={
																		item[
																			`${btnKeyName}Guid`
																		]
																	}
																	className="mt-2"
																	checked={
																		oneCheck ===
																		item[
																			`${btnKeyName}Guid`
																		]
																	}
																	onChange={() => {}}
																/>
															</label>
														</td>
														<td className="text-start">
															{btnKeyName ===
																'material' ||
															btnKeyName ===
																'service'
																? item[
																		`${btnKeyName}No`
																  ]
																: item[
																		`${btnKeyName}Code`
																  ]}
														</td>

														<td className="text-start">
															{btnKeyName ===
															'employee'
																? item.fullname
																: btnKeyName ===
																		'cube' ||
																  'vendor' ||
																  'customer' ||
																  'material'
																? item.name01
																: ''}
														</td>

														{(btnKeyName ===
															'vendor' ||
															btnKeyName ===
																'customer') && (
															<td className="text-start">
																{btnKeyName ===
																'vendor'
																	? item.vendorTaxId
																	: btnKeyName ===
																	  'customer'
																	? item.purchaserAddTel
																	: ''}
															</td>
														)}
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>

							<hr
								className="mx-4 mt-3 mb-0"
								style={{
									border: `1px solid var(--grey2)`,
								}}
							/>

							{/* 確定btn */}
							<div className="modal-footer justify-content-center border-0 pt-4 pb-5">
								<button
									type="button"
									className="btn btn-primary mediumBtn mx-1"
									style={{
										margin: '0 20px',
										width: '120px',
									}}
									onClick={modalSave}
								>
									{t('common:button.confirmButton')}
								</button>
								<button
									type="button"
									className="btn btn-outline-primarylllt mediumBtn mx-1"
									onClick={handleClose}
								>
									{t('common:button.cancelButton')}
								</button>
							</div>
						</div>
					</ModalDialog>
				</ModalWrapper>
			)}
		</>
	);
};
