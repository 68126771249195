import { useState } from 'react';
import PageTitleBar from '../../../../components/PageTitleBar/PageTitleBar';
import Page0 from './Pages/Page0/Page0';
import Page1 from './Pages/Page1/Page1';
import Page2 from './Pages/Page2/Page2';
import Page3 from './Pages/Page3/Page3';
import { DialogConfirm } from './Pages/DialogConfirm';
import { useTranslation } from 'react-i18next';

function LgsSalesOrderPreparing_Edit() {
    //#region state initialization
    // i18n
    const { t } = useTranslation(['lgsSalesOrderPreparing', 'common']);

    // state
    const [parentData, setParentData] = useState({
        isEverEnterPage: {
            page0: false,
            page1: false,
            page2: false,
            page3: false,
        },
        page0: {},
        page1: {},
        page2: {},
        page3: {},
    });
    const [page, setPage] = useState(0);

    // console.log(parentData);

    const pageTitle = t('lgsSalesOrderPreparing:lgsSalesOrderPreparingEdit');
    const processSteps = [
        t('lgsSalesOrderPreparing:selectOrder'),
        t('lgsSalesOrderPreparing:goodsDetails'),
        // t("lgsSalesOrderPreparing:invoiceDetails"),
        t('lgsSalesOrderPreparing:confirmation'),
    ];

    //#endregion

    //#region handlePage
    const handlePrevPage = () => {
        setPage(page - 1);
    };

    const handleNextPage = () => {
        //console.log("handleNextPage");
        setPage(page + 1);
    };
    //#endregion

    //#region DialogConfirm

    //  返回btn確認dialog
    const [dialogConfirmData, setDialogConfirmData] = useState({
        btnName: '',
        isOpen: false,
        goTo: '',
        title: '',
    });
    const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);
    const [tableBodyData, setTableBodyData] = useState();
    const [testDataParents1, setTestDataParents1] = useState();

    // DialogConfirm Modal open
    const handleDialogConfirm = () => {
        const newData = {
            btnName: 'goToRoot',
            isOpen: true,
            goTo: 'toRoot',
            title:
                t('lgsSalesOrderPreparing:confirmLeavePurchaseOrderPage') + '?',
        };
        setDialogConfirmData(newData);
    };

    //#endregion

    //console.log(parentData);

    return (
        <>
            <div id="detailsView">
                <PageTitleBar
                    pageTitle={pageTitle}
                    previousPageButton={true}
                    goTo={-1}
                    isDialogConfirm={true}
                    handleDialogConfirm={handleDialogConfirm}
                />

                {/* DialogConfirm element */}
                <DialogConfirm
                    dialogConfirmData={dialogConfirmData}
                    setDialogConfirmData={setDialogConfirmData}
                    page={page}
                    setPage={setPage}
                    setIsConfirmBtnClicked={setIsConfirmBtnClicked}
                />

                <div>
                    {page === 0 && (
                        <Page0
                            parentData={parentData}
                            setParentData={setParentData}
                            page={page}
                            processSteps={processSteps}
                            handleNextPage={handleNextPage}
                            t={t}
                        />
                    )}
                    {page === 1 && (
                        <Page1
                            setDialogConfirmData={setDialogConfirmData}
                            parentData={parentData}
                            setParentData={setParentData}
                            tableBodyData={tableBodyData}
                            setTableBodyData={setTableBodyData}
                            page={page}
                            processSteps={processSteps}
                            handleNextPage={handleNextPage}
                            t={t}
                        />
                    )}
                    {page === 2 && (
                        <Page2
                            parentData={parentData}
                            setParentData={setParentData}
                            tableBodyData={tableBodyData}
                            setTableBodyData={setTableBodyData}
                            testDataParents1={testDataParents1}
                            setTestDataParents1={setTestDataParents1}
                            page={page}
                            setPage={setPage}
                            processSteps={processSteps}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                            t={t}
                        />
                    )}
                    {page === 3 && (
                        <Page3
                            parentData={parentData}
                            setParentData={setParentData}
                            tableBodyData={tableBodyData}
                            setTableBodyData={setTableBodyData}
                            page={page}
                            setPage={setPage}
                            processSteps={processSteps}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                            t={t}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default LgsSalesOrderPreparing_Edit;
