import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import { useCookies } from 'react-cookie';
import { ReactComponent as Icn_OpenModal } from '../../../../../assets/images/icn_OpenModal.svg';
import CurrencyModal from './CurrencyModal';
import CustomerModal from './CustomerModal';
import SectorModal from './SectorModal';
import InvoiceFormatTypeModal from './InvoiceFormatTypeModal';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as CopyPage } from '../../../../../assets/images/icn_copyPage.svg';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import StatusBar from '../../../../../components/StatusBar/StatusBar';
import { OrderStatusBtn } from '../../../../../components/Buttons/OrderStatusBtn';

// #region styled-components

const NumberInputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}

	-webkit-inner-spin-button,
	-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:hover::-webkit-inner-spin-button,
	&:hover::-webkit-outer-spin-button {
		-webkit-appearance: none !important;
		opacity: 0;
	}

	&:focus::-webkit-inner-spin-button,
	&:focus::-webkit-outer-spin-button {
		-webkit-appearance: none !important;
		opacity: 0;
	}
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

const InputElement = styled.input`
	font-size: 14px;
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	background-color: ${(prop) => (prop.disabled ? 'var(--grey1)' : '#fff')};
`;

const SelectElement = styled.select`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	cursor: ${(prop) => (prop.disabled ? 'default' : 'pointer')};
	background-color: ${(prop) => (prop.disabled ? 'var(--grey1)' : '#fff')};

	/* 下拉箭頭圖案 */
	background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
		linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
	background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
	background-size: 6px 6px, 6px 6px;
	background-repeat: no-repeat;
	-webkit-appearance: none;
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const Wrapper = styled.div`
	min-height: 720px;
`;

const NoInfoRow = styled.div`
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	margin-top: 16px;
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

const BtnNormal = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 88px;
	height: 32px;
	margin-right: 8px;
	svg {
		margin-right: 8px;
	}
`;

const DialogBtn = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 12px;
	cursor: ${(prop) => (prop.disabled ? 'default' : 'pointer')};
	height: 32px;
	background-color: ${(prop) =>
		prop.disabled ? 'var(--bs-secondary-bg)' : '#fff'};
`; // dialog按鈕

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕
//#endregion

function Page0({
	page,
	setPage,
	processSteps,
	handleNextPage,
	salesOrderData,
	setSalesOrderData,
	copySalesOrderData,
	isEdit,
}) {
	const { t } = useTranslation(['common', 'lgsSalesOrder']);
	const [cookies, setCookie] = useCookies();
	//console.log(copySalesOrderData);
	//console.log(cookies);

	const [orderTypeList, setOrderTypeList] = useState([]);
	const [currencyList, setCurrencyList] = useState([]);
	const [customerList, setCustomerList] = useState([]);
	const [invoiceTaxRateTypeList, setInvoiceTaxRateTypeList] = useState([]);
	const [sectorList, setSectorList] = useState([]);
	const [invoiceFormatTypeList, setInvoiceFormatTypeList] = useState([]);
	const companyCurrencyCode = cookies._company_currency_code;
	const decimalPlaceTl = cookies._company_currency_decimalplace_tl;

	const [data, setData] = useState({
		orderGuid: '', // 銷售訂單Guid
		orderTypeGuid: '',
		orderTypeCode: '', // 銷售訂單類型
		categoryControl: '', //	銷售訂單Control
		orderDate: '', // 銷售日期
		currencyShow: '', // 幣別顯示
		currencyCode: '', // 交易幣別
		currencyName: '', // 交易幣別名稱
		decimalPlaceTl: decimalPlaceTl, // 交易幣別小數位數
		foreignCurrency: '', // 外幣交易
		exchangeRate: '', // 匯率
		customerGuid: '', // 客戶Guid
		recipient: '', // 客戶
		customerPhone1: '', // 客戶聯絡代表(聯絡人)電話1
		customerPhone2: '', // 客戶聯絡代表(聯絡人)電話2
		customerAddress: '', // 客戶聯絡代表(聯絡人)地址
		outputTaxGuid: '', // 發票稅別Guid
		outputTaxCode: '', // 發票稅別Code
		outputTaxName: '', // 發票稅別Name
		outputTaxRate: '', // 發票進項稅率
		sectorGuid: '', // 收貨部門Guid
		sectorName: '', // 收貨部門
		invoiceFormatTypeGuid: '',
		invoiceFormatTypeCode: '',
		invoiceFormatTypeName: '', // 發票類型
		taxID: '', // 統一編號
		invoiceTitle: '', // 發票抬頭
		note_Customer: '', // 購買者註記
		note_Internal: '', // 公司內部註記
	});

	// fakeData
	// const [data, setData] = useState({
	// 	categoryControl: undefined,
	// 	currencyCode: 'JPY',
	// 	currencyCodeWarning: '',
	// 	currencyName: '日圓',
	// 	currencyShow: 'JPY 日圓',
	// 	customerAddress: '234台灣台北市天龍國天龍路1號',
	// 	customerCode: '001',
	// 	customerGuid: '26b90f14-3290-4769-7da0-08dbd3a7f787',
	// 	customerClassGuid: '10b9a4a2-a20b-4e87-78f6-08dbd3a7e932',
	// 	customerPhone1: '0910123456',
	// 	customerPhone2: '0911789456',
	// 	decimalPlaceTl: decimalPlaceTl,
	// 	exchangeRate: '.25',
	// 	exchangeRateWarning: '',
	// 	foreignCurrency: true,
	// 	outputTaxCode: 'SALETAX1',
	// 	outputTaxGuid: '44741d3c-8adf-40b2-3d9c-08dc1af55e4c',
	// 	outputTaxName: '銷控制稅別',
	// 	outputTaxRate: '5',
	// 	invoiceFormatTypeCode: 'aaa',
	// 	invoiceFormatTypeGuid: '08d11827-b980-44f4-20e8-08dbcfaa5ca7',
	// 	invoiceFormatTypeName: '發票類型1',
	// 	invoiceTitle: '芙莉蓮魔法公司',
	// 	note_Customer: '魔仗買一支',
	// 	note_Internal: '童叟無欺',
	// 	orderDate: '2024-01-01',
	// 	orderDateWarning: '',
	// 	orderGuid: '',
	// 	orderTypeCode: 'SALESORDERTYPE1',
	// 	orderTypeCodeWarning: '',
	// 	orderTypeGuid: '3c62f2f7-f5ac-431a-80b4-08dc1b1774e9',
	// 	orderTypeName: '銷售訂單類型1',
	// 	recipient: '客戶1',
	// 	recipientWarning: '',
	// 	sectorGuid: '296089b4-007e-4714-8e82-08dc012e404e',
	// 	sectorGuidWarning: '',
	// 	sectorName: '部門2',
	// 	taxID: '456789123',
	// });

	console.log(data);

	const handleChangePage1 = () => {
		// 送出前驗證
		const newData = { ...data }; //我的 data
		let falseCount = 0;

		const idKeys = Object.keys(newData);
		//console.log(idKeys);

		let array = [
			'orderTypeCode',
			'orderDate',
			'currencyCode',
			'outputTaxGuid',
			'outputTaxRate',
			'sectorGuid',
			'recipient',
			'invoiceFormatTypeGuid',
		];

		newData.foreignCurrency === true && array.push('exchangeRate');

		// required必填欄位驗證
		//console.log('Before loop', newData);

		array.forEach((keyName) => {
			//console.log('Inside loop', keyName, newData[keyName]);
			if (newData[keyName] === '') {
				newData[`${keyName}Warning`] = t('lgsSalesOrder:fillData');
				falseCount++;
			}
		});

		console.log('After loop', newData);

		setData(newData);
		console.log(newData);

		console.log(falseCount);
		if (falseCount === 0) return true;
	};

	// 整理為後端需要的資料，存到父層
	const handleChangePage = () => {
		if (handleChangePage1()) {
			setSalesOrderData({
				...salesOrderData,
				page0: data,
			});
			handleNextPage();
		}
	};

	// input 有值時，清空錯誤訊息
	useEffect(() => {
		const newData = { ...data };

		if (newData['orderTypeCode']) {
			newData[`orderTypeCodeWarning`] = ''; // 清空錯誤訊息
			setData(newData);
		}
		if (newData['orderDate']) {
			newData[`orderDateWarning`] = ''; // 清空錯誤訊息
			setData(newData);
		}
		if (newData['exchangeRate']) {
			newData[`exchangeRateWarning`] = ''; // 清空錯誤訊息
			setData(newData);
		}
		if (newData['sectorGuid']) {
			newData[`sectorGuidWarning`] = ''; // 清空錯誤訊息
			setData(newData);
		}
		if (newData['currencyCode']) {
			newData[`currencyCodeWarning`] = ''; // 清空錯誤訊息
			setData(newData);
		}
		if (newData['recipient']) {
			newData[`recipientWarning`] = ''; // 清空錯誤訊息
			setData(newData);
		}
	}, [
		data.orderTypeCode,
		data.orderDate,
		data.exchangeRate,
		data.sectorGuid,
		data.currencyCode,
		data.recipient,
	]);
	//console.log(data);
	// console.log(salesOrderData);

	const [currencyTableShow, setCurrencyTableShow] = useState(false);
	const handleCurrencyClose = () => setCurrencyTableShow(false);
	const handleCurrencyShow = () => {
		setCurrencyTableShow(true);
	};

	const [customerTableShow, setCustomerTableShow] = useState(false);
	const handleCustomerClose = () => setCustomerTableShow(false);
	const handleCustomerShow = () => {
		setCustomerTableShow(true);
	};

	const [customer1TableShow, setCustomer1TableShow] = useState(false);
	const handleCustomer1Close = () => setCustomer1TableShow(false);
	const handleCustomer1Show = () => {
		setCustomer1TableShow(true);
	};

	const [sectorTableShow, setSectorTableShow] = useState(false);
	const handleSectorClose = () => setSectorTableShow(false);
	const handleSectorShow = () => {
		setSectorTableShow(true);
	};

	const [invoiceFormatTypeTableShow, setInvoiceFormatTypeTableShow] =
		useState(false);
	const handleInvoiceFormatTypeClose = () =>
		setInvoiceFormatTypeTableShow(false);
	const handleInvoiceFormatTypeShow = () => {
		setInvoiceFormatTypeTableShow(true);
	};

	const getCreateData = () => {
		axios
			.get(`api/Logistics/LgsSalesOrder/getCreateDatapage0`)
			.then((res) => {
				console.log(res.data);
				setOrderTypeList(res.data.orderTypeList);
				setSectorList(res.data.sectorList);
				setCurrencyList(res.data.currencyList);
				setCustomerList(res.data.customerList);
				setInvoiceTaxRateTypeList(res.data.invoiceTaxRateTypeList);
				setInvoiceFormatTypeList(res.data.invoiceFormatTypeList);
			});
	};

	const handleGetExchangeRate = (orderDate, currencyCode) => {
		//console.log(orderDate, currencyCode);

		// call api (axios)
		axios.get(`api/Logistics/LgsSalesOrder/getExchangeRate`).then((res) => {
			console.log(res.data);
		});
		// setData.rate ------這邊判斷還沒完成
	};

	// 載入畫面時從複製訂單拿到資料
	useEffect(() => {
		Object.values(copySalesOrderData).length !== 0 && getCopyData();
	}, [copySalesOrderData]);

	// 從API拿到的資料
	useEffect(() => {
		getCreateData(); // 拿各種可選資料的list(幣別/客戶/發票類型...)
	}, []);

	const getCopyData = () => {
		//console.log(copySalesOrderData.salesOrder);

		const {
			categoryControl = '',
			currencyCode = '',
			currencyCodeWarning = '',
			currencyName = '',
			address: customerAddress = '',
			customerCode = '',
			customerGuid = '',
			customerClassGuid = '',
			phone1: customerPhone1 = '',
			phone2: customerPhone2 = '',
			exchageRate: exchangeRate = '',
			exchangeRateWarning = '',
			foreignCurrency = '',
			outputTaxCode = '',
			outputTaxRateTypeGuid: outputTaxGuid = '',
			outputTaxRateTypeName: outputTaxName = '',
			outputTaxRate = '',
			invoiceFormatTypeCode = '',
			invoiceFormatTypeGuid = '',
			invoiceFormatTypeName = '',
			invoiceTitle = '',
			noteCustomer: note_Customer = '',
			noteInternal: note_Internal = '',
			orderDate = '',
			orderDateWarning = '',
			orderGuid = '',
			orderTypeCode = '',
			orderTypeCodeWarning = '',
			orderTypeGuid = '',
			orderTypeName = '',
			recipient = '',
			recipientWarning = '',
			sectorGuid = '',
			sectorGuidWarning = '',
			sectorName = '',
			taxId = '',
		} = copySalesOrderData.salesOrder;

		// 將要保留的屬性列出，不需要寫太多的 ?? ''
		let newData = {
			categoryControl,
			currencyCode,
			currencyCodeWarning,
			currencyName,
			currencyShow: `${currencyCode} ${currencyName}`,
			customerAddress,
			customerCode,
			customerGuid,
			customerClassGuid,
			customerPhone1,
			customerPhone2,
			decimalPlaceTl: decimalPlaceTl,
			exchangeRate,
			exchangeRateWarning,
			foreignCurrency,
			outputTaxCode,
			outputTaxGuid,
			outputTaxName,
			outputTaxRate,
			invoiceFormatTypeCode,
			invoiceFormatTypeGuid,
			invoiceFormatTypeName,
			invoiceTitle,
			note_Customer,
			note_Internal,
			orderDate: orderDate.split('T')[0],
			orderDateWarning,
			orderGuid,
			orderTypeCode,
			orderTypeCodeWarning,
			orderTypeGuid,
			orderTypeName,
			recipient,
			recipientWarning,
			sectorGuid,
			sectorGuidWarning,
			sectorName,
			taxID: taxId,
		};

		//console.log('newData', newData);

		Object.keys(copySalesOrderData).length !== 0 && setData({ ...newData });
	};

	// 控制訂單狀態
	const handleOrderStatus = async (status) => {
		await axios
			.put(`api/Logistics/LgsSalesOrder/updateSalesOrderStatus`, {
				orderGuid: salesOrderData.page0.orderGuid,
				status,
				rowStamp: salesOrderData.page0.rowStamp,
			})
			.then((res) => {
				console.log(res.data);
				window.location.reload();
			})
			.catch((error) => console.log(error));
	};

	// 編輯過程，回到此頁後，回復資料。一開始父層為空，所以到這邊會直接清空data，必須做判斷：
	useEffect(() => {
		Object.keys(salesOrderData.page0).length !== 0 &&
			setData(salesOrderData.page0);
	}, [salesOrderData.page0]);

	// Delete it later
	// do it in the inputs of currency & orderDate instead of do it in effect
	useEffect(() => {
		if (data.orderDate && data.currencyShow)
			return handleGetExchangeRate(data.orderDate, data.currencyCode);
	}, [data]);

	return (
		<>
			<Container className="">
				<Left className="">
					{page === 0 && isEdit ? (
						<OrderStatusBtn
							status={salesOrderData.page0?.status}
							// 1. 如果狀態是'0'(訂單未完成)，則顯示為"訂單關閉"，因此要將狀態改為'2'。
							// 2. 如果狀態是'2'(訂單關閉)，則顯示為"訂單開啟"，因此要將狀態改為'0'。
							handleClick={() =>
								handleOrderStatus(
									salesOrderData.page0?.status === '0'
										? '2'
										: salesOrderData.page0?.status === '2'
										? '0'
										: ''
								)
							}
						/>
					) : (
						<BtnNormal2
							className="btn-primary d-inline-block me-2 mediumSmallBtn"
							keyName="advancedSearchCancel"
							word={t('lgsSalesOrder:orderCopy')}
							handleClick={() => {
								setPage('copy');
							}}
						>
							<CopyPage />
						</BtnNormal2>
					)}
				</Left>

				<Center className="">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>

				<Right className="">
					{page < processSteps.length - 1 ? (
						<NextBtnSmall
							handleClick={() => {
								handleChangePage();
							}}
						/>
					) : (
						<NextBtnSmall className="d-none" />
					)}
				</Right>
			</Container>
			<div className="position-relative">
				<StatusBar status={salesOrderData.page0?.status} />

				<Wrapper
					className="bg-white d-flex flex-column pt-4 position-relative"
					style={{ borderRadius: '0 0 .75rem .75rem' }}
				>
					<h1
						className="px-4 mb-0 border-bottom border-1"
						style={{
							color: '#1278E8',
							fontSize: '20px',
							fontWeight: 'bold',
							paddingBottom: '12px',
						}}
					>
						{t('lgsSalesOrder:basicInformation')}
					</h1>

					<div className="col-12 p-4">
						<div className="row">
							{/* #Col1------------------------------------ */}
							<div className="col-lg-4 px-4 pr-xl-6">
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'orderTypeGuid'}>
										{t('lgsSalesOrder:orderTypeCode')}
									</LabelNormal>
									<SelectElement
										id={'orderTypeGuid'}
										value={data.orderTypeGuid}
										disabled={isEdit && true}
										onChange={(e) => {
											const options = e.target.options; //(e) event的 target(這邊指的是select)
											const content =
												options[options.selectedIndex]
													.text; //這邊指的是選到第幾個的文字

											const content2 =
												options[options.selectedIndex];

											console.log(content2);
											const categoryControl =
												orderTypeList[
													options.selectedIndex - 1
												].categoryControl;

											setData({
												...data,
												orderTypeGuid: e.target.value,
												orderTypeCode:
													content2.getAttribute(
														'typeCode'
													),
												orderTypeName: content,
												categoryControl:
													categoryControl,
											});
										}}
									>
										<option value={''} disabled>
											{t('lgsSalesOrder:selectData')}
										</option>
										{orderTypeList.map((el) => (
											<option
												key={el.orderTypeGuid}
												id={el.orderTypeGuid}
												value={el.orderTypeGuid}
												onChange={() => {}}
												typecode={el.typeCode}
											>
												{el.name01}
											</option>
										))}
									</SelectElement>
									<span className="text-danger ms-1 mt-1">
										{data.orderTypeCodeWarning}
									</span>
								</div>

								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'orderDate'}>
										{t('lgsSalesOrder:orderDate')}
									</LabelNormal>
									<InputElement
										type="date"
										id="orderDate"
										value={data.orderDate || ''}
										onChange={(e) => {
											setData({
												...data,
												orderDate: e.target.value,
											});
										}}
										disabled={isEdit && true}
									/>
									<span className="text-danger ms-1 mt-1">
										{data.orderDateWarning}
									</span>
								</div>

								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal>
										{t('lgsSalesOrder:currency')}
									</LabelNormal>
									<div
										className="input-group customerGuidInput"
										id="currencyCode"
									>
										<InputElement
											className="form-control border-right-0 item-view-CustomerGuid border-grey2"
											value={data.currencyShow || ''}
											disabled={isEdit && true}
											onChange={() => {}}
											//value={`${'data.currencyCode'} ${'data.currencyName'}`}
											type="text"
											id="currencyCode"
											name="currencyCode"
											style={{
												cursor: 'pointer',
												pointerEvents: 'none',
												borderRight: 0,
											}}
										/>
										<DialogBtn
											disabled={isEdit && true}
											onClick={() => {
												!isEdit && handleCurrencyShow();
											}}
										>
											<Icn_OpenModal />
										</DialogBtn>
									</div>
									<span className="text-danger ms-1 mt-1">
										{data.currencyCodeWarning}
									</span>
								</div>
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'exchangeRate'}>
										{t('lgsSalesOrder:exchangeRate')}
									</LabelNormal>

									{data.currencyCode ===
									companyCurrencyCode ? (
										<InputElement
											type={'text'}
											id={'exchangeRate'}
											placeholder={t(
												'lgsSalesOrder:localCurrencyTransaction'
											)}
											value={''}
											onChange={(e) => {
												setData({
													...data,
													exchangeRate:
														e.target.value,
												});
											}}
											disabled
										/>
									) : (
										<InputElement
											type={'text'}
											id={'exchangeRate'}
											value={data.exchangeRate || ''}
											onChange={(e) => {
												setData({
													...data,
													exchangeRate:
														e.target.value || '',
												});
											}}
											disabled={isEdit && true}
										/>
									)}
									<span className="text-danger ms-1 mt-1">
										{data.exchangeRateWarning}
									</span>
								</div>
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'outputTaxGuid'}>
										{t('lgsSalesOrder:invoiceTaxCategory')}
									</LabelNormal>
									<SelectElement
										id={'outputTaxGuid'}
										value={data.outputTaxGuid || ''}
										disabled={isEdit && true}
										onChange={(e) => {
											const options = e.target.options;
											const content = e.target.value
												? options[options.selectedIndex]
														.text
												: '';
											const content2 =
												options[options.selectedIndex];
											// console.log(options.selected);

											console.log(content2);
											console.log(
												content2.getAttribute('taxRate')
											); //取 options 中的值的方法

											setData({
												...data,
												outputTaxGuid: e.target.value,
												outputTaxCode:
													content2.getAttribute(
														'taxCode'
													) ?? '',
												outputTaxName: content,
												//----------連動修改 發票稅率
												outputTaxRate:
													content2.getAttribute(
														'taxRate'
													) ?? '',
												outputTaxGuidWarning: '',
												outputTaxRateWarning: '',
											});
										}}
									>
										<option value="" disabled>
											{t('lgsSalesOrder:selectData')}
										</option>
										{invoiceTaxRateTypeList.map((el) => (
											<option
												key={el.rateTypeGuid} // 從 API 來的資料為 rateTypeGuid，整理到 data 為 outputTaxGuid
												id={el.rateTypeGuid}
												value={el.rateTypeGuid || ''}
												onChange={() => {}}
												taxcode={el.taxCode}
												taxrate={el.taxRate}
											>
												{el.name01}
											</option>
										))}
									</SelectElement>
									<span className="text-danger ms-1 mt-1">
										{data.outputTaxGuidWarning}
									</span>
								</div>

								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'outputTaxRate'}>
										{t('lgsSalesOrder:invoiceTaxRate')}
									</LabelNormal>
									<InputElement
										type={'text'}
										id={'outputTaxRate'}
										value={data.outputTaxRate || ''}
										onChange={(e) => {
											setData({
												...data,
												outputTaxRate:
													e.target.value || '',
												outputTaxRateWarning: '',
											});
										}}
										disabled={isEdit && true}
									/>
									<span className="text-danger ms-1 mt-1">
										{data.outputTaxRateWarning}
									</span>
								</div>
							</div>
							{/* #endCol1------------------------------------ */}

							{/* #Col2------------------------------------ */}
							<div className="col-lg-4 px-4 px-xl-5">
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal>
										{t('lgsSalesOrder:salesDepartment')}
									</LabelNormal>
									<div
										className="input-group sectorGuidInput"
										id="sectorGuidInput"
									>
										<InputElement
											className="form-control border-right-0 item-view-SectorGuid border-grey2"
											value={data.sectorName || ''}
											onChange={() => {}}
											type="text"
											id="sectorText"
											name="sectorText"
											style={{
												cursor: 'pointer',
												pointerEvents: 'none',
												borderRight: 0,
											}}
											disabled={isEdit && true}
										/>

										<DialogBtn
											disabled={isEdit && true}
											onClick={() => {
												!isEdit && handleSectorShow();
											}}
										>
											<Icn_OpenModal />
										</DialogBtn>
									</div>
									<span className="text-danger ms-1 mt-1">
										{data.sectorGuidWarning}
									</span>
								</div>

								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									{/* //<div className="formArea pb-0"> */}
									{/* <div className="form-group"> */}
									<LabelNormal>
										{t('lgsSalesOrder:customer')}
									</LabelNormal>
									<div
										className="input-group customerGuidInput"
										id="customerGuidInput"
									>
										<InputElement
											className="form-control border-right-0 item-view-CustomerGuid border-grey2"
											value={data.recipient || ''} //value 抓取 data.recipient
											onChange={() => {}}
											type="text"
											id="customerCode"
											name="customerCode"
											style={{
												cursor: 'pointer',
												pointerEvents: 'none',
												borderRight: 0,
											}}
											disabled={isEdit && true}
										/>
										<DialogBtn
											disabled={isEdit && true}
											onClick={() => {
												!isEdit && handleCustomerShow();
											}}
										>
											<Icn_OpenModal />
										</DialogBtn>
									</div>
									<span className="text-danger ms-1 mt-1">
										{data.recipientWarning}
									</span>
								</div>

								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'customerPhone1'}>
										{t('lgsSalesOrder:customerPhone1')}
									</LabelNormal>
									<NumberInputElement
										type={'number'}
										id={'customerPhone1'}
										value={data.customerPhone1 || ''}
										onChange={(e) => {
											setData({
												...data,
												customerPhone1:
													e.target.value || '',
											});
										}}
										disabled={false}
										disabledCssType={0}
									/>
									<span className="text-danger ms-1 mt-1"></span>
								</div>
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'customerPhone2'}>
										{t('lgsSalesOrder:customerPhone2')}
									</LabelNormal>
									<NumberInputElement
										type={'number'}
										id={'customerPhone2'}
										value={data.customerPhone2 || ''}
										onChange={(e) => {
											setData({
												...data,
												customerPhone2:
													e.target.value || '',
											});
										}}
										disabled={false}
										disabledCssType={0}
									/>
									<span className="text-danger ms-1 mt-1"></span>
								</div>
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'customerAddress'}>
										{t('lgsSalesOrder:customerAddress')}
									</LabelNormal>
									<NumberInputElement
										type={'text'}
										id={'customerAddress'}
										value={data.customerAddress || ''}
										onChange={(e) => {
											setData({
												...data,
												customerAddress:
													e.target.value || '',
											});
										}}
										disabled={false}
										disabledCssType={0}
									/>
									<span className="text-danger ms-1 mt-1"></span>
								</div>
							</div>
							{/* #endCol2------------------------------------ */}

							{/* #Col3------------------------------------ */}
							<div className="col-lg-4 px-4 pl-xl-6">
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal>
										{t('lgsSalesOrder:invoiceFormatType')}
									</LabelNormal>
									<div
										className="input-group invoiceFormatTypeGuid"
										id="invoiceFormatTypeGuid"
									>
										<InputElement
											className="form-control border-right-0 item-view-InvoiceFormatTypeGuid border-grey2"
											value={
												data.invoiceFormatTypeName || ''
											}
											onChange={() => {}}
											type="text"
											id="invoiceFormatTypeText"
											name="invoiceFormatTypeText"
											style={{
												cursor: 'pointer',
												pointerEvents: 'none',
												borderRight: 0,
											}}
										/>

										<DialogBtn
											style={{
												backgroundColor: '#fff',
												cursor: 'pointer',
											}}
											onClick={() => {
												handleInvoiceFormatTypeShow();
											}}
										>
											<Icn_OpenModal />
										</DialogBtn>
									</div>
									<span className="text-danger ms-1 mt-1">
										{data.invoiceFormatTypeGuidWarning}
									</span>
								</div>

								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'taxID'}>
										{t('lgsSalesOrder:taxID')}
									</LabelNormal>
									<NumberInputElement
										type={'text'}
										id={'taxID'}
										value={data.taxID || ''}
										onChange={(e) => {
											setData({
												...data,
												taxID: e.target.value || '',
											});
										}}
										disabled={false}
										disabledCssType={0}
									/>
									<span className="text-danger ms-1 mt-1"></span>
								</div>
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'invoiceTitle'}>
										{t('lgsSalesOrder:invoiceTitle')}
									</LabelNormal>
									<NumberInputElement
										type={'text'}
										id={'invoiceTitle'}
										value={data.invoiceTitle || ''}
										onChange={(e) => {
											setData({
												...data,
												invoiceTitle:
													e.target.value || '',
											});
										}}
										disabled={false}
										disabledCssType={0}
									/>
									<span className="text-danger ms-1 mt-1"></span>
								</div>
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'note_Customer'}>
										{t('lgsSalesOrder:note_Customer')}
									</LabelNormal>
									<TextareaElement
										//style={elementStyle}
										id={'note_Customer'}
										rows={'4'}
										cols={'30'}
										value={data.note_Customer || ''}
										//defaultValue={defaultValue}

										onChange={(e) => {
											setData({
												...data,
												note_Customer:
													e.target.value || '',
											});
										}}
										disabled={false}
										disabledCssType={0}
									/>
									<span className="text-danger ms-1 mt-1"></span>
								</div>
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'note_Internal'}>
										{t('lgsSalesOrder:note_Internal')}
									</LabelNormal>
									<TextareaElement
										//style={elementStyle}
										id={'note_Internal'}
										rows={'4'}
										cols={'30'}
										value={data.note_Internal || ''}
										//defaultValue={defaultValue}

										onChange={(e) => {
											setData({
												...data,
												note_Internal:
													e.target.value || '',
											});
										}}
										disabled={false}
										disabledCssType={0}
									/>
									<span className="text-danger ms-1 mt-1"></span>
								</div>
							</div>
							{/* #endCol3------------------------------------ */}
						</div>
					</div>
				</Wrapper>
			</div>
			<CurrencyModal
				modalOpen={currencyTableShow}
				handleClose={handleCurrencyClose}
				currencyList={currencyList}
				data={data}
				setData={setData}
				companyCurrencyCode={companyCurrencyCode}
			/>
			<CustomerModal
				modalOpen={customerTableShow}
				handleClose={handleCustomerClose}
				customerList={customerList}
				invoiceTaxRateTypeList={invoiceTaxRateTypeList}
				data={data}
				setData={setData}
			/>
			<SectorModal
				modalOpen={sectorTableShow}
				handleClose={handleSectorClose}
				sectorList={sectorList}
				data={data}
				setData={setData}
			/>
			<InvoiceFormatTypeModal
				modalOpen={invoiceFormatTypeTableShow}
				handleClose={handleInvoiceFormatTypeClose}
				invoiceFormatTypeList={invoiceFormatTypeList}
				data={data}
				setData={setData}
			/>
		</>
	);
}
export default Page0;
