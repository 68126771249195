import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PrevBtnSmall } from '../../../../../../components/Buttons/PrevBtnSmall';
import { NextBtnSmall } from '../../../../../../components/Buttons/NextBtnSmall';
import { StepByStepBar } from '../../../../../../components/StepByStepBar/StepByStepBar';
import { SaveSubmitBtn } from '../../../../../../components/Buttons/SaveSubmitBtn';
import { CancelSubmitBtn } from '../../../../../../components/Buttons/CancelSubmitBtn';
import TopInfo from '../TopInfo';
import { TabPage } from '../../../exports';
import LgsSalesOrderPage4Tab_SalesOrderDetails from './SalesOrderDetails';
import LgsSalesOrderPage4Tab_PaymentTerm from './PaymentTerm';
import useAxios from '../../../../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';
import StatusBar from '../../../../../../components/StatusBar/StatusBar';

// #region styled-components

// 換上下頁元件
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

// 表格
const Wrapper = styled.div``;

//#endregion

function Page4(props) {
	const { t } = useTranslation(['common', 'lgsSalesOrder']);

	const {
		salesOrderData,
		setSalesOrderData,
		page,
		processSteps,
		handlePrevPage,
		handleNextPage,
		isEdit,
	} = props;

	// 分頁title
	const tabNavList = [
		t('lgsSalesOrder:salesDetails'),
		t('lgsSalesOrder:paymentTerms'),
	];

	// 分頁內容 component
	const tabContent = [
		<LgsSalesOrderPage4Tab_SalesOrderDetails
			salesOrderData={salesOrderData}
		/>,
		<LgsSalesOrderPage4Tab_PaymentTerm salesOrderData={salesOrderData} />,
	];

	// page1此頁面資料管控
	const [newSalesOrderData, setNewSalesOrderData] = useState([]);
	console.log(newSalesOrderData);

	console.log('parentData', salesOrderData);

	// 整理資料
	const organizeData = () => {
		const oldData = { ...salesOrderData };
		const isForeignCurrency = salesOrderData.page0.foreignCurrency;
		const outputTaxRate = salesOrderData.page0.outputTaxRate;
		const exChangeRate = salesOrderData.page0.exchangeRate;

		// isEdit為true時，為編輯模式，否則為新增模式

		const salesOrderKitList = oldData.page1.map((el, page1Index) => {
			return {
				SalesOrderKitItemList: el.kitSetItemInfoList.map((item) => {
					return {
						...(isEdit && { ItemGuid: item.itemGuid }),
						...(isEdit && { KitGuid: item.kitGuid }),
						DisplayOrder: item.displayOrder,
						SalesCategoryCode: item.salesCategoryCode,
						MaterialGuid: item.materialGuid,
						ServiceGuid: item.serviceGuid,
						AccountGuid: item.accountGuid,
						NetPrice: isForeignCurrency
							? item.netPrice * exChangeRate
							: item.netPrice,
						NetAmount: isForeignCurrency
							? item.netAmount * exChangeRate
							: item.netAmount,
						TaxAmount: isForeignCurrency
							? (item.netAmount * exChangeRate * outputTaxRate) /
							  100
							: (item.netAmount * outputTaxRate) / 100,
						ForeignNetPrice: isForeignCurrency
							? item.netPrice
							: '0',
						ForeignNetAmount: isForeignCurrency
							? item.netAmount
							: '0',
						ForeignTaxAmount: isForeignCurrency
							? (item.netAmount * outputTaxRate) / 100
							: '0',
						QuantitySale: item.newQuantity,
						DeliverDate: item.deliverDate,
						Note: item.note,
						Status: isEdit ? el.kitSetMainInfo.status : '',
						RowStamp: isEdit ? item.rowStamp : '',
					};
				}),

				Action: isEdit ? el.kitSetMainInfo.action : '',
				...(isEdit && { KitGuid: el.kitSetMainInfo.kitGuid }),
				...(isEdit && { OrderGuid: salesOrderData.page0.orderGuid }),
				DisplayOrder: page1Index + 1,
				SalesKitGuid: el.kitSetMainInfo.salesKitGuid,
				SalesKitSetGuid: el.kitSetMainInfo.kitSetGuid,
				QuantitySales: el.kitSetMainInfo.quantity,
				NetPrice: isForeignCurrency
					? (el.kitSetMainInfo.newUnitPrice /
							el.kitSetMainInfo.quantity) *
					  exChangeRate
					: el.kitSetMainInfo.newUnitPrice /
					  el.kitSetMainInfo.quantity,
				NetAmount: isForeignCurrency
					? el.kitSetMainInfo.newUnitPrice * exChangeRate
					: el.kitSetMainInfo.newUnitPrice,
				TaxAmount: isForeignCurrency
					? (el.kitSetMainInfo.newUnitPrice *
							exChangeRate *
							outputTaxRate) /
					  100
					: (el.kitSetMainInfo.newUnitPrice * outputTaxRate) / 100,
				ForeignNetPrice: isForeignCurrency
					? el.kitSetMainInfo.newUnitPrice /
					  el.kitSetMainInfo.quantity
					: '0',
				ForeignNetAmount: isForeignCurrency
					? el.kitSetMainInfo.newUnitPrice
					: '0',
				ForeignTaxAmount: isForeignCurrency
					? (el.kitSetMainInfo.newUnitPrice * outputTaxRate) / 100
					: '0',
				DeliverDate: el.kitSetMainInfo.deliverDateKitSet,
				Status: isEdit ? el.kitSetMainInfo.status : '',
				RowStamp: isEdit ? el.kitSetMainInfo.rowStamp : '',
			};
		});

		console.log(salesOrderKitList);

		const mapPaymentTerm = ({
			displayOrder,
			baseOn,
			payDate,
			days,
			amount,
			foreignAmount,
			percentage,
			note,
		}) => ({
			displayOrder,
			baseOn,
			...(payDate !== '' && { payDate }),
			...(days !== '' && { days }),
			amount,
			foreignAmount,
			percentage,
			note,
		});

		const salesOrderPaymentTermList = oldData.page2.paymentTermList.map(
			(paymentTerm) => ({
				...mapPaymentTerm(paymentTerm),
				...(isEdit && {
					action: paymentTerm.action,
					termGuid: paymentTerm.termGuid,
					rowStamp: paymentTerm.rowStamp,
				}),
			})
		);

		let newData = {
			salesOrder: {
				...(isEdit && { OrderGuid: salesOrderData.page0.orderGuid }),
				OrderNo: isEdit ? salesOrderData.page0.orderNo : '',
				OrderTypeGuid: salesOrderData.page0.orderTypeGuid,
				OrderDate: salesOrderData.page0.orderDate,
				SectorGuid: salesOrderData.page0.sectorGuid,
				...(salesOrderData.page0.outputTaxGuid !== '' && {
					OutputTaxRateTypeGuid: salesOrderData.page0.outputTaxGuid,
				}),
				...(salesOrderData.page0.outputTaxRate !== '' && {
					OutputTaxRate: salesOrderData.page0.outputTaxRate,
				}),
				CurrencyCode: salesOrderData.page0.currencyCode,
				ForeignCurrency: salesOrderData.page0.foreignCurrency,
				NetAmount: salesOrderData.topInfoData.netAmount,
				TaxAmount: salesOrderData.topInfoData.taxAmount,
				GrossAmount: salesOrderData.topInfoData.grossAmount,
				ForeignNetAmount: salesOrderData.topInfoData.foreignNetAmount,
				ForeignTaxAmount: salesOrderData.topInfoData.foreignTaxAmount,
				ForeignGrossAmount:
					salesOrderData.topInfoData.foreignGrossAmount,
				ExchangeRate: salesOrderData.page0.exchangeRate,
				CustomerGuid: salesOrderData.page0.customerGuid,
				Recipient: salesOrderData.page0.recipient,
				Phone1: salesOrderData.page0.customerPhone1,
				Phone2: salesOrderData.page0.customerPhone2,
				Address: salesOrderData.page0.customerAddress,
				...(salesOrderData.page0.invoiceFormatTypeGuid !== '' && {
					InvoiceFormatTypeGuid:
						salesOrderData.page0.invoiceFormatTypeGuid,
				}),
				TaxId: salesOrderData.page0.taxID,
				InvoiceTitle: salesOrderData.page0.invoiceTitle,
				NoteCustomer: salesOrderData.page0.note_Customer,
				NoteInternal: salesOrderData.page0.note_Internal,
				RowStamp: salesOrderData.page0.rowStamp,
			},
			salesOrderKitList: salesOrderKitList,
			salesOrderPaymentTermList: salesOrderPaymentTermList,
		};

		// 將資料形式改為formData回傳給後端
		// const formData = new FormData();

		// formData.append('SalesOrder', JSON.stringify(newData.salesOrder));
		// formData.append(
		// 	'SalesOrderKitList',
		// 	JSON.stringify(newData.salesOrderKitList)
		// );
		// formData.append(
		// 	'SalesOrderPaymentTermList',
		// 	JSON.stringify(newData.salesOrderPaymentTermList)
		// );

		// // 新增附件-以同樣的keyName一筆筆append
		// newData.insertAppendixDataList.forEach((el) => {
		// 	formData.append('insertAppendixDataList', el);
		// });

		// console.log(newData.insertAppendixDataList);
		// console.log([...formData.entries()]);

		// return formData;

		return {
			SalesOrder: newData.salesOrder,
			SalesOrderKitList: newData.salesOrderKitList,
			SalesOrderPaymentTermList: newData.salesOrderPaymentTermList,
		};
	};

	// 儲存
	const { isLoading, error, sendRequest: axiosSaveData } = useAxios();

	const handleSubmit = () => {
		const newData = organizeData();

		const headers = {
			'Content-Type': 'application/json',
		};
		const url = isEdit
			? `api/Logistics/LgsSalesOrder/updateSalesOrder`
			: `api/Logistics/LgsSalesOrder/insertSalesOrder`;
		const method = isEdit ? 'PUT' : 'POST';
		const data = newData;

		axiosSaveData({ headers, url, method, data }, (res) => {
			if (res.response.status === 200) {
				console.log(res);

				const newParentData = { ...salesOrderData };
				newParentData.page4.orderNo = res;

				setSalesOrderData(newParentData);

				res && handleNextPage();
			} else {
				alert(res.response.data.message);
			}
		});
		console.log(salesOrderData);

		console.log(error);
	};

	return (
		<>
			<Container>
				<Left>
					{page !== 0 && (
						<PrevBtnSmall
							handleClick={() => {
								handlePrevPage();
							}}
						/>
					)}
				</Left>
				<Center className="ps-14">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right>
					{page < processSteps.length - 1 ? (
						<NextBtnSmall
							handleClick={() => {
								handleNextPage();
							}}
						/>
					) : (
						<>
							<SaveSubmitBtn handleSubmit={handleSubmit} />
							<CancelSubmitBtn
								className={'btn-outline-primary'}
							/>
						</>
					)}
				</Right>
			</Container>

			<StatusBar status={salesOrderData.page0?.status} />
			<Wrapper
				className="row bg-white px-6 pt-4 pb-4 mx-0 mb-3"
				style={{ borderRadius: '0 0 .75rem .75rem' }}
			>
				<div className="col-12 px-0 ">
					<TopInfo
						topInfoData={salesOrderData.topInfoData}
						salesOrderData={salesOrderData}
					/>
				</div>
			</Wrapper>

			<TabPage
				wrapperStyle={{ minHeight: '530px' }}
				liColWidth={'col-2'}
				tabNavList={tabNavList}
				tabContent={tabContent}
				defaultPage={0}
			/>
		</>
	);
}

export default Page4;
