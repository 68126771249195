import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../../assets/images/icn_inputDialog.svg';
import { BtnNormal2 } from '../../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { DialogCol } from '../DialogCol';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
    margin-bottom: 0;
    color: var(--grey4);
    background-color: #fff;
`;

const THead = styled.thead`
    height: 40px;
`;

const TR = styled.tr`
    height: 40px;
    &:hover {
        cursor: ${(hoverEffect) =>
            hoverEffect === 'true' ? 'pointer' : 'default'};
        background-color: ${(hoverEffect) =>
            hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
    }
`;

const TR1 = styled.tr`
    height: 5px;
    background-color: #00ff00 !important;
`;

const TR2 = styled.tr`
    height: 40px;
    background-color: #eef4fd;
`;

const THStyled = styled.th`
    width: 248px;
    text-align: left;
    padding-left: 15px;
    /* Add any other styles specific to TH here */
`;

const TH = styled.th`
    vertical-align: middle;
    border: 1px solid var(--grey1) !important;
    &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-right: none !important;
    }
`;

const TBody = styled.tbody``;

const TD = styled.td`
    cursor: auto;
    border: 1px solid var(--grey1) !important;
    &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-right: none !important;
    }

    background-color: ${(prop) =>
        prop.active ? '#fbfcfd !important' : '#fff'};

    position: relative;
    padding: 7px 8px !important;
    max-height: 40px;
`;

const Container = styled.div`
    display: flex;
    justify-content: start;
    margin: 16px 0;
`;

const Input = styled.input`
    padding-inline: 8px;
    border: ${(prop) => (prop.active ? '1px solid #e3e8ee' : 'none')};
    border-radius: 4px;

    /* 去除 type="number" 上下按鈕 */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::placeholder {
        color: var(--grey3);
    }

    &:disabled {
        border: none;
        background-color: transparent;
    }
`;

// -----Dialog------
const DialogWrapper = styled.div`
    position: relative;
    align-items: stretch;
    width: 100%;
    height: 25px;
    --bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
    display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
    flex: 1 1 auto;
    border: ${(prop) => (prop.active ? '1px solid #e3e8ee' : 'none')};
    border-radius: 4px 0 0 4px;
    border-right: 0;
    padding: 6px 8px;
    height: 25px;
    &:focus-visible {
        outline: 0;
    }
    pointer-events: none;
    background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
    display: inline-block;
    position: absolute;
    height: 100%;
    top: -1px;
    right: 24px;
    width: 24px;
    text-align: center;
    display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
    cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    border: ${(prop) => (prop.active ? '1px solid #e3e8ee' : 'none')};
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 8px;
    cursor: pointer;
`; // dialog按鈕

//#endregion

function GoodsTable(props) {
    //#region state initiation

    const {
        salesOrderKitList,
        salesOrderKitItemList,
        pageData,
        setPageData,
        headTitleList,
        bodyTitleList,
        tableHeadStyles,
        tableBodyStyles,
        inputProperties,
        dialogBtnKeyName,
        setDialogBtnKeyName,
        isOpen,
        setIsOpen,
        modalData,
        setModalData,
        t,
    } = props;

    console.log(pageData);

    const [tableBodyData, setTableBodyData] = useState(salesOrderKitItemList);

    //全部理貨
    const handleReceiveAll = () => {
        const newData = [...tableBodyData];

        const originalShipmentItemList = [...pageData.originalShipmentItemList];

        newData.forEach((el, index) => {
            const quantityPack = originalShipmentItemList[index].quantityPack;
            const quantityUnpack =
                originalShipmentItemList[index].quantityUnpack;
            const quantitySale = originalShipmentItemList[index].quantitySale;
            const quantity = originalShipmentItemList[index].quantity;

            // 如果 quantitySale = quantityPack(銷售量 = 已理貨量) -> 不作處理
            // 如果還有尚未理貨的數量 -> 把 newQuantityPack(這次的理貨量) 變成尚未理貨的數量
            if (!(quantitySale === quantityPack)) {
                el.quantity = quantityUnpack + quantity;
            }
        });

        setTableBodyData([...newData]);

        //  取出 updateData 中需要修改的部分, 修改完後更新整個 pageData
        setPageData({
            ...pageData,
            updateData: {
                ...pageData.updateData,
                salesOrderShipmentItemList: [...newData],
            },
        });
    };

    //雙擊裡貨
    const handleDoubleClick = (shipmentItemGuid) => {
        const newData = [...tableBodyData];
        const originalShipmentItemList = [...pageData.originalShipmentItemList];

        const copyTarget = originalShipmentItemList.find((el) => el.shipmentItemGuid === shipmentItemGuid)

        const target = newData.find((el) => el.shipmentItemGuid === shipmentItemGuid);
        console.log(target);

        target.quantity =
        copyTarget.quantityUnpack +
            copyTarget.quantity;
            console.log(target);


        // //newData[rowIndex].quantityPack = newData[rowIndex].quantityUnpack;
        setTableBodyData([...newData]);
        // console.log(newData)

        const newUpdateData = pageData.updateData;
        newUpdateData.salesOrderKitItemList = [...newData];
        setPageData({
            ...pageData,
            updateData: {
                ...pageData.updateData,
                salesOrderShipmentItemList: [...newData],
            },
        });
    };

    const handleEditTable = (itemGuid, rowIndex) => {
        setTableBodyData((prevTableBodyData) => {
            return prevTableBodyData.map((el, index) => {
                // 如果是被點擊的那一個 row, 變成 active === true, 反之改成 active === false
                return { ...el, active: index === rowIndex ? true : false };
            });
        });
    };

    // input改變時
    const handleInputChange = (
        value,
        kitGuid,
        rowIndex,
        inputIndex
        //toFloat,
        //data
    ) => {
        const newData = [...tableBodyData];
        // 1. Use filter() to find the target kitSet
        // 2. Use the [rowIndex] the find the target row of the target kitSet
        const targetRow = newData.filter((el) => el.kitGuid === kitGuid);
        targetRow[rowIndex][bodyTitleList[inputIndex]] = value;

        // console.log(targetRow, rowIndex);

        const newUpdateData = pageData.updateData;
        newUpdateData.salesOrderShipmentItemList = [...newData];
        setPageData({
            ...pageData,
            updateData: {
                ...pageData.updateData,
                salesOrderShipmentItemList: [...newData],
            },
        });
    };

    const handleInputBlur = (
        value,
        kitGuid,
        rowIndex,
        inputIndex,
        inputName
    ) => {
        const originalShipmentItemList = [...pageData.originalShipmentItemList];
        const newData = [...tableBodyData];
        const targetRow = newData.filter((el) => el.kitGuid === kitGuid)[
            rowIndex
        ];
        //console.log(targetRow);
        //console.log(newData[rowIndex]);

        if (inputName === 'quantity') {
            //const quantityUnpack = newData[rowIndex]["quantityUnpack"]; //這是錯的資料列
            const quantityUnpack = targetRow.quantityUnpack;
            const quantity = originalShipmentItemList[rowIndex].quantity;
            const newPageData = { ...pageData };

            // 收貨數量 不大於 未收數量
            if (Number(value) > Number(quantityUnpack) + Number(quantity)) {
                //newData[rowIndex]["quantityPack"] = quantityUnpack;
                targetRow[bodyTitleList[inputIndex]] =
                    Number(quantityUnpack) + Number(quantity);
                setTableBodyData(newData);
            } else if (Number(value) < 0) {
                // 未收數量不為負數
                targetRow['quantityPack'] = 0;
                console.log('newData', newData);

                console.log(targetRow[bodyTitleList[inputIndex]]);
                newPageData.salesOrderKitItemList = [...newData];
                setPageData(newPageData);
            }

            newPageData.salesOrderKitItemList = [...newData];
            setPageData(newPageData);
        }

        // 手動批次編號，判斷是否滿碼
        if (inputName === 'batchNo' && newData[rowIndex].batchNoAuto === '0') {
            const { batchNumberingFull, batchNumberingLength } =
                newData[rowIndex];
            const inputValueLength = value.length;

            // console.log(
            // 	batchNumberingFull,
            // 	batchNumberingLength,
            // 	inputValueLength
            // );

            if (
                batchNumberingFull &&
                inputValueLength !== batchNumberingLength
            ) {
                newData[rowIndex][inputName] = '';
            } else {
                newData[rowIndex][`${inputName}Warning`] = '';
            }

            setTableBodyData(newData);

            const newPageData = { ...pageData };
            newPageData.salesOrderKitItemList = [...newData];
            setPageData({ ...newPageData });
        }
    };

    // 採購型態 code轉換文字
    // const handlePurchaseCategoryName = (purchaseCategoryCode) => {
    //   switch (purchaseCategoryCode) {
    //     case "M":
    //       return "產品";
    //     case "E":
    //       return "服務";
    //     case "A":
    //       return "資產";
    //   }
    // };

    // 銷售商品型態 code轉換文字
    const handleSalesCategoryName = (salesCategoryCode) => {
        switch (salesCategoryCode) {
            case 'P':
                return '產品';
            case 'S':
                return '服務';
        }
    };

    // 理貨內容 串字串
    const handlePurchaseContent = (data) => {
        const {
            salesCategoryCode,
            materialCode,
            materialName,
            serviceCode,
            serviceName,
        } = data;

        switch (salesCategoryCode) {
            case 'P':
                return `${materialCode} : ${materialName}`;
            case 'S':
                return `${serviceCode} : ${serviceName}`;
        }
    };

    //#endregion

    //#region DialogCol
    const [targetRow, setTargetRow] = useState(null);
    const [indexDialog, setIndexDialog] = useState('');

    // DialogCol input 黃色按鈕取消
    const handleCancelClick = (shipmentItemGuid, rowIndex) => {
        const newUpdateData = pageData.updateData;

        console.log(rowIndex);
        console.log(newUpdateData.salesOrderShipmentItemList);
        console.log(shipmentItemGuid);
        const newTargetRow = newUpdateData.salesOrderShipmentItemList.filter(
            (el) => {
                if (el.shipmentItemGuid === shipmentItemGuid) {
                    el.cubeGuid = '';
                    el.cubeCode = '';
                    el.cubeName = '';
                    return el;
                } else {
                    return el;
                }
            }
        );

        console.log(newTargetRow);

        newUpdateData.salesOrderShipmentItemList[rowIndex].cubeGuid = '';
        newUpdateData.salesOrderShipmentItemList[rowIndex].cubeCode = '';
        newUpdateData.salesOrderShipmentItemList[rowIndex].cubeName = '';
        console.log(newUpdateData);

        setTableBodyData(newUpdateData.salesOrderShipmentItemList);
        setPageData({
            ...pageData,
            updateData: {
                ...pageData.updateData,
                ...newUpdateData,
            },
        });
    };

    // DialogCol Modal open
    const handleOpen = (shipmentItemGuid, rowIndex, inputIndex) => {
        const newData = [...tableBodyData];
        // 1. Use filter() to find the target kitSet
        // 2. Use the [rowIndex] the find the target row of the target kitSet
        const newTargetRow = newData.filter(
            (el) => el.shipmentItemGuid === shipmentItemGuid
        );

        setTargetRow(newTargetRow);

        setDialogBtnKeyName('cube');
        setModalData(pageData.materialCubeList);
        setIndexDialog(rowIndex); // 獲取真實的index，傳給DialogCol
        setIsOpen(true);
    };

    //#endregion

    return (
        <div className="position-relative">
            {/* DialogCol element */}
            <DialogCol
                targetRow={targetRow}
                indexDialog={indexDialog}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                modalData={modalData}
                setModalData={setModalData}
                btnKeyName={dialogBtnKeyName}
                pageData={pageData}
                setPageData={setPageData}
                tableBodyData={tableBodyData}
                setTableBodyData={setTableBodyData}
            />

            <IndexTableContainer className="bg-white d-flex flex-column position-relative">
                <Container>
                    <BtnNormal2
                        className="btn-primary ms-3 smallBtn"
                        keyName="advance-search"
                        handleClick={handleReceiveAll}
                        word={'全部理貨'}
                    ></BtnNormal2>
                </Container>

                <IndexTable className="table">
                    <THead className="table-head">
                        <TR>
                            {headTitleList?.map((el) => (
                                <TH
                                    key={`tableHead${headTitleList?.indexOf(
                                        el
                                    )}`}
                                    style={
                                        tableHeadStyles[
                                            headTitleList?.indexOf(el)
                                        ]
                                    }
                                >
                                    {el}
                                </TH>
                            ))}
                        </TR>
                    </THead>

                    <TR1></TR1>
                    {salesOrderKitList?.map((parent, parentIndex) => (
                        <React.Fragment key={`ParentRow${parentIndex}`}>
                            {/* TR2 */}
                            <TR2>
                                <TH></TH> <TH></TH>
                                <THStyled>{parent.salesKitName}</THStyled>
                                <THStyled>{parent.quantitySales}</THStyled>
                                <TH></TH> <TH></TH> <TH></TH> <TH></TH>{' '}
                                <TH></TH>
                            </TR2>

                            {/* TR1 */}
                            <TR1></TR1>

                            {/* TBody */}
                            <TBody className="" key={`TBody${parentIndex}`}>
                                {tableBodyData
                                    .filter(
                                        (data) =>
                                            data.kitGuid === parent.kitGuid
                                    )
                                    .map((data, rowIndex) => {
                                        return (
                                            <TR
                                                key={`GoodsTableRow${rowIndex}`}
                                                onClick={(e) =>
                                                    handleEditTable(
                                                        data.itemGuid,
                                                        rowIndex
                                                    )
                                                }
                                            >
                                                {bodyTitleList?.map(
                                                    (el, inputIndex) => {
                                                        // console.log(el)
                                                        // 不可編輯欄位(不為input)
                                                        if (
                                                            [
                                                                'displayOrder',
                                                                'salesCategoryCode',
                                                                'salesCategoryontent',
                                                                'quantitySale', //銷售數量
                                                            ].includes(el)
                                                        )
                                                            return (
                                                                <TD
                                                                    style={
                                                                        tableBodyStyles[
                                                                            bodyTitleList?.indexOf(
                                                                                el
                                                                            )
                                                                        ]
                                                                    }
                                                                    className={`border`}
                                                                    active={
                                                                        data.active
                                                                    }
                                                                    key={`${bodyTitleList?.indexOf(
                                                                        el
                                                                    )}`}
                                                                >
                                                                    <div className="px-2">
                                                                        {el ===
                                                                        'salesCategoryCode'
                                                                            ? handleSalesCategoryName(
                                                                                  data[
                                                                                      el
                                                                                  ]
                                                                              )
                                                                            : data[
                                                                                  el
                                                                              ]}
                                                                        {el ===
                                                                            'salesCategoryontent' &&
                                                                            handlePurchaseContent(
                                                                                data
                                                                            )}
                                                                    </div>
                                                                </TD>
                                                            );

                                                        // 未理數量
                                                        if (
                                                            el ===
                                                            'quantityUnpack'
                                                        ) {
                                                            return (
                                                                <TD
                                                                    style={
                                                                        tableBodyStyles[
                                                                            bodyTitleList?.indexOf(
                                                                                el
                                                                            )
                                                                        ]
                                                                    }
                                                                    className={`border`}
                                                                    active={
                                                                        data.active
                                                                    }
                                                                    key={`${bodyTitleList?.indexOf(
                                                                        el
                                                                    )}`}
                                                                >
                                                                    <div className="px-2">
                                                                        {
                                                                            data.quantityUnpack
                                                                        }
                                                                    </div>
                                                                </TD>
                                                            );
                                                        }

                                                        // 理貨數量
                                                        if (el === 'quantity') {
                                                            return (
                                                                <TD
                                                                    style={
                                                                        tableBodyStyles[
                                                                            bodyTitleList?.indexOf(
                                                                                el
                                                                            )
                                                                        ]
                                                                    }
                                                                    className={`border`}
                                                                    active={
                                                                        data.active
                                                                    }
                                                                    key={`${bodyTitleList?.indexOf(
                                                                        el
                                                                    )}`}
                                                                >
                                                                    <Input
                                                                        style={{
                                                                            minWidth:
                                                                                '24px',
                                                                            width: '100%',
                                                                            outline:
                                                                                'none',
                                                                        }}
                                                                        className={`${'text-end'}`}
                                                                        value={
                                                                            data?.quantity ||
                                                                            0
                                                                        }
                                                                        active={
                                                                            data.active
                                                                        }
                                                                        type={
                                                                            inputProperties[
                                                                                inputIndex
                                                                            ]
                                                                                ?.type
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            handleInputChange(
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                                data.kitGuid,
                                                                                rowIndex,
                                                                                inputIndex
                                                                            );
                                                                        }}
                                                                        onDoubleClick={(
                                                                            e
                                                                        ) =>
                                                                            handleDoubleClick(data.shipmentItemGuid
                                                                            )
                                                                        }
                                                                        onBlur={(
                                                                            e
                                                                        ) =>
                                                                            handleInputBlur(
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                                data.kitGuid,
                                                                                rowIndex,
                                                                                inputIndex,
                                                                                el
                                                                            )
                                                                        }
                                                                    />
                                                                </TD>
                                                            );
                                                        }

                                                        // 物料倉位
                                                        if (
                                                            data.salesCategoryCode ===
                                                                'P' &&
                                                            el ===
                                                                'materialCube'
                                                        ) {
                                                            // console.log(data)
                                                            return (
                                                                <TD
                                                                    style={
                                                                        tableBodyStyles[
                                                                            bodyTitleList?.indexOf(
                                                                                el
                                                                            )
                                                                        ]
                                                                    }
                                                                    className={`border`}
                                                                    active={
                                                                        data.active
                                                                    }
                                                                    key={`${bodyTitleList?.indexOf(
                                                                        el
                                                                    )}`}
                                                                >
                                                                    <DialogWrapper className="row flex-nowrap">
                                                                        <InputDialogElementData
                                                                            type="text"
                                                                            value={
                                                                                data.cubeGuid ||
                                                                                ''
                                                                            }
                                                                            onChange={() => {}}
                                                                        />
                                                                        <InputDialogElementView
                                                                            style={{
                                                                                minWidth:
                                                                                    '85px',
                                                                                cursor: 'auto',
                                                                            }}
                                                                            active={
                                                                                data.active
                                                                            }
                                                                            value={
                                                                                data.cubeName ||
                                                                                ''
                                                                            }
                                                                            onChange={() => {}}
                                                                        />
                                                                        {data?.active && (
                                                                            <>
                                                                                <CancelInputBtn
                                                                                    onClick={() => {
                                                                                        handleCancelClick(
                                                                                            data.shipmentItemGuid,
                                                                                            rowIndex
                                                                                        );
                                                                                    }}
                                                                                    isValueExist={
                                                                                        data.cubeGuid
                                                                                    }
                                                                                >
                                                                                    <Icn_InputYellowCancel />
                                                                                </CancelInputBtn>

                                                                                <DialogBtn
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    active={
                                                                                        data.active
                                                                                    }
                                                                                    onClick={(
                                                                                        e
                                                                                    ) =>
                                                                                        handleOpen(
                                                                                            data.shipmentItemGuid,
                                                                                            rowIndex,
                                                                                            inputIndex
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Icn_InputDialog />
                                                                                </DialogBtn>
                                                                            </>
                                                                        )}
                                                                    </DialogWrapper>
                                                                    {data[
                                                                        'cubeGuidWarning'
                                                                    ] && (
                                                                        <div className="mt-xl-1">
                                                                            <span className="text-danger ms-1">
                                                                                {
                                                                                    data[
                                                                                        'cubeGuidWarning'
                                                                                    ]
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </TD>
                                                            );
                                                        } else if (
                                                            el ===
                                                            'materialCube'
                                                        ) {
                                                            // 不為資產與費用時，不可編輯
                                                            return (
                                                                <TD
                                                                    style={
                                                                        tableBodyStyles[
                                                                            bodyTitleList?.indexOf(
                                                                                el
                                                                            )
                                                                        ]
                                                                    }
                                                                    className={`border`}
                                                                    active={
                                                                        data.active
                                                                    }
                                                                    key={`${bodyTitleList?.indexOf(
                                                                        el
                                                                    )}`}
                                                                ></TD>
                                                            );
                                                        }

                                                        // 批次編號
                                                        if (el === 'batchNo') {
                                                            return (
                                                                <TD
                                                                    style={
                                                                        tableBodyStyles[
                                                                            bodyTitleList?.indexOf(
                                                                                el
                                                                            )
                                                                        ]
                                                                    }
                                                                    className={`border`}
                                                                    active={
                                                                        data.active
                                                                    }
                                                                    key={`${bodyTitleList?.indexOf(
                                                                        el
                                                                    )}`}
                                                                >
                                                                    <Input
                                                                        style={{
                                                                            minWidth:
                                                                                '24px',
                                                                            width: '100%',
                                                                            outline:
                                                                                'none',
                                                                        }}
                                                                        className={`${'text-end'}`}
                                                                        value={
                                                                            data[
                                                                                'batchNo'
                                                                            ] ||
                                                                            ''
                                                                        }
                                                                        // disabled={
                                                                        //     data[
                                                                        //         'salesCategoryCode'
                                                                        //     ] !==
                                                                        //         'P' ||
                                                                        //     data[
                                                                        //         'batchNoAuto'
                                                                        //     ] ===
                                                                        //         '1'
                                                                        // }
                                                                        disabled
                                                                        active={
                                                                            data.active
                                                                        }
                                                                        type={
                                                                            inputProperties[
                                                                                inputIndex
                                                                            ]
                                                                                ?.type
                                                                        }
                                                                        placeholder={
                                                                            data[
                                                                                'batchNoAuto'
                                                                            ] ===
                                                                                '0' &&
                                                                            data.batchNumberingFull
                                                                                ? `${t(
                                                                                      'lgsPurchaseOrderGoodsInvoice:requiredAndMustBeFullWidth'
                                                                                  )}(${
                                                                                      data.batchNumberingLength
                                                                                  }${t(
                                                                                      'lgsPurchaseOrderGoodsInvoice:code'
                                                                                  )})`
                                                                                : ''
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            handleInputChange(
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                                data.kitGuid,
                                                                                rowIndex,
                                                                                inputIndex
                                                                            );
                                                                        }}
                                                                    />
                                                                    {data[
                                                                        'batchNoWarning'
                                                                    ] && (
                                                                        <div className="mt-xl-1">
                                                                            <span className="text-danger ms-1">
                                                                                {
                                                                                    data[
                                                                                        'batchNoWarning'
                                                                                    ]
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </TD>
                                                            );
                                                        }

                                                        // 明細註記
                                                        if (el === 'note') {
                                                            //console.log(el);
                                                            return (
                                                                <TD
                                                                    style={
                                                                        tableBodyStyles[
                                                                            bodyTitleList?.indexOf(
                                                                                el
                                                                            )
                                                                        ]
                                                                    }
                                                                    className={`border`}
                                                                    active={
                                                                        data.active
                                                                    }
                                                                    key={`${bodyTitleList?.indexOf(
                                                                        el
                                                                    )}`}
                                                                >
                                                                    <Input
                                                                        style={{
                                                                            minWidth:
                                                                                '24px',
                                                                            width: '100%',
                                                                            outline:
                                                                                'none',
                                                                        }}
                                                                        className={`${'text-end'}`}
                                                                        value={
                                                                            data[
                                                                                el
                                                                            ] ||
                                                                            ''
                                                                        }
                                                                        active={
                                                                            data.active
                                                                        }
                                                                        type={
                                                                            inputProperties[
                                                                                inputIndex
                                                                            ]
                                                                                ?.type
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            handleInputChange(
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                                data.kitGuid,
                                                                                rowIndex,
                                                                                inputIndex
                                                                            );
                                                                        }}
                                                                    />
                                                                </TD>
                                                            );
                                                        }
                                                    }
                                                )}
                                            </TR>
                                        );
                                    })}
                            </TBody>
                        </React.Fragment>
                    ))}
                </IndexTable>
            </IndexTableContainer>
        </div>
    );
}

export default GoodsTable;
