import { useState, useEffect } from 'react';
import { Table } from '../../../../../../components/exports';
import { ReactComponent as Icn_check } from '../../../../../../assets/images/icn_Check-r.svg';
import { useTranslation } from 'react-i18next';
function LgsSalesOrderPage4Tab_SalesOrderDetails({ salesOrderData }) {
	const { t } = useTranslation(['common', 'lgsSalesOrder']);
	const [tableData, setTableData] = useState([]);
	const [tableFooter, setTableFooter] = useState([]);

	const headTitleList = [
		'#',
		t('lgsSalesOrder:salesContent'),
		t('lgsSalesOrder:salesQuantity'),
		t('lgsSalesOrder:unitPriceExcludingTax'),
		t('lgsSalesOrder:amountExcludingTax'),
		t('lgsSalesOrder:deliveryDate'),
		t('lgsSalesOrder:annotation'),
	];

	const bodyTitleList = [
		'displayOrder',
		'salesOrderContent',
		'quantitySales',
		'netPrice',
		'netAmount',
		'deliverDate',
		'note',
	];

	const tableHeadStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '380px',
			textAlign: 'center',
		},
		{
			width: '90px',
			textAlign: 'center',
		},
		{
			width: '164px',
			textAlign: 'center',
		},
		{
			width: '164px',
			textAlign: 'center',
		},
		{
			width: '104px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
	];

	const tableBodyStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '380px',
			textAlign: 'left',
		},
		{
			width: '90px',
			textAlign: 'right',
		},
		{
			width: '164px',
			textAlign: 'right',
		},
		{
			width: '164px',
			textAlign: 'right',
		},
		{
			width: '104px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'left',
		},
	];

	// console.log('salesOrderData', salesOrderData);

	let salesOrderDetails = salesOrderData.page1.map((el, index) => ({
		displayOrder: index + 1,
		salesOrderContent:
			el.kitSetItemInfoList[0].name + ' ' + el.kitSetMainInfo.specSetName,
		quantitySales: el.kitSetMainInfo.quantity,
		netPrice:
			(el.kitSetMainInfo.newUnitPrice * 1) /
			(el.kitSetMainInfo.quantity * 1),
		netAmount: el.kitSetMainInfo.newUnitPrice * 1,
		deliverDate: el.kitSetMainInfo.deliverDateKitSet,
		note: el.kitSetMainInfo.note,
	}));

	useEffect(() => {
		setTableData(salesOrderDetails);
		setTableFooter([
			'',
			t('lgsSalesOrder:totalAmount'),
			'',
			'',
			salesOrderData.page0.foreignCurrency
				? salesOrderData.topInfoData.foreignNetAmount
				: salesOrderData.topInfoData.netAmount,
			'',
			'',
		]);
	}, []);
	console.log('salesOrderDetails', salesOrderDetails);

	return (
		<>
			{tableData && (
				<Table
					guidName="salesCategoryCode"
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					tableData={tableData}
					tableFooter={tableFooter}
				></Table>
			)}
		</>
	);
}

export default LgsSalesOrderPage4Tab_SalesOrderDetails;
