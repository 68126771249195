import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import TopInfo from './TopInfo';
import { DialogWarning } from '../DialogWarning';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import BottomInfo from './BottomInfo';
import dayjs from 'dayjs';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import axios from 'axios';

// #region styled-components

const DocumentTitle = styled.div`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #282828;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
`;

const Right = styled.div`
    display: flex;
`;

//#endregion

function Page1(props) {
    //#region state initiation
    const {
        pageAction,
        setDialogConfirmData,
        parentData,
        setParentData,
        salesOrderData,
        page,
        processSteps,
        handleNextPage,
        dialogBtnKeyName,
        setDialogBtnKeyName,
        isOpen,
        setIsOpen,
        modalData,
        setModalData,
        warningMessage,
        setWarningMessage,
        CurrencyCode,
        CurrencyName,
        DecimalPlaceTl,
        t,
    } = props;

    const [pageData, setPageData] = useState(parentData);

    const [tableBodyData, setTableBodyData] = useState(
        parentData?.salesOrderShipmentItemList
    );
    const [salesOrderKitList, setSalesOrderKitList] = useState(
        parentData?.salesOrderKitList
    );

    const deliveryMethodList = [
        {
            key: '0',
            value: '自取/自行送回',
        },
        {
            key: '1',
            value: '公司配送/取回',
        },
        {
            key: '2',
            value: '物流業者',
        },
    ];

    const statusList = [
        // {
        //     key: '0',
        //     value: '理貨中（建立理貨單）',
        // },
        // {
        //     key: '1',
        //     value: '理貨完成',
        // },
        {
            key: '2',
            value: '待出貨（建立出貨單）',
        },
        {
            key: '3',
            value: '出貨在途（交給物流）',
        },
        {
            key: '4',
            value: '出貨已送達',
        },
    ];

    //#endregion

    //#region function

    // 暫存改被修改的資料
    const handleTemporaryStore = (pageData) => {
        const newParentData = { ...parentData, ...pageData };

        setParentData(newParentData);
    };

    // 回到page0 清空暫存資料
    const handleBackPage0 = () => {
        const newData = {
            btnName: 'goToPage0',
            isOpen: true,
            goTo: 'preStep',
            title: t('steps.confirmRechooseOrder') + '?',
        };
        setDialogConfirmData(newData);

        // handlePrevPage();
        // handleTemporaryStore();
    };

    const handleSubmit = () => {
        const newSalesOrderShipment = {
            ...pageData.salesOrderShipment,
            ...pageData.preUpdateData,
        };

        console.log(newSalesOrderShipment);
        setParentData(newSalesOrderShipment);
        handleNextPage();
    };

    // 往下一頁之前的檢查
    // const handleSubmit = async () => {
    //     let isValid = false;

    //     const newReceive = { ...pageData?.receive };

    //     const keyToCheckLocal = [
    //         'postingDate',
    //         'staffGuid',
    //         'bankAccountGuid',
    //         'grossAmount',
    //     ];

    //     const keyToCheckForeign = [
    //         'postingDate',
    //         'staffGuid',
    //         'bankAccountGuid',
    //         'exchangeRate',
    //         'foreignGrossAmount',
    //     ];

    //     const keyToCheck = pageData?.salesOrder.foreignCurrency
    //         ? keyToCheckForeign
    //         : keyToCheckLocal;

    //     const checkAllFilledIn = () => {
    //         keyToCheck.forEach((el) => {
    //             newReceive[`${el}Warning`] = '';
    //             if (!newReceive[el]) {
    //                 newReceive[`${el}Warning`] = t(
    //                     'page1.bottom.fillInInformation'
    //                 );
    //             }
    //         });

    //         const result = keyToCheck.every((el) => {
    //             return newReceive[el];
    //         });

    //         console.log(result);
    //         return result;
    //     };

    //     const checkPaymentValue = () => {
    //         let result = true;
    //         newReceive.foreignGrossAmountWarning = '';
    //         newReceive.grossAmountWarning = '';

    //         const {
    //             foreignCurrency,
    //             grossAmount,
    //             grossAmountPayment,
    //             foreignGrossAmountPayment,
    //             foreignGrossAmount,
    //         } = pageData?.salesOrder;

    //         if (foreignCurrency) {
    //             const restAmount =
    //                 foreignGrossAmount - foreignGrossAmountPayment;
    //             if (pageData?.receive.foreignGrossAmount > restAmount) {
    //                 newReceive.foreignGrossAmountWarning = t(
    //                     'page1.bottom.paymentAmountExceedsBalance'
    //                 );
    //                 result = false;
    //             }

    //             if (pageData?.receive.foreignGrossAmount <= 0) {
    //                 newReceive.foreignGrossAmountWarning = t(
    //                     'page1.bottom.paymentAmountShouldBePositive'
    //                 );
    //                 result = false;
    //             }
    //         } else {
    //             const restAmount = grossAmount - grossAmountPayment;
    //             if (pageData?.receive.grossAmount > restAmount) {
    //                 newReceive.grossAmountWarning = t(
    //                     'page1.bottom.paymentAmountExceedsBalance'
    //                 );
    //                 result = false;
    //             }

    //             if (pageData?.receive.grossAmount <= 0) {
    //                 newReceive.grossAmountWarning = t(
    //                     'page1.bottom.paymentAmountShouldBePositive'
    //                 );
    //                 result = false;
    //             }
    //         }
    //         return result;
    //     };

    //     const checkExchangeRate = () => {
    //         let result = true;
    //         newReceive.exchangeRateWarning = '';

    //         if (pageData?.receive.exchangeRate <= 0) {
    //             newReceive.exchangeRateWarning = t(
    //                 'page1.bottom.exchangeRateShouldBePositive'
    //             );
    //             result = false;
    //         }

    //         return result;
    //     };

    //     checkPaymentValue() && checkExchangeRate() && checkAllFilledIn();

    //     await setPageData({ ...pageData, receive: newReceive });

    //     if (checkAllFilledIn() && checkPaymentValue() && checkExchangeRate()) {
    //         isValid = true;
    //     }

    //     if (isValid) {
    //         console.log(
    //             pageData?.salesOrder.foreignGrossAmountPayment,
    //             pageData?.receive.foreignGrossAmount
    //         );

    //         const newForeignGrossAmountPayment =
    //             Number(pageData?.salesOrder.foreignGrossAmountPayment) +
    //             Number(pageData?.receive.foreignGrossAmount);
    //         const newGrossAmountPayment =
    //             Number(pageData?.salesOrder.grossAmountPayment) +
    //             Number(pageData?.receive.grossAmount);
    //         handleTemporaryStore({
    //             ...pageData,
    //             receive: newReceive,
    //             salesOrder: {
    //                 ...pageData?.salesOrder,
    //                 foreignGrossAmountPayment: newForeignGrossAmountPayment,
    //                 grossAmountPayment: newGrossAmountPayment,
    //             },
    //         });
    //         handleNextPage();
    //     }
    // };

    // state
    // axios
    const { sendRequest: axiosDataPage1 } = useAxios();

    //#endregion
    // useEffect(() => {

    //         setPageData(parentData.salesOrderShipment);

    // }, []);

    /**
     * DialogWarning 返回btn確認dialog
     **/
    const [isWarningOpen, setIsWarningOpen] = useState(false);

    // DialogWarning Modal open
    const handleDialogWarning = () => {
        setIsWarningOpen(true);
    };

    const InsertSalesOrderGoodsReturnInfo = () => {
        console.log(pageData);

        const url = `api/Logistics/LgsSalesOrderGoodsReturn/${
            pageAction === 'UPDATE' ? 'update' : 'insert'
        }`;

        axios
            .post(url, {
                shipmentGuid: pageData.salesOrderShipment.shipmentGuid,
                salesOrderShipment: {
                    ...pageData.salesOrderShipment,
                    status: '0',
                },
            })
            .then((res) => {
                handleNextPage();
            });
    };

    console.log(pageData);

    return (
        <>
            <Container className="">
                <Left className="">
                    {page !== 0 && (
                        <BtnNormal
                            className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
                            key={'PrevBtn'}
                            onClick={() => {
                                handleBackPage0();
                            }}
                        >
                            {t('button.prevStep', { ns: 'common' })}
                        </BtnNormal>
                    )}
                </Left>
                <Center className="">
                    <StepByStepBar steps={processSteps} activePage={page} />
                </Center>
                <Right className="">
                    <BtnNormal2
                        className="btn-primary d-inline-block me-2 mediumSmallBtn"
                        keyName="finish"
                        word="完成(待退貨)"
                        handleClick={InsertSalesOrderGoodsReturnInfo}
                    />
                    <BtnNormal2
                        className="btn-outline-primary d-inline-block me-2 mediumSmallBtn"
                        keyName="cancel"
                        word="取消"
                        handleClick={() => handleBackPage0()}
                    />
                </Right>
            </Container>

            {pageData && (
                <>
                    <div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
                        <div className="mb-5">
                            <DocumentTitle>退貨單</DocumentTitle>
                        </div>
                        <TopInfo
                            pageAction={pageAction}
                            pageData={pageData}
                            CurrencyCode={CurrencyCode}
                            CurrencyName={CurrencyName}
                            DecimalPlaceTl={DecimalPlaceTl}
                            page={page}
                            setPageData={setPageData}
                            t={t}
                            statusList={statusList}
                        />
                        <BottomInfo
                            pageData={pageData}
                            salesOrderKitList={salesOrderKitList}
                            setPageData={setPageData}
                            tableBodyData={tableBodyData}
                            setTableBodyData={setTableBodyData}
                            dialogBtnKeyName={dialogBtnKeyName}
                            setDialogBtnKeyName={setDialogBtnKeyName}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            modalData={modalData}
                            setModalData={setModalData}
                            t={t}
                        />
                    </div>
                </>
            )}
        </>
    );
}

export default Page1;
