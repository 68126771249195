import { useState, useEffect, useCallback } from 'react';
import useAxios from '../../../../../../../hooks/useAxios';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Table } from '../../../../../../../components/exports';
import PaymentTable from './PaymentTable';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

function LgsPurchaseOrder_PaymentHistory(props) {
    const { t } = useTranslation();

    const { salesOrderPayment, foreignCurrency } = props;
    const [sortBy, setSortBy] = useState('postingDate');
    const [displayColumns, setDisplayColumns] = useState([]);
    const [data, setData] = useState({});
    const guid = useLocation().pathname.split('/').pop();
    const { isLoading, error, sendRequest: axiosPayment } = useAxios();

	const [tableData, setTableData] = useState(salesOrderPayment);
	const [totalExchangeDifferenceAmount, setTotalExchangeDifferenceAmount] = useState(0);
	const [totalForeignGrossAmount, setTotalForeignGrossAmount] = useState(0);
	const [totalGrossAmount, setTotalGrossAmount] = useState(0)

    let headTitleList = [];
    let bodyTitleList = [];
    let tableHeadStyles = [];
    let tableBodyStyles = [];

    useEffect(() => {
		const newTableData = [...tableData];
		let totalExchangeDifferenceAmount = 0;
		let totalForeignGrossAmount = 0;
		let totalGrossAmount = 0;

		newTableData.forEach((el, idx) => {
			el.displayOrder = idx + 1;
			el.postingDate = dayjs(el.postingDate).format('YYYY-MM-DD');
	
			if (el.isReturn === "1") {
				el.foreignGrossAmount = -(Math.abs(el.foreignGrossAmount))
				el.grossAmount = -(Math.abs(el.grossAmount))
			}
	
			totalGrossAmount +=
				(el.isReturn === '1' ? -1 : 1) * Math.abs(Number(el.grossAmount));
			totalForeignGrossAmount +=
				(el.isReturn === '1' ? -1 : 1) * Math.abs(Number(el.foreignGrossAmount));
			totalExchangeDifferenceAmount +=
				(el.isReturn === '1' ? -1 : 1) *
				Math.abs(Number(el.exchangeDifferenceAmount));
		});
	
		setTableData(newTableData);

        setData(salesOrderPayment);
		setTotalExchangeDifferenceAmount(totalExchangeDifferenceAmount);
		setTotalForeignGrossAmount(totalForeignGrossAmount);	
		setTotalGrossAmount(totalGrossAmount)
    }, []);

    if (error) {
        console.log('error !', error);
    }

    if (foreignCurrency) {
        headTitleList = [
            '#',
            t('lgsSalesOrderPayment:page2.paymentHistory.paymentDate'),
            t('lgsSalesOrderPayment:page2.paymentHistory.payer'),
            t(
                'lgsSalesOrderPayment:page2.paymentHistory.accountingVoucherNumber'
            ),
            t('lgsSalesOrderPayment:page2.paymentHistory.refund'),
            t('lgsSalesOrderPayment:page2.paymentHistory.currency'),
            t(
                'lgsSalesOrderPayment:page2.paymentHistory.localCurrencyPaymentAmount'
            ),
            t(
                'lgsSalesOrderPayment:page2.paymentHistory.foreignCurrencyPaymentAmount'
            ),
            t(
                'lgsSalesOrderPayment:page2.paymentHistory.exchangeRateDifferenceAmount'
            ),
            t('lgsSalesOrderPayment:page2.paymentHistory.annotation'),
        ];

        bodyTitleList = [
            'displayOrder',
            'postingDate',
            'staffName',
            'documentNo',
            'isReturn',
            'currencyCode',
            'grossAmount',
            'foreignGrossAmount',
            'exchangeDifferenceAmount',
            'note',
        ];

        tableHeadStyles = [
            {
                width: '48px',
                textAlign: 'center',
            },
            {
                width: '112px',
                textAlign: 'center',
            },
            {
                width: '136px',
                textAlign: 'center',
            },
            {
                width: '160px',
                textAlign: 'center',
            },
            {
                width: '80px',
                textAlign: 'center',
            },
            {
                width: '96px',
                textAlign: 'center',
            },
            {
                width: '120px',
                textAlign: 'center',
            },
            {
                width: '120px',
                textAlign: 'center',
            },
            {
                width: '120px',
                textAlign: 'center',
            },
            {
                width: '200px',
                textAlign: 'center',
            },
        ];

        tableBodyStyles = [
            {
                width: '48px',
                textAlign: 'center',
            },
            {
                width: '112px',
                textAlign: 'left',
            },
            {
                width: '136px',
                textAlign: 'left',
            },
            {
                width: '160px',
                textAlign: 'left',
            },
            {
                width: '80px',
                textAlign: 'center',
            },
            {
                width: '96px',
                textAlign: 'center',
            },
            {
                width: '120px',
                textAlign: 'right',
            },
            {
                width: '120px',
                textAlign: 'right',
            },
            {
                width: '120px',
                textAlign: 'right',
            },
            {
                width: '200px',
                textAlign: 'left',
            },
        ];
    } else {
        headTitleList = [
            '#',
            t('lgsSalesOrderPayment:page2.paymentHistory.paymentDate'),
            t('lgsSalesOrderPayment:page2.paymentHistory.payer'),
            t(
                'lgsSalesOrderPayment:page2.paymentHistory.accountingVoucherNumber'
            ),
            t('lgsSalesOrderPayment:page2.paymentHistory.refund'),
            t('lgsSalesOrderPayment:page2.paymentHistory.currency'),
            t('lgsSalesOrderPayment:page2.paymentHistory.paymentAmount'),
            t('lgsSalesOrderPayment:page2.paymentHistory.annotation'),
        ];

        bodyTitleList = [
            'displayOrder',
            'postingDate',
            'staffName',
            'documentNo',
            'isReturn',
            'currencyCode',
            'grossAmount',
            'note',
        ];

        tableHeadStyles = [
            {
                width: '48px',
                textAlign: 'center',
            },
            {
                width: '112px',
                textAlign: 'center',
            },
            {
                width: '136px',
                textAlign: 'center',
            },
            {
                width: '160px',
                textAlign: 'center',
            },
            {
                width: '80px',
                textAlign: 'center',
            },
            {
                width: '96px',
                textAlign: 'center',
            },
            {
                width: '160px',
                textAlign: 'center',
            },
            {
                //     width: '120px',
                textAlign: 'center',
            },
        ];

        tableBodyStyles = [
            {
                width: '48px',
                textAlign: 'center',
            },
            {
                width: '112px',
                textAlign: 'left',
            },
            {
                width: '136px',
                textAlign: 'left',
            },
            {
                width: '160px',
                textAlign: 'left',
            },
            {
                width: '80px',
                textAlign: 'center',
            },
            {
                width: '96px',
                textAlign: 'center',
            },
            {
                width: '160px',
                textAlign: 'right',
            },
            {
                // width: '120px',
                textAlign: 'right',
            },
        ];
    }

    return (
        <>
            {tableData && (
                <PaymentTable
                    guidName="paymentGuid"
                    headTitleList={headTitleList}
                    bodyTitleList={bodyTitleList}
                    tableHoverEffect={false}
                    totalGrossAmount={totalGrossAmount}
                    totalForeignGrossAmount={totalForeignGrossAmount}
                    totalExchangeDifferenceAmount={
                        totalExchangeDifferenceAmount
                    }
                    indexData={salesOrderPayment}
                    tableHeadStyles={tableHeadStyles}
                    tableBodyStyles={tableBodyStyles}
                    foreignCurrency={foreignCurrency}
                />
            )}
        </>
    );
}

export default LgsPurchaseOrder_PaymentHistory;
