import React from "react";
import styled from "styled-components";
import { ReactComponent as Icn_Cancel } from "../../../../../assets/images/icn_Cancel.svg";
import { ReactComponent as Icn_AlertExclamation } from "../../../../../assets/images/icn_AlertExclamation.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// #region styled-components
const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
`;

const ModalDialog = styled.div`
  max-width: 472px !important;
  min-height: calc(100% - 10.5rem);
`;

// #endregion

export const DialogWarning = ({
  isWarningOpen,
  setIsWarningOpen,
  warningMessage,
  isRedirect,
}) => {
  const { t } = useTranslation(["common", "lgsPurchaseOrderGoodsInvoice"]); // i18n

  const navigate = useNavigate();

  /**
   * Modal cancel close
   **/
  const handleClose = () => {
    setIsWarningOpen(false);

    // 如果缺少資料則引導至首頁
    isRedirect && navigate("/");
  };

  return (
    <>
      {isWarningOpen && (
        <ModalWrapper onClick={handleClose}>
          <ModalDialog
            className="modal-dialog d-flex align-items-center"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="modal-content border-0 bg-white"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: "12px",
                paddingBlock: "2.407px",
              }}
            >
              <div className="modal-header border-0 px-4 py-5">
                <h5 className="position-relative d-flex justify-content-center align-items-center w-100 text-danger fw-bold fontSize1Rem mb-0">
                  <Icn_AlertExclamation
                    className="position-absolute"
                    style={{ left: "4px" }}
                  />
                  <span>{warningMessage}</span>
                </h5>
                <button
                  style={{ top: "4px", right: "12px" }}
                  type="button"
                  className="btn close position-absolute p-2"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  <Icn_Cancel />
                </button>
              </div>

              <div className="modal-footer justify-content-center border-0 pt-0 pb-5">
                <button
                  type="button"
                  className="btn btn-primary mediumBtn m-0"
                  style={{
                    width: "120px",
                    height: "32px",
                  }}
                  onClick={handleClose}
                >
                  {t("common:button.confirmButton")}
                </button>
              </div>
            </div>
          </ModalDialog>
        </ModalWrapper>
      )}
    </>
  );
};
