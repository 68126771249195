// #region 程式說明--銷售訂單

/*  **銷售訂單**
    
    Author：
    2024/02/06: 子建: 初版 (檢視頁面的歷程總覽與發票歷程目前由於還未定案，因此暫時無功能)
    
    **主要功能**：

    01.提供「列表」、「查詢」、「新增」、「檢視」、「修改」、「刪除」、「啟動」、「停用」、「刪除」、「異動記錄」功能
    02.列表：顯示所有銷售訂單，並提供搜尋功能。
    03.查詢：顯示單筆銷售訂單的資料，包括基本資料、明細、收款條件、歷程總覽、出貨歷程、退貨歷程、發票歷程、收款歷程。
    04.新增：新增銷售訂單，內容包含基本資料、銷售明細、收款條件。
    05.修改：修改銷售訂單，內容包含基本資料、銷售明細、收款條件。
    06.啟動：重啟銷售訂單。
    07.關閉：關閉銷售訂單。
    08.異動記錄：查看銷售訂單的異動記錄。
    
    **使用者步驟**：

    A. 銷售訂單列表
        01.從側邊欄點選「銷售訂單」，進入銷售訂單「列表」頁面。
        02.點選頁面上方的選單按鈕，將可以選擇進入「異動記錄」以查詢全部銷售訂單異動記錄。
        03.點選進階搜尋按鈕，會彈出搜尋條件 dialog，並進行銷售訂單搜尋。
        04.使用者可點擊列表中每欄的標題，將以此標題為排序依據，並以反向排序或正向排序顯示銷售訂單列表資料。
        05.如果使用者已進行進階搜尋，則會列表上方出現已選擇的搜尋條件的小卡片，以顯示已選擇的搜尋條件。
        06.使用者可點擊上述的小卡片，以刪除該進階搜尋條件，或點擊「進階查詢」小卡片，以刪除所有進階搜尋條件。

    B. 進階搜尋
        01.點選進階搜尋按鈕，會彈出搜尋條件 dialog，並進行銷售訂單搜尋。
        02.使用者可選擇之搜尋條件：客戶、銷售日期、銷售訂單編號、交易幣別。
        03.使用者可選擇之搜尋邏輯：包括、不包括、等於、不等於、大於、小於、大於等於、小於等於。
        04.使用者可選擇之搜尋邏輯會根據其選擇的搜尋條件而有所變化，但不會出現上述方法以外的選項。
        05.使用者可於右方的輸入框輸入搜尋條件，輸入框的種類會有「文字」、「數字」、「日期」、「下拉選單」四種。
        06.使右方的輸入框種類會根據其選擇的搜尋條件而有所變化，但不會出現上述方法以外的選項。
        07.使用者可一個或多個搜尋條件，當多於一個搜尋條件時，會多出現一個搜尋邏輯的選項，會有「且」和「或」兩個選頂。
        08.「且」代表「且」搜尋邏輯，「或」代表「或」搜尋邏輯。
        09.當多於一個搜尋條件時，會出現一個刪除小按鈕於每一筆搜尋條件的右側，點選後會刪除該搜尋條件。
        10.在 dialog 中間會有一個橘色的刪除小按鈕，點選後會刪除全部搜尋條件。
        11.點選「確定」，進行銷售訂單搜尋，並回到銷售訂單「列表」頁面顯示搜尋結果。

    C. 新增銷售訂單
        01.如需新增銷售訂單及其資料，請點選「新增」以進入「新增」頁面。
        02.新增頁面分為四個步驟，分別為「基本資料」、「銷售明細」、「收款條件」、「確認」。
        03.必須要先完成前項步驟並通過該頁面驗證才能進行下一步，過程中可回到上一步並保留已輸入的資料。
		04.在「基本資料」部分，輸入銷售訂單基本資料，點選下一步箭頭。
		05.在「銷售明細」部分，上面顯示為訂單基本資訊，右側表格金額會依照有無外幣有所不同；下方有一個「新增商品組合」按鈕，點選後會開啟新增商品組合的dialog。
		06.依序在dialog中先選取「商品」，再選取對應的「銷售商品組合」，其中「銷售商品組合」為「可銷售」，並且當滑鼠hover時於右側顯示對應的「品項」。
		07.當使用者選擇「銷售商品組合」後，按下確定後會依照商品是否已設定符合條件的價格，如果有符合則新增；沒有符合則跳出提醒視窗，無法新增此商品。
		08.價格條件式依照「商品組合」的資料表中，1.符合此銷售日期區間 2.優先選擇符合此客戶分類並依照排序順序數字大的優先 3.再依照符合通用客戶的價格，擇最優先資料。
		09.新增後的「銷售商品組合」卡片會有三個層級，最外層為「商品組合」，第二層為「品項」，第三層為「品項的詳細內容」，可以收合。
		10.在最外層為「商品組合」有刪除按鈕可以刪除整筆「商品組合」，不能單獨刪除某個品項。
		11.在新增商品組合時，dialog按下確定後，預設會帶入此筆銷售價格的下限值，以及其所設定的金額。
		12.並且在其所對應的「品項」卡片中，會顯示此筆「商品組合」預設的數量，並且品項價格是以「percentage」去乘以銷售組合的金額後得到品項的金額再顯示；品項單價則是再除以數量。
		13.因此當改變「商品組合」的價格時，會同步更新「品項」所對應的金額。
		14.當改變「商品組合」的數量時，會重新判斷是否此數量有符合的價格區間設定，如果沒有符合的則會跳出提醒，並將數量維持在上一個數量。
		15.當確實改變「商品組合」的數量時，會同步更新「品項」所對應的數量。
		16.「品項」不能個別更改數量、單價、金額。
		17.當選擇「商品組合」的交貨日時，會同步更新「品項」所對應的交貨日為同一日；然而也可以個別更改「品項」的交貨日；所有交貨日都為必填。
		18.第三步：「收款條件」，其收款金額顯示為應稅金額，並且加總必須為銷售訂單應稅總額。
        19.當調整收款比例時，會依照應稅總額算出對應的收款金額。
		20.收款比例的總合必須為100。
		21.所有金額在此如果有外幣時，都是以外幣顯示；否則以本幣顯示。
        22.在「確認」頁面，可再次確認銷售明細與收款條件後，按下儲存，將此筆銷售訂單存入資料庫。
        23.銷售訂單新增完成後，將會獲得銷售訂單編號，此時可選擇繼續新增或返回銷售訂單「查詢」。
		24.銷售訂單有「訂單複製」的功能，當我們在新增的「基本資料」時，點選「訂單複製」後，將會可從過去的銷售訂單中選擇一筆資料作為範本帶入資料。

    D. 查詢銷售訂單
        01.如需查詢銷售訂單，請點選「列表」頁面中的任一筆資料,以進入「查詢」頁面。
        02.查詢頁面的標籤分頁共有8頁，分別為「基本資料」、「明細」、「收款條件」、「歷程總覽」、「出貨歷程」、「退貨歷程」、「發票歷程」、「收款歷程」。
        03.「基本資料」，顯示銷售訂單基本資料。
        04.「明細」，顯示銷售商品組合與品項內容。
		05.「收款條件」，顯示收款條件。
		06.「歷程總覽」，顯示歷程總覽。
        07.「出貨歷程」，顯示理貨與出貨歷程，子項為對應的品項明細。
        08.「退貨條件」，顯示退貨與驗貨歷程，子項為對應的品項明細。
        09.「發票歷程」，顯示發票歷程。
        10.「收款歷程」，顯示收款歷程。
        11.此頁面有「修改」、「訂單複製」的功能，
        12.選擇「訂單複製」後，可將目前所查詢的此筆訂單資料帶入並開啟新增頁面。

    E. 修改銷售訂單
        01.如需修改銷售訂單及其資料，請點選「修改」以進入「修改」頁面。
        02.修改頁面分為四個步驟，分別為「基本資料」、「銷售明細」、「收款條件」、「確認」。
        03.必須要先完成前項步驟並通過該頁面驗證才能進行下一步，過程中可回到上一步並保留已輸入的資料。
		04.在「基本資料」部分，有以下欄位不可修改，銷售訂單類型、銷售日期、交易幣別、匯率、發票稅別、發票稅率(%)、銷售部門、客戶名稱。
		05.在「銷售明細」部分，上面顯示為訂單基本資訊，右側表格金額會依照有無外幣有所不同；下方有一個「新增商品組合」按鈕，點選後會開啟新增商品組合的dialog。
		06.依序在dialog中先選取「商品」，再選取對應的「銷售商品組合」，其中「銷售商品組合」為「可銷售」，並且當滑鼠hover時於右側顯示對應的「品項」。
		07.當使用者選擇「銷售商品組合」後，按下確定後會依照商品是否已設定符合條件的價格，如果有符合則新增；沒有符合則跳出提醒視窗，無法新增此商品。
		08.價格條件式依照「商品組合」的資料表中，1.符合此銷售日期區間 2.優先選擇符合此客戶分類並依照排序順序數字大的優先 3.再依照符合通用客戶的價格，擇最優先資料。
		09.新增後的「銷售商品組合」卡片會有三個層級，最外層為「商品組合」，第二層為「品項」，第三層為「品項的詳細內容」，可以收合。
		10.在最外層為「商品組合」有刪除按鈕可以刪除整筆「商品組合」，不能單獨刪除某個品項。
		11.在新增商品組合時，dialog按下確定後，預設會帶入此筆銷售價格的下限值，以及其所設定的金額。
		12.並且在其所對應的「品項」卡片中，會顯示此筆「商品組合」預設的數量，並且品項價格是以「percentage」去乘以銷售組合的金額後得到品項的金額再顯示；品項單價則是再除以數量。
		13.因此當改變「商品組合」的價格時，會同步更新「品項」所對應的金額。
		14.當改變「商品組合」的數量時，會重新判斷是否此數量有符合的價格區間設定，如果沒有符合的則會跳出提醒，並將數量維持在上一個數量。
		15.當確實改變「商品組合」的數量時，會同步更新「品項」所對應的數量。
		16.「品項」不能個別更改數量、單價、金額。
		17.當選擇「商品組合」的交貨日時，會同步更新「品項」所對應的交貨日為同一日；然而也可以個別更改「品項」的交貨日；所有交貨日都為必填。
		18.第三步：「收款條件」，其收款金額顯示為應稅金額，並且加總必須為銷售訂單應稅總額。
        19.當調整收款比例時，會依照應稅總額算出對應的收款金額。
		20.收款比例的總合必須為100。
		21.所有金額在此如果有外幣時，都是以外幣顯示；否則以本幣顯示。
        22.在「確認」頁面，可再次確認銷售明細與收款條件後，按下儲存，將此筆銷售訂單存入資料庫。
        23.銷售訂單修改完成後，此時可返回銷售訂單「查詢」。
		24.銷售訂單的最上方會顯示目前訂單的狀態，共有三種狀態：訂單未完成、訂單已完成、訂單關閉。
		25.此頁面有「訂單關閉/重啟」的功能，如銷售訂單正處於未完成狀態，將可選擇「訂單關閉」；處於關閉狀態，將可選擇「訂單重啟」。

    F. 銷售訂單異動記錄
        01.異動記錄以列表方式呈現，預設以時間正序排序。
        02.使用者可點擊列表中每欄的標題，將以此標題為排序依據，並以反向排序或正向排序顯示銷售訂單列表資料。
        03.點選進階搜尋按鈕，會彈出搜尋條件 dialog，並進行異動記錄的進階搜尋。
        04.此進階查詢功能使用邏輯和「列表」頁面的進階查詢相同。

    **功能頁面**：

    (一)「新增」頁面
        1.使用者輸入：
            (1)基本資料：
                (A)銷售訂單類型（必填）
                (B)銷售日期（必填）
                (C)交易幣別（必填）
				(D)匯率（如果使用外幣則必填）
				(E)發票稅別
				(F)發票稅率(%)
                (G)銷售部門（必填）
                (H)客戶名稱（必填）
				(I)客戶業務代表(聯絡人)電話1
				(J)客戶業務代表(聯絡人)電話2
				(K)客戶業務代表(聯絡人)地址
				(L)發票類型
				(M)發票統一編號
				(N)發票抬頭
				(O)購買者註記
				(P)公司內部註記

			(2)銷售明細：
				(A)商品組合
				    (i)交貨日（必填）
				    (ii)數量（必填，並且須符合價格條件的下限值）
				    (iii)金額未稅（必填）
				(A)品項
				    (i)交貨日（必填）
				    (ii)註記

			(3)收款條件：
                (A)收款日期基礎（如有條件則必填）
                (B)收款日期天數
                (C)收款日期
                (D)收款金額
                (E)收款比例(%)（如有條件則必填，加總需為100）
                (F)註記

        2.儲存前檢查：
            (1)必填項目：如資料輸入有缺，將會顯示錯誤訊息於其輸入框下（請填寫資料）。
                (A)基本資料：銷售訂單類型，銷售日期，交易幣別，匯率（如果使用外幣則必填），銷售部門，客戶名稱
				(B)銷售明細：
					(i)商品組合：交貨日，數量（必填），金額未稅
					(ii)品項：交貨日
				(C)收款條件：收款日期基礎（如有條件則必填），收款比例(%)（如有條件則必填）
                
            (2)輸入內容檢查：如有錯誤，將會顯示錯誤訊息於其輸入框下。
                (A)銷售明細-商品組合-數量：須符合價格條件的下限值，否則顯示「商品未設定符合此數量的價格」
				(B)銷售明細-商品-商品組合：須有符合的價格條件，否則顯示「商品未設定符合此數量的價格」
				(C)收款條件-收款比例：加總必須為100

            (3)如輸入的資料無錯誤，則會進入儲存資料的流程。

    (二)「修改」頁面
        1.畫面邏輯幾乎與「新增」頁面相同。
        2.無法「修改」的欄位如下:
			(1)基本資料：
                (A)銷售訂單類型
                (B)銷售日期
                (C)交易幣別
				(D)匯率
				(E)發票稅別
				(F)發票稅率(%)
                (G)銷售部門
                (H)客戶名稱
*/

// #endregion 程式說明--銷售訂單

import { useState, useEffect, useCallback } from 'react';
import { salesOrderAdvancedSearchData as searchContentList } from '../../../utils/advancedSearchData';
import styled from 'styled-components';
import useAxios from '../../../hooks/useAxios';
import { PageTitleBar } from '../../../components/exports';
import { CreateBtnNormal } from '../../../components/Buttons/CreateBtnNormal';
import { SearchBtnSmall } from '../../../components/Buttons/SearchBtnSmall';
import { DropdownMenuArea } from '../../../components/DropdownMenuArea/DropdownMenuArea';
import { ReactComponent as LogIcon } from '../../../assets/images/icn_Log.svg';
import Table from '../../../components/Table/Table';
import AdvancedSearchModal from '../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../../components/Modals/AdvancedSearchResultTag';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

// #region styled-components
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
	display: flex;
`;

//#endregion

function LgsSalesOrder_Index() {
	const { t } = useTranslation(['common', 'lgsSalesOrder']);

	// 使用 useAuth Hook 取得 isLogin 和 checkAuth
	const { isLogin, checkAuth } = useAuth();

	// 在組件生命週期方法中呼叫 checkAuth 函式檢查登入狀態
	useEffect(() => {
		checkAuth();
	}, []);

	const [fetchedData, setFetchedData] = useState([]);
	const [displayColumns, setDisplayColumns] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const view = 'index';
	// const sortBy = 'orderDate';
	const [sortBy, setSortBy] = useState('orderDate desc');
	const [orderBy, setOrderBy] = useState('desc');
	const guidName = 'orderGuid';
	const pageTitle = t('lgsSalesOrder:salesOrder');
	const { isLoading, error, sendRequest: axiosIndexData } = useAxios();

	const [advancedSearchShow, setAdvancedSearchShow] = useState(false);

	if (error) {
		console.log('error !', error);
	}

	const headTitleList = [
		'#',
		t('lgsSalesOrder:customerCode'),
		t('lgsSalesOrder:customer'),
		t('lgsSalesOrder:salesDepartment'),
		t('lgsSalesOrder:orderDate'),
		t('lgsSalesOrder:orderStatus'),
		t('lgsSalesOrder:salesOrderNumber'),
		t('lgsSalesOrder:currencyCode'),
		t('lgsSalesOrder:displayTaxableSales'),
		t('lgsSalesOrder:displaySalesAmountExcludingTax'),
		t('lgsSalesOrder:displayTaxAmount'),
		t('lgsSalesOrder:grossAmount'),
		t('lgsSalesOrder:netAmount'),
		t('lgsSalesOrder:taxAmount'),
	];

	const bodyTitleList = [
		'displayOrder',
		'customerCode',
		'recipient',
		'sectorName',
		'orderDate',
		'status',
		'orderNo',
		'currencyCode',
		'displayGrossAmount',
		'displayNetAmount',
		'displayTaxAmount',
		'grossAmount',
		'netAmount',
		'taxAmount',
	];

	const tableStyles = [
		{
			minWidth: '54px',
			textAlign: 'center',
		},
		{
			minWidth: '100px',
			textAlign: 'left',
		},
		{
			minWidth: '150px',
			textAlign: 'left',
		},
		{
			minWidth: '150px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'left',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '72px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'center',
		},
		{
			minWidth: '120px',
			textAlign: 'center',
		},
		{
			minWidth: '112px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
	];

	const columnMustShow = ['displayOrder', 'orderDate', 'orderNo'];

	// 進階搜尋 一進去的第一筆的模板
	const advancedSearchDataTemp = {
		searchLogic: 'AND',
		column: searchContentList[0].column,
		searchOperator: '==',
		searchValue: '',
		searchContent: '',
		inputWarningContent: '',
		selectOptionList: searchContentList[0].selectOptionList,
	};

	// 進階搜尋實際資料
	const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
	// 進階搜尋畫面資料
	const [displayList, setDisplayList] = useState([]);

	// Advanced Search

	//todo: default sort col => orderDate

	/// 1. 第一次進入 index
	/// 2. 改變進階查詢
	/// 3. 改變筆數
	/// 4. 改變排序
	/// 都需要重新向後端拿資料, 所以合成一個 function 處理

	// advancedSearchDataList: 進階查詢資料
	// sort: 排序
	const fetchData = (
		advancedSearchDataList = null,
		pageSize = 20, // 每頁筆數
		sort = sortBy,
		pageNumber = 1
	) => {
		const url = `api/Logistics/LgsSalesOrder/getList`;
		console.log(advancedSearchDataList);
		// 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
		if (advancedSearchDataList) {
			// only "one" search data
			advancedSearchDataList[0].searchLogic = '';
		}

		axiosIndexData(
			{
				url,
				method: 'post',
				data: {
					sort,
					pageSize,
					page: pageNumber,
					advancedSearchDataList,
					TransactionCode: 'lgsSalesOrder', //permission 由前端傳直給後端，判斷權限
				},
			},
			(res) => {
				console.log(res);
				const { indexData, permission, numberOfData, totalPage } = res;

				const newIndexData = indexData.map((item) => {
					return {
						...item,
						orderDate: item.orderDate.split('T')[0],
					};
				});

				// 分頁資訊
				setPaginationData({
					...paginationData,
					numberOfData: numberOfData,
					totalPage: totalPage,
					pageNumber: pageNumber,
				});

				// table 資料
				setFetchedData({
					indexData: newIndexData,
					permission,
					paginationData,
				});

				function isArrayFn(obj) {
					if (typeof Array.isArray === 'function') {
						return Array.isArray(obj); // 如果瀏覽器支援就用 isArray() 方法
					} else {
						// 否則就使用 toString 方法
						return (
							Object.prototype.toString.call(obj) ===
							'[object Array]'
						);
					}
				}

				/// 這和 api 回傳的 result 無關
				// 如果有 advancedSearchDataList, 且是 array
				// console.log('original', advancedSearchDataList);
				if (isArrayFn(advancedSearchDataList)) {
					// 因為後端給的 select list 名稱不一定 和 後端在做進階查詢時的欄位名稱一樣
					// 所以要將進階查詢時的欄位(searchFilter) 轉換為 tableColumn
					advancedSearchDataList.map((el) => {
						searchContentList.map((item) => {
							// console.log(item);
							if (el.searchFilter === item.tableColumn) {
								el.searchFilter = item.searchFromDataBase;
							}
						});
					});

					// console.log('after edit', advancedSearchDataList);

					setDisplayList(advancedSearchDataList);
					setAdvancedSearchDataList(advancedSearchDataList);
				}

				handleAdvancedSearchModalClose();
				localStorage.setItem('Permission_LgsSalesOrder', permission);
			}
		);
	};

	const getLocalStorageData = useCallback(() => {
		const gettingData = async () => {
			const data = await (JSON.parse(
				localStorage.getItem(`${guidName}DisplayColumns`)
			) || bodyTitleList);

			setDisplayColumns(data);

			return data;
		};

		return gettingData();
	}, []);

	const handleOpenAdvancedSearch = () => {
		console.log('handleOpenAdvancedSearch');
	};

	const handleAdvancedSearchModalClose = () => setAdvancedSearchShow(false);
	const handleAdvancedSearchModalShow = () => {
		setAdvancedSearchShow(true);
	};

	const deleteAllFilters = () => {
		setDisplayList([]);
		setAdvancedSearchDataList([]);
		fetchData();
	};

	const deleteFilter = (targetIndex) => {
		console.log('deleteFilter index', targetIndex);
		console.log(advancedSearchDataList[targetIndex]);
		console.log(displayList[targetIndex]);

		const newAdvancedSearchDataList = advancedSearchDataList.filter(
			(_, index) => index !== targetIndex
		);
		const newDisplayList = displayList.filter(
			(_, index) => index !== targetIndex
		);
		setDisplayList(newDisplayList);
		setAdvancedSearchDataList(newAdvancedSearchDataList);
		fetchData(
			newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null
		);
	};

	useEffect(() => {
		// fetchIndexInfo();
		getLocalStorageData();
		fetchData();

		return () => {
			// cleanup
		};
	}, [getLocalStorageData]);

	// console.log(advancedSearchDataList);

	return (
		<>
			<div id="indexView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={false}
				/>
				<Container>
					<Left>
						<CreateBtnNormal
							permission={fetchedData?.permission}
							word={t('button.createButton')}
						/>
					</Left>
					<Center></Center>
					<Right>
						<SearchBtnSmall
							permission={fetchedData?.permission}
							handleClick={handleAdvancedSearchModalShow}
						/>
						<DropdownMenuArea permission={fetchedData?.permission}>
							<LogIcon />
							<span>{t('button.logButton')}</span>
						</DropdownMenuArea>
					</Right>
				</Container>
				<div className="position-relative firstRowMinHeight bg-white d-flex flex-column p-4 position-relative overflow-auto">
					<AdvancedSearchResultTag
						advancedSearchDataList={advancedSearchDataList}
						deleteAllFilters={deleteAllFilters}
						deleteFilter={deleteFilter}
						ns="lgsSalesOrder"
					/>
					{fetchedData?.indexData && (
						<Table
							guidName={guidName}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableData={fetchedData?.indexData}
							indexData={fetchedData?.indexData}
							sortBy={sortBy}
							setSortBy={setSortBy}
							displayColumns={displayColumns}
							setDisplayColumns={setDisplayColumns}
							paginationData={paginationData}
							setPaginationData={setPaginationData}
							fetchData={fetchData}
							fetchedData={fetchedData}
							tableStyles={tableStyles}
							columnMustShow={columnMustShow}
							rowClick={true}
							advancedSearchDataList={advancedSearchDataList}
							displaySetting={true}
							orderBy={orderBy}
							setOrderBy={setOrderBy}
						/>
					)}
				</div>
			</div>

			{advancedSearchShow && (
				<AdvancedSearchModal
					isOpen={advancedSearchShow}
					handleClose={handleAdvancedSearchModalClose}
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					advancedSearchDataList={advancedSearchDataList}
					setAdvancedSearchDataList={setAdvancedSearchDataList}
					advancedSearchDataTemp={advancedSearchDataTemp}
					fetchData={fetchData}
					sortBy={sortBy}
					setSortBy={setSortBy}
					setFetchedData={setFetchedData}
					displayList={
						displayList.length > 0
							? displayList
							: [{ ...advancedSearchDataTemp }]
					}
					setDisplayList={setDisplayList}
					searchContentList={searchContentList}
					ns="lgsSalesOrder"
				/>
			)}
		</>
	);
}

export default LgsSalesOrder_Index;
