// selectOptionList
// 進階搜尋 中間的 operator，總共有7種 operator

// 1 包括 -> "contains"
// 2 不包括 -> "!contains"
// 3 等於 -> "=="
// 4 不等於 -> "!="
// 5 大於 -> ">"
// 6 大於等於 -> ">="
// 7 小於 -> "<"
// 8 小於等於 -> "<="

// list 參數
// 1. column -> 左邊第一排 select 的多國語言代號

// 進銷存附件分類
export const appendixClass = [
  {
    column: "appendixClassCode",
    name: "附件分類代碼",
    type: "text",
    tableColumn: "appendixClassCode",
    searchFromDataBase: "appendixClassCode",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "name01",
    name: "名稱1",
    type: "text",
    tableColumn: "name01",
    searchFromDataBase: "name01",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
];

// 採購訂單
export const purchaseOrderAdvancedSearchData = [
  {
    column: "vendor",
    name: "vendor",
    type: "list",
    tableColumn: "vendorGuid", // call fetchData 時 db 會去找的 column name
    listName: "vendorList", // api 回傳的相對應 list 名稱
    options: ["vendorCode", "name01"],
    searchFromDataBase: "vendorGuid",
    selectOptionList: ["==", "!="], // 會有什麼 operator
  },
  {
    column: "orderDate",
    name: "採購日期",
    type: "date",
    tableColumn: "orderDate",
    searchFromDataBase: "orderDate",
    selectOptionList: [
      "==",
      "!=",
      "contains",
      "!contains",
      ">",
      ">=",
      "<",
      "<=",
    ],
  },

  {
    column: "orderNo",
    type: "text",
    tableColumn: "orderNo",
    searchFromDataBase: "orderNo",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "currency",
    name: "幣別",
    tableColumn: "currencyGuid",
    listName: "currencyList",
    searchFromDataBase: "currencyGuid",
    type: "list",
    options: ["currencyCode", "name01"],
    selectOptionList: ["==", "!="],
  },
];

// 採購訂單編號規則
export const purchaseOrderNoRuleAdvancedSearchData = [
  {
    column: "name",
    type: "text",
    tableColumn: "name",
    searchFromDataBase: "name",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "noRule",
    type: "text",
    tableColumn: "noRule",
    searchFromDataBase: "noRule",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "startNo",
    type: "text",
    tableColumn: "startNo",
    searchFromDataBase: "startNo",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "incremental",
    type: "number",
    tableColumn: "incremental",
    searchFromDataBase: "incremental",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
];

// 採購訂單類型
export const purchaseOrderTypeAdvancedSearchData = [
  {
    column: "typeCode",
    name: "代碼",
    type: "text",
    tableColumn: "typeCode",
    searchFromDataBase: "typeCode",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "name01",
    name: "名稱",
    type: "text",
    tableColumn: "name01",
    searchFromDataBase: "name01",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "noRule",
    name: "編號規則",
    type: "text", //後面搜尋欄位形式--要補 list API
    tableColumn: "noRule",
    searchFromDataBase: "noRule",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
];

// 採購訂單收貨與收發票
export const purchaseOrderGoodsInvoiceAdvancedSearchData = [
  {
    column: "vendorCode",
    type: "text",
    tableColumn: "vendorCode",
    searchFromDataBase: "vendorCode",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "vendor",
    name: "vendor",
    type: "list",
    tableColumn: "vendorGuid", // call fetchData 時 db 會去找的 column name
    listName: "vendorList", // api 回傳的相對應 list 名稱
    options: ["vendorCode", "name01"],
    searchFromDataBase: "vendorGuid",
    selectOptionList: ["==", "!="], // 會有什麼 operator
  },

  {
    column: "orderDate",
    name: "採購日期",
    type: "date",
    tableColumn: "orderDate",
    searchFromDataBase: "orderDate",
    selectOptionList: [
      "==",
      "!=",
      "contains",
      "!contains",
      ">",
      ">=",
      "<",
      "<=",
    ],
  },
  {
    column: "orderNo",
    type: "text",
    tableColumn: "orderNo",
    searchFromDataBase: "orderNo",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  // {
  // 	column: 'orderType',
  // 	type: 'list',
  // 	tableColumn: 'typeGuid',
  // 	listName: 'orderTypeList',
  // 	options: ['typeCode', 'name01'],
  // 	searchFromDataBase: 'typeGuid',
  // },
  {
    column: "currency",
    name: "幣別",
    tableColumn: "currencyGuid",
    listName: "currencyList",
    searchFromDataBase: "currencyGuid",
    type: "list",
    options: ["currencyCode", "name01"],
    selectOptionList: ["==", "!="],
  },
  // {
  // 	column: 'displayTaxAmount',
  // 	type: 'number',
  // 	tableColumn: 'displayTaxAmount',
  // 	searchFromDataBase: 'displayTaxAmount',
  // },
  // {
  // 	tableColumn: 'grossAmount',

  // 	column: 'grossAmount',
  // 	name: '本地採購稅額',
  // 	type: 'number',
  // 	searchFromDataBase: 'grossAmount',
  // 	options: ['taxCode', 'name01'],
  // },
  // {
  // 	column: 'invoiceTaxRateType',
  // 	type: 'list',
  // 	searchFromDataBase: 'inputTaxGuid',
  // 	listName: 'invoiceTaxRateTypeList',
  // 	options: ['taxCode', 'name01'],
  // },
];

// 採購訂單退貨
export const purchaseOrderGoodsReturnAdvancedSearchData = [
  {
    column: "vendorCode",
    type: "text",
    tableColumn: "vendorCode",
    searchFromDataBase: "vendorCode",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "vendor",
    name: "vendor",
    type: "list",
    tableColumn: "vendorGuid", // call fetchData 時 db 會去找的 column name
    listName: "vendorList", // api 回傳的相對應 list 名稱
    options: ["vendorCode", "name01"],
    searchFromDataBase: "vendorGuid",
    selectOptionList: ["==", "!="], // 會有什麼 operator
  },

  {
    column: "postingDate",
    name: "收貨日期",
    type: "date",
    tableColumn: "postingDate",
    searchFromDataBase: "postingDate",
    selectOptionList: [
      "==",
      "!=",
      "contains",
      "!contains",
      ">",
      ">=",
      "<",
      "<=",
    ],
  },
  {
    column: "orderNo",
    type: "text",
    tableColumn: "orderNo",
    searchFromDataBase: "orderNo",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "currency",
    name: "幣別",
    tableColumn: "currencyGuid",
    listName: "currencyList",
    searchFromDataBase: "currencyGuid",
    type: "list",
    options: ["currencyCode", "name01"],
    selectOptionList: ["==", "!="],
  },
  // {
  // 	column: 'displayTaxAmount',
  // 	type: 'number',
  // 	tableColumn: 'displayTaxAmount',
  // 	searchFromDataBase: 'displayTaxAmount',
  // },
  // {
  // 	tableColumn: 'grossAmount',
  // 	column: 'grossAmount',
  // 	name: '本地採購稅額',
  // 	type: 'number',
  // 	searchFromDataBase: 'grossAmount',
  // 	options: ['taxCode', 'name01'],
  // },
];

// 採購訂單收發票
export const purchaseOrderInvoiceAdvancedSearchData = [
  {
    column: "vendorCode",
    type: "text",
    tableColumn: "vendorCode",
    searchFromDataBase: "vendorCode",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "vendor",
    name: "vendor",
    type: "list",
    tableColumn: "vendorGuid", // call fetchData 時 db 會去找的 column name
    listName: "vendorList", // api 回傳的相對應 list 名稱
    options: ["vendorCode", "name01"],
    searchFromDataBase: "vendorGuid",
    selectOptionList: ["==", "!="], // 會有什麼 operator
  },

  {
    column: "orderDate",
    name: "採購日期",
    type: "date",
    tableColumn: "orderDate",
    searchFromDataBase: "orderDate",
    selectOptionList: [
      "==",
      "!=",
      "contains",
      "!contains",
      ">",
      ">=",
      "<",
      "<=",
    ],
  },
  {
    column: "orderNo",
    type: "text",
    tableColumn: "orderNo",
    searchFromDataBase: "orderNo",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  // {
  // 	column: 'orderType',
  // 	type: 'list',
  // 	tableColumn: 'typeGuid',
  // 	listName: 'orderTypeList',
  // 	options: ['typeCode', 'name01'],
  // 	searchFromDataBase: 'typeGuid',
  // },
  {
    column: "currency",
    name: "幣別",
    tableColumn: "currencyGuid",
    listName: "currencyList",
    searchFromDataBase: "currencyGuid",
    type: "list",
    options: ["currencyCode", "name01"],
    selectOptionList: ["==", "!="],
  },
  // {
  // 	column: 'displayTaxAmount',
  // 	type: 'number',
  // 	tableColumn: 'displayTaxAmount',
  // 	searchFromDataBase: 'displayTaxAmount',
  // },
  // {
  // 	tableColumn: 'grossAmount',

  // 	column: 'grossAmount',
  // 	name: '本地採購稅額',
  // 	type: 'number',
  // 	searchFromDataBase: 'grossAmount',
  // 	options: ['taxCode', 'name01'],
  // },
  // {
  // 	column: 'invoiceTaxRateType',
  // 	type: 'list',
  // 	searchFromDataBase: 'inputTaxGuid',
  // 	listName: 'invoiceTaxRateTypeList',
  // 	options: ['taxCode', 'name01'],
  // },
];

// 採購訂單退發票
export const purchaseOrderInvoiceReturnAdvancedSearchData = [
  {
    column: "vendorCode",
    type: "text",
    tableColumn: "vendorCode",
    searchFromDataBase: "vendorCode",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "vendor",
    name: "vendor",
    type: "list",
    tableColumn: "vendorGuid", // call fetchData 時 db 會去找的 column name
    listName: "vendorList", // api 回傳的相對應 list 名稱
    options: ["vendorCode", "name01"],
    searchFromDataBase: "vendorGuid",
    selectOptionList: ["==", "!="], // 會有什麼 operator
  },

  {
    column: "invoiceDate",
    name: "採購日期",
    type: "date",
    tableColumn: "invoiceDate",
    searchFromDataBase: "invoiceDate",
    selectOptionList: [
      "==",
      "!=",
      "contains",
      "!contains",
      ">",
      ">=",
      "<",
      "<=",
    ],
  },
  {
    column: "invoiceNo",
    type: "text",
    tableColumn: "invoiceNo",
    searchFromDataBase: "invoiceNo",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "currencyCode",
    name: "幣別",
    tableColumn: "currencyGuid",
    listName: "currencyList",
    searchFromDataBase: "currencyGuid",
    type: "list",
    options: ["currencyCode", "name01"],
    selectOptionList: ["==", "!="],
  },
  // {
  // 	column: 'totalAmount',
  // 	type: 'number',
  // 	tableColumn: 'totalAmount',
  // 	searchFromDataBase: 'totalAmount',
  // },
];

// 採購訂單收貨檢驗
export const purchaseOrderGoodsInspectAdvancedSearchData = [
  {
    column: "vendorCode",
    type: "text",
    tableColumn: "vendorCode",
    searchFromDataBase: "vendorCode",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "vendor",
    name: "vendor",
    type: "list",
    tableColumn: "vendorGuid", // call fetchData 時 db 會去找的 column name
    listName: "vendorList", // api 回傳的相對應 list 名稱
    options: ["vendorCode", "name01"],
    searchFromDataBase: "vendorGuid",
    selectOptionList: ["==", "!="], // 會有什麼 operator
  },
  {
    column: "postingDate",
    name: "收貨日期",
    type: "date",
    tableColumn: "postingDate",
    searchFromDataBase: "postingDate",
    selectOptionList: [
      "==",
      "!=",
      "contains",
      "!contains",
      ">",
      ">=",
      "<",
      "<=",
    ],
  },
  {
    column: "documentNo",
    type: "text",
    tableColumn: "documentNo",
    searchFromDataBase: "documentNo",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
];

// 採購訂單類型
export const serviceClassAdvancedSearchData = [
  {
    column: "classCode",
    type: "text",
    tableColumn: "classCode",
    searchFromDataBase: "classCode",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "name01",
    type: "text",
    tableColumn: "name01",
    searchFromDataBase: "name01",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "serviceOriginCategoryCode",
    type: "list", //後面搜尋欄位形式--要補 list API
    tableColumn: "ServiceOriginCategoryCode",
    searchFromDataBase: "CategoryCode",
    selectOptionList: ["==", "!=", "contains", "!contains"],
    listName: "serviceOriginCategoryCodeList", // api 回傳的相對應 list 名稱
    options: ["categoryCode", "name"],
    selectOptionList: ["==", "!="], // 會有什麼 operator
    optionsValue: "categoryCode",
  },
  {
    column: "uomCode",
    type: "list",
    tableColumn: "uomCode", // call fetchData 時 db 會去找的 column name
    listName: "uomList", // api 回傳的相對應 list 名稱
    options: ["uomcode", "name01"],
    searchFromDataBase: "UOMCode",
    selectOptionList: ["==", "!="], // 會有什麼 operator
    optionsValue: "uomCode",
  },
];

// 銷售商品主檔
export const salesKitAdvancedSearchData = [
  {
    column: "salesKitCode",
    type: "text",
    tableColumn: "salesKitCode",
    searchFromDataBase: "salesKitCode",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "name01",
    type: "text",
    tableColumn: "name01",
    searchFromDataBase: "name01",
    selectOptionList: ["==", "!="],
  },
  {
    column: "status",
    type: "list",
    tableColumn: "status", // call fetchData 時 db 會去找的 column name
    listName: "statusList", // api 回傳的相對應 list 名稱
    options: ["name"],
    searchFromDataBase: "status",
    selectOptionList: ["==", "!="], // 會有什麼 operator
    optionsValue: "status",
  },
];

// 銷售訂單主檔
export const salesOrderAdvancedSearchData = [
  {
    column: "customer",
    name: "customer",
    type: "list",
    tableColumn: "customerGuid", // call fetchData 時 db 會去找的 column name
    listName: "customerList", // api 回傳的相對應 list 名稱
    options: ["customerCode", "name01"],
    searchFromDataBase: "customerGuid",
    selectOptionList: ["==", "!="], // 會有什麼 operator
    optionsValue: "customerGuid",
  },
  {
    column: "orderDate",
    name: "銷售日期",
    type: "date",
    tableColumn: "orderDate",
    searchFromDataBase: "orderDate",
    selectOptionList: ["==", "!=", ">", "<"],
  },

  {
    column: "orderNo",
    type: "text",
    tableColumn: "orderNo",
    searchFromDataBase: "orderNo",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "currency",
    name: "幣別",
    tableColumn: "currencyCode",
    listName: "currencyList",
    searchFromDataBase: "currencyCode",
    type: "list",
    options: ["currencyCode", "name01"],
    selectOptionList: ["==", "!="],
    optionsValue: "currencyCode",
  },
];

export const serviceMasterAdvancedSearchData = [
  {
    column: "serviceNo",
    type: "text",
    tableColumn: "serviceNo",
    searchFromDataBase: "serviceNo",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "name01",
    type: "text",
    tableColumn: "name01",
    searchFromDataBase: "name01",
    selectOptionList: ["==", "!="],
  },
  {
    column: "serviceClass",
    type: "list", //後面搜尋欄位形式--要補 list API
    tableColumn: "classCode",
    searchFromDataBase: "classCode",
    selectOptionList: ["==", "!="],
    listName: "serviceClassList", // api 回傳的相對應 list 名稱
    options: ["classCode", "name01"],
    selectOptionList: ["==", "!="], // 會有什麼 operator
    optionsValue: "classGuid",
  },
  {
    column: "uomCode",
    type: "list",
    tableColumn: "uomCode", // call fetchData 時 db 會去找的 column name
    listName: "uomList", // api 回傳的相對應 list 名稱
    options: ["uomcode", "name01"],
    searchFromDataBase: "UOMCode",
    selectOptionList: ["==", "!="], // 會有什麼 operator
    optionsValue: "uomCode",
  },
  {
    column: "forSale",
    type: "list",
    tableColumn: "forSale", // call fetchData 時 db 會去找的 column name
    listName: "forSaleList", // api 回傳的相對應 list 名稱
    options: ["forSale", "name"],
    searchFromDataBase: "UOMCode",
    selectOptionList: ["==", "!="], // 會有什麼 operator
    optionsValue: "forSale",
  },
  {
    column: "status",
    type: "list",
    tableColumn: "status", // call fetchData 時 db 會去找的 column name
    listName: "statusList", // api 回傳的相對應 list 名稱
    options: ["name"],
    searchFromDataBase: "status",
    selectOptionList: ["==", "!="], // 會有什麼 operator
    optionsValue: "status",
  },
];

// 銷售訂單理貨
export const lgsSalesOrderPreparingAdvancedSearchData = [
  {
    column: "vendorCode",
    type: "text",
    tableColumn: "vendorCode",
    searchFromDataBase: "vendorCode",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  {
    column: "vendor",
    name: "vendor",
    type: "list",
    tableColumn: "vendorGuid", // call fetchData 時 db 會去找的 column name
    listName: "vendorList", // api 回傳的相對應 list 名稱
    options: ["vendorCode", "name01"],
    searchFromDataBase: "vendorGuid",
    selectOptionList: ["==", "!="], // 會有什麼 operator
  },

  {
    column: "orderDate",
    name: "採購日期",
    type: "date",
    tableColumn: "orderDate",
    searchFromDataBase: "orderDate",
    selectOptionList: [
      "==",
      "!=",
      "contains",
      "!contains",
      ">",
      ">=",
      "<",
      "<=",
    ],
  },
  {
    column: "orderNo",
    type: "text",
    tableColumn: "orderNo",
    searchFromDataBase: "orderNo",
    selectOptionList: ["==", "!=", "contains", "!contains"],
  },
  // {
  // 	column: 'orderType',
  // 	type: 'list',
  // 	tableColumn: 'typeGuid',
  // 	listName: 'orderTypeList',
  // 	options: ['typeCode', 'name01'],
  // 	searchFromDataBase: 'typeGuid',
  // },
  {
    column: "currency",
    name: "幣別",
    tableColumn: "currencyGuid",
    listName: "currencyList",
    searchFromDataBase: "currencyGuid",
    type: "list",
    options: ["currencyCode", "name01"],
    selectOptionList: ["==", "!="],
  },
  // {
  // 	column: 'displayTaxAmount',
  // 	type: 'number',
  // 	tableColumn: 'displayTaxAmount',
  // 	searchFromDataBase: 'displayTaxAmount',
  // },
  // {
  // 	tableColumn: 'grossAmount',

  // 	column: 'grossAmount',
  // 	name: '本地採購稅額',
  // 	type: 'number',
  // 	searchFromDataBase: 'grossAmount',
  // 	options: ['taxCode', 'name01'],
  // },
  // {
  // 	column: 'invoiceTaxRateType',
  // 	type: 'list',
  // 	searchFromDataBase: 'inputTaxGuid',
  // 	listName: 'invoiceTaxRateTypeList',
  // 	options: ['taxCode', 'name01'],
  // },
];
