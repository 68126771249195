import { useState, useEffect, useCallback } from 'react';
import { PageTitleBar } from '../exports';
import { useLocation, useParams } from 'react-router-dom';

import PageCopy from './Pages/PageCopy/PageCopy';
import Page0 from './Pages/Page0';
import Page1 from './Pages/Page1';
import Page2 from './Pages/Page2';
import Page4 from './Pages/page4/Page4';
import Page5 from './Pages/Page5';
import { DialogConfirm } from './Pages/DialogConfirm';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useCookies } from 'react-cookie';

// #region styled-components
// #endregion

function LgsSalesOrder_CreateAndEdit({ isEdit }) {
	const { t } = useTranslation(['common', 'lgsSalesOrder']);

	const [salesOrderData, setSalesOrderData] = useState({
		isEverEnterPage: {
			page0: false,
			page1: false,
			page2: false,
		},
		topInfoData: {},
		page0: {},
		page1: [],
		page2: { paymentTermList: [], totals: {} },
		page4: {},
	});

	const [page, setPage] = useState(0);
	const pageTitle = `${t('lgsSalesOrder:salesOrder')} ${
		isEdit ? salesOrderData.page0.orderNo ?? '' : ''
	}`;

	//#region page1
	const [displayColumns, setDisplayColumns] = useState([]);
	const [copySalesOrderData, setCopySalesOrderData] = useState({});
	const guidName = 'salesOrderGuid';
	const location = useLocation();
	const [cookies, setCookie] = useCookies();
	const decimalPlaceTl = cookies._company_currency_decimalplace_tl;
	const { salesOrderGuid } = useParams();

	const processSteps = [
		t('lgsSalesOrder:basicInformation'),
		t('lgsSalesOrder:salesDetails'),
		t('lgsSalesOrder:paymentTerms'),
		t('lgsSalesOrder:confirm'),
	];

	const headTitleList = [
		'#',
		t('lgsSalesOrder:vendorCode'),
		t('lgsSalesOrder:vendor'),
		t('lgsSalesOrder:orderDate'),
		t('lgsSalesOrder:orderNo'),
		t('lgsSalesOrder:currencyCode'),
		t('lgsSalesOrder:displayGrossAmount'),
		t('lgsSalesOrder:displayNetAmount'),
		t('lgsSalesOrder:displayTaxAmount'),
		t('lgsSalesOrder:grossAmount'),
		t('lgsSalesOrder:netAmount'),
		t('lgsSalesOrder:taxAmount'),
	];

	const bodyTitleList = [
		'displayOrder',
		'vendorCode',
		'vendor',
		'orderDate',
		'orderNo',
		'currencyCode',
		'displayNetAmount',
		'displayTaxAmount',
		'displayGrossAmount',
		'grossAmount',
		'netAmount',
		'taxAmount',
	];

	const tableStyles = [
		{
			minWidth: '54px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '240px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'left',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '72px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'center',
		},
		{
			minWidth: '120px',
			textAlign: 'center',
		},
		{
			minWidth: '112px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
	];

	const columnMustShow = ['displayOrder', 'orderDate', 'orderNo'];

	const getLocalStorageData = useCallback(() => {
		const gettingData = async () => {
			const data = await (JSON.parse(
				localStorage.getItem(`${guidName}DisplayColumns`)
			) || bodyTitleList);

			setDisplayColumns(data);

			return data;
		};

		return gettingData();
	}, []);

	//#endregion

	const handlePrevPage = () => {
		setPage(page - 1);
	};

	const handleNextPage = () => {
		setPage(page + 1);
	};

	/**
	 * DialogConfirm 返回btn確認dialog
	 **/
	const [isOpen, setIsOpen] = useState(false);

	// DialogConfirm Modal open
	const handleDialogConfirm = () => {
		setIsOpen(true);
	};

	const getEditData = async () => {
		await axios
			.get(
				`api/Logistics/LgsSalesOrder/getEditData?guid=${salesOrderGuid}`
			)
			.then(async (res) => {
				console.log(res.data);

				const {
					salesOrder,
					salesOrderKitList,
					salesOrderPaymentTermList,
				} = res.data;

				const {
					currencyCode,
					foreignCurrency,
					customerClassGuid,
					orderDate,
				} = salesOrder;

				// page0
				const getPage0Data = async (salesOrder) => {
					const {
						categoryControl = '',
						currencyCode = '',
						currencyCodeWarning = '',
						currencyName = '',
						address: customerAddress = '',
						customerCode = '',
						customerGuid = '',
						customerClassGuid = '',
						phone1: customerPhone1 = '',
						phone2: customerPhone2 = '',
						exchageRate: exchangeRate = '',
						exchangeRateWarning = '',
						foreignCurrency = '',
						outputTaxCode = '',
						outputTaxRateTypeGuid: outputTaxGuid = '',
						outputTaxRateTypeName: outputTaxName = '',
						outputTaxRate = '',
						invoiceFormatTypeCode = '',
						invoiceFormatTypeGuid = '',
						invoiceFormatTypeName = '',
						invoiceTitle = '',
						noteCustomer: note_Customer = '',
						noteInternal: note_Internal = '',
						orderDate = '',
						orderDateWarning = '',
						orderGuid = '', // edit才有
						orderNo = '', // edit才有
						orderTypeCode = '',
						orderTypeCodeWarning = '',
						orderTypeGuid = '',
						orderTypeName = '',
						rowStamp = '', // edit才有
						recipient = '',
						recipientWarning = '',
						sectorGuid = '',
						sectorGuidWarning = '',
						sectorName = '',
						taxId = '',
						status = '', // edit才有
					} = salesOrder;

					let newData = {
						categoryControl,
						currencyCode,
						currencyCodeWarning,
						currencyName,
						currencyShow: `${currencyCode} ${currencyName}`,
						customerAddress,
						customerCode,
						customerGuid,
						customerClassGuid,
						customerPhone1,
						customerPhone2,
						decimalPlaceTl: decimalPlaceTl,
						exchangeRate,
						exchangeRateWarning,
						foreignCurrency,
						outputTaxCode,
						outputTaxGuid,
						outputTaxName,
						outputTaxRate,
						invoiceFormatTypeCode,
						invoiceFormatTypeGuid,
						invoiceFormatTypeName,
						invoiceTitle,
						note_Customer,
						note_Internal,
						orderDate: orderDate.split('T')[0],
						orderDateWarning,
						orderGuid, // !!只有edit時要給值
						orderNo, // !!只有edit時要給值
						orderTypeCode,
						orderTypeCodeWarning,
						orderTypeGuid,
						orderTypeName,
						rowStamp, // !!只有edit時要給值
						recipient,
						recipientWarning,
						sectorGuid,
						sectorGuidWarning,
						sectorName,
						taxID: taxId,
						status, // !!只有edit時要給值
					};

					//console.log('newData', newData);
					return newData;
				};

				// page1
				const getPage1Data = async (salesOrderKitList) => {
					let newArray = [];
					const salesKitList = await axios
						.get(`api/Logistics/LgsSalesOrder/getCreateDatapage1`)
						.then((res) => res.data.salesKitList);

					salesOrderKitList.forEach(async (obj) => {
						console.log(obj);
						const { salesOrderKitItemList } = obj;

						console.log(salesOrderKitItemList);

						// 組合cardName;
						const salesKit = salesKitList.find((el) => {
							return el.salesKitGuid === obj.salesKitGuid;
						});
						const cardName =
							salesKit.salesKitCode + ' ' + salesKit.name01;

						if (salesOrderKitItemList.length) {
							const list = [];
							salesOrderKitItemList.forEach((item, index) => {
								const newCardData = {
									displayOrder: index + 1,
									cardName,
									name: cardName,
									cardChildName:
										item.materialName || item.serviceName,
									cardChildName2: '',
									salesCategoryCode: item.salesCategoryCode,

									salesKitGuid: obj.salesKitGuid,
									kitGuid: obj.kitGuid, // !!只有edit時要給值
									kitSetGuid: obj.salesKitSetGuid,
									itemGuid: item.itemGuid, // !!只有edit時要給值
									materialGuid: item.materialGuid,
									serviceGuid: item.serviceGuid,
									status: item.status, // !!只有edit時要給值
									rowStamp: item.rowStamp, // !!只有edit時要給值

									// accountGuid: item.accountGuid ?? '',
									netPrice: foreignCurrency
										? item.foreignNetPrice
										: item.netPrice,
									netAmount: foreignCurrency
										? item.foreignNetAmount
										: item.netAmount,

									newQuantity: item.quantitySale, // 實際顯示的數量(跟著父層的數量變動)
									percentage: item.percentage, //
									deliverDate: item.deliverDate.split('T')[0],
									note: item.note ?? '',
									serviceAppendixUrl:
										item.serviceAppendixUrl ?? '',
									materialAppendixUrl:
										item.materialAppendixUrl ?? '',
								};

								console.log(newCardData);
								list.push(newCardData);
							});

							const specSetName =
								obj.spec1Name01 +
								' / ' +
								obj.spec2Name01 +
								' / ' +
								obj.spec3Name01;

							// 獲取符合此商品組合-價格條件的list
							const priceData = await axios
								.get(
									`api/Logistics/LgsSalesOrder/getSalesKitPrice?SalesKitGuid=${
										obj.salesKitGuid
									}&CurrencyCode=${currencyCode}&CustomerClassGuid=${customerClassGuid}&orderDate=${
										orderDate.split('T')[0]
									}&KitSetGuid=${obj.salesKitSetGuid}`
								)
								.then((res) => {
									// console.log(res.data);

									// 先判斷有沒有符合的價格條件
									return {
										salesKitPriceList:
											res.data.salesKitPriceList,
										salesKitSetPriceList:
											res.data.salesKitSetPriceList,
									};
								});

							newArray.push({
								kitSetMainInfo: {
									action: 'origin',
									specSetName,
									quantity: obj.quantitySales,
									newUnitPrice: foreignCurrency
										? obj.foreignNetAmount
										: obj.netAmount,
									salesKitGuid: obj.salesKitGuid,
									deliverDateKitSet:
										obj.deliverDate.split('T')[0],
									kitGuid: obj.kitGuid, // !!只有edit時要給值
									kitSetGuid: obj.salesKitSetGuid,
									status: obj.status, // !!只有edit時要給值
									rowStamp: obj.rowStamp, // !!只有edit時要給值
								},
								kitSetItemInfoList: list,
								priceData: priceData,
							});

							console.log(newArray);
						}
					});

					console.log(newArray);
					return newArray;
				};

				// page2
				const getPage2Data = async (salesOrderPaymentTermList) => {
					const newSalesOrderPaymentTermList =
						salesOrderPaymentTermList.map((el) => {
							return {
								...el,
								action: 'origin',
								payDate: el.payDate.split('T')[0],
							};
						});

					return {
						paymentTermList: [...newSalesOrderPaymentTermList],
						totals: {},
					};
				};

				const page0Data = await getPage0Data(salesOrder);
				const page1Data = await getPage1Data(salesOrderKitList);
				const page2Data = await getPage2Data(salesOrderPaymentTermList);

				setSalesOrderData({
					...salesOrderData,
					page0: page0Data,
					page1: page1Data,
					page2: page2Data,
				});
			});
	};

	console.log('salesOrderData', salesOrderData);

	useEffect(() => {
		// fetchIndexInfo();
		getLocalStorageData();

		return () => {
			// cleanup
		};
	}, [getLocalStorageData]);

	useEffect(() => {
		console.log(location);
		const data = location.state?.data;
		console.log(data);
		if (data) {
			setCopySalesOrderData(data);
		}

		isEdit && getEditData(); // 獲取edit資料
	}, []);

	return (
		<>
			<div id="detailsView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={true}
					backTo={-1}
					isDialogConfirm={true}
					handleDialogConfirm={handleDialogConfirm}
				/>

				{/* DialogConfirm element */}
				<DialogConfirm
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					backTo={-1}
				/>

				<div>
					{page === 'copy' && isEdit === false && (
						<PageCopy
							page={page}
							setPage={setPage}
							salesOrderData={salesOrderData}
							setSalesOrderData={setSalesOrderData}
							data={copySalesOrderData}
							setData={setCopySalesOrderData}
						/>
					)}
					{page === 0 && (
						<Page0
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handleNextPage={handleNextPage}
							salesOrderData={salesOrderData}
							setSalesOrderData={setSalesOrderData}
							copySalesOrderData={copySalesOrderData}
							isEdit={isEdit}
						/>
					)}
					{page === 1 && (
						<Page1
							salesOrderData={salesOrderData}
							setSalesOrderData={setSalesOrderData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copySalesOrderData={copySalesOrderData}
							setCopySalesOrderData={setCopySalesOrderData}
							isEdit={isEdit}
						/>
					)}
					{page === 2 && (
						<Page2
							salesOrderData={salesOrderData}
							setSalesOrderData={setSalesOrderData}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableStyles={tableStyles}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copySalesOrderData={copySalesOrderData}
							setCopySalesOrderData={setCopySalesOrderData}
							isEdit={isEdit}
						/>
					)}
					{page === 3 && (
						<Page4
							salesOrderData={salesOrderData}
							setSalesOrderData={setSalesOrderData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copySalesOrderData={copySalesOrderData}
							setCopySalesOrderData={setCopySalesOrderData}
							isEdit={isEdit}
						/>
					)}
					{page === 4 && (
						<Page5
							salesOrderData={salesOrderData}
							setSalesOrderData={setSalesOrderData}
							setPage={setPage}
							page={page}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							copySalesOrderData={copySalesOrderData}
							setCopySalesOrderData={setCopySalesOrderData}
							isEdit={isEdit}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default LgsSalesOrder_CreateAndEdit;
