import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Icn_Cancel } from '../../../../assets/images/icn_Cancel.svg';
import { useTranslation } from 'react-i18next';
import FormControlInput from '../../../../components/Inputs/FormControlInput';
import { useEffect, useRef, useState } from 'react';

// #region styled-components
const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	width: 100%;
	height: 100%;
`;

const ModalDialog = styled.div`
	max-width: 448px !important;
	min-height: calc(100% - 10.5rem);
`;

// #endregion

export const DialogFeatureInput = ({
	dialogFeatureInputData,
	setDialogFeatureInputData,
	langList,
	featureList,
	handleFinalSubmit,
	pageData,
	setPageData,
}) => {
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	const originalData = { name01: '', name02: '', name03: '' };
	const [data, setData] = useState(originalData);
	const [err, setErr] = useState({ name01: false });
	const [isFeatureInputConfirmed, setIsFeatureInputConfirmed] =
		useState(false);
	const {
		feature1Name01,
		feature1Name02,
		feature1Name03,
		feature2Name01,
		feature2Name02,
		feature2Name03,
		feature3Name01,
		feature3Name02,
		feature3Name03,
	} = featureList;

	console.log(data);
	console.log(featureList);
	/**
	 * Modal儲存
	 **/
	function modalSave() {
		data.name01 === '' &&
			setErr({
				name01: true,
			});

		if (data.name01 !== '') {
			let updateData = {};

			if (dialogFeatureInputData.createOrEdit === 'create') {
				// 依照目前特徵名稱的Name01哪個還未設定，就更新哪一個特徵
				if (feature1Name01 === '') {
					updateData = {
						feature1Name01: data.name01,
						feature1Name02: data.name02,
						feature1Name03: data.name03,
					};
				} else if (feature2Name01 === '') {
					updateData = {
						feature2Name01: data.name01,
						feature2Name02: data.name02,
						feature2Name03: data.name03,
					};
				} else if (feature3Name01 === '') {
					updateData = {
						feature3Name01: data.name01,
						feature3Name02: data.name02,
						feature3Name03: data.name03,
					};
				}
			} else if (dialogFeatureInputData.createOrEdit === 'edit') {
				if (dialogFeatureInputData.index === 0) {
					updateData = {
						feature1Name01: data.name01,
						feature1Name02: data.name02,
						feature1Name03: data.name03,
					};
				} else if (dialogFeatureInputData.index === 1) {
					updateData = {
						feature2Name01: data.name01,
						feature2Name02: data.name02,
						feature2Name03: data.name03,
					};
				} else if (dialogFeatureInputData.index === 2) {
					updateData = {
						feature3Name01: data.name01,
						feature3Name02: data.name02,
						feature3Name03: data.name03,
					};
				}
			}

			setPageData({
				...pageData,
				goodsItemInfo: {
					...pageData.goodsItemInfo,
					...updateData,
				},
			});

			setIsFeatureInputConfirmed(true);
			handleClose();
		}
	}

	useEffect(() => {
		if (dialogFeatureInputData.createOrEdit === 'edit') {
			if (dialogFeatureInputData.index === 0) {
				console.log(feature1Name01, feature1Name02, feature1Name03);
				setData({
					name01: feature1Name01,
					name02: feature1Name02,
					name03: feature1Name03,
				});
			} else if (dialogFeatureInputData.index === 1) {
				setData({
					name01: feature2Name01,
					name02: feature2Name02,
					name03: feature2Name03,
				});
			} else if (dialogFeatureInputData.index === 2) {
				setData({
					name01: feature3Name01,
					name02: feature3Name02,
					name03: feature3Name03,
				});
			}
		}
	}, [dialogFeatureInputData]);

	useEffect(() => {
		if (isFeatureInputConfirmed) {
			handleFinalSubmit(false, 'notChange', true); // (isCopyItemSave, statusAction, isFeatureItemSave)
			setIsFeatureInputConfirmed(false);
			setData(originalData);
		}
	}, [isFeatureInputConfirmed]);

	console.log(dialogFeatureInputData);
	console.log(data);

	const handleClose = () => {
		setDialogFeatureInputData({ ...dialogFeatureInputData, isOpen: false });
		setData(originalData);
	};

	return (
		<>
			{dialogFeatureInputData.isOpen && (
				<ModalWrapper onClick={handleClose}>
					<ModalDialog
						className="modal-dialog d-flex align-items-center"
						onClick={(e) => e.stopPropagation()}
					>
						<div
							className="modal-content border-0 bg-white"
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
								borderRadius: '12px',
							}}
						>
							<div className="modal-header border-0 px-4 pt-5 pb-3">
								<h5 className="w-100 text-center fw-bold fontSize1Rem mb-0">
									{dialogFeatureInputData.title}
								</h5>
								<button
									style={{ top: '12px', right: '12px' }}
									type="button"
									className="btn close position-absolute "
									data-dismiss="modal"
									aria-label="Close"
									onClick={handleClose}
								>
									<Icn_Cancel />
								</button>
							</div>

							<div className="modal-body text-center px-11 py-0 d-flex flex-column">
								<div className="col-12 mb-2">
									<FormControlInput
										id="name01"
										labelText={''}
										value={data.name01}
										onChange={(e) => {
											setData({
												...data,
												name01: e.target.value,
											});

											setErr({
												...err,
												name01:
													e.target.value === ''
														? true
														: false,
											});
										}}
										disabled={false}
										disabledCssType={0}
										isErr={err.name01}
										errMsg={t('errMsg.mustFill')}
									/>
								</div>

								{langList[1] !== '' && (
									<div className="col-12 mb-2">
										<FormControlInput
											id="name02"
											labelText={`${t(
												'lgsSalesKit:name'
											)}( ${langList[1]} )`}
											value={data.name02}
											onChange={(e) => {
												setData({
													...data,
													name02: e.target.value,
												});
											}}
											disabled={false}
											disabledCssType={0}
										/>
									</div>
								)}

								{langList[2] !== '' && (
									<div className="col-12 mb-2">
										<FormControlInput
											id="name03"
											labelText={`${t(
												'lgsSalesKit:name'
											)}( ${langList[2]} )`}
											value={data.name03}
											onChange={(e) => {
												setData({
													...data,
													name02: e.target.value,
												});
											}}
											disabled={false}
											disabledCssType={0}
										/>
									</div>
								)}
							</div>

							<div className="modal-footer justify-content-center border-0 pt-3 pb-5">
								<button
									type="button"
									className="btn btn-outline-primarylllt mediumBtn mx-2"
									onClick={handleClose}
								>
									{t('common:button.cancelButton')}
								</button>
								<button
									type="button"
									className="btn btn-primary mediumBtn mx-2"
									style={{
										margin: '0 20px',
										width: '120px',
									}}
									onClick={modalSave}
								>
									{t('common:button.confirmButton')}
								</button>
							</div>
						</div>
					</ModalDialog>
				</ModalWrapper>
			)}
		</>
	);
};
