import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { BtnNormal2 } from '../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { DialogCol } from '../Dialog/DialogCol';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../../../assets/images/icn_Edit.svg';
import { ReactComponent as Icn_chevronTop } from '../../../../assets/images/icn_chevron-top.svg';
import { ReactComponent as CreateIcon } from '../../../../assets/images/icn_Create.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icn_Trash.svg';
import { DialogListStatus } from '../Dialog/DialogListStatus/DialogListStatus';
import axios from 'axios';
import Attachment from './Attachment';
import SalesDetails from './SalesDetails';
import { DialogCustomerClass } from '../Dialog/DialogCustomerClass';
import { DialogConfirm } from '../Dialog/DialogConfirm';
import { DialogTwoCol } from '../Dialog/DialogTwoCol';
import { DialogCopyConfirm } from '../Dialog/DialogCopyConfirm';
import { DialogCustomerClassDeleteConfirm } from '../Dialog/DialogCustomerClassDeleteConfirm';
import { DialogFeatureInput } from '../Dialog/DialogFeatureInput';
import PriceArea from './PriceArea';
import showAlert from '../../../../utils/swal';
import { DialogFeatureDeleteConfirm } from '../Dialog/DialogFeatureDeleteConfirm';

// #region styled-components

const HoverContent = styled.div`
	display: none;
	position: absolute;
	left: 16px;
	top: 5px;
`;

const EditStyle = styled(EditIcon)`
	path {
		fill: var(--primary);
	}
`;

const BtnSpec = styled.button`
	border: 1px solid var(--success);
	border-radius: 8px;
	color: var(--success);
	background: transparent;

	&:hover {
		cursor: pointer;
		background-color: var(--success);
		color: #fff;
	}
`;

//#endregion

function BottomInfo(props) {
	// i18n
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	const {
		pageData,
		setPageData,
		tableBodyData,
		setTableBodyData,
		dialogBtnKeyName,
		setDialogBtnKeyName,
		isOpen,
		setIsOpen,
		modalData,
		setModalData,
		langList,
		getSpecList,
		specList,
		salesKitGuid,
		error,
		baseInfoList,
		selectedCustomerClassList,
		setSelectedCustomerClassList,
		isDetailRoute,
		isEditRoute,
		getDetails,
		handleFinalSubmit,
		isCopyDialogOpen,
		setIsCopyDialogOpen,
		featureList,
		specSelectedList,
		setSpecSelectedList,
		handleIsDisplayKitSetMoreData,
	} = props;
	// console.log(props);

	console.log(salesKitGuid);

	const [isSpecDialogOpen, setIsSpecDialogOpen] = useState(false); // 是否開啟規格dialog
	const [itemDisplayList, setItemDisplayList] = useState({
		imgArea: true,
		salesDetailArea: true,
		priceArea: true,
	}); // 是否顯示詳細資訊區塊
	const [dialogFeatureInputData, setDialogFeatureInputData] = useState({
		isOpen: false,
	}); // 新增特徵視窗
	const [dialogConfirmData, setDialogConfirmData] = useState({
		isOpen: false,
		title: t('lgsSalesKit:switchSpecWillDeleteNewInputData'), // 切換規格或顏色將會刪除新輸入的資料
		confirmInfo: { specGuid: '', featureName: '' },
	}); // 確認"切換"規格或顏色視窗
	const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);

	const [dialogCopyConfirmData, setDialogCopyConfirmData] = useState({
		isOpen: false,
		title: '',
	}); // 確認"複製"規格或顏色視窗
	const [dialogFeatureDeleteData, setDialogFeatureDeleteData] = useState({
		isOpen: false,
		title: '刪除特徵',
	}); // 確認"刪除"特徵
	const [dialogCustomerClassDeleteData, setDialogCustomerClassDeleteData] =
		useState({
			isOpen: false,
			title: t('lgsSalesKit:deleteCustomerCategory'), // 刪除客戶分類
			customerClassGuid: '',
		}); // 確認"刪除"客戶分類
	const [isCustomerClassDeleteClicked, setIsCustomerClassDeleteClicked] =
		useState(false);

	const [copyFunctionName, setCopyFunctionName] = useState(''); // "複製"的名稱 (copyAppendix copyItem copySetPrice)

	// 特徵
	const [authorized, setAuthorized] = useState(false); //  feature1/feature2/feature3

	// 左邊表格的資料
	const [middleIndexList, setMiddleIndexList] = useState([]);
	const [middleOriginIndexList, setMiddleOriginIndexList] = useState([]);

	// 客戶分類List
	const [isDialogCustomerClassOpen, setIsDialogCustomerClassOpen] =
		useState(false); // 客戶分類dialog
	const [customerClassBtnActive, setCustomerClassBtnActive] = useState(''); //客戶分類BTN 預設為空

	console.log('specList', specList);

	// 選擇的三個feature specGuid
	const handleSpecSelected = (specGuid, featureName) => {
		let newData = [];

		if (featureName === 'feature1') {
			newData = [specGuid, specSelectedList[1], specSelectedList[2]];
		} else if (featureName === 'feature2') {
			newData = [specSelectedList[0], specGuid, specSelectedList[2]];
		} else if (featureName === 'feature3') {
			newData = [specSelectedList[0], specSelectedList[1], specGuid];
		}

		setSpecSelectedList(newData);
		console.log(newData[0], newData[1], newData[2]);

		getDetails(newData[0], newData[1], newData[2]); // 獲取最新的資料
	};

	// 開啟規格視窗
	const handleOpenSpecDialog = (authorized) => {
		setIsSpecDialogOpen(true);
		setAuthorized(authorized); // feature1/feature2/feature3
		handleLeftColBtnClick(authorized); // 獲取表格資料
	};

	// 點擊規格視窗 左欄
	const handleLeftColBtnClick = async (authorized) => {
		await axios
			.post(
				`api/Logistics/LgsSalesKitSpec/getList`,
				JSON.stringify(salesKitGuid), // 銷售主檔guid
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			.then((res) => {
				// 只篩選出feature1/feature2/feature3 其中一種的spec
				const filterData = res.data.indexData.filter(
					(el) =>
						el.feature.toString() === authorized.split('feature')[1]
				);
				setMiddleIndexList(filterData);
				setMiddleOriginIndexList(filterData); // 保留原始資料，作為"新增"時，可參考的對象
			});
	};

	// 客戶分類dialog
	const handleOpenDialogCustomerClass = (
		btnKeyName,
		listName,
		isSharedPrice
	) => {
		setDialogBtnKeyName(btnKeyName);

		// 已選擇的客戶類別GUID
		const selectedCustomerClassGuidList = selectedCustomerClassList.map(
			(el) => el.customerClassGuid
		);

		// baseInfoList[listName]的customerClassGuid不包含selectedCustomerClassGuidList已經有的guid
		// 剩下的就是可以選擇的
		const temp = baseInfoList[listName].filter(
			(el) =>
				!selectedCustomerClassGuidList.includes(el.customerClassGuid)
		);
		// console.log(temp);

		setModalData(temp);
		setIsDialogCustomerClassOpen(true);
	};

	const handleCustomerClassBtnClick = (customerClassGuid) => {
		setCustomerClassBtnActive(customerClassGuid);
	};

	// console.log(dialogConfirmData);
	useEffect(() => {
		if (isConfirmBtnClicked) {
			handleSpecSelected(
				dialogConfirmData.confirmInfo.specGuid,
				dialogConfirmData.confirmInfo.featureName
			);
			setIsConfirmBtnClicked(false);
		}

		if (isCustomerClassDeleteClicked) {
			console.log(dialogCustomerClassDeleteData);

			// 刪除左欄客戶分類
			setSelectedCustomerClassList(
				selectedCustomerClassList.filter((el) => {
					return (
						el.customerClassGuid !==
						dialogCustomerClassDeleteData.customerClassGuid
					);
				})
			);

			// 刪除價格條件
			const newData = { ...pageData };

			const newSalesKitSetPriceList = newData.salesKitSetPriceList.map(
				(el) => {
					if (
						el.customerClassGuid ===
						dialogCustomerClassDeleteData.customerClassGuid
					) {
						if (
							el.action === 'native' ||
							el.action === 'update' ||
							el.action === 'delete'
						) {
							return { ...el, action: 'delete' };
						} else return el;
					} else {
						return el;
					}
				}
			);

			// 將 'insert' 的元素從陣列中刪除
			const filteredSalesKitSetPriceList = newSalesKitSetPriceList.filter(
				(el) => el.action !== 'insert'
			);

			console.log(filteredSalesKitSetPriceList);

			setPageData({
				...newData,
				salesKitSetPriceList: [...filteredSalesKitSetPriceList],
			});

			setIsCustomerClassDeleteClicked(false);
		}
	}, [isConfirmBtnClicked, isCustomerClassDeleteClicked]);

	console.log(specSelectedList);

	useEffect(() => {
		let newPageData = { ...pageData };

		if (
			specList.length !== 0 &&
			(specList?.feature1?.length !== 0 ||
				specList?.feature2?.length !== 0 ||
				specList?.feature3?.length !== 0)
		) {
			// 預設選第一個feature
			setSpecSelectedList([
				featureList.feature1Name01
					? specList.feature1.filter((el) => el.status === '0')[0]
							?.specGuid
					: null,
				featureList.feature2Name01
					? specList.feature2.filter((el) => el.status === '0')[0]
							?.specGuid
					: null,
				featureList.feature3Name01
					? specList.feature3.filter((el) => el.status === '0')[0]
							?.specGuid
					: null,
			]);
			setPageData({
				...newPageData,
				goodsItemInfo: {
					...pageData.goodsItemInfo,
					feature1SpecGuid: specList.feature1.filter(
						(el) => el.status === '0'
					)[0]?.specGuid,
					feature2SpecGuid: specList.feature2.filter(
						(el) => el.status === '0'
					)[0]?.specGuid,
					feature3SpecGuid: specList.feature3.filter(
						(el) => el.status === '0'
					)[0]?.specGuid,
				},
			});
		}
	}, [
		specList,
		isDetailRoute,
		isEditRoute,
		featureList.feature1Name01,
		featureList.feature2Name01,
		featureList.feature3Name01,
	]); // 每次換頁要重置specSelectedList

	console.log(featureList);
	console.log(specList.feature1?.find((el) => el.status === '0'));

	console.log(
		specList.feature1?.find((el) => el.status === '0') ||
			specList.feature2?.find((el) => el.status === '0') ||
			specList.feature3?.find((el) => el.status === '0')
	);

	console.log(specSelectedList);

	return (
		<div className="position-relative">
			{/* DialogCol element */}
			<DialogCol
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				modalData={modalData}
				setModalData={setModalData}
				btnKeyName={dialogBtnKeyName}
				pageData={pageData}
				setPageData={setPageData}
				tableBodyData={tableBodyData}
				setTableBodyData={setTableBodyData}
			/>
			<DialogListStatus
				isSpecDialogOpen={isSpecDialogOpen}
				setIsSpecDialogOpen={setIsSpecDialogOpen}
				authorized={authorized}
				handleLeftColBtnClick={handleLeftColBtnClick}
				middleIndexList={middleIndexList}
				setMiddleIndexList={setMiddleIndexList}
				middleOriginIndexList={middleOriginIndexList}
				langList={langList}
				getSpecList={getSpecList}
				salesKitGuid={salesKitGuid}
				featureList={featureList}
				specList={specList}
				specSelectedList={specSelectedList}
				setSpecSelectedList={setSpecSelectedList}
				pageData={pageData}
				setPageData={setPageData}
			/>
			<DialogCustomerClass
				isDialogCustomerClassOpen={isDialogCustomerClassOpen}
				setIsDialogCustomerClassOpen={setIsDialogCustomerClassOpen}
				modalData={modalData}
				setModalData={setModalData}
				btnKeyName={dialogBtnKeyName}
				pageData={pageData}
				setPageData={setPageData}
				tableBodyData={tableBodyData}
				setTableBodyData={setTableBodyData}
				selectedCustomerClassList={selectedCustomerClassList}
				setSelectedCustomerClassList={setSelectedCustomerClassList}
			/>
			<DialogFeatureInput
				dialogFeatureInputData={dialogFeatureInputData}
				setDialogFeatureInputData={setDialogFeatureInputData}
				langList={langList}
				featureList={featureList}
				handleFinalSubmit={handleFinalSubmit}
				pageData={pageData}
				setPageData={setPageData}
			/>
			<DialogFeatureDeleteConfirm
				dialogFeatureDeleteData={dialogFeatureDeleteData}
				setDialogFeatureDeleteData={setDialogFeatureDeleteData}
				handleFinalSubmit={handleFinalSubmit}
				pageData={pageData}
				setPageData={setPageData}
				setSpecSelectedList={setSpecSelectedList}
				specSelectedList={specSelectedList}
			/>
			<DialogConfirm
				dialogConfirmData={dialogConfirmData}
				setDialogConfirmData={setDialogConfirmData}
				setIsConfirmBtnClicked={setIsConfirmBtnClicked}
			/>
			<DialogCopyConfirm
				dialogCopyConfirmData={dialogCopyConfirmData}
				setDialogCopyConfirmData={setDialogCopyConfirmData}
				setIsCopyDialogOpen={setIsCopyDialogOpen}
				setCopyFunctionName={setCopyFunctionName}
				handleFinalSubmit={handleFinalSubmit}
			/>
			{/* DialogTwoCol 複製 element */}
			<DialogTwoCol
				isCopyDialogOpen={isCopyDialogOpen}
				setIsCopyDialogOpen={setIsCopyDialogOpen}
				specList={specList}
				specSelectedList={specSelectedList}
				copyFunctionName={copyFunctionName}
				salesKitGuid={salesKitGuid}
				getDetails={getDetails}
				featureList={featureList}
			/>
			<DialogCustomerClassDeleteConfirm
				dialogCustomerClassDeleteData={dialogCustomerClassDeleteData}
				setDialogCustomerClassDeleteData={
					setDialogCustomerClassDeleteData
				}
				setIsCustomerClassDeleteClicked={
					setIsCustomerClassDeleteClicked
				}
			/>
			<div className="specArea">
				<div className="d-flex mb-3">
					<BtnNormal2
						className={`${'btn-primary'} smallBtn me-3 ${
							isDetailRoute && 'd-none'
						}`}
						elementStyle={{
							borderColor: 'var(--bs-primarylt)',
							width: '88px',
						}}
						handleClick={() => {
							isDetailRoute
								? console.log('')
								: featureList.feature1Name01 === '' ||
								  featureList.feature2Name01 === '' ||
								  featureList.feature3Name01 === ''
								? setDialogFeatureInputData({
										...dialogFeatureInputData,
										isOpen: true,
										createOrEdit: 'create',
										title: '新增特徵',
								  })
								: showAlert({
										html: `<div class="mt-4 text-danger fw-bold">最多只能有3種特徵</div>`,
										htmlSvg: 'danger',
										size: 'small',
										confirmButtonText: t(
											'button.confirmButton'
										),
										showCloseButton: false,
										allowOutsideClick: true,
								  });
						}}
					>
						<CreateIcon className="me-2" />
						特徵
					</BtnNormal2>
				</div>
				{/*  */}
				{[
					featureList.feature1Name01,
					featureList.feature2Name01,
					featureList.feature3Name01,
				].map((item, index) => {
					return (
						item && (
							<div className="d-flex mb-3">
								<BtnNormal2
									className="smallBtn"
									key={item}
									keyName="advance-search"
									elementStyle={{
										border: 'none',
										width: '140px',
										position: 'relative',
										display: 'flex',
										justifyContent: 'start',
										paddingLeft: '36px',
									}}
									handleClick={() => {
										isDetailRoute ? {} : {};
									}}
								>
									{isDetailRoute ? (
										<></>
									) : (
										<HoverContent className="hover-content">
											{/* <DeleteIcon
												className="me-2"
												onClick={() => {
													isDetailRoute
														? {}
														: setDialogFeatureDeleteData(
																{
																	isOpen: true,
																	title: '刪除特徵',
																	index,
																}
														  );
												}}
											/> */}
											<EditStyle
												onClick={() => {
													isDetailRoute
														? {}
														: setDialogFeatureInputData(
																{
																	...dialogFeatureInputData,
																	isOpen: true,
																	createOrEdit:
																		'edit',
																	title: '修改特徵',
																	index,
																	featureList,
																}
														  );
												}}
											>
												<EditIcon />
											</EditStyle>
										</HoverContent>
									)}

									<div className="me-auto">
										{index === 0
											? featureList.feature1Name01 ||
											  (featureList.feature1Name02 ??
													'') ||
											  (featureList.feature1Name03 ?? '')
											: index === 1
											? featureList.feature2Name01 ||
											  (featureList.feature2Name02 ??
													'') ||
											  (featureList.feature2Name03 ?? '')
											: index === 2
											? featureList.feature3Name01 ||
											  (featureList.feature3Name02 ??
													'') ||
											  (featureList.feature3Name03 ?? '')
											: ''}
									</div>

									<BtnSpec
										className={`${
											isDetailRoute && 'd-none'
										}`}
										onClick={() => {
											isDetailRoute
												? {}
												: handleOpenSpecDialog(
														`feature${index + 1}`
												  );
										}}
									>
										spec
									</BtnSpec>
								</BtnNormal2>
								<div className="text-danger ms-1 my-1">
									{
										pageData.goodsItemInfo[
											`feature${index + 1}SpecGuidWarning`
										]
									}
								</div>

								{specList[`feature${index + 1}`]
									.filter((item) => item.status === '0')
									?.map((el) => {
										return (
											<BtnNormal2
												className={`${
													specSelectedList.includes(
														el.specGuid
													)
														? 'btn-primary'
														: 'btn-outline-primary'
												} smallBtn me-3`}
												key={el.specGuid}
												keyName={el.specGuid}
												elementStyle={{
													borderColor:
														'var(--bs-primarylt)',
													width: '120px',
												}}
												handleClick={() => {
													isDetailRoute
														? handleSpecSelected(
																el.specGuid,
																`feature${
																	index + 1
																}`
														  )
														: setDialogConfirmData({
																isOpen: true,
																title: t(
																	'lgsSalesKit:switchSpecWillDeleteNewInputData'
																),
																// 切換特徵將會刪除新輸入的資料
																confirmInfo: {
																	specGuid:
																		el.specGuid,
																	featureName: `feature${
																		index +
																		1
																	}`,
																},
														  });
												}}
												word={el.name01}
											></BtnNormal2>
										);
									})}
							</div>
						)
					);
				})}
			</div>

			{/* 必須要有至少一個feature，並且期至少有一個對應的spec是啟動的狀態，才顯示1.可銷售2.照片3.銷售明細4.價格 */}
			{handleIsDisplayKitSetMoreData() && (
				<>
					{/* 商品組合可銷售 */}
					<div className="border-bottom border-grey1 w-100 my-4"></div>
					<div className="d-flex ms-2">
						<label
							htmlFor="statusAvailableSale"
							className="fontSize125Rem fw-bold text-black pe-2"
							style={{ cursor: 'pointer' }}
						>
							商品組合可銷售
						</label>
						<input
							type="checkbox"
							name="statusAvailableSale"
							id="statusAvailableSale"
							style={{ cursor: 'pointer' }}
							checked={
								pageData.salesKitSet?.status === '0'
									? true
									: false
							}
							disabled={isDetailRoute}
							onChange={(e) => {
								setPageData({
									...pageData,
									salesKitSet: {
										...pageData.salesKitSet,
										status: e.target.checked ? '0' : '1',
									},
								});
							}}
						/>
					</div>
					<div className="border-bottom border-grey1 w-100 my-4"></div>

					{/* 照片 */}
					<div className="imgArea">
						<div className="d-flex">
							<button
								className="border-0 bg-transparent fw-bold fontSize125Rem"
								onClick={() =>
									setItemDisplayList({
										...itemDisplayList,
										imgArea: !itemDisplayList.imgArea,
									})
								}
							>
								<span className="me-3">商品組合照片</span>
								{/* 照片 */}
								<Icn_chevronTop
									className="iconChevron"
									style={{
										transform: itemDisplayList.imgArea
											? ''
											: 'rotate(.5turn)',
									}}
								/>
							</button>
							{isEditRoute && (
								<BtnNormal2
									className={`btn-warning smallBtn ms-3`}
									key={'salesDetailCopyBtn'}
									keyName={'salesDetailCopyBtn'}
									elementStyle={{
										width: '56px',
									}}
									handleClick={() => {
										isDetailRoute
											? {}
											: setDialogCopyConfirmData({
													isOpen: true,
													title: t(
														'lgsSalesKit:beforeSelectingCopyData'
													),
													// 選擇複製資料前
											  });
										setCopyFunctionName('copyAppendix');
									}}
									word={t('lgsSalesKit:copy')}
									// 複製
								></BtnNormal2>
							)}
						</div>

						{itemDisplayList.imgArea && (
							<Attachment
								// view="create"
								data={pageData}
								setData={setPageData}
								isDetailRoute={isDetailRoute}
							/>
						)}
					</div>

					<div className="border-bottom border-grey1 w-100 my-4"></div>

					{/* 銷售明細 */}
					<div className="salesDetailArea">
						<div className="d-flex">
							<button
								className="border-0 bg-transparent fw-bold fontSize125Rem"
								onClick={() =>
									setItemDisplayList({
										...itemDisplayList,
										salesDetailArea:
											!itemDisplayList.salesDetailArea,
									})
								}
							>
								<span className="me-3">
									{t('lgsSalesKit:salesDetails')}
								</span>
								{/* 銷售明細 */}
								<Icn_chevronTop
									className="iconChevron"
									style={{
										transform:
											itemDisplayList.salesDetailArea
												? ''
												: 'rotate(.5turn)',
									}}
								/>
							</button>
							{isEditRoute && (
								<BtnNormal2
									className={`btn-warning smallBtn ms-3`}
									key={'salesDetailCopyBtn'}
									keyName={'salesDetailCopyBtn'}
									elementStyle={{
										width: '56px',
									}}
									handleClick={() => {
										isDetailRoute
											? {}
											: setDialogCopyConfirmData({
													isOpen: true,
													title: t(
														'lgsSalesKit:beforeSelectingCopyData'
													),
													// 選擇複製資料前
											  });
										setCopyFunctionName('copySetItem');
									}}
									word={t('lgsSalesKit:copy')}
									// 複製
								></BtnNormal2>
							)}
						</div>

						{itemDisplayList.salesDetailArea && (
							<SalesDetails
								// view="create"
								data={pageData}
								setData={setPageData}
								error={error}
								baseInfoList={baseInfoList}
								isDetailRoute={isDetailRoute}
								isEditRoute={isEditRoute}
							/>
						)}
					</div>

					{/* 價格 (假使非單一價格時顯示) */}
					<>
						{pageData.goodsItemInfo?.samePrice === '0' && (
							<>
								<div className="border-bottom border-grey1 w-100 my-4"></div>

								<PriceArea
									itemDisplayList={itemDisplayList}
									setItemDisplayList={setItemDisplayList}
									isEditRoute={isEditRoute}
									isDetailRoute={isDetailRoute}
									customerClassBtnActive={
										customerClassBtnActive
									}
									selectedCustomerClassList={
										selectedCustomerClassList
									}
									pageData={pageData}
									setPageData={setPageData}
									error={error}
									baseInfoList={baseInfoList}
									handleCustomerClassBtnClick={
										handleCustomerClassBtnClick
									}
									setDialogCustomerClassDeleteData={
										setDialogCustomerClassDeleteData
									}
									setDialogCopyConfirmData={
										setDialogCopyConfirmData
									}
									handleOpenDialogCustomerClass={
										handleOpenDialogCustomerClass
									}
									setCopyFunctionName={setCopyFunctionName}
								/>
							</>
						)}
					</>
				</>
			)}
		</div>
	);
}

export default BottomInfo;
