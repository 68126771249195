import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Page0Partial from './Page0Partial';
import AdvancedSearchModal from '../../../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../../../../components/Modals/AdvancedSearchResultTag';
import { useTranslation } from 'react-i18next';
import IndexTablePartial from '../../../../../components/Table/IndexTablePartial';

// #region styled-components

//#endregion

function Page0Table({
    setPurchaseOrderGuid,
    table,
    singleChecked,
    setSingleChecked,
    advancedSearchShow,
    setAdvancedSearchShow,
    fetchData,
    fetchedData,
    setFetchedData,
    sortBy,
    setSortBy,
    guidName,
    headTitleList,
    bodyTitleList,
    tableStyles,
    columnMustShow,
    displayColumns,
    setDisplayColumns,
    paginationData,
    setPaginationData,
    searchContentList,
	orderBy, setOrderBy
}) {
    const { t, i18n } = useTranslation();

    // 進階搜尋 一進去的第一筆的模板
    const advancedSearchDataTemp = {
        searchLogic: 'AND',
        column: searchContentList[0].column,
        searchOperator: '==',
        searchValue: '',
        searchContent: '',
        inputWarningContent: '',
        selectOptionList: searchContentList[0].selectOptionList,
    };

    // 進階搜尋實際資料
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
    // 進階搜尋畫面資料
    const [displayList, setDisplayList] = useState([]);

    // Advanced Search

    //todo: default sort col => orderDate

    const handleOpenAdvancedSearch = () => {
        console.log('handleOpenAdvancedSearch');
    };

    const handleAdvancedSearchModalClose = () => setAdvancedSearchShow(false);
    const handleAdvancedSearchModalShow = () => {
        setAdvancedSearchShow(true);
    };

    const deleteAllFilters = () => {
        setDisplayList([]);
        setAdvancedSearchDataList([]);
        fetchData();
    };

    const deleteFilter = (targetIndex) => {
        console.log('deleteFilter index', targetIndex);
        console.log(advancedSearchDataList[targetIndex]);
        console.log(displayList[targetIndex]);

        const newAdvancedSearchDataList = advancedSearchDataList.filter(
            (_, index) => index !== targetIndex
        );
        const newDisplayList = displayList.filter(
            (_, index) => index !== targetIndex
        );
        setDisplayList(newDisplayList);
        setAdvancedSearchDataList(newAdvancedSearchDataList);
        fetchData(
            newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null
        );
    };

    // console.log(advancedSearchDataList);

    return (
        <>
            <AdvancedSearchResultTag
                advancedSearchDataList={advancedSearchDataList}
                deleteAllFilters={deleteAllFilters}
                deleteFilter={deleteFilter}
                ns="lgsSalesOrderPayment"
            />
            <div id="indexView">
                <div className="position-relative firstRowMinHeight bg-white d-flex flex-column p-4 position-relative overflow-auto">
                    {fetchedData?.indexData && (
                        <IndexTablePartial
                            t={t}
                            guidName={guidName}
                            headTitleList={headTitleList}
                            bodyTitleList={bodyTitleList}
                            tableData={fetchedData?.indexData}
                            indexData={fetchedData?.indexData}
                            sortBy={sortBy}
							setSortBy={setSortBy}
                            displayColumns={displayColumns}
                            setDisplayColumns={setDisplayColumns}
                            paginationData={paginationData}
                            setPaginationData={setPaginationData}
                            fetchData={fetchData}
                            fetchedData={fetchedData}
                            tableStyles={tableStyles}
                            columnMustShow={columnMustShow}
                            rowClick={true}
                            advancedSearchDataList={advancedSearchDataList}
                            clickSelection={1}
                            setPurchaseOrderGuid={setPurchaseOrderGuid}
                            table={table}
                            singleChecked={singleChecked}
                            setSingleChecked={setSingleChecked}
							orderBy={orderBy}
							setOrderBy={setOrderBy}
                        />
                    )}
                </div>
            </div>

            {advancedSearchShow && (
                <AdvancedSearchModal
                    isOpen={advancedSearchShow}
                    handleClose={handleAdvancedSearchModalClose}
                    headTitleList={headTitleList}
                    bodyTitleList={bodyTitleList}
                    advancedSearchDataList={advancedSearchDataList}
                    setAdvancedSearchDataList={setAdvancedSearchDataList}
                    advancedSearchDataTemp={advancedSearchDataTemp}
                    fetchData={fetchData}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    setFetchedData={setFetchedData}
                    displayList={
                        displayList.length > 0
                            ? displayList
                            : [{ ...advancedSearchDataTemp }]
                    }
                    setDisplayList={setDisplayList}
                    searchContentList={searchContentList}
					orderBy={orderBy}
					setOrderBy={setOrderBy}
                    ns="lgsSalesOrder"
                />
            )}
        </>
    );
}

export default Page0Table;
