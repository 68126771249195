import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import useAxios from '../../../../../hooks/useAxios';
import { ReactComponent as Icn_chevronCircleDown } from '../../../../../assets/images/icn_chevronCircle-down.svg';
import TopInfo from './TopInfo';
import Page1_Item from './Page1_Item';
import { PrevBtnSmall } from '../../../../../components/Buttons/PrevBtnSmall';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { useTranslation } from 'react-i18next';
import { DialogProduct } from './DialogProduct';
import axios from 'axios';
import StatusBar from '../../../../../components/StatusBar/StatusBar';
import showAlert from '../../../../../utils/swal';

//#region styled-components

// 換上下頁元件
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

// 錯誤popup
const UnableBtnErrPopup = styled.div`
	top: -44px;
	left: 0;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
	z-index: 2000;
`;

// 摺疊父子元件
const Wrapper = styled.div`
	min-height: 740px;
`;

const ToggleBtn = styled.h6`
	color: var(--primary);
	font-size: 14px;
	margin-top: 16px;
	margin-bottom: 0;
	cursor: pointer;
`;

//#endregion

function Page1(props) {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsSalesOrder']);

	const {
		salesOrderData,
		setSalesOrderData,
		page,
		processSteps,
		handlePrevPage,
		handleNextPage,
		copySalesOrderData,
	} = props;

	// state
	const [dialogDataFromBE, setDialogDataFromBE] = useState({});
	const [toggleBtnAllOpen, setToggleBtnAllOpen] = useState(true); // 展開/收合全部 btn
	const [topInfoData, setTopInfoData] = useState({}); // topInfoData
	const [newSalesOrderData, setNewSalesOrderData] = useState([]); // page1此頁面資料管控

	// axios
	const { sendRequest: axiosDataPage1 } = useAxios();

	console.log('newSalesOrderData', newSalesOrderData);
	console.log('copySalesOrderData', copySalesOrderData);

	const [cookies] = useCookies();

	//#endregion

	//#region 換頁時驗證並暫存data到父層
	const handleChangePage = (direction) => {
		// 送出前驗證
		let newData = newSalesOrderData;
		let falseCount = 0;

		console.log(newData);

		newData.map((el) => {
			// required必填欄位驗證
			let array = [
				'deliverDateKitSet',
				'quantity',
				'netUnitPrice',
				'deliverDate',
			];

			array.forEach((keyName) => {
				if (el['kitSetMainInfo'][keyName] === '') {
					// 加入錯誤訊息
					el['kitSetMainInfo'][`${keyName}Warning`] = t(
						'lgsSalesOrder:fillData'
					);
					falseCount++;
				}

				el['kitSetItemInfoList'].forEach((item) => {
					if (item[keyName] === '') {
						// 加入錯誤訊息
						item[`${keyName}Warning`] = t('lgsSalesOrder:fillData');
						falseCount++;
					}
				});
			});
		});
		setNewSalesOrderData([...newData]);
		console.log(newData);

		// 存資料到父層
		const saveToParent = () => {
			let parentData = { ...salesOrderData };
			parentData.page1 = [...newSalesOrderData];
			parentData.topInfoData = { ...topInfoData };

			setSalesOrderData(parentData);
		};

		// a.通過驗證存到父層，並到下一頁
		// console.log(falseCount);
		if (falseCount === 0 && direction === 'next') {
			// 驗證至少有一筆商品
			if (
				newData.length !== 0 &&
				newData.find((el) => {
					return el.kitSetMainInfo.action !== 'delete';
				})
			) {
				saveToParent();
				handleNextPage();
			} else {
				showAlert({
					html: `<div class="mt-4 text-danger fw-bold">${t(
						'lgsSalesOrder:pleaseAddAtLeastOneProduct'
					)}</div>`,
					htmlSvg: 'danger',
					size: 'medium',
					confirmButtonText: t('button.confirmButton'),
					showCloseButton: true,
					allowOutsideClick: false,
				});
			}
		}

		// b.不須通過驗證，將現有資料存到父層，並到上一頁
		if (direction === 'prev') {
			saveToParent();
			handlePrevPage();
		}
	};
	console.log('parentData', salesOrderData);

	//#endregion

	//#region 浮點數限制與轉換

	// 1.小數點位數限制
	const localDecimalPlaceTl = parseInt(
		cookies._company_currency_decimalplace_tl
	);
	const localDecimalPlaceUp = parseInt(
		cookies._company_currency_decimalplace_up
	);
	const foreignDecimalPlaceTl = parseInt(salesOrderData.page0.decimalPlaceTl);

	// console.log(
	// 	localDecimalPlaceTl,
	// 	localDecimalPlaceUp,
	// 	foreignDecimalPlaceTl
	// );

	// 2.轉換浮點數，並四捨五入到小數第decimalPlaces位
	function convertToFloat(inputStr, decimalPlaces) {
		let floatNum = parseFloat(inputStr);

		if (!isNaN(floatNum)) {
			floatNum =
				Math.round(floatNum * Math.pow(10, decimalPlaces)) /
				Math.pow(10, decimalPlaces);
		}

		return floatNum;
	}

	//#endregion

	//#region card內容改變，重新計算topInfo數值
	const reCalculateTopInfo = (newData) => {
		// foreignCurrency;
		if (salesOrderData.page0.foreignCurrency) {
			let newForeignNetAmount = 0;
			newData.forEach((el) => {
				newForeignNetAmount += el.kitSetMainInfo.newUnitPrice * 1;
			});

			let newNetAmount = convertToFloat(
				newForeignNetAmount * salesOrderData.page0.exchangeRate,
				localDecimalPlaceTl
			);

			let newOutputTaxRate = salesOrderData.page0.outputTaxRate / 100;

			const newTopInfo = {
				foreignNetAmount: convertToFloat(
					newForeignNetAmount,
					foreignDecimalPlaceTl
				),
				foreignTaxAmount: convertToFloat(
					newForeignNetAmount * newOutputTaxRate,
					foreignDecimalPlaceTl
				),
				foreignGrossAmount: convertToFloat(
					newForeignNetAmount +
						newForeignNetAmount * newOutputTaxRate,
					foreignDecimalPlaceTl
				),
				netAmount: convertToFloat(newNetAmount, localDecimalPlaceTl),
				taxAmount: convertToFloat(
					newNetAmount * newOutputTaxRate,
					localDecimalPlaceTl
				),
				grossAmount: convertToFloat(
					newNetAmount + newNetAmount * newOutputTaxRate,
					localDecimalPlaceTl
				),
			};

			setTopInfoData({ ...topInfoData, ...newTopInfo });
		} else {
			let newNetAmount = 0;
			newData.forEach((el) => {
				newNetAmount += el.kitSetMainInfo.newUnitPrice * 1;
			});

			let newOutputTaxRate = salesOrderData.page0.outputTaxRate / 100;

			const newTopInfo = {
				foreignNetAmount: 0,
				foreignTaxAmount: 0,
				foreignGrossAmount: 0,
				netAmount: convertToFloat(newNetAmount, localDecimalPlaceTl),
				taxAmount: convertToFloat(
					newNetAmount * newOutputTaxRate,
					localDecimalPlaceTl
				),
				grossAmount: convertToFloat(
					newNetAmount + newNetAmount * newOutputTaxRate,
					localDecimalPlaceTl
				),
			};

			setTopInfoData({ ...topInfoData, ...newTopInfo });
		}
	};

	console.log('topInfoData', topInfoData);

	//#endregion

	//#region 第一次獲得後端card資料(複製訂單)
	const createOrganizeItemListFromCopy = async (
		dataFromCopy,
		dialogDataFromBE
	) => {
		let newArray = [...newSalesOrderData];
		const { currencyCode, foreignCurrency, customerClassGuid, orderDate } =
			dataFromCopy.salesOrder;

		await Promise.all(
			dataFromCopy.salesOrderKitList.map(async (obj) => {
				console.log(obj);
				const { salesOrderKitItemList } = obj;

				console.log(salesOrderKitItemList);

				//  組合cardName
				const salesKit = dialogDataFromBE.salesKitList.find((el) => {
					return el.salesKitGuid === obj.salesKitGuid;
				});
				const cardName = salesKit.salesKitCode + ' ' + salesKit.name01;

				if (salesOrderKitItemList.length) {
					const list = [];
					await Promise.all(
						salesOrderKitItemList.map((item, index) => {
							const newCardData = {
								displayOrder: index + 1,
								cardName,
								name: cardName,
								cardChildName:
									item.materialName || item.serviceName,
								cardChildName2: '',
								salesCategoryCode: item.salesCategoryCode,

								salesKitGuid: obj.salesKitGuid,
								kitSetGuid: obj.salesKitSetGuid,
								// itemGuid: item.itemGuid,
								materialGuid: item.materialGuid,
								serviceGuid: item.serviceGuid,

								// accountGuid: item.accountGuid ?? '',
								netPrice: foreignCurrency
									? item.foreignNetPrice
									: item.netPrice,
								netAmount: foreignCurrency
									? item.foreignNetAmount
									: item.netAmount,

								newQuantity: item.quantitySale, // 實際顯示的數量(跟著父層的數量變動)
								percentage: item.percentage, //
								deliverDate: item.deliverDate.split('T')[0],
								note: item.note ?? '',
								serviceAppendixUrl:
									item.serviceAppendixUrl ?? '',
								materialAppendixUrl:
									item.materialAppendixUrl ?? '',
							};

							console.log(newCardData);
							list.push(newCardData);
						})
					);
					const specSetName =
						obj.spec1Name01 +
						' / ' +
						obj.spec2Name01 +
						' / ' +
						obj.spec3Name01;

					// 獲取符合此商品組合-價格條件的list
					const priceData = await axios
						.get(
							`api/Logistics/LgsSalesOrder/getSalesKitPrice?SalesKitGuid=${
								obj.salesKitGuid
							}&CurrencyCode=${currencyCode}&CustomerClassGuid=${customerClassGuid}&orderDate=${
								orderDate.split('T')[0]
							}&KitSetGuid=${obj.salesKitSetGuid}`
						)
						.then((res) => {
							// console.log(res.data);

							// 先判斷有沒有符合的價格條件
							return {
								salesKitPriceList: res.data.salesKitPriceList,
								salesKitSetPriceList:
									res.data.salesKitSetPriceList,
							};
						});

					newArray.push({
						kitSetMainInfo: {
							action: 'insert',
							specSetName,
							// ...defaultPriceInfo,
							quantity: obj.quantitySales,
							newUnitPrice: foreignCurrency
								? obj.foreignNetAmount
								: obj.netAmount,
							salesKitGuid: obj.salesKitGuid,
							deliverDateKitSet: obj.deliverDate.split('T')[0],
						},
						kitSetItemInfoList: list,
						priceData: priceData,
					});

					console.log(newArray);
				}
			})
		);

		console.log(newArray);

		setNewSalesOrderData(newArray);

		reCalculateTopInfo(newArray);
	};

	const isEverEnterPage = salesOrderData.isEverEnterPage.page1;
	// console.log(isEverEnterPage);

	//#endregion

	//#region useEffect
	useEffect(() => {
		// 1.axios get BE data
		const url = `api/Logistics/LgsSalesOrder/getCreateDatapage1`;
		axiosDataPage1({ url }, (res) => {
			setDialogDataFromBE(res);

			// 2.如果複製訂單，並且第一次進入此頁
			if (
				Object.keys(copySalesOrderData).length !== 0 &&
				copySalesOrderData.salesOrderKitList.length !== 0 &&
				!isEverEnterPage
			) {
				createOrganizeItemListFromCopy(copySalesOrderData, res);

				// 標記是否曾拿取過資料
				let newData = { ...salesOrderData };
				newData.isEverEnterPage.page1 = true;

				setSalesOrderData(newData);
			}
		});

		// 3. 回到此頁時，從parent回復資料
		// 有資料則回復資料
		Object.keys(salesOrderData.page1).length !== 0 &&
			setNewSalesOrderData(salesOrderData.page1);

		// 更新topInfoData
		setTopInfoData(salesOrderData.topInfoData);

		// 重新計算金額，更新topInfo金額
		reCalculateTopInfo(salesOrderData.page1);
	}, []);
	console.log('BackEndData', dialogDataFromBE);

	//#endregion

	return (
		<>
			{/* 上下步驟 */}
			<Container>
				<Left>
					{page !== 0 && (
						<PrevBtnSmall
							handleClick={() => {
								handleChangePage('prev');
							}}
						/>
					)}
				</Left>
				<Center>
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right>
					{page < processSteps.length - 1 ? (
						<NextBtnSmall
							handleClick={() => {
								handleChangePage('next');
							}}
						/>
					) : (
						<NextBtnSmall className="d-none" />
					)}
				</Right>
			</Container>

			{/* 主功能 */}
			<StatusBar status={salesOrderData.page0?.status} />

			<Wrapper
				className="row bg-white px-6 pt-4 pb-11 mx-0"
				style={{ borderRadius: '0 0 .75rem .75rem' }}
			>
				<div className="col-12 px-0 ">
					{/* topInfo */}
					<TopInfo
						topInfoData={topInfoData}
						salesOrderData={salesOrderData}
					/>
					<hr className="mt-5 mb-4" />

					{/* button row */}
					<div className="position-relative d-flex mb-3">
						<DialogProduct
							btnWord={t('lgsSalesOrder:salesKit')}
							btnKeyName="salesKit"
							btnWord2={t('lgsSalesOrder:salesKitSet')}
							btnKeyName2="sector"
							salesOrderData={salesOrderData}
							newSalesOrderData={newSalesOrderData}
							setNewSalesOrderData={setNewSalesOrderData}
							dialogDataFromBE={dialogDataFromBE}
							currencyCode={salesOrderData.page0.currencyCode}
							customerClassGuid={
								salesOrderData.page0.customerClassGuid
							}
							reCalculateTopInfo={reCalculateTopInfo}
						/>

						{/* 展開/收折全部 BTN */}
						<div className="ms-auto">
							{toggleBtnAllOpen ? (
								<ToggleBtn
									onClick={() => {
										// 箭頭btn向下
										document
											.querySelectorAll('.iconChevron')
											.forEach(
												(el) =>
													(el.style.transform =
														'rotate(0.5turn)')
											);

										// 隱藏全部子層
										document
											.querySelectorAll('.childInfoTr')
											.forEach((item) =>
												item.classList.add('d-none')
											);
										setToggleBtnAllOpen(false);
									}}
								>
									{t('lgsSalesOrder:foldAll')}
									{/* 箭頭btn向上 */}
									<Icn_chevronCircleDown
										className="ms-2 mb-1"
										style={{
											transform: 'rotate(0.5turn)',
										}}
									/>
								</ToggleBtn>
							) : (
								<ToggleBtn
									onClick={() => {
										// 箭頭btn向上
										document
											.querySelectorAll('.iconChevron')
											.forEach(
												(el) =>
													(el.style.transform = '')
											);

										// 顯示子層
										document
											.querySelectorAll('.childInfoTr')
											.forEach((item) =>
												item.classList.remove('d-none')
											);
										setToggleBtnAllOpen(true);
									}}
								>
									{t('lgsSalesOrder:expandAll')}
									{/* 箭頭btn向下 */}
									<Icn_chevronCircleDown className="ms-2 mb-1" />
								</ToggleBtn>
							)}
						</div>
					</div>

					{/* card */}
					<div className="row">
						<Page1_Item
							dialogDataFromBE={dialogDataFromBE}
							parentData={salesOrderData}
							newSalesOrderData={newSalesOrderData}
							setNewSalesOrderData={setNewSalesOrderData}
							topInfoData={topInfoData}
							setTopInfoData={setTopInfoData}
							localDecimalPlaceTl={localDecimalPlaceTl}
							localDecimalPlaceUp={localDecimalPlaceUp}
							convertToFloat={convertToFloat}
							reCalculateTopInfo={reCalculateTopInfo}
						/>
					</div>
				</div>
			</Wrapper>
		</>
	);
}

export default Page1;
