import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_Cancel } from '../../../../../assets/images/icn_Cancel.svg';
import { ReactComponent as CreateIcon } from '../../../../../assets/images/icn_Create.svg';
import useSortableData from '../../../../../hooks/useSortableData';
import { useTranslation } from 'react-i18next';
import DialogListStatus_RightCol_Edit from './DialogListStatus_RightCol_Edit';
import DialogListStatus_RightCol_Create from './DialogListStatus_RightCol_Create';
import DialogListStatus_RightCol_Details from './DialogListStatus_RightCol_Details';
import axios from 'axios';

// #region styled-components
const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	width: 100%;
	height: 100%;
`;

const ModalDialog = styled.div`
	max-width: 800px !important;
	min-height: calc(100% - 3.5rem);
`;

// center table
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
`;

const TH = styled.th`
	vertical-align: middle;
	border: none;
`;

const TBody = styled.tbody``;

const TD = styled.td`
	border: none;
`;

const BtnCreate = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	cursor: pointer;
`;

// #endregion

export const DialogListStatus = ({
	isSpecDialogOpen,
	setIsSpecDialogOpen,
	authorized, // 銷售規格、顏色
	handleLeftColBtnClick,
	middleIndexList,
	setMiddleIndexList,
	middleOriginIndexList,
	langList,
	getSpecList,
	salesKitGuid,
	featureList,
	specList,
	setSpecSelectedList,
	specSelectedList,
	pageData,
	setPageData,
}) => {
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	const [displayColumns, setDisplayColumns] = useState([]);
	const [sortBy, setSortBy] = useState('displayOrder asc');

	const [centerActive, setCenterActive] = useState('');
	const [rightActive, setRightActive] = useState(''); // create,edit,details
	const [rightColInfo, setRightColInfo] = useState({});

	const headTitleList = ['#', t('lgsSalesKit:name'), t('lgsSalesKit:status')]; //['#', '名稱', '狀態']

	const bodyTitleList = ['displayOrder', 'name01', 'status'];

	const tableStyles = [
		{
			maxWidth: '56px',
			minWidth: '56px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '100px',
			textAlign: 'left',
		},
	];

	// const getLocalStorageData = useCallback(() => {
	// 	const gettingData = async () => {
	// 		const data = await (JSON.parse(
	// 			localStorage.getItem(`${guidName}DisplayColumns`)
	// 		) || bodyTitleList);

	// 		setDisplayColumns(data);

	// 		return data;
	// 	};

	// 	return gettingData();
	// }, []);

	// center
	const { items, requestSort, sortConfig } = useSortableData(middleIndexList);
	const [orderBy, setOrderBy] = useState('asc');

	const statusSpanObj = {
		0: <span></span>,
		1: (
			<span>
				<span
					className="bg-danger d-inline-block me-2"
					style={{
						width: '8px',
						height: '8px',
						borderRadius: '50%',
					}}
				></span>
				<span className="text-grey4">
					{t('lgsSalesKit:notSellable')}
					{/* 不可銷售 */}
				</span>
			</span>
		),
	};

	const getClassNamesFor = (name) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	console.log(middleIndexList);
	// getDetail
	const handleRowClick = async (specGuid) => {
		console.log('handleRowClick', specGuid);

		// 如果沒有傳入specGuid，則不顯示details(例如關閉視窗時)，目的為了讓details在切換不同feature時不會出現原先的資料
		if (!specGuid) {
			setCenterActive('');
			setRightActive('details');
			setRightColInfo({});
			return;
		}

		handleLeftColBtnClick(authorized); // 重置

		setCenterActive(specGuid);
		setRightActive('details');

		await axios
			.get(`api/Logistics/LgsSalesKitSpec/getDetails/${specGuid}`)
			.then((res) => {
				console.log(res.data);
				setRightColInfo(res.data);
			});
	};

	const handleSortChange = (newTarget) => {
		let newSortBy;
		let newOrderBy;

		const oldTarget = sortBy.split(' ')[0];

		console.log(oldTarget);
		console.log(newTarget);
		console.log(orderBy);

		if (oldTarget === newTarget) {
			newOrderBy = orderBy === 'desc' ? 'asc' : 'desc';
		} else {
			newOrderBy = 'asc';
		}

		console.log(newOrderBy);

		newSortBy = `${newTarget} ${newOrderBy}`;

		setSortBy(newSortBy);
		setOrderBy(newOrderBy);

		requestSort(newTarget);
		console.log(sortConfig);
	};

	// 新增按鈕
	const handleCreateNoRule = () => {
		setRightActive('create');
		setCenterActive('00000000-0000-0000-0000-000000000000');

		// 使用原始資料，讓使用者重複click時，還是原本的資料+一個空白的顯示資料
		setMiddleIndexList([
			...middleOriginIndexList,
			{
				specGuid: '00000000-0000-0000-0000-000000000000',
				status: '0',
				name01: '',
			},
		]);
	};

	useEffect(() => {
		setDisplayColumns(bodyTitleList);
	}, []);

	return (
		<>
			{isSpecDialogOpen && (
				<ModalWrapper
					onClick={() => {
						setIsSpecDialogOpen(false);
						handleRowClick('');
					}}
				>
					<ModalDialog
						className="modal-dialog d-flex align-items-center"
						onClick={(e) => e.stopPropagation()}
					>
						<div
							className="modal-content border-0 bg-white"
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
								borderRadius: '12px',
							}}
						>
							<div className="modal-header px-4 pt-4 pb-3">
								<h5 className="text-primary fw-bold fontSize125Rem mb-0">
									{authorized === 'feature1'
										? featureList.feature1Name01
										: authorized === 'feature2'
										? featureList.feature2Name01
										: authorized === 'feature3'
										? featureList.feature3Name01
										: ''}
								</h5>
								<button
									style={{ top: '12px', right: '12px' }}
									type="button"
									className="close position-absolute btn"
									onClick={() => {
										setIsSpecDialogOpen(false);
										handleRowClick('');
									}}
								>
									<Icn_Cancel />
								</button>
							</div>

							<div className="modal-body text-center px-4 py-0 mt-2">
								<div
									style={{ height: '675px' }}
									className="row mx-0"
								>
									{/* @* 中欄 *@ */}
									<div className="center col-8 ps-0 pe-2">
										<div
											style={{
												height: '650px',
												overflowY: 'scroll',
												border: '1px solid var(--grey1)',
												borderRadius:
													'.75rem .75rem 0 0',
											}}
											className="bg-white position-relative"
										>
											<BtnCreate
												className={`position-absolute top-0 end-0 mt-1 me-1 border-0 bg-primary`}
												onClick={() =>
													handleCreateNoRule()
												}
											>
												<CreateIcon />
											</BtnCreate>

											<IndexTableContainer>
												<IndexTable
													className="table"
													style={{
														overflowX: 'auto',
														whiteSpace: 'nowrap',
														borderCollapse:
															'collapse',
														borderSpacing: '0',
														wordWrap: 'break-word',
														wordBreak: 'break-all',
													}}
												>
													<THead
														className="table-head"
														items={items}
														requestSort={
															requestSort
														}
														sortConfig={sortConfig}
													>
														<TR className="d-flex">
															{headTitleList?.map(
																(el) => {
																	return (
																		<TH
																			style={
																				tableStyles[
																					headTitleList.indexOf(
																						el
																					)
																				]
																			}
																			key={`tableHead${headTitleList.indexOf(
																				el
																			)}`}
																			className={`px-2 w-100 cursor-pointer bg-grey1 ${
																				!displayColumns.includes(
																					bodyTitleList[
																						headTitleList.indexOf(
																							el
																						)
																					]
																				)
																					? 'd-none'
																					: 'd-block'
																			} ${
																				bodyTitleList[
																					headTitleList.indexOf(
																						el
																					)
																				]
																			} ${getClassNamesFor(
																				bodyTitleList[
																					headTitleList.indexOf(
																						el
																					)
																				]
																			)}`}
																			onClick={() => {
																				handleSortChange(
																					bodyTitleList[
																						headTitleList.indexOf(
																							el
																						)
																					]
																				);
																			}}
																		>
																			{el}
																			{bodyTitleList[
																				headTitleList.indexOf(
																					el
																				)
																			] ===
																				sortBy.split(
																					' '
																				)[0] &&
																				(orderBy ===
																				'desc' ? (
																					<span className="ml-1">
																						▼
																					</span>
																				) : (
																					<span className="ml-1">
																						▲
																					</span>
																				))}
																		</TH>
																	);
																}
															)}
														</TR>
													</THead>
													<TBody>
														{items?.map(
															(data, index) => (
																<TR
																	className="list-item table-item d-flex"
																	id={
																		data[
																			'specGuid'
																		]
																	}
																	key={
																		data[
																			'specGuid'
																		]
																	}
																	onClick={(
																		e
																	) =>
																		data[
																			'specGuid'
																		] !==
																		'00000000-0000-0000-0000-000000000000'
																			? handleRowClick(
																					data[
																						'specGuid'
																					]
																			  )
																			: null
																	}
																>
																	{bodyTitleList.map(
																		(
																			el
																		) => {
																			if (
																				el ===
																				'status'
																			)
																				return (
																					<TD
																						style={
																							tableStyles[
																								bodyTitleList.indexOf(
																									el
																								)
																							]
																						}
																						className={`p-2 w-100 ${
																							!displayColumns.includes(
																								el
																							)
																								? 'd-none'
																								: 'd-block'
																						} ${
																							centerActive ===
																							data[
																								'specGuid'
																							]
																								? ' bg-grey1'
																								: ''
																						}`}
																						key={`${el}${data['specGuid']}`}
																					>
																						{
																							statusSpanObj[
																								data[
																									el
																								]
																							]
																						}
																					</TD>
																				);
																			return (
																				<TD
																					style={{
																						...tableStyles[
																							bodyTitleList.indexOf(
																								el
																							)
																						],
																						overflow:
																							'hidden',
																					}}
																					// style={
																					//     tableStyles[
																					//         bodyTitleList.indexOf(el)
																					//     ]
																					// }
																					className={`p-2 w-100 ${
																						!displayColumns.includes(
																							el
																						)
																							? 'd-none'
																							: 'd-block'
																					} ${
																						centerActive ===
																						data[
																							'specGuid'
																						]
																							? ' bg-grey1'
																							: ''
																					}`}
																					key={`${el}${data['specGuid']}`}
																				>
																					{
																						data[
																							el
																						]
																					}
																				</TD>
																			);
																		}
																	)}
																</TR>
															)
														)}
													</TBody>
												</IndexTable>
											</IndexTableContainer>
										</div>
									</div>

									{/* @* 右欄 *@ */}
									<div className="right col-4 ps-2 pe-0">
										{rightActive === 'create' ? (
											<DialogListStatus_RightCol_Create
												leftActive={authorized}
												handleLeftColBtnClick={
													handleLeftColBtnClick
												}
												setMiddleIndexList={
													setMiddleIndexList
												}
												middleOriginIndexList={
													middleOriginIndexList
												}
												middleListLength={
													middleIndexList.length
												}
												setRightActive={setRightActive}
												langList={langList}
												getSpecList={getSpecList}
												salesKitGuid={salesKitGuid}
											/>
										) : rightActive === 'details' ? (
											<DialogListStatus_RightCol_Details
												rightColInfo={rightColInfo}
												setRightActive={setRightActive}
												langList={langList}
												getSpecList={getSpecList}
											/>
										) : rightActive === 'edit' ? (
											<DialogListStatus_RightCol_Edit
												leftActive={authorized}
												rightColInfo={rightColInfo}
												setRightActive={setRightActive}
												handleLeftColBtnClick={
													handleLeftColBtnClick
												}
												handleCenterRowClick={
													handleRowClick
												}
												langList={langList}
												getSpecList={getSpecList}
												salesKitGuid={salesKitGuid}
												specList={specList}
												specSelectedList={
													specSelectedList
												}
												setSpecSelectedList={
													setSpecSelectedList
												}
												pageData={pageData}
												setPageData={setPageData}
											/>
										) : null}
									</div>
								</div>
							</div>
						</div>
					</ModalDialog>
				</ModalWrapper>
			)}
		</>
	);
};
