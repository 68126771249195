import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ReactComponent as CreateIcon } from '../../../../../assets/images/icn_Create.svg';
import { ReactComponent as Icn_Cancel } from '../../../../../assets/images/icn_Cancel.svg';
import { ReactComponent as Icn_Search2 } from '../../../../../assets/images/icn_Search2.svg';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import useAxios from '../../../../../hooks/useAxios';
import useSortableData from '../../../../../hooks/useSortableData';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import showAlert from '../../../../../utils/swal';

// #region styled-components
const ModalThead = styled.thead`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalTh = styled.th`
	padding: 0 0.75rem;
	cursor: pointer !important;
	border: none !important;
`;
const ModalLabel = styled.label`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalInput = styled.input`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;

const SearchModalInput = styled.input`
	font-size: 14px;
	width: 240px;
	height: 32px;
	padding: 0 0.75rem;
	cursor: pointer !important;
	text-transform: uppercase;
`;

const ModalSearchBtn = styled.button`
	top: 1px;
	right: ${(props) => (props.isModal1 ? '74px' : '157px')};
	height: 30px;
	border-radius: 0 0.25rem 0.25rem 0;
`;
// #endregion

export const DialogProduct = ({
	dialogDataFromBE,
	btnWord,
	btnKeyName,
	btnWord2,
	salesOrderData,
	newSalesOrderData,
	setNewSalesOrderData,
	currencyCode,
	customerClassGuid,
	reCalculateTopInfo,
}) => {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsSalesOrder']);

	// state
	const [modal, setModal] = useState(false);
	const [modalData, setModalData] = useState([]);

	const [modal2, setModal2] = useState(false);
	const [modal2Data, setModal2Data] = useState({
		salesKitSetList: [],
		salesKitSetItemList: [],
		salesKitPriceList: [],
		salesKitSetPriceList: [],
	});

	const [modal2Children, setModal2Children] = useState(false);
	const [modal2ChildrenData, setModal2ChildrenData] = useState([]);
	const [modal2ChildrenOriginData, setModal2ChildrenOriginData] = useState(
		[]
	);

	const [oneCheck, setOneCheck] = useState('');
	const [cardName, setCardName] = useState('');
	const [salesKitGuid, setSalesKitGuid] = useState('');

	// console.log(oneCheck);

	// ref
	const searchModalInput = useRef('');
	const searchModal2Input = useRef('');

	// axios
	const { sendRequest: axiosKitSetData } = useAxios();

	// base function
	const toggle = () => setModal(!modal);
	const toggle2 = () => setModal2(!modal2);

	//#endregion

	//#region clickCreateModal(createBtn)

	const clickCreateModal = (e) => {
		e.preventDefault();
		setModalData(dialogDataFromBE.salesKitList);
		toggle();
	};

	//#endregion

	//#region Modal搜尋

	// 搜尋
	const searchModalBtn = () => {
		//取得搜尋文字
		let searchModalInputText = searchModalInput.current
			? searchModalInput.current.value.toUpperCase()
			: searchModal2Input.current.value.toUpperCase();

		let modalTrList = document.querySelectorAll('.modalTr');

		if (searchModalInputText !== '') {
			//隱藏全部欄位
			modalTrList.forEach((item) => {
				item.classList.add('d-none');
			});

			// 搜尋每個Tr中的每個Td欄位
			modalTrList.forEach((item) => {
				// console.log(item.children);

				[...item.children].forEach((td) => {
					if (
						td.textContent
							.toUpperCase()
							.includes(searchModalInputText)
					) {
						td.parentElement.classList.remove('d-none');
					}
				});
			});

			//顯示取消搜尋btn
			document
				.querySelector('.cancelSearchModalBtn')
				.classList.remove('d-none');
		}
	};

	// 取消搜尋
	const cancelSearchModalBtn = (i) => {
		// 顯示全部欄位
		document.querySelectorAll('.modalTr').forEach((item) => {
			item.classList.remove('d-none');
		});

		// 清空搜尋欄位
		if (searchModalInput.current) searchModalInput.current.value = '';
		if (searchModal2Input.current) searchModal2Input.current.value = '';

		// 隱藏取消搜尋btn
		document.querySelector('.cancelSearchModalBtn').classList.add('d-none');
	};

	//#endregion

	//#region Modal clickTableRow(只能選一個)

	function clickTableRow(e, guid) {
		// console.log(guid);
		setOneCheck(guid);

		//點選tr 使checkbox被選取
		let majorCheck =
			e.target.parentElement.children[0].querySelector('input');

		//避免input無法直接點擊
		if (e.nodeName != 'INPUT' && e.nodeName != 'LABEL') {
			majorCheck.checked = !majorCheck.checked;
		}
	}

	//#endregion

	//#region Modal儲存

	// console.log(modalData);
	function modalSave() {
		const { orderDate } = salesOrderData.page0;

		// 選取哪些checkbox
		const handleSelectedData = () => {
			const selectedId = [];

			document
				.querySelectorAll('.modal-content [type=checkbox]')
				.forEach((el) => {
					el.checked && selectedId.push(el.id);
				});

			selectedId.forEach((id) => {
				let item = modalData.find((el) => {
					return el[`salesKitGuid`] === id;
				});

				setCardName(item.salesKitCode + ' ' + item['name01']);
				setSalesKitGuid(item.salesKitGuid);
			});

			const url = `api/Logistics/LgsSalesOrder/getSalesKitSet?SalesKitGuid=${selectedId[0]}&CurrencyCode=${currencyCode}&CustomerClassGuid=${customerClassGuid}&orderDate=${orderDate}`;
			axiosKitSetData(
				{ url },
				({
					salesKitSetList,
					salesKitSetItemList,
					salesKitPriceList,
					salesKitSetPriceList,
				}) => {
					console.log({
						salesKitSetList,
						salesKitSetItemList,
						salesKitPriceList,
						salesKitSetPriceList,
					});

					setModal2Data({
						salesKitSetList,
						salesKitSetItemList,
						salesKitPriceList,
						salesKitSetPriceList,
					});

					setModal2ChildrenOriginData(salesKitSetItemList);
					setModal2ChildrenData([]);
				}
			);

			toggle(); //關
			toggle2(); //開
		};

		oneCheck.length !== 0 && handleSelectedData();
	}

	//#endregion

	//#region Modal2儲存

	// console.log('modal2Data', modal2Data);
	async function modal2Save() {
		const { orderDate } = salesOrderData.page0;
		const selectedId = [];
		document
			.querySelectorAll('.modal-content [type=checkbox]')
			.forEach((el) => {
				el.checked && selectedId.push(el.id);
			});
		console.log(selectedId);

		// 選取哪些checkbox
		const handleSelectedData = (priceData) => {
			const selectedKitSetList = [];
			const selectedKitSetPriceList = [];
			const selectedKitSetItemList = [];
			let defaultPriceInfo = {};

			// 暫時先限縮只能選一個
			selectedId.forEach((id) => {
				// 1.selectedKitSet
				let selectedKitSet = modal2Data.salesKitSetList.find(
					(el) => el[`kitSetGuid`] == id
				);
				selectedKitSetList.push(selectedKitSet);

				// 2.selectedKitSetPrice
				const list =
					priceData.salesKitPriceList.length !== 0
						? priceData.salesKitPriceList
						: priceData.salesKitSetPriceList.length !== 0
						? priceData.salesKitSetPriceList
						: [];

				// console.log(priceData);
				// console.log(list);
				// 目前是後端已經排序好，依照數量由前往後選，預設是選第一個
				// 後端邏輯：當customerClassGuid存在時，取displayOrder最小的；否則取customerClassGuid null時，displayOrder最小的
				defaultPriceInfo = list[0];

				selectedKitSetPriceList.push(...list);
				console.log(selectedKitSetPriceList);

				// 3.selectedKitSetItemList
				let selectedKitSetItem = modal2Data.salesKitSetItemList.filter(
					(el) => {
						return el.kitSetGuid === id;
					}
				);

				selectedKitSetItemList.push(...selectedKitSetItem);
			});
			// console.log(selectedList);

			return {
				selectedKitSetList,
				selectedKitSetPriceList,
				selectedKitSetItemList,
				defaultPriceInfo,
			};
		};

		// 新增卡片
		const handleAddCard = (priceData) => {
			let newArray = [...newSalesOrderData];
			const {
				selectedKitSetList,
				selectedKitSetPriceList,
				selectedKitSetItemList,
				defaultPriceInfo,
			} = handleSelectedData(priceData);

			console.log({
				selectedKitSetList,
				selectedKitSetPriceList,
				selectedKitSetItemList,
			});

			if (selectedKitSetItemList.length) {
				const list = [];
				selectedKitSetItemList.forEach((item, index) => {
					// 更新或新增欄位
					const newCardData = {
						displayOrder: index + 1,
						cardName,
						name: cardName,
						cardChildName:
							item.materialName01 || item.serviceName01,
						cardChildName2: '',
						salesCategoryCode: item.salesCategoryCode,

						salesKitGuid: salesKitGuid,
						kitSetGuid: item.kitSetGuid,
						// itemGuid: item.itemGuid,
						materialGuid: item.materialGuid,
						serviceGuid: item.serviceGuid,

						// accountGuid: item.accountGuid,
						netPrice:
							(defaultPriceInfo.unitPrice * item.percentage) /
							100 /
							item.quantity,
						netAmount:
							(defaultPriceInfo.unitPrice *
								defaultPriceInfo.quantityFrom *
								item.percentage) /
							100,

						newQuantity:
							item.quantity * defaultPriceInfo.quantityFrom, // 實際顯示的數量(跟著父層的數量變動)，預設為找到的priceData的下限數量
						percentage: item.percentage,
						deliverDate: newArray[0]?.deliverDate || '', // 第一筆選擇的[交貨日期]，作為後面尚未輸入的筆數的預設值。
						note: item.note ?? '',
						serviceAppendixUrl: item.serviceAppendixUrl ?? '',
						materialAppendixUrl: item.materialAppendixUrl ?? '',
					};

					console.log(newCardData);
					list.push(newCardData);
				});

				const specSetName =
					selectedKitSetList[0].spec1Name01 +
					' / ' +
					selectedKitSetList[0].spec2Name01 +
					' / ' +
					selectedKitSetList[0].spec3Name01;

				newArray.push({
					kitSetMainInfo: {
						action: 'insert',
						specSetName,
						...defaultPriceInfo,
						quantity: defaultPriceInfo.quantityFrom, // 預設price找到的最低數量
						newUnitPrice:
							defaultPriceInfo.unitPrice *
							defaultPriceInfo.quantityFrom, // 實際顯示的單價(跟著原始數量時的unitPrice變動)
						salesKitGuid: salesKitGuid,
						deliverDateKitSet: '',
					},
					kitSetItemInfoList: list,
					priceData: priceData,
				});

				console.log(newArray);
				setNewSalesOrderData(newArray);

				reCalculateTopInfo(newArray);

				setOneCheck('');

				toggle2();
			}
		};

		await axios
			.get(
				`api/Logistics/LgsSalesOrder/getSalesKitPrice?SalesKitGuid=${salesKitGuid}&CurrencyCode=${currencyCode}&CustomerClassGuid=${customerClassGuid}&orderDate=${orderDate}&KitSetGuid=${selectedId[0]}`
			)
			.then((res) => {
				console.log(res.data);

				// 先判斷有沒有符合的價格條件
				const priceData = {
					salesKitPriceList: res.data.salesKitPriceList,
					salesKitSetPriceList: res.data.salesKitSetPriceList,
				};

				if (
					priceData.salesKitPriceList.length === 0 &&
					priceData.salesKitSetPriceList.length === 0
				) {
					showAlert({
						html: `<div class="mt-4 text-danger fw-bold">商品未設定符合條件的價格</div>`,
						htmlSvg: 'danger',
						size: 'medium',
						confirmButtonText: t('button.confirmButton'),
						showCloseButton: false,
						allowOutsideClick: true,
					});
					return;
				} else {
					handleAddCard(priceData);
				}
			});
	}

	//#endregion

	//#region Modal取消

	const modalCancel = () => {
		toggle(); //關
	};

	//#endregion

	//#region Modal2取消

	const modal2Cancel = () => {
		setModal2Data({
			salesKitSetList: [],
			salesKitSetItemList: [],
			salesKitPriceList: [],
			salesKitSetPriceList: [],
		});
		setOneCheck('');

		toggle2(); //關

		toggle(); //開
	};

	//#endregion

	//#region Modal1 排序

	let headTitleList1 = [
		t('lgsSalesOrder:dialogCol.salesKitClassCode'),
		t('lgsSalesOrder:dialogCol.salesKitCode'),
		t('lgsSalesOrder:dialogCol.name'),
	];
	let bodyTitleList1 = ['salesKitClassCode', 'salesKitCode', 'name01'];

	const [orderBy1, setOrderBy1] = useState('asc');
	const [UISort1, setUISort1] = useState('salesKitCode');
	const {
		items: items1,
		requestSort: requestSort1,
		sortConfig: sortConfig1,
	} = useSortableData(modalData, bodyTitleList1[0], 'ascending');

	const handleSortChange1 = (newTarget) => {
		const oldTarget = UISort1;
		setUISort1(newTarget);

		if (oldTarget === newTarget) {
			setOrderBy1(orderBy1 === 'desc' ? 'asc' : 'desc');
		} else {
			setOrderBy1('asc');
		}

		setModalData(items1);
	};

	//#endregion

	//#region Modal2 排序

	let headTitleList2 = [
		t('lgsSalesOrder:dialogCol.feature1'),
		t('lgsSalesOrder:dialogCol.feature2'),
		t('lgsSalesOrder:dialogCol.feature3'),
	];
	let bodyTitleList2 = ['spec1Name01', 'spec2Name01', 'spec3Name01'];

	const [orderBy2, setOrderBy2] = useState('asc');
	const [UISort2, setUISort2] = useState('spec1Name01');
	const {
		items: items2,
		requestSort: requestSort2,
		sortConfig: sortConfig2,
	} = useSortableData(
		modal2Data.salesKitSetList,
		bodyTitleList2[0],
		'ascending'
	);

	const handleSortChange2 = (newTarget) => {
		const oldTarget = UISort2;
		setUISort2(newTarget);

		if (oldTarget === newTarget) {
			setOrderBy2(orderBy2 === 'desc' ? 'asc' : 'desc');
		} else {
			setOrderBy2('asc');
		}

		setModal2Data({ ...modal2Data, salesKitSetList: items2 });
	};

	const [currentMouseGuid, setCurrentMouseGuid] = useState('');
	const handleMouseEnter = (guid) => {
		setCurrentMouseGuid(guid);
		const originList = modal2ChildrenOriginData.filter(
			(el) => el.kitSetGuid === guid
		);
		setModal2ChildrenData(originList);
	};
	const handleMouseLeave = () => {
		setCurrentMouseGuid('');
		setModal2ChildrenData('');
	};

	//#endregion

	//#region Modal2Children 排序

	let headTitleList2Children = [
		t('lgsSalesOrder:dialogCol.kitsSetItemName'),
		t('lgsSalesOrder:dialogCol.kitsSetQuantity'),
		t('lgsSalesOrder:dialogCol.kitsSetPercentage'),
	];
	let bodyTitleList2Children = ['materialName01', 'quantity', 'percentage'];

	const [orderBy2Children, setOrderBy2Children] = useState('asc');
	const [UISort2Children, setUISort2Children] = useState('materialName01');
	const {
		items: items2Children,
		requestSort: requestSort2Children,
		sortConfig: sortConfig2Children,
	} = useSortableData(
		modal2ChildrenData,
		bodyTitleList2Children[0],
		'ascending'
	);

	const handleSortChange2Children = (newTarget) => {
		const oldTarget = UISort2;
		setUISort2Children(newTarget);

		if (oldTarget === newTarget) {
			setOrderBy2Children(orderBy2Children === 'desc' ? 'asc' : 'desc');
		} else {
			setOrderBy2Children('asc');
		}

		setModal2ChildrenData(items2Children);
	};

	//#endregion

	return (
		<div>
			{/* 開關button */}
			<BtnNormal2
				className="btn-primary d-inline-block me-2 smallBtn"
				keyName={btnKeyName}
				word={btnWord}
				handleClick={(e) => clickCreateModal(e)}
			>
				<CreateIcon className="me-2" style={{ marginBottom: '2px' }} />
			</BtnNormal2>

			{/* Modal */}
			<Modal
				isOpen={modal}
				toggle={toggle}
				centered={true}
				style={{
					maxWidth: '824px',
					border: 'none',
				}}
			>
				<ModalHeader className="position-relative pt-4 pb-2 ps-4">
					<p
						style={{ fontSize: '20px', color: 'var(--primary)' }}
						className="fw-bold mb-1"
					>
						{btnWord}
					</p>
					<button
						style={{ top: '12px', right: '12px' }}
						type="button"
						className="close position-absolute btn"
						onClick={toggle}
					>
						<Icn_Cancel />
					</button>
				</ModalHeader>
				<ModalBody className="px-6 pt-6 pb-0">
					<form asp-action="Index" method="get">
						<div className="modal-body p-0">
							<div className="wrap mx-2">
								<div className="row">
									<div className="position-relative col-12 col-md-5 p-0 mb-3">
										<SearchModalInput
											type="text"
											name="searchModalInput"
											ref={searchModalInput}
											className="form-control searchModalInput"
											placeholder={t(
												'lgsSalesOrder:dialogCol.quickSearch'
											)}
										/>
										<ModalSearchBtn
											type="button"
											className="searchModalBtn position-absolute border-0 btn btn-primaryllt px-3 d-flex align-items-center"
											isModal1={true}
											onClick={() => searchModalBtn()}
										>
											<Icn_Search2 />
										</ModalSearchBtn>
										<a
											className="cancelSearchModalBtn position-absolute d-none"
											style={{
												top: '4px',
												right: '48px',
												cursor: 'pointer',
											}}
											onClick={() =>
												cancelSearchModalBtn()
											}
										>
											<Icn_InputYellowCancel />
										</a>
									</div>
								</div>

								<div
									style={{ height: '440px' }}
									className="row"
								>
									<div
										style={{
											height: '440px',
											overflowY: 'scroll',
										}}
										className="col-12 px-0 border border-primaryllt rounded-xl"
									>
										<table className="table tableModal">
											<ModalThead
												className="table-head position-sticky"
												style={{ top: '0' }}
												items={items1}
												requestSort={requestSort1}
												sortConfig={sortConfig1}
											>
												<tr>
													<ModalTh className="p-0">
														<ModalLabel className="m-0 w-100 text-center">
															<ModalInput
																className="totalCheckBtn d-none"
																type="checkbox"
															/>
															<span></span>
														</ModalLabel>
													</ModalTh>
													{headTitleList1?.map(
														(el, index) => {
															return (
																<th
																	key={
																		bodyTitleList1[
																			index
																		] +
																		index
																	}
																	onClick={() => {
																		requestSort1(
																			bodyTitleList1[
																				headTitleList1.indexOf(
																					el
																				)
																			]
																		);
																		handleSortChange1(
																			bodyTitleList1[
																				headTitleList1.indexOf(
																					el
																				)
																			]
																		);
																	}}
																>
																	{el}
																	{bodyTitleList1[
																		headTitleList1.indexOf(
																			el
																		)
																	] ===
																		UISort1 &&
																		(orderBy1 ===
																		'desc' ? (
																			<span className="ml-1">
																				▼
																			</span>
																		) : (
																			<span className="ml-1">
																				▲
																			</span>
																		))}
																</th>
															);
														}
													)}
												</tr>
											</ModalThead>
											<tbody>
												{items1.map((item) => {
													return (
														<tr
															key={
																item[
																	`${btnKeyName}Guid`
																]
															}
															className="modalTr list-item table-item tableData"
															onClick={(e) =>
																clickTableRow(
																	e,
																	item[
																		`${btnKeyName}Guid`
																	]
																)
															}
														>
															<td className="text-center p-0">
																<label className="m-0 w-100 px-2">
																	<input
																		type="checkbox"
																		id={
																			item[
																				`${btnKeyName}Guid`
																			]
																		}
																		value={
																			item[
																				`${btnKeyName}Guid`
																			]
																		}
																		className="mt-2"
																		checked={
																			oneCheck ===
																			item[
																				`${btnKeyName}Guid`
																			]
																		}
																		onChange={() => {}}
																	/>
																</label>
															</td>
															<td className="text-start">
																{btnKeyName ===
																'salesKit'
																	? item[
																			`${btnKeyName}ClassCode`
																	  ]
																	: item[
																			`${btnKeyName}No`
																	  ]}
															</td>
															<td className="text-start">
																{btnKeyName ===
																'salesKit'
																	? item[
																			`${btnKeyName}Code`
																	  ]
																	: item[
																			`${btnKeyName}No`
																	  ]}
															</td>
															<td className="text-start">
																{item[`name01`]}
															</td>
															{btnKeyName !==
																'salesKit' && (
																<td className="text-start">
																	{
																		item[
																			`${btnKeyName}ClassName`
																		]
																	}
																</td>
															)}
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<hr className="border-grey1 mt-5 mb-0" />
						<div className="modal-footer justify-content-center border-0 pt-4 pb-5">
							<button
								type="button"
								className="btn btn-primary mediumBtn mx-1"
								onClick={modalSave}
							>
								{t('button.confirmButton')}
							</button>
							<button
								type="button"
								className="btn btn-outline-primarylllt mediumBtn mx-1"
								onClick={modalCancel}
							>
								{t('button.cancelButton')}
							</button>
						</div>
					</form>
				</ModalBody>
			</Modal>

			{/* Modal2 */}
			<Modal
				isOpen={modal2}
				toggle={toggle2}
				centered={true}
				style={{
					maxWidth: '1024px',
					border: 'none',
				}}
			>
				<ModalHeader className="position-relative pt-4 pb-2 ps-4">
					<p
						style={{ fontSize: '20px', color: 'var(--primary)' }}
						className="fw-bold mb-1"
					>
						{btnWord2}
					</p>
					<button
						style={{ top: '12px', right: '12px' }}
						type="button"
						className="close position-absolute btn"
						onClick={toggle2}
					>
						<Icn_Cancel />
					</button>
				</ModalHeader>
				<ModalBody className="px-6 pt-6 pb-0">
					<form asp-action="Index" method="get">
						<div className="modal-body p-0">
							<div className="wrap mx-2">
								<div className="row">
									<div className="position-relative col-12 col-md-5 p-0 mb-3">
										<SearchModalInput
											type="text"
											name="searchModal2Input"
											ref={searchModal2Input}
											className="form-control searchModal2Input"
											placeholder={t(
												'lgsSalesOrder:dialogCol.quickSearch'
											)}
										/>
										<ModalSearchBtn
											type="button"
											className="searchModal2Btn position-absolute border-0 btn btn-primaryllt px-3 d-flex align-items-center"
											isModal1={false}
											onClick={() => searchModalBtn()}
										>
											<Icn_Search2 />
										</ModalSearchBtn>
										<a
											className="cancelSearchModalBtn position-absolute d-none"
											style={{
												top: '4px',
												right: '48px',
												cursor: 'pointer',
											}}
											onClick={() =>
												cancelSearchModalBtn()
											}
										>
											<Icn_InputYellowCancel />
										</a>
									</div>
								</div>

								<div
									style={{ height: '440px' }}
									className="row"
								>
									<div
										style={{
											height: '440px',
											overflowY: 'scroll',
										}}
										className="col-6 px-0 border border-primaryllt rounded-xl me-2"
									>
										<table className="table tableModal">
											<ModalThead
												className="table-head position-sticky"
												style={{ top: '0' }}
												items={items2}
												requestSort={requestSort2}
												sortConfig={sortConfig2}
											>
												<tr>
													<ModalTh className="p-0">
														<ModalLabel className="m-0 w-100 text-center">
															<ModalInput
																className="totalCheckBtn d-none"
																type="checkbox"
															/>
															<span></span>
														</ModalLabel>
													</ModalTh>
													{headTitleList2?.map(
														(el, index) => {
															return (
																<th
																	key={
																		bodyTitleList2[
																			index
																		] +
																		index
																	}
																	onClick={() => {
																		requestSort2(
																			bodyTitleList2[
																				headTitleList2.indexOf(
																					el
																				)
																			]
																		);
																		handleSortChange2(
																			bodyTitleList2[
																				headTitleList2.indexOf(
																					el
																				)
																			]
																		);
																	}}
																>
																	{el}
																	{bodyTitleList2[
																		headTitleList2.indexOf(
																			el
																		)
																	] ===
																		UISort2 &&
																		(orderBy2 ===
																		'desc' ? (
																			<span className="ml-1">
																				▼
																			</span>
																		) : (
																			<span className="ml-1">
																				▲
																			</span>
																		))}
																</th>
															);
														}
													)}
												</tr>
											</ModalThead>
											<tbody>
												{items2.map((item) => {
													return (
														<tr
															key={
																item[
																	`kitSetGuid`
																]
															}
															className="modalTr list-item table-item tableData"
															onClick={(e) =>
																clickTableRow(
																	e,
																	item[
																		`kitSetGuid`
																	]
																)
															}
															onMouseEnter={() =>
																handleMouseEnter(
																	item[
																		`kitSetGuid`
																	]
																)
															}
															onMouseLeave={() =>
																handleMouseLeave()
															}
														>
															<td
																className="text-center p-0"
																style={{
																	backgroundColor:
																		currentMouseGuid ===
																		item[
																			`kitSetGuid`
																		]
																			? 'var(--primaryllt)'
																			: 'transparent',
																}}
															>
																<label className="m-0 w-100 px-2">
																	<input
																		type="checkbox"
																		id={
																			item[
																				`kitSetGuid`
																			]
																		}
																		value={
																			item[
																				`kitSetGuid`
																			]
																		}
																		checked={
																			oneCheck ===
																			item[
																				`kitSetGuid`
																			]
																		}
																		className="mt-2"
																	/>
																</label>
															</td>
															<td
																className="text-start"
																style={{
																	backgroundColor:
																		currentMouseGuid ===
																		item[
																			`kitSetGuid`
																		]
																			? 'var(--primaryllt)'
																			: 'transparent',
																}}
															>
																{
																	item[
																		`spec1Name01`
																	]
																}
															</td>
															<td
																className="text-start"
																style={{
																	backgroundColor:
																		currentMouseGuid ===
																		item[
																			`kitSetGuid`
																		]
																			? 'var(--primaryllt)'
																			: 'transparent',
																}}
															>
																{
																	item[
																		`spec2Name01`
																	]
																}
															</td>
															<td
																className="text-start"
																style={{
																	backgroundColor:
																		currentMouseGuid ===
																		item[
																			`kitSetGuid`
																		]
																			? 'var(--primaryllt)'
																			: 'transparent',
																}}
															>
																{
																	item[
																		`spec3Name01`
																	]
																}
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>

									<div
										style={{
											height: '440px',
											overflowY: 'scroll',
										}}
										className="col px-0 border border-primaryllt rounded-xl ps-3 ms-2"
									>
										<table className="table tableModal">
											<ModalThead
												className="table-head position-sticky"
												style={{ top: '0' }}
												items={items2Children}
												requestSort={
													requestSort2Children
												}
												sortConfig={sortConfig2Children}
											>
												<tr>
													{headTitleList2Children?.map(
														(el, index) => {
															return (
																<th
																	key={
																		bodyTitleList2[
																			index
																		] +
																		index
																	}
																	style={{
																		width:
																			index ===
																			0
																				? '250px'
																				: '',
																	}}
																	// onClick={() => {
																	// 	requestSort2Children(
																	// 		bodyTitleList2Children[
																	// 			headTitleList2Children.indexOf(
																	// 				el
																	// 			)
																	// 		]
																	// 	);
																	// 	handleSortChange2Children(
																	// 		bodyTitleList2Children[
																	// 			headTitleList2Children.indexOf(
																	// 				el
																	// 			)
																	// 		]
																	// 	);
																	// }}
																>
																	{el}
																	{/* {bodyTitleList2Children[
																		headTitleList2Children.indexOf(
																			el
																		)
																	] ===
																		UISort2Children &&
																		(orderBy2Children ===
																		'desc' ? (
																			<span className="ml-1">
																				▼
																			</span>
																		) : (
																			<span className="ml-1">
																				▲
																			</span>
																		))} */}
																</th>
															);
														}
													)}
												</tr>
											</ModalThead>
											<tbody>
												{items2Children.map((item) => {
													return (
														<tr
															key={
																item[`itemGuid`]
															}
															className="modalTr list-item table-item tableData"
															style={{
																cursor: 'default',
															}}
														>
															{/* 物料或服務 */}
															<td className="text-start">
																{item[
																	`materialName01`
																] ||
																	item[
																		'serviceName01'
																	]}
															</td>
															<td className="text-start">
																{
																	item[
																		`quantity`
																	]
																}
															</td>
															<td className="text-start">
																{
																	item[
																		`percentage`
																	]
																}
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<hr className="border-grey1 mt-5 mb-0" />
						<div className="modal-footer justify-content-center border-0 pt-4 pb-5">
							<button
								type="button"
								className="btn btn-primary mediumBtn mx-1"
								onClick={modal2Save}
							>
								{t('button.confirmButton')}
							</button>
							<button
								type="button"
								className="btn btn-outline-primarylllt mediumBtn mx-1"
								onClick={modal2Cancel}
							>
								{t('button.cancelButton')}
							</button>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</div>
	);
};
