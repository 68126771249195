import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../../components/Buttons/NextBtnSmall';
import { BtnNormal } from '../../../../../../components/Buttons/BtnNormal';
import InvoiceTable from './InvoiceTable';
import { useTranslation } from 'react-i18next';
import { DialogWarning } from '../DialogWarning';
import { DialogConfirm } from './DialogConfirm';

// #region styled-components

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center; /* 垂直方向置中 */
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
    margin: 0 auto; /* 將左右的 margin 設為 auto */
`;

const Right = styled.div`
    display: flex;
`;

//#endregion

function Page2(props) {
    // props
    const {
        parentData,
        setParentData,
        page,
        setPage,
        processSteps,
        handlePrevPage,
        handleNextPage,
        warningMessage,
        t,
    } = props;

    // state
    const [pageData, setPageData] = useState(parentData);

    console.log('parentData', parentData);
    console.log('pageData', pageData);
    const { sendRequest: axiosDataPage2 } = useAxios();

    const fakeData = [2, 2];
    const { decimalPlaceTl, decimalPlaceUp } = fakeData;
    const [tableBodyData, setTableBodyData] = useState([]);
    const [salesOrderKitList, setSalesOrderKitList] = useState([]);

    useEffect(() => {
        if (
            parentData &&
            parentData.updateData &&
            parentData.page1.salesOrderKitItemList
        ) {
            setTableBodyData(parentData.updateData.salesOrderKitItemList);
        }

        if (
            parentData &&
            parentData.updateData &&
            parentData.page1.salesOrderKitList
        ) {
            setSalesOrderKitList(parentData.updateData.salesOrderKitList);
        }
    }, [parentData]);

    //#endregion

    //#region variable initiation

    // table header 的名稱
    const headTitleList = [
        '#',
        '型態',
        '銷售內容',
        '銷售數量',
        '未理數量',
        '理貨數量',
        t('lgsSalesOrderPreparing:materialCube'),
        t('lgsSalesOrderPreparing:batchNo'),
        t('lgsSalesOrderPreparing:note'),
    ];

    // table body 的資料名稱
    const bodyTitleList = [
        'displayOrder',
        'salesCategoryCode',
        'salesCategoryontent',
        'quantitySale',
        'quantityUnpack',
        'newQuantityPack',
        'materialCube',
        'batchNo',
        'note',
    ];

    // 不同 input 的 type
    const inputProperties = [
        {},
        {},
        {},
        {},
        {},
        { type: 'number' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
    ];

    // table header 的 style
    const tableHeadStyles = [
        {
            width: '56px',
            textAlign: 'center',
        },
        {
            width: '80px',
            textAlign: 'center',
        },
        {
            width: '248px',
            textAlign: 'center',
        },
        {
            width: '80px',
            textAlign: 'center',
        },
        {
            width: '112px',
            textAlign: 'center',
        },
        {
            width: '136px',
            textAlign: 'center',
        },
        {
            width: '160px',
            textAlign: 'center',
        },
        {
            width: '160px',
            textAlign: 'center',
        },
        {
            width: '160px',
            textAlign: 'center',
        },
    ];

    // table body 的 style
    const tableBodyStyles = [
        {
            width: '56px',
            textAlign: 'center',
        },
        {
            width: '80px',
            textAlign: 'left',
        },
        {
            width: '248px',
            textAlign: 'left',
        },
        {
            width: '80px',
            textAlign: 'left',
        },
        {
            width: '112px',
            textAlign: 'right',
        },
        {
            width: '136px',
            textAlign: 'right',
        },
        {
            width: '160px',
            textAlign: 'left',
        },
        {
            width: '160px',
            textAlign: 'left',
        },
        {
            width: '160px',
            textAlign: 'left',
        },
    ];

    //#endregion

    //#region function

    // 暫存改被修改的資料

    const { sendRequest: axiosPreparingSubmit } = useAxios();

    // 暫存被修改的資料
    const handleTemporaryStore = () => {
        const newParentData = { ...parentData };
        const newUpdateData = pageData?.updateData;

        newParentData.updateData = newUpdateData;
        // 標記是否曾拿取過資料
        newParentData.isEntered = true;

        setParentData(newParentData);
    };

    // 往下一頁之前的檢查
    const handleSubmit = (isDelivery) => {
        handleTemporaryStore(isDelivery);
        const newParentData = { ...parentData };
        console.log('updateData', newParentData.updateData);
        console.log('isDelivery, ', isDelivery);
        //console.log("isDelivery", isDelivery);

        // 根據 isDelivery 設置 status
        const status = isDelivery ? '1' : '0';

        const salesOrderShipment = {
            //documentNo: 123, //出貨/退貨單編號，不用給
            status: status, //理貨/出貨狀態。儲存(暫不出貨) status=0，儲存(可出貨) status=1
            packGuid: newParentData.updateData.packGuid, //理貨/退回驗貨的員工Guid
            packTime: newParentData.updateData.packTime, //理貨/退回驗貨的時間
            note: newParentData.updateData.note, //註記
            // deliveryMethod,
            // logisticCompanyGuid,
            // logisticTrackingNo,
            // logisticShippedAt,
            // logisticReceivedAt,
        };

        const {
            action = 'UPDATE',
            shipmentGuid,
            packTime,
            packGuid,
            outBoundDate,
            outboundStaffGuid,
            note,
            accountDocumentGuid,
            deliveryMethod,
            logisticCompanyGuid,
            logisticTrackingNo,
            logisticShippedAt,
            logisticReceivedAt,
            rowStamp,
        } = newParentData.updateData;

        const salesOrderShipmentItemList =
            newParentData.updateData.salesOrderShipmentItemList.map((item) => {
                console.log(item)
                return {
                    ...item,
                    action: 'UPDATE',
                };
            });

        axiosPreparingSubmit(
            {
                url: `api/Logistics/LgsSalesOrderPreparing/update`,
                method: 'POST',
                data: {
                    salesOrderShipment: {
                        action,
                        shipmentGuid,
                        packTime,
                        packGuid,
                        outBoundDate,
                        outboundStaffGuid,
                        note,
                        accountDocumentGuid,
                        deliveryMethod,
                        logisticCompanyGuid,
                        logisticTrackingNo,
                        logisticShippedAt,
                        logisticReceivedAt,
                        status,
                        rowStamp,
                    },
                    salesOrderShipmentItemList: salesOrderShipmentItemList,
                },
            },
            async (res) => {
                console.log(res);
                const newParentData = {
                    ...salesOrderShipment,
                    ...parentData,
                    res,
                };

                setParentData(newParentData);
                console.log(newParentData);

                handleNextPage();
            }
        );
    };

    const [isWarningOpen, setIsWarningOpen] = useState(false);

    // DialogWarning Modal open
    const handleDialogWarning = () => {
        setIsWarningOpen(true);
    };

    useEffect(() => {
        // parents -> page1

        setTableBodyData(parentData.updateData.salesOrderKitItemList);
    }, []);

    //20240108
    const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');
    warningMessage;
    const handleCancel = () => {};
    // DialogConfirm Modal open

    //  返回btn確認dialog
    const [dialogConfirmData, setDialogConfirmData] = useState({
        btnName: '',
        isOpen: false,
        goTo: '',
        title: '',
    });

    /**
     * DialogConfirm 返回btn確認dialog
     **/
    const [isOpen, setIsOpen] = useState(false);
    // DialogConfirm Modal open
    const handleDialogConfirm = () => {
        setIsOpen(true);
    };

    return (
        <>
            {/* DialogWarning element */}

            <DialogConfirm isOpen={isOpen} setIsOpen={setIsOpen} backTo={-1} />

            <Container className="">
                <Left className="">
                    {page !== 0 && (
                        <BtnNormal
                            className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
                            key={'PrevBtn'}
                            onClick={() => {
                                handlePrevPage();
                                handleTemporaryStore();
                            }}
                        >
                            {t('common:button.prevStep')}
                        </BtnNormal>
                    )}
                </Left>
                <Center className="">
                    <StepByStepBar steps={processSteps} activePage={page} />
                </Center>
                <Right className="">
                    <BtnNormal
                        className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
                        key={'UndoneBtn'}
                        onClick={(e) => {
                            e.preventDefault();
                            // setIsDelivery(false);
                            handleSubmit(false);
                        }}
                    >
                        {'儲存(暫不出貨)'}
                    </BtnNormal>

                    <BtnNormal
                        className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
                        key={'FinishedBtn'}
                        onClick={(e) => {
                            e.preventDefault();
                            // setIsDelivery(true);
                            handleSubmit(true);
                        }}
                    >
                        {'儲存(可出貨)'}
                    </BtnNormal>
                    <BtnNormal
                        className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
                        key={'CancelBtn'}
                        onClick={(e) => {
                            handleDialogConfirm();
                            // e.preventDefault();
                            // handleSubmit();
                        }}
                    >
                        {'取消'}
                    </BtnNormal>
                </Right>
            </Container>

            <div className="position-relative">
                <div className="bg-white rounded-xxl d-flex flex-column px-2 py-3 position-relative">
                    <InvoiceTable
                        parentData={parentData}
                        setParentData={setParentData}
                        pageData={pageData}
                        setPageData={setPageData}
                        headTitleList={headTitleList}
                        bodyTitleList={bodyTitleList}
                        tableHeadStyles={tableHeadStyles}
                        tableBodyStyles={tableBodyStyles}
                        decimalPlaceTl={decimalPlaceTl}
                        page={page}
                        setPage={setPage}
                        tableBodyData={
                            pageData?.updateData?.salesOrderShipmentItemList
                        }
                        salesOrderKitList={
                            pageData?.updateData?.salesOrderKitList
                        }
                        setTableBodyData={setTableBodyData}
                    />
                </div>
            </div>
        </>
    );
}

export default Page2;
