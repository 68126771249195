import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import ThreeColLayout from '../../../../../components/ThreeColLayout/ThreeColLayout';
import { DialogCol } from '../DialogCol';
import { ReactComponent as Icn_OpenModal } from '../../../../../assets/images/icn_OpenModal.svg';

//#region styled-components

const SelectElement = styled.select`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
`;

const TopTable = styled.table`
    font-size: 12px;
    color: #616e83;
    background-color: #f7f8fc;
    width: 100% !important;
    height: 128px !important;
    border: 1px solid var(--grey1) !important;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
`;

const TBody = styled.tbody``;

const TR = styled.tr`
    height: 32px;
    border: 2px solid var(--grey1) !important;
`;
const TH1 = styled.th`
    padding: 0px;
    height: 32px;
    width: 23% !important;
    text-align: center !important;
`;
const TH = styled.th`
    padding: 0px;
    height: 32px;
    width: 38.5% !important;
    text-align: center !important;
`;
const TD1 = styled.td`
    padding: 0px;
    height: 32px;
    width: 23% !important;
    border: 1px solid var(--grey1) !important;
    text-align: center !important;
`;
const TD = styled.td`
    padding-right: 8px;
    height: 32px;
    width: 38.5% !important;
    border: 1px solid var(--grey1) !important;

    text-align: right !important;
`;

const LabelNormal = styled.label`
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;

const InputElement = styled.input`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    &:disabled {
        background-color: #eff2f5;
    }
`;

const InputElement2 = styled.input`
    border: 1px solid var(--grey2);
    border-right: none;
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    &:disabled {
        background-color: #eff2f5;
    }
`;

const TextareaElement = styled.textarea`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
`;

// -----DialogCol------
const DialogWrapper = styled.div`
    position: relative;
    align-items: stretch;
    width: 100%;
    --bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
    display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
    flex: 1 1 auto;
    border: 1px solid var(--grey2);
    border-radius: 4px 0 0 4px;
    border-right: 0;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    pointer-events: none;
    background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 3px;
    right: 32px;
    width: 24px;
    text-align: center;
    display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
    cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 8px;
    cursor: pointer;
`; // dialog按鈕

//#endregion

const MiddleInfo = ({
    pageData,
    setPageData,
    page,
    DecimalPlaceTl,
    t,
    deliveryMethodList,
    pageAction,
}) => {
    const { preUpdateData } = pageData;

    const {
        accountDocumentGuid,
        customerGuid,
        customerName,
        deliveryMethod,
        documentNo,
        isReturn,
        logisticCompanyGuid,
        logisticReceivedAt,
        logisticShippedAt,
        logisticTrackingNo,
        note,
        orderDate,
        orderGuid,
        orderNo,
        outboundDate,
        outboundStaffCode,
        outboundStaffGuid,
        outboundStaffName,
        packGuid,
        packTime,
        parentDocumentGuid,
        shipmentGuid,
        status,
    } = preUpdateData;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleInputChange = (e) => {
        const newInvoice = { ...preUpdateData };
        newInvoice[e.target.name] = e.target.value;

console.log(newInvoice)

        setPageData({
            ...pageData,
            preUpdateData: newInvoice,
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-4 px-4 pr-xl-6">
                    <div className="row mb-3">
                        <div className="col-lg-12">
                            <div className="d-flex flex-column">
                                <LabelNormal>
                                    {t('page1.deliveryMethod')}
                                </LabelNormal>
                                <SelectElement
                                    id="deliveryMethod"
                                    name="deliveryMethod"
                                    value={deliveryMethod || 0}
                                    onChange={handleInputChange}
                                >
                                    {deliveryMethodList?.map(
                                        ({ key, value }) => (
                                            <option
                                                key={key}
                                                id={key}
                                                value={key}
                                            >
                                                {value}
                                            </option>
                                        )
                                    )}
                                </SelectElement>
                                <span className="text-danger ms-1 mt-1">
                                    {
                                        pageData?.preUpdateData
                                            ?.bankAccountGuidWarning
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 px-4 pr-xl-6"></div>
                <div className="col-lg-4 px-4 pr-xl-6">
                    <div className="row mb-3">
                        <div className="col-lg-12">
                            <div className="d-flex flex-column">
                                <LabelNormal>
                                    {t('page1.pickupTime')}
                                </LabelNormal>
                                <InputElement
                                    type="datetime-local"
                                    id="logisticShippedAt"
                                    name="logisticShippedAt"
                                    className="mb-3"
                                    disabled={page !== 1}
                                    value={logisticShippedAt || ''}
                                    onChange={handleInputChange}
                                    step="1"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 px-4 pr-xl-6">
                    <div className="row mb-3">
                        <div className="col-lg-12">
                            <div className="d-flex flex-column">
                                <LabelNormal htmlFor={`staff`}>
                                    {t('page1.logisticsProvider')}
                                </LabelNormal>
                                <div
                                    className="input-group vendorGuidInput"
                                    id="currencyCode"
                                >
                                    <InputElement
                                        className="form-control border-right-0 item-view-VendorGuid border-grey2"
                                        onChange={() => {}}
                                        type="text"
                                        id="currencyCode"
                                        name="currencyCode"
                                        style={{
                                            cursor: 'pointer',
                                            pointerEvents: 'none',
                                            borderRight: 0,
                                        }}
                                        disabled
                                        value=""
                                    />
                                    <DialogBtn
                                        style={{
                                            backgroundColor: '#eff2f5',
                                            cursor: 'pointer',
                                            pointerEvents: 'none',
                                        }}
                                        onClick={() => {
                                            // handleCurrencyShow();
                                        }}
                                    >
                                        <Icn_OpenModal />
                                    </DialogBtn>
                                </div>

                                {/* DialogCol element */}
                                {/* <DialogCol
                                    isOpen={isModalOpen}
                                    setIsOpen={setIsModalOpen}
                                    modalData={employeeList}
                                    // setModalData={setModalData}
                                    btnKeyName={'employee'}
                                    pageData={pageData}
                                    setPageData={setPageData}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 px-4 pr-xl-6">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex flex-column">
                                <LabelNormal>
                                    {t('page1.trackingNumber')}
                                </LabelNormal>
                                <InputElement
                                    type="text"
                                    id="logisticTrackingNo"
                                    name="logisticTrackingNo"
                                    className="mb-3"
                                    value={logisticTrackingNo || ''}
                                    onChange={handleInputChange}
                                    disabled={page !== 1}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 px-4 pr-xl-6">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex flex-column">
                                <LabelNormal>
                                    {t('page1.deliveryTime')}
                                </LabelNormal>
                                <InputElement
                                    type="datetime-local"
                                    id="logisticReceivedAt"
                                    name="logisticReceivedAt"
                                    className="mb-3"
                                    disabled={page !== 1}
                                    value={logisticReceivedAt || ''}
                                    onChange={handleInputChange}
                                    step="1"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MiddleInfo;
