import { useState, useEffect } from 'react';
import { Table } from '../../../../../../components/exports';
import { useTranslation } from 'react-i18next';

function LgsSalesOrder_PaymentTerm({ detailsInfo }) {
	const { t } = useTranslation(['common', 'lgsSalesOrder']);
	const [tableData, setTableData] = useState([]);
	const [tableFooter, setTableFooter] = useState([]);
	const { foreignCurrency } = detailsInfo.salesOrder;

	const headTitleList = [
		'#',
		t('lgsSalesOrder:paymentDateBasis'),
		t('lgsSalesOrder:paymentDateDays'),
		t('lgsSalesOrder:paymentDate'),
		t('lgsSalesOrder:paymentAmount'),
		t('lgsSalesOrder:paymentPercentage'),
		t('lgsSalesOrder:annotation'),
	];

	const bodyTitleList = [
		'displayOrder',
		'baseOn',
		'days',
		'payDate',
		foreignCurrency ? 'foreignAmount' : 'amount',
		'percentage',
		'note',
	];

	const tableHeadStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
	];

	const tableBodyStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'right',
		},
		{
			width: '200px',
			textAlign: 'right',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
	];

	const baseOnObj = {
		0: t('lgsSalesOrder:duePaymentDate'),
		1: t('lgsSalesOrder:purchaseDate'),
		2: t('lgsSalesOrder:deliveryDate2'),
		3: t('lgsSalesOrder:receiptDate'),
		4: t('lgsSalesOrder:inspectionDate'),
	};

	//#endregion

	useEffect(() => {
		detailsInfo.salesOrderPaymentTermList?.forEach((el) => {
			el.baseOn = baseOnObj[el.baseOn] ? baseOnObj[el.baseOn] : el.baseOn;
			el.payDate = el.payDate.split('T')[0];
		});

		const totalAmount = detailsInfo.salesOrderPaymentTermList?.reduce(
			(acc, cur) => acc + (cur.amount || cur.foreignAmount),
			0
		);

		const totalPercentage = detailsInfo.salesOrderPaymentTermList?.reduce(
			(acc, cur) => acc + (cur.percentage || cur.foreignPercentage),
			0
		);

		setTableData(detailsInfo.salesOrderPaymentTermList);
		setTableFooter([
			'',
			'',
			'',
			t('lgsSalesOrder:totalAmount'),
			totalAmount,
			totalPercentage,
			'',
		]);
	}, []);

	return (
		<>
			{tableData && (
				<Table
					guidName="paymentTermGuid"
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					tableData={tableData}
					tableFooter={tableFooter}
				></Table>
			)}
		</>
	);
}

export default LgsSalesOrder_PaymentTerm;
