import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icn_chevronCircleDown } from '../../../../../../assets/images/icn_chevronCircle-down.svg';
import { ReactComponent as Icn_chevronTop } from '../../../../../../assets/images/icn_chevron-top.svg';
import axios from 'axios';
//#region styled-components

// 摺疊父子元件
const ToggleBtn = styled.h6`
	color: var(--primary);
	font-size: 14px;
	margin-top: 16px;
	margin-bottom: 0;
	cursor: pointer;
`;

const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const TR = styled.tr`
	height: 40px;

	&:hover {
		background-color: transparent !important;
	}
	&:hover:nth-of-type(0) {
		background-color: var(--primaryllt) !important;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: none !important;
	background-color: ${(prop) =>
		prop.grandPaOutside ? '#EEF4FD !important' : '#f7f8fc !important'};
	text-align: left !important;
	font-weight: bold !important;

	&:nth-of-type(1) {
		width: 48px;
	}

	&:nth-of-type(2) {
		/* width: 96px; */
	}

	&:nth-of-type(3) {
		/* width: 336px; */
		padding: 0 !important;
		/* padding-right: 56px !important; */
	}
	&:nth-of-type(6) {
		text-align: right !important;
	}
`;

const ChildTD = styled.td`
	height: 40px;
	text-align: center;

	&:first-of-type {
		width: 144px;
	}
`;

const OrderItemImg = styled.img`
	max-width: 96px;
	max-height: 88px;
	object-fit: cover;
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;

const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;
// #endregion

function LgsSalesOrder_DetailAndGoods(props) {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsSalesOrder']);

	//#endregion
	const [toggleBtnAllOpen, setToggleBtnAllOpen] = useState(true); // 展開/收合全部 btn
	const [newSalesOrderData, setNewSalesOrderData] = useState([]);
	const { foreignCurrency } = props.detailsInfo.salesOrder.foreignCurrency;

	console.log(newSalesOrderData);

	// page1
	const getNewSalesOrderData = async (salesOrderKitList) => {
		let newArray = [];
		const salesKitList = await axios
			.get(`api/Logistics/LgsSalesOrder/getCreateDatapage1`)
			.then((res) => res.data.salesKitList);

		console.log(salesKitList);

		salesOrderKitList.forEach(async (obj) => {
			console.log(obj);
			const { salesOrderKitItemList } = obj;

			console.log(salesOrderKitItemList);

			// 組合cardName;
			const salesKit = salesKitList.find((el) => {
				return el.salesKitGuid === obj.salesKitGuid;
			});
			const cardName = salesKit.salesKitCode + ' ' + salesKit.name01;

			if (salesOrderKitItemList.length) {
				const list = [];
				salesOrderKitItemList.forEach((item, index) => {
					const newCardData = {
						displayOrder: index + 1,
						cardName,
						name: cardName,
						cardChildName: item.materialName || item.serviceName,
						cardChildName2: '',
						salesCategoryCode: item.salesCategoryCode,

						salesKitGuid: obj.salesKitGuid,
						kitGuid: obj.kitGuid,
						kitSetGuid: obj.salesKitSetGuid,
						itemGuid: item.itemGuid,
						materialGuid: item.materialGuid,
						serviceGuid: item.serviceGuid,
						status: item.status,
						rowStamp: item.rowStamp,

						// accountGuid: item.accountGuid ?? '',
						netPrice: foreignCurrency
							? item.foreignNetPrice
							: item.netPrice,
						netAmount: foreignCurrency
							? item.foreignNetAmount
							: item.netAmount,

						newQuantity: item.quantitySale, // 實際顯示的數量(跟著父層的數量變動)
						percentage: item.percentage, //
						deliverDate: item.deliverDate.split('T')[0],
						note: item.note ?? '',
						serviceAppendixUrl: item.serviceAppendixUrl ?? '',
						materialAppendixUrl: item.materialAppendixUrl ?? '',
					};

					console.log(newCardData);
					list.push(newCardData);
				});

				const specSetName =
					obj.spec1Name01 +
					' / ' +
					obj.spec2Name01 +
					' / ' +
					obj.spec3Name01;

				newArray.push({
					kitSetMainInfo: {
						action: 'origin',
						specSetName,
						quantity: obj.quantitySales,
						newUnitPrice: foreignCurrency
							? obj.foreignNetAmount
							: obj.netAmount,
						salesKitGuid: obj.salesKitGuid,
						deliverDateKitSet: obj.deliverDate.split('T')[0],
						kitGuid: obj.kitGuid,
						kitSetGuid: obj.salesKitSetGuid,
						status: obj.status,
						rowStamp: obj.rowStamp,
					},
					kitSetItemInfoList: list,
				});

				console.log(newArray);
			}
		});

		console.log(newArray);
		return newArray;
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await getNewSalesOrderData(
					props.detailsInfo.salesOrderKitList
				);
				// console.log(data);

				setNewSalesOrderData([...data]);
			} catch (error) {
				console.error('獲取數據時出錯：', error);
			}
		};

		fetchData();
	}, [props.detailsInfo.salesOrderKitList]);

	const handleGrandPaArrowBtn = () => {
		const trElements = event.target
			.closest('tbody')
			.querySelectorAll('tr:not(.list-item)');

		// console.log(trElements);
		// console.log(event.target.closest('svg').style.transform);

		// 將找到的 tr 元素添加 'd-none' 的 className
		trElements.forEach((tr) => {
			if (event.target.closest('svg')) {
				event.target.closest('svg').style.transform === ''
					? tr.classList.remove('d-none')
					: tr.classList.add('d-none');
			}
		});
	};

	let cardDisplayNum = 0;

	console.log(newSalesOrderData);

	return (
		<div id="indexView" className="px-4 py-3">
			{
				<>
					{/* button row */}
					<div className="position-relative d-flex mb-3">
						{/* 展開/收折全部 BTN */}
						<div className="ms-auto">
							{toggleBtnAllOpen ? (
								<ToggleBtn
									onClick={() => {
										// 箭頭btn向下
										document
											.querySelectorAll('.iconChevron')
											.forEach(
												(el) =>
													(el.style.transform =
														'rotate(0.5turn)')
											);

										// 隱藏全部子層
										document
											.querySelectorAll('.childInfoTr')
											.forEach((item) =>
												item.classList.add('d-none')
											);
										setToggleBtnAllOpen(false);
									}}
								>
									{t('lgsSalesOrder:foldAll')}
									{/* 箭頭btn向上 */}
									<Icn_chevronCircleDown
										className="ms-2 mb-1"
										style={{
											transform: 'rotate(0.5turn)',
										}}
									/>
								</ToggleBtn>
							) : (
								<ToggleBtn
									onClick={() => {
										// 箭頭btn向上
										document
											.querySelectorAll('.iconChevron')
											.forEach(
												(el) =>
													(el.style.transform = '')
											);

										// 顯示子層
										document
											.querySelectorAll('.childInfoTr')
											.forEach((item) =>
												item.classList.remove('d-none')
											);
										setToggleBtnAllOpen(true);
									}}
								>
									{t('lgsSalesOrder:expandAll')}
									{/* 箭頭btn向下 */}
									<Icn_chevronCircleDown className="ms-2 mb-1" />
								</ToggleBtn>
							)}
						</div>
					</div>

					{/* card */}
					<div className="row">
						{newSalesOrderData?.map((allList, kitSetIndex) => {
							allList.kitSetMainInfo.action !== 'delete' &&
								cardDisplayNum++;

							return (
								<div
									className={`position-relative rounded-xxl ${
										kitSetIndex !== 0 ? 'mt-4' : ''
									} ${
										allList.kitSetMainInfo.action ===
											'delete' && 'd-none'
									}`}
									style={{
										backgroundColor: '#EEF4FD',
										border: '1px solid var(--grey1)',
									}}
									key={`"allList"${kitSetIndex}`}
								>
									<IndexTableContainer className="d-flex flex-column p-2 pb-4 position-relative">
										<IndexTable className="table SalesOrderCardWrapper">
											<TBody>
												{/* 阿公層 */}
												<TR className="list-item table-item">
													<TD grandPaOutside={true}>
														{cardDisplayNum}
													</TD>
													<TD
														grandPaOutside={true}
														className={`text-left p-2 text-center`}
														colSpan={2}
													>
														{
															allList
																.kitSetItemInfoList[0]
																.cardName
														}
													</TD>
													<TD
														grandPaOutside={true}
														className={`text-left p-2 text-center`}
													>
														<div
															className={`d-flex align-items-center justify-content-between`}
														>
															<div>
																{
																	allList
																		.kitSetMainInfo
																		.specSetName
																}
															</div>

															<div className="d-flex justify-content-end align-items-center">
																<div>
																	<LabelNormal
																		htmlFor={`deliverDateKitSet${kitSetIndex}`}
																		className={`me-2`}
																	>
																		{t(
																			'lgsSalesOrder:deliveryDate2'
																		)}
																	</LabelNormal>
																	<InputElement
																		style={{
																			maxWidth:
																				'115px',
																			maxHeight:
																				'32px',
																		}}
																		className="flex-fill"
																		type="date"
																		id={`deliverDateKitSet${kitSetIndex}`}
																		value={
																			allList
																				.kitSetMainInfo
																				.deliverDateKitSet
																		}
																		onChange={(
																			e
																		) => {}}
																		disabled
																	/>
																</div>
															</div>
														</div>
													</TD>

													<TD
														grandPaOutside={true}
														className={`px-0`}
													>
														<div className="text-end">
															<LabelNormal
																htmlFor={`kitSetQuantity${kitSetIndex}`}
																className="me-2"
															>
																{t(
																	'lgsSalesOrder:quantity'
																)}
															</LabelNormal>
															<InputElement
																style={{
																	maxWidth:
																		'60px',
																}}
																className="flex-fill"
																type="number"
																min="1"
																id={`kitSetQuantity${kitSetIndex}`}
																value={
																	allList
																		.kitSetMainInfo
																		.quantity
																}
																onChange={(
																	e
																) => {}}
																disabled
															/>
														</div>
													</TD>

													<TD
														grandPaOutside={true}
														className="d-flex align-item-center justify-content-end"
													>
														<div className="d-flex align-items-center ps-3 me-auto">
															<div>
																<LabelNormal
																	htmlFor={`netAmount${kitSetIndex}`}
																	className={`me-2`}
																>
																	{t(
																		'lgsSalesOrder:amountExcludingTax'
																	)}
																</LabelNormal>
																<InputElement
																	style={{
																		maxWidth:
																			'120px',
																		maxHeight:
																			'32px',
																	}}
																	className="flex-fill"
																	type="number"
																	id={`netAmount${kitSetIndex}`}
																	value={
																		allList
																			.kitSetMainInfo
																			.newUnitPrice
																	}
																	onChange={(
																		e
																	) => {}}
																	disabled
																/>
															</div>
														</div>

														{/* 展開按鈕 */}
														<div
															className={`d-inline-block text-left p-2 ps-0 text-center`}
															style={{
																cursor: 'pointer',
															}}
															onClick={(e) => {
																// icon箭頭方向
																if (
																	e.target.closest(
																		'svg'
																	)
																) {
																	e.target.closest(
																		'svg'
																	).style
																		.transform ===
																	''
																		? (e.target.closest(
																				'svg'
																		  ).style.transform =
																				'rotate(.5turn)')
																		: (e.target.closest(
																				'svg'
																		  ).style.transform =
																				'');
																}

																handleGrandPaArrowBtn();
															}}
														>
															<Icn_chevronTop
																className="iconChevron"
																style={{
																	transform:
																		kitSetIndex ===
																		0
																			? ''
																			: 'rotate(.5turn)',
																}}
															/>
														</div>
													</TD>
												</TR>

												{/* 品項 */}
												{allList?.kitSetItemInfoList?.map(
													(data, index) => {
														return (
															<React.Fragment
																key={`"salesOrderDataItem"${index}`}
															>
																{/* 父層 */}
																<TR className="list-item table-item">
																	<TD className="ps-4">
																		{index +
																			1}
																	</TD>
																	<TD
																		className={`text-left p-2 text-center`}
																	>
																		{/* 資料顯示轉換 -型態  */}
																		{data.salesCategoryCode ===
																		'P'
																			? t(
																					'lgsSalesOrder:material'
																			  )
																			: data.salesCategoryCode ===
																			  'S'
																			? t(
																					'lgsSalesOrder:service'
																			  )
																			: data.salesCategoryCode}
																	</TD>

																	<TD
																		className={`text-left p-2 text-center`}
																	>
																		{
																			data.cardChildName
																		}
																	</TD>

																	<TD
																		className={`px-0`}
																	>
																		<div className="d-flex">
																			<span className="flex-grow-1 collapsedItem">
																				{
																					data.quantitySales
																				}
																			</span>
																			<span className="flex-grow-1 collapsedItem">
																				{
																					data.uomName01
																				}
																			</span>
																		</div>
																	</TD>
																	<TD></TD>

																	<TD className="text-end pe-4">
																		<span className="me-5 collapsedItem">
																			{
																				data.netAmount
																			}
																		</span>

																		{/* 展開按鈕 */}
																		<div
																			className={`d-inline-block text-left p-2 text-center`}
																			style={{
																				cursor: 'pointer',
																			}}
																			onClick={(
																				e
																			) => {
																				// icon箭頭方向
																				if (
																					e.target.closest(
																						'svg'
																					)
																				) {
																					e.target.closest(
																						'svg'
																					)
																						.style
																						.transform ===
																					''
																						? (e.target.closest(
																								'svg'
																						  ).style.transform =
																								'rotate(.5turn)')
																						: (e.target.closest(
																								'svg'
																						  ).style.transform =
																								'');
																				}

																				// 是否顯示子層
																				e.target
																					.closest(
																						'tr'
																					)
																					.nextElementSibling.classList.contains(
																						'd-none'
																					)
																					? e.target
																							.closest(
																								'tr'
																							)
																							.nextElementSibling.classList.remove(
																								'd-none'
																							) &&
																					  alert(
																							1
																					  )
																					: e.target
																							.closest(
																								'tr'
																							)
																							.nextElementSibling.classList.add(
																								'd-none'
																							);

																				if (
																					e.target.closest(
																						'svg'
																					)
																				) {
																					e.target.closest(
																						'svg'
																					).style.transform =
																						'rotate("0.5turn")';
																				}
																			}}
																		>
																			<Icn_chevronTop
																				className="iconChevron"
																				style={{
																					transform:
																						index ===
																						0
																							? ''
																							: 'rotate(.5turn)',
																				}}
																			/>
																		</div>
																	</TD>
																</TR>

																{/* 子層 */}
																<tr
																	// 1.第一個預設開啟
																	// className={`${index !== 0 && 'd-none'} childInfoTr rounded-xxl`}
																	// 2.全部預設開啟
																	className={`childInfoTr rounded-xxl`}
																>
																	{data.length !==
																		0 && (
																		<>
																			<ChildTD
																				colSpan={
																					2
																				}
																				className="p-0 ps-3 border-0"
																				style={{
																					backgroundColor:
																						'#F7F8FC',
																				}}
																			>
																				<div
																					className="h-100 p-4"
																					style={{
																						backgroundColor:
																							'white',
																					}}
																				>
																					<OrderItemImg
																						className="rounded-xxl"
																						src={
																							location.origin +
																							(data.materialAppendixUrl ||
																								data.serviceAppendixUrl)
																						}
																						alt="OrderItemImg"
																					/>
																				</div>
																			</ChildTD>
																			<ChildTD className="ps-0 py-0 border-0">
																				<div className="py-4 text-align-left text-grey4">
																					<span>
																						{
																							data.cardChildName
																						}
																					</span>
																					<br />

																					{data?.cardChildName2 ? (
																						<span>
																							{
																								data?.cardChildName2
																							}
																						</span>
																					) : (
																						<span className="invisible">
																							123
																						</span>
																					)}
																				</div>
																				<div className="pt-2 pb-4 pe-8 text-align-left">
																					<div
																						className={`wrapper d-flex flex-column "mb-3`}
																					>
																						<TextareaElement
																							style={{
																								minHeight:
																									'0px',
																							}}
																							id={`note${index}`}
																							rows={
																								2
																							}
																							value={
																								data.note ||
																								''
																							}
																							onChange={(
																								e
																							) => {}}
																							disabled
																						/>
																					</div>
																				</div>
																			</ChildTD>

																			<ChildTD className="ps-0 pe-6 py-0 border-0">
																				<div className="pt-4 d-flex text-align-left text-grey4">
																					<div
																						className={`wrapper row flex-column mb-3 pe-5 flex-fill`}
																					>
																						<LabelNormal
																							htmlFor={`kitSetItemQuantity${index}`}
																						>
																							{t(
																								'lgsSalesOrder:salesQuantity'
																							)}
																						</LabelNormal>
																						<InputElement
																							style={{
																								minWidth:
																									'120px',
																							}}
																							className="flex-fill"
																							type="number"
																							min="1"
																							id={`kitSetItemQuantity${index}`}
																							disabled
																							value={
																								data.newQuantity
																							}
																							onChange={(
																								e
																							) => {}}
																						/>
																					</div>

																					<div
																						className={`wrapper d-flex flex-column mb-3 flex-fill`}
																					>
																						<LabelNormal
																							htmlFor={`deliverDate${index}`}
																						>
																							{t(
																								'lgsSalesOrder:deliveryDate'
																							)}
																						</LabelNormal>
																						<InputElement
																							style={{
																								minWidth:
																									'135px',
																							}}
																							className="flex-fill"
																							type="date"
																							id={`deliverDate${index}`}
																							value={
																								data.deliverDate
																							}
																							onChange={(
																								e
																							) => {}}
																							disabled
																						/>
																					</div>
																				</div>
																			</ChildTD>

																			<ChildTD
																				colSpan="2"
																				className="ps-0 pe-3 py-0 border-0"
																				style={{
																					backgroundColor:
																						'#F7F8FC',
																				}}
																			>
																				<div
																					className="h-100 pt-4 d-flex text-align-left text-grey4"
																					style={{
																						backgroundColor:
																							'white',
																					}}
																				>
																					<div
																						className={`d-flex flex-column pe-4 mb-3`}
																					>
																						<LabelNormal
																							htmlFor={`netPrice${index}`}
																						>
																							{t(
																								'lgsSalesOrder:unitPriceExcludingTax'
																							)}
																						</LabelNormal>
																						<InputElement
																							style={{
																								minWidth:
																									'120px',
																								maxHeight:
																									'32px',
																							}}
																							className="flex-fill"
																							type="number"
																							id={`netPrice${index}`}
																							disabled
																							value={
																								data.netPrice
																							}
																						/>
																					</div>

																					<div
																						className={`d-flex flex-column pe-3 mb-3`}
																					>
																						<LabelNormal
																							htmlFor={`netAmount${index}`}
																						>
																							{t(
																								'lgsSalesOrder:amountExcludingTax'
																							)}
																						</LabelNormal>
																						<InputElement
																							style={{
																								minWidth:
																									'120px',
																								maxHeight:
																									'32px',
																							}}
																							className="flex-fill"
																							type="number"
																							id={`netAmount${index}`}
																							disabled
																							value={
																								data.netAmount
																							}
																						/>
																					</div>
																				</div>
																			</ChildTD>
																		</>
																	)}
																</tr>
															</React.Fragment>
														);
													}
												)}
												<tr>
													<td
														colSpan={6}
														style={{
															backgroundColor:
																'#F7F8FC',
															height: '16px',
															border: 'none',
														}}
													></td>
												</tr>
											</TBody>
										</IndexTable>
									</IndexTableContainer>
								</div>
							);
						})}
					</div>
				</>
			}
		</div>
	);
}

export default LgsSalesOrder_DetailAndGoods;
