//<!-- Sector Modal -->
import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PaymentTermTable from './PaymentTermTable';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { PrevBtnSmall } from '../../../../../components/Buttons/PrevBtnSmall';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import { ReactComponent as Icn_chevronTop } from '../../../../../assets/images/icn_chevron-top.svg';
import { ReactComponent as Icn_Trash } from '../../../../../assets/images/icn_Trash.svg';
import { ReactComponent as Icn_Cancel } from '../../../../../assets/images/icn_Cancel.svg';
import { ReactComponent as Icn_Search2 } from '../../../../../assets/images/icn_Search2.svg';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { useLocation } from 'react-router-dom';
import useAxios from '../../../../../hooks/useAxios';
import Input from '../../../../../components/FormElement/Input';
import Select from '../../../../../components/FormElement/Select';
import Textarea from '../../../../../components/FormElement/Textarea';
import { ReactComponent as Icn_OpenModal } from '../../../../../assets/images/icn_OpenModal.svg';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';

// #region styled-components

const ModalThead = styled.thead`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalTh = styled.th`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
	border: none !important;
`;
const ModalLabel = styled.label`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalInput = styled.input`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
`;

const SearchModalInput = styled.input`
	height: 35px;
	font-size: 14px;
	padding: 0 0.75rem;
	cursor: pointer !important;
	text-transform: uppercase;
`;

const ModalSearchBtn = styled.button`
	top: 1px;
	right: 1px;
	height: 33px;
	border-radius: 0 0.25rem 0.25rem 0;
`;

//#endregion

function SectorModal(props) {
	const { t } = useTranslation(['common', 'lgsSalesOrder']);

	const { modalOpen, handleClose, sectorList, data, setData } = props;
	const [oneCheck, setOneCheck] = useState('');
	const searchModalInput = useRef('');
	const [selectedData, setSelectedData] = useState(null);
	// console.log(props);

	const headTitleList = [
		'',
		t('lgsSalesOrder:dialogCol.number'),
		t('lgsSalesOrder:dialogCol.name'),
	];

	const tableHeadStyles = [
		{ width: '48px', textAlign: 'center' },
		{ width: '200px', textAlign: 'left' },
		{ width: '280px', textAlign: 'left' },
	];

	const bodyTitleList = ['number', 'name', 'col3', 'col4'];

	const tableBodyStyles = [
		{ width: '48px', textAlign: 'center' },
		{ width: '200px', textAlign: 'left' },
		{ width: '280px', textAlign: 'left' },
	];

	/**
	 *搜尋Modal
	 **/
	const searchModalBtn = () => {
		//取得搜尋文字
		let searchModalInputText = searchModalInput.current.value.toUpperCase();

		let modalTrList = document.querySelectorAll('.modalTr');

		if (searchModalInputText !== '') {
			//隱藏全部欄位
			modalTrList.forEach((el) => {
				el.classList.add('d-none');
			});

			// 搜尋每個Tr中的每個Td欄位
			modalTrList.forEach((el) => {
				// console.log(el.children);

				[...el.children].forEach((td) => {
					if (
						td.textContent
							.toUpperCase()
							.includes(searchModalInputText)
					) {
						td.parentElement.classList.remove('d-none');
					}
				});
			});

			//顯示取消搜尋btn
			document
				.querySelector('.cancelSearchModalBtn')
				.classList.remove('d-none');
		}
	};

	/**
	 *取消搜尋
	 **/
	const cancelSearchModalBtn = (i) => {
		// 顯示全部欄位
		document.querySelectorAll('.modalTr').forEach((el) => {
			el.classList.remove('d-none');
		});

		// 清空搜尋欄位
		searchModalInput.current.value = '';

		// 隱藏取消搜尋btn
		document.querySelector('.cancelSearchModalBtn').classList.add('d-none');
	};

	/**
	 * clickTableRow
	 **/
	function clickTableRow(el) {
		console.log(el);
		// Get the guid to decide which one is selected
		const targetGuid = el.sectorGuid;
		setOneCheck(targetGuid);

		// Get the info for display (code & name)
		const sectorCode = el.sectorCode;
		const sectorName = el.name01;
		const sectorGuid = el.sectorGuid;

		setSelectedData({
			sectorCode,
			sectorName,
			sectorGuid,
		});
	}

	const handleSubmit = () => {
		const { sectorCode, sectorName, sectorGuid } = selectedData;
		setData({
			...data,
			sectorName,
			sectorGuid,
		});
	};

	return (
		<div>
			<Modal
				isOpen={modalOpen}
				//onHide={handleClose}
				centered={true}
				{...SectorModal}
				style={{ maxWidth: '624px' }}
			>
				<ModalHeader className="position-relative pt-4 pb-2 ps-4">
					<p
						style={{ fontSize: '20px' }}
						className="text-primary fw-bold mb-1"
					>
						{t('lgsSalesOrder:salesDepartment')}
					</p>
					<button
						style={{ top: '12px', right: '12px' }}
						type="button"
						className="close position-absolute btn"
						onClick={handleClose}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							id="icn_Clear"
							viewBox="0 0 16 16"
							width="16"
							height="16"
						>
							<defs>
								<clipPath id="clip-path-clear22">
									<rect
										id="Rectangle_3088"
										fill="#686868"
										width="10"
										height="10"
										data-name="Rectangle 3088"
									/>
								</clipPath>
							</defs>
							<rect
								id="Rectangle_3086"
								fill="none"
								width="16"
								height="16"
								data-name="Rectangle 3086"
							/>
							<g
								id="Group_6190"
								transform="translate(3 3)"
								data-name="Group 6190"
							>
								<g
									id="Group_6188"
									clipPath='url("#clip-path")'
									data-name="Group 6188"
								>
									<path
										id="Path_1410"
										fill="#686868"
										transform="translate(0)"
										d="M 1.134 0.188 a 0.669 0.669 0 0 0 -0.946 0.946 L 4.054 5 L 0.188 8.866 a 0.669 0.669 0 0 0 0.946 0.946 L 5 5.946 L 8.866 9.812 a 0.669 0.669 0 0 0 0.946 -0.946 L 5.946 5 L 9.812 1.134 A 0.669 0.669 0 0 0 8.866 0.188 L 5 4.054 Z"
										data-name="Path 1410"
									/>
								</g>
							</g>
						</svg>
					</button>
				</ModalHeader>
				<ModalBody className="px-6 pt-6 pb-0">
					<form asp-action="Index" method="get">
						<div className="modal-body p-0">
							<div className="wrap mx-3">
								<div className="row">
									<div className="position-relative col-12 col-md-5 p-0 mb-3">
										<SearchModalInput
											type="text"
											name="searchModalInput"
											ref={searchModalInput}
											className="form-control searchModalInput"
											placeholder={t(
												'lgsSalesOrder:dialogCol.quickSearch'
											)}
										/>
										<ModalSearchBtn
											type="button"
											className="searchModalBtn position-absolute border-0 btn btn-primaryllt px-3 d-flex align-items-center"
											onClick={() => searchModalBtn()}
										>
											<Icn_Search2 />
										</ModalSearchBtn>
										<a
											className="cancelSearchModalBtn position-absolute d-none"
											style={{
												top: '5px',
												right: '-22px',
												cursor: 'pointer',
											}}
											onClick={() =>
												cancelSearchModalBtn()
											}
										>
											<Icn_InputYellowCancel />
										</a>
									</div>
								</div>

								<div
									style={{ height: '440px' }}
									className="row"
								>
									<div
										style={{
											height: '440px',
											overflowY: 'scroll',
										}}
										className="col-12 px-0 border border-primaryllt rounded-xl"
									>
										<table className="table tableModal">
											<ModalThead
												className="table-head position-sticky"
												style={{ top: '0' }}
											>
												<tr>
													{headTitleList.map((el) => (
														<ModalTh
															className="p-0"
															key={`tableHead${headTitleList.indexOf(
																el
															)}`}
															style={
																tableHeadStyles[
																	headTitleList.indexOf(
																		el
																	)
																]
															}
														>
															<ModalLabel className="m-0 w-100 text-start">
																<ModalInput
																	className="totalCheckBtn d-none"
																	type="checkbox"
																/>
																{el}
															</ModalLabel>
														</ModalTh>
													))}
												</tr>
											</ModalThead>

											<tbody>
												{sectorList.map((el) => {
													return (
														<tr
															key={el.sectorGuid}
															className="modalTr list-item table-item tableData"
															data-guid={
																el.sectorGuid
															}
															data-personnelguid={
																el.sectorGuid
															}
															data-personnelcode={
																el.sectorCode
															}
															data-personnelname={
																el.name01
															}
															onClick={() =>
																clickTableRow(
																	el
																)
															}
														>
															<td className="text-center p-0">
																<label className="m-0 w-100 px-2">
																	<input
																		className="mt-2"
																		type="checkbox"
																		name="plantRadio"
																		id={
																			el.sectorGuid
																		}
																		checked={
																			oneCheck ===
																			el.sectorGuid
																		}
																		value={
																			el.sectorGuid
																		}
																		onChange={() => {}}
																	/>
																</label>
															</td>
															<td className="text-start userTableColumn">
																{el.sectorCode}
															</td>
															<td className="text-start userTableColumn">
																{el.name01}
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<hr className="border-grey1 mt-5 mb-0" />
						<div className="modal-footer justify-content-center border-0 pt-4 pb-5">
							<button
								type="button"
								className="btn btn-primary mediumBtn mx-1"
								onClick={() => {
									handleSubmit();
									handleClose();
								}}
							>
								{t('button.confirmButton')}
							</button>
							<button
								type="button"
								className="btn btn-outline-primarylllt mediumBtn mx-1"
								onClick={() => {
									handleClose();
								}}
								// onClick="cancelTableModal(this, 'personnel')"
							>
								{t('button.cancelButton')}
							</button>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default SectorModal;
