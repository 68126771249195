import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Page0Partial from './Page0Partial';
import AdvancedSearchModal from '../../../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../../../../components/Modals/AdvancedSearchResultTag';
import { useTranslation } from 'react-i18next';
import IndexTablePartial from '../../../../../components/Table/IndexTablePartial';

// #region styled-components

//#endregion
/**
 * Page0Table 組件，用於顯示銷售訂單支付相關頁面的表格內容。
 *
 * 主要功能：
 * 1. 顯示包含排序、分頁和行選擇功能的表格。
 * 2. 提供進階搜索功能，可根據條件篩選數據。
 * 3. 支援對表格列的排序操作。
 * 4. 提供多語言支持，使用 react-i18next 的 t 函數進行文本翻譯。
 * 5. 透過 react-query 處理數據的獲取和管理。
 *
 * @param {Object} props - 組件的屬性
 * @param {Function} props.setPurchaseOrderGuid - 更新訂單 GUID 的函數
 * @param {Object} props.table - 表格相關的配置和數據
 * @param {Array} props.singleChecked - 選中行的 GUID 列表
 * @param {Function} props.setSingleChecked - 更新選中行列表的函數
 * @param {boolean} props.advancedSearchShow - 進階搜索模態框的顯示狀態
 * @param {Function} props.setAdvancedSearchShow - 更新進階搜索模態框顯示狀態的函數
 * @param {Function} props.fetchData - 當需要額外數據時的請求函數
 * @param {Object} props.fetchedData - 從服務器獲取的數據
 * @param {Function} props.setFetchedData - 更新從服務器獲取的數據的函數
 * @param {string} props.sortBy - 當前排序的屬性名稱
 * @param {Function} props.setSortBy - 更新排序屬性的函數
 * @param {string} props.guidName - 行數據中作為唯一標識的屬性名稱
 * @param {Array} props.headTitleList - 表格頭部的標題列表
 * @param {Array} props.bodyTitleList - 表格每列對應的屬性名稱列表
 * @param {Object} props.tableStyles - 表格列的樣式
 * @param {Array} props.columnMustShow - 必須顯示的列
 * @param {Array} props.displayColumns - 當前顯示的列
 * @param {Function} props.setDisplayColumns - 更新顯示列的函數
 * @param {Object} props.paginationData - 分頁相關的數據對象
 * @param {Function} props.setPaginationData - 更新分頁數據的函數
 * @param {Array} props.searchContentList - 進階搜索的條件列表
 * @param {string} props.orderBy - 當前排序的方向（升序/降序）
 * @param {Function} props.setOrderBy - 更新排序方向的函數
 *
 * @returns {JSX.Element} Page0Table 組件的 JSX 元素
 */
function Page0Table({
    setPurchaseOrderGuid,
    table,
    singleChecked,
    setSingleChecked,
    advancedSearchShow,
    setAdvancedSearchShow,
    fetchData,
    fetchedData,
    setFetchedData,
    sortBy,
    setSortBy,
    guidName,
    headTitleList,
    bodyTitleList,
    tableStyles,
    columnMustShow,
    displayColumns,
    setDisplayColumns,
    paginationData,
    setPaginationData,
    searchContentList,
	orderBy, setOrderBy
}) {
    const { t, i18n } = useTranslation();

    // 進階搜尋 一進去的第一筆的模板
    const advancedSearchDataTemp = {
        searchLogic: 'AND',
        column: searchContentList[0].column,
        searchOperator: '==',
        searchValue: '',
        searchContent: '',
        inputWarningContent: '',
        selectOptionList: searchContentList[0].selectOptionList,
    };

    // 進階搜尋實際資料
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
    // 進階搜尋畫面資料
    const [displayList, setDisplayList] = useState([]);

    // Advanced Search

    //todo: default sort col => orderDate

    const handleOpenAdvancedSearch = () => {
        console.log('handleOpenAdvancedSearch');
    };

    const handleAdvancedSearchModalClose = () => setAdvancedSearchShow(false);
    const handleAdvancedSearchModalShow = () => {
        setAdvancedSearchShow(true);
    };

    const deleteAllFilters = () => {
        setDisplayList([]);
        setAdvancedSearchDataList([]);
        fetchData();
    };

    const deleteFilter = (targetIndex) => {
        console.log('deleteFilter index', targetIndex);
        console.log(advancedSearchDataList[targetIndex]);
        console.log(displayList[targetIndex]);

        const newAdvancedSearchDataList = advancedSearchDataList.filter(
            (_, index) => index !== targetIndex
        );
        const newDisplayList = displayList.filter(
            (_, index) => index !== targetIndex
        );
        setDisplayList(newDisplayList);
        setAdvancedSearchDataList(newAdvancedSearchDataList);
        fetchData(
            newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null
        );
    };

    // console.log(advancedSearchDataList);

    return (
        <>
            <AdvancedSearchResultTag
                advancedSearchDataList={advancedSearchDataList}
                deleteAllFilters={deleteAllFilters}
                deleteFilter={deleteFilter}
                ns="lgsSalesOrderPayment"
            />
            <div id="indexView">
                <div className="position-relative firstRowMinHeight bg-white d-flex flex-column p-4 position-relative overflow-auto">
                    {fetchedData?.indexData && (
                        <IndexTablePartial
                            t={t}
                            guidName={guidName}
                            headTitleList={headTitleList}
                            bodyTitleList={bodyTitleList}
                            tableData={fetchedData?.indexData}
                            indexData={fetchedData?.indexData}
                            sortBy={sortBy}
							setSortBy={setSortBy}
                            displayColumns={displayColumns}
                            setDisplayColumns={setDisplayColumns}
                            paginationData={paginationData}
                            setPaginationData={setPaginationData}
                            fetchData={fetchData}
                            fetchedData={fetchedData}
                            tableStyles={tableStyles}
                            columnMustShow={columnMustShow}
                            rowClick={true}
                            advancedSearchDataList={advancedSearchDataList}
                            clickSelection={1}
                            setPurchaseOrderGuid={setPurchaseOrderGuid}
                            table={table}
                            singleChecked={singleChecked}
                            setSingleChecked={setSingleChecked}
							orderBy={orderBy}
							setOrderBy={setOrderBy}
                        />
                    )}
                </div>
            </div>

            {advancedSearchShow && (
                <AdvancedSearchModal
                    isOpen={advancedSearchShow}
                    handleClose={handleAdvancedSearchModalClose}
                    headTitleList={headTitleList}
                    bodyTitleList={bodyTitleList}
                    advancedSearchDataList={advancedSearchDataList}
                    setAdvancedSearchDataList={setAdvancedSearchDataList}
                    advancedSearchDataTemp={advancedSearchDataTemp}
                    fetchData={fetchData}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    setFetchedData={setFetchedData}
                    displayList={
                        displayList.length > 0
                            ? displayList
                            : [{ ...advancedSearchDataTemp }]
                    }
                    setDisplayList={setDisplayList}
                    searchContentList={searchContentList}
					orderBy={orderBy}
					setOrderBy={setOrderBy}
                    ns="lgsSalesOrder"
                />
            )}
        </>
    );
}

export default Page0Table;
