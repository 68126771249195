import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import useAxios from '../../../../hooks/useAxios';
import {
	BrowserRouter as Router,
	useParams,
	useNavigate,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	PageTitleBar,
	TabPage,
	LgsSalesOrder_TopCommonInfo,
	LgsSalesOrder_BaseInfo,
	LgsSalesOrder_DetailAndGoods,
	LgsSalesOrder_PaymentTerm,
	LgsSalesOrder_GoodsHistory,
	LgsSalesOrder_ReturnGoodsHistory,
	LgsSalesOrder_InvoiceHistory,
	LgsSalesOrder_PaymentHistory,
	LgsSalesOrder_HistoryOverview,
} from '../exports';

import { CopyCreateBtn } from '../../../../components/Buttons/CopyCreateBtn';
import { EditBtnNormal } from '../../../../components/Buttons/EditBtnNormal';

import StatusBar from '../../../../components/StatusBar/StatusBar';
import { useTranslation } from 'react-i18next';
import { PrintBtnNormal } from '../../../../components/Buttons/PrintBtnNormal';
import { useCookies } from 'react-cookie';

// #region styled-components

//遮罩層的樣式
const Overlay = styled.div`
	display: ${(props) => (props.isLoading ? 'block' : 'none')};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7); /* 半透明黑色背景 */
	color: white;
	text-align: center;
	z-index: 9999; /* 設定一個足夠高的 z-index 值，使遮罩層位於最上層 */
`;

const Loader = styled.div`
	border: 5px solid #f3f3f3;
	border-top: 5px solid #3498db;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 2s linear infinite;
	margin: 0 auto;
	margin-top: 25%;

	@media screen and (min-width: 768px) {
		&.w-md-50 {
			width: 50% !important;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function LgsSalesOrder_Details() {
	const { t } = useTranslation(['common', 'lgsSalesOrder']);

	// react-hook-form
	const {
		register,
		formState: { errors },
		setValue,
		getValues,
	} = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onBlur',
	});

	let { salesOrderGuid } = useParams();
	const navigate = useNavigate();
	const [cookies, setCookie] = useCookies();

	const [isLoading, setIsLoading] = useState(false);

	const { error, sendRequest: axiosDetailData } = useAxios();

	if (error) {
		console.log('error !', error);
	}

	const [detailsInfo, setDetailsInfo] = useState([]);

	const pageTitle = `${t('lgsSalesOrder:salesOrder')} ${
		detailsInfo?.salesOrder?.orderNo ?? ''
	}`;

	const handleCopyPurchaseOrder = () => {
		axios(
			`api/Logistics/LgsSalesOrder/copySalesOrder?guid=${salesOrderGuid}`
		)
			.then((response) => {
				// console.log(response.data);
				navigate('/Logistics/LgsSalesOrder/Create', {
					state: {
						data: response.data,
					},
				});
			})
			.catch((error) => console.log(error));
	};

	// const handleOrderStatus = async (status) => {
	// 	await axios
	// 		.put(`api/Logistics/LgsSalesOrder/updateSalesOrderStatus`, {
	// 			orderGuid: salesOrderGuid,
	// 			status: status,
	// 			rowStamp: detailsInfo.salesOrder.rowStamp,
	// 		})
	// 		.then((res) => {
	// 			console.log(res.data);

	// 			const url = `api/Logistics/LgsSalesOrder/salesOrder?guid=${salesOrderGuid}`;
	// 			axiosDetailData({ url }, (res) => {
	// 				setDetailsInfo(res.data);
	// 			});
	// 		})
	// 		.catch((error) => console.log(error));
	// };

	// 列印
	const handlePrint = async () => {
		setIsLoading(true); // 顯示遮罩層

		const {
			orderGuid,
			orderNo,
			orderTypeName,
			currencyName,
			orderDate,
			sectorName,
			customerName,
			phone1,
			address,
			noteCustomer,
			foreignCurrency,
			netAmount,
			taxAmount,
			grossAmount,
			foreignTaxAmount,
			foreignGrossAmount,
			foreignNetAmount,
			outputTaxRate,
		} = detailsInfo.salesOrder;

		const { salesOrderKitList } = detailsInfo;

		const newSalesOrderKitList = salesOrderKitList.map(
			({
				displayOrder,
				deliverDate,
				foreignNetPrice,
				foreignNetAmount,
				netPrice,
				netAmount,
				quantitySales,
				salesKitName,
				spec1Name01,
				spec2Name01,
				spec3Name01,
				salesOrderKitItemList,
			}) => {
				return {
					displayOrder,
					deliverDate,
					foreignNetPrice,
					foreignNetAmount,
					netPrice,
					netAmount,
					quantitySales,
					salesKitName,
					specName: `${spec1Name01}/${spec2Name01}/${spec3Name01}`,

					salesOrderKitItemList: salesOrderKitItemList.map(
						({
							displayOrder,
							// deliverDate,
							// foreignNetPrice,
							// foreignNetAmount,
							// netPrice,
							// netAmount,
							salesCategoryCode,
							quantitySale,
							materialName,
							serviceName,
						}) => {
							return {
								displayOrder,
								// deliverDate,
								// foreignNetPrice,
								// foreignNetAmount,
								// netPrice,
								// netAmount,
								salesCategoryCode,
								quantitySale,
								materialName,
								serviceName,
							};
						}
					),
				};
			}
		);

		await axios({
			url: 'api/Logistics/lgsSalesOrder/Print',
			method: 'POST',
			data: {
				ReportTitle: {
					reportTitle: t('lgsSalesOrder:salesOrder'),
					orderNoT: t('lgsSalesOrder:orderNo'),
					orderTypeNameT: t('lgsSalesOrder:orderType'),
					orderDateT: t('lgsSalesOrder:orderDate'),
					sectorNameT: t('lgsSalesOrder:salesDepartment'),
					customerNameT: t('lgsSalesOrder:customer'),
					noteCustomerT: t('lgsSalesOrder:note_Customer2'),
					phone1T: t('lgsSalesOrder:customerPhonePrintTitle'),
					addressT: t('lgsSalesOrder:customerAddressPrintTitle'),
					taxAmountT:
						t('lgsSalesOrder:businessTax') +
						`(${outputTaxRate}%)：`,
					currencyNameT:
						t('lgsSalesOrder:total') + `(${currencyName})：`,
				},
				ReportOption: {
					Language: cookies._system_culture.split('uic=')[1],
					Page: t('lgsSalesOrder:page'),
					PageSize: 'A4',
					BalancePositiveDebit: true,
					ThousandsSeparator: false,
					ZeroAsBlank: false,
					NegValParentheses: false,
					NegValRedColor: false,
				},
				SalesOrder: {
					orderGuid,
					orderNo,
					orderTypeName,
					orderDate,
					sectorName,
					customerName,
					noteCustomer,
					phone1,
					address,
					currencyName,
					foreignCurrency,
					netAmount,
					taxAmount,
					grossAmount,
					foreignTaxAmount,
					foreignGrossAmount,
					foreignNetAmount,
					outputTaxRate,
				},
				SalesOrderKitList: newSalesOrderKitList,
			},
			responseType: 'blob', // 設置響應類型為blob
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(function (response) {
				// 檢查伺服器回應是否成功
				if (response.status === 200) {
					return response.data;
				} else {
					throw new Error('Network response was not ok');
				}
			})
			.then(function (data) {
				// 在此處處理二進制數據（PDF）
				var blob = new Blob([data], { type: 'application/pdf' });

				let pdfUrl = URL.createObjectURL(blob);
				let pdfWindow = window.open(
					pdfUrl,
					'_blank',
					'width=1080,height=800'
				);
				pdfWindow.onload = function () {
					pdfWindow.print();
				};
				URL.revokeObjectURL(pdfUrl);
			})
			.finally(function () {
				setIsLoading(false); // 隱藏遮罩層
			})
			.catch(function (error) {
				console.error('Fetch error:', error);
			});
	};

	useEffect(() => {
		const url = `api/Logistics/LgsSalesOrder/salesOrder?guid=${salesOrderGuid}`;
		axiosDetailData({ url }, (res) => {
			setDetailsInfo(res.data);
		});
	}, []);

	console.log(detailsInfo);

	// 分頁title
	const tabNavList = [
		t('lgsSalesOrder:basicInformation'),
		t('lgsSalesOrder:details'),
		t('lgsSalesOrder:paymentTerms'),
		t('lgsSalesOrder:overviewHistory'),
		t('lgsSalesOrder:receiptHistory'),
		t('lgsSalesOrder:returnGoodsHistory'),
		t('lgsSalesOrder:invoiceHistory'),
		t('lgsSalesOrder:paymentHistory'),
	];

	// 分頁內容 component
	const tabContent = [
		<LgsSalesOrder_BaseInfo
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsSalesOrder_DetailAndGoods
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsSalesOrder_PaymentTerm
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsSalesOrder_HistoryOverview
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsSalesOrder_GoodsHistory
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsSalesOrder_ReturnGoodsHistory
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsSalesOrder_InvoiceHistory
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsSalesOrder_PaymentHistory
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
	];

	return (
		<div id="detailsView">
			{/* 遮罩層 */}
			<Overlay isLoading={isLoading}>
				<Loader></Loader>
				<p className="mt-3">Loading...</p>
			</Overlay>

			<PageTitleBar
				pageTitle={pageTitle}
				previousPageButton={true}
				backTo={-1}
				isDialogConfirm={false}
			/>
			<Container>
				<Left>
					<EditBtnNormal permission={[]} guid={salesOrderGuid} />
					{/* <OrderStatusBtn
						status={detailsInfo.salesOrder?.status}
						// 1. 如果狀態是'0'(訂單未完成)，則顯示為"訂單關閉"，因此要將狀態改為'2'。
						// 2. 如果狀態是'2'(訂單關閉)，則顯示為"訂單開啟"，因此要將狀態改為'0'。
						handleClick={() =>
							handleOrderStatus(
								detailsInfo.salesOrder?.status === '0'
									? '2'
									: detailsInfo.salesOrder?.status === '2'
									? '0'
									: ''
							)
						}
					/> */}
					<CopyCreateBtn handleClick={handleCopyPurchaseOrder} />
				</Left>
				<Center></Center>
				<Right>
					<PrintBtnNormal handleClick={() => handlePrint()} />
				</Right>
			</Container>
			<form>
				{detailsInfo && (
					<>
						<StatusBar status={detailsInfo.salesOrder?.status} />
						<LgsSalesOrder_TopCommonInfo
							register={register}
							errors={errors}
							setValue={setValue}
							getValues={getValues}
							detailsInfo={detailsInfo}
						/>
						<TabPage
							tabNavList={tabNavList}
							tabContent={tabContent}
							defaultPage={0}
						/>
					</>
				)}
			</form>
		</div>
	);
}

export default LgsSalesOrder_Details;
