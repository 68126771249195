import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import ThreeColLayout from '../../../../../components/ThreeColLayout/ThreeColLayout';
import { DialogCol } from '../DialogCol';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { disabledStyle } from '../../../../../components/FormElement/shared/DisabledStyle';

//#region styled-components

const SelectElement = styled.select`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}

	${disabledStyle}
`;

const TopTable = styled.table`
	font-size: 12px;
	color: #616e83;
	background-color: #f7f8fc;
	width: 100% !important;
	height: 128px !important;
	border: 1px solid var(--grey1) !important;
	border-collapse: separate;
	border-spacing: 0;
	border-radius: 10px;
`;

const TBody = styled.tbody``;

const TR = styled.tr`
	height: 32px;
	border: 2px solid var(--grey1) !important;
`;
const TH1 = styled.th`
	padding: 0px;
	height: 32px;
	width: 23% !important;
	text-align: center !important;
`;
const TH = styled.th`
	padding: 0px;
	height: 32px;
	width: 38.5% !important;
	text-align: center !important;
`;
const TD1 = styled.td`
	padding: 0px;
	height: 32px;
	width: 23% !important;
	border: 1px solid var(--grey1) !important;
	text-align: center !important;
`;
const TD = styled.td`
	padding-right: 8px;
	height: 32px;
	width: 38.5% !important;
	border: 1px solid var(--grey1) !important;

	text-align: right !important;
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const InputElement = styled.input`
	/* border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    &:disabled {
        background-color: #eff2f5;
    } */

	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	width: 100%;
	${disabledStyle};
`;

const InputElement2 = styled.input`
	border: 1px solid var(--grey2);
	border-right: none;
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

// -----DialogCol------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	--bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

//#endregion

const BottomInfo = ({
	pageData,
	setPageData,
	CurrencyCode,
	CurrencyName,
	DecimalPlaceTl,
	t
}) => {
	const { staffList, bankingAccountList, salesOrder } = pageData;

	const handleInput = (inputName) => {
		pageData.goodsItemInfo[inputName] = event.target.value;

		setPageData(pageData);
	};

	const LeftCol = () => {
		const handleStaffChange = (val) => {
			setPageData({ ...pageData, staffGuid: val });
		};

		const handleBankingAccountChange = (val) => {
			setPageData({ ...pageData, bankAccountGuid: val });
		};

		const handlePostingDateChange = (val) => {
			setPageData({ ...pageData, postingDate: val });
		};

		return (
			<>
				<div className="wrapper d-flex flex-column mb-3">
					<LabelNormal>
						{t('lgsSalesOrderPayment:page1.bottom.postingDate')}
					</LabelNormal>
					<InputElement
						type="text"
						id="postingDate"
						value={dayjs(Date.now()).format('YYYY-MM-DD')}
						onChange={(e) =>
							handlePostingDateChange(e.target.value)
						}
						disabled={true}
						disabledCssType={1}
					/>
					<span className="text-danger ms-1 mt-1">
						{/* {data.vendorNameWarning} */}
					</span>
				</div>

				<div className="wrapper d-flex flex-column mb-3">
					<LabelNormal>
						{t('lgsSalesOrderPayment:page1.bottom.staff')}
					</LabelNormal>
					<InputElement
						id="staff"
						value={pageData?.receive?.staffName}
						onChange={(e) => handleStaffChange(e.target.value)}
						disabled={true}
						disabledCssType={1}
					>
						{/* {staffList?.map((el) => (
                            <option
                                key={el.staffGuid}
                                id={el.staffGuid}
                                value={el.staffGuid}
                            >
                                {el.fullname}
                            </option>
                        ))} */}
					</InputElement>
					<span className="text-danger ms-1 mt-1">
						{/* {data.orderTypeCodeWarning} */}
					</span>
				</div>
			</>
		);
	};

	const CenterCol = () => (
		<>
			<div className="row">
				<div className="wrapper d-flex flex-column mb-3 col">
                            <div className="d-flex flex-column mb-3">
                                <LabelNormal>
                                    {t('page1.bottom.shipmentTransitionBalance')}
                                </LabelNormal>
                                <InputElement
                                    type="text"
                                    id="shipmentTransitionBalance"
                                    value={
                                        pageData?.salesOrder?.shipmentTransitionBalance ||
                                        ''
                                    }
                                    min="1"
                                    onChange={(e) =>
                                        handleShipmentTransitionBalanceChange(
                                            e.target.value
                                        )
                                    }
									disabled={true}
									disabledCssType={1}
                                />
                            </div>
                            <div className="d-flex flex-column mb-3">
                                <LabelNormal>
                                    {t('page1.bottom.accountsReceivableBalance')}
                                </LabelNormal>
                                <InputElement
                                    type="text"
                                    id="accountsReceivableBalance"
                                    value={
                                        pageData?.salesOrder?.accountsReceivableBalance ||
                                        ''
                                    }
                                    min="1"
                                    onChange={(e) =>
                                        handleShipmentTransitionBalanceChange(
                                            e.target.value
                                        )
                                    }
									disabled={true}
									disabledCssType={1}
                                />
                            </div>
                            <div className="d-flex flex-column mb-3">
                                <LabelNormal>
                                    {t('page1.bottom.differenceBalance')}
                                </LabelNormal>
                                <InputElement
                                    type="text"
                                    id="varianceBalance"
                                    value={
                                        pageData?.salesOrder?.varianceBalance ||
                                        ''
                                    }
                                    min="1"
                                    onChange={(e) =>
                                        handleShipmentTransitionBalanceChange(
                                            e.target.value
                                        )
                                    }
									disabled={true}
									disabledCssType={1}
                                />
                            </div>
                        </div>

			</div>
		</>
	);

	const RightCol = () => (
		<>
			<div className={`wrapper d-flex flex-column mb-3`}>
				<LabelNormal>
					{t('lgsSalesOrderPayment:page1.bottom.note')}
				</LabelNormal>
				<TextareaElement
					//style={elementStyle}
					id="note"
					rows="4"
					cols="30"
					value={pageData?.receive?.note || ''}
					onChange={() => handleInput('note')}
					disabled={true}
					disabledCssType={0}
				/>
			</div>
		</>
	);

	return (
		<ThreeColLayout
			LeftCol={<LeftCol />}
			CenterCol={<CenterCol />}
			RightCol={<RightCol />}
		/>
	);
};

export default BottomInfo;
