import { useState, useEffect, useCallback } from 'react';
//import { purchaseOrderGoodsInvoiceAdvancedSearchData as searchContentList } from "../../../../../utils/advancedSearchData";
import { lgsSalesOrderPreparingAdvancedSearchData as searchContentList } from '../../../../../../utils/advancedSearchData';
import useAxios from '../../../../../../hooks/useAxios';
import Page0Partial from './Page0Partial';
import AdvancedSearchModal from '../../../../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../../../../../components/Modals/AdvancedSearchResultTag';
import { useTranslation } from 'react-i18next';

function Page0Table({
    singleChecked,
    setSingleChecked,
    advancedSearchShow,
    setAdvancedSearchShow,
}) {
    // i18n
    const { t } = useTranslation(['common', 'lgsSalesOrderPreparing']);

    const [fetchedData, setFetchedData] = useState([]);
    const [displayColumns, setDisplayColumns] = useState([]);
    const [paginationData, setPaginationData] = useState({});
    const [sortBy, setSortBy] = useState('orderDate desc');
    const guidName = 'shipmentGuid';
    const { sendRequest: axiosIndexData } = useAxios();

    const headTitleList = [
        '#',
        t('lgsSalesOrderPreparing:vendorCode'),
        t('lgsSalesOrderPreparing:vendor'),
        t('lgsSalesOrderPreparing:orderDate'),
        t('lgsSalesOrderPreparing:orderNo'),
        t('lgsSalesOrderPreparing:preparingNo'),
        t('lgsSalesOrderPreparing:status'),
        //t("lgsSalesOrderPreparing:displayNetAmount"),
        //t("lgsSalesOrderPreparing:displayTaxAmount"),
        //t("lgsSalesOrderPreparing:grossAmount"),
        //t("lgsSalesOrderPreparing:netAmount"),
        //t("lgsSalesOrderPreparing:taxAmount"),
    ];

    const bodyTitleList = [
        'displayOrder',
        'customerCode',
        'customerName',
        'orderDate',
        'orderNo',
        'documentNo',
        'status',
        // "displayNetAmount",
        // "displayTaxAmount",
        // "grossAmount",
        // "netAmount",
        // "taxAmount",
    ];

    const tableStyles = [
        {
            minWidth: '54px',
            textAlign: 'center',
        },
        {
            minWidth: '160px',
            textAlign: 'left',
        },
        {
            minWidth: '240px',
            textAlign: 'left',
        },
        {
            minWidth: '120px',
            textAlign: 'left',
        },
        {
            minWidth: '160px',
            textAlign: 'left',
        },
        {
            minWidth: '72px',
            textAlign: 'left',
        },
        {
            minWidth: '120px',
            textAlign: 'center',
        },
        {
            minWidth: '120px',
            textAlign: 'center',
        },
        {
            minWidth: '112px',
            textAlign: 'center',
        },
        {
            minWidth: '160px',
            textAlign: 'right',
        },
        {
            minWidth: '160px',
            textAlign: 'right',
        },
        {
            minWidth: '160px',
            textAlign: 'right',
        },
    ];

    const columnMustShow = [
        'displayOrder',
        'customerCode',
        'orderDate',
        'documentNo',
    ];

    // 進階搜尋 一進去的第一筆的模板
    const advancedSearchDataTemp = {
        searchLogic: 'AND',
        column: searchContentList[0].column,
        searchOperator: '==',
        searchValue: '',
        searchContent: '',
        inputWarningContent: '',
        selectOptionList: searchContentList[0].selectOptionList,
    };

    // 進階搜尋實際資料
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
    // 進階搜尋畫面資料
    const [displayList, setDisplayList] = useState([]);

    // Advanced Search

    //todo: default sort col => orderDate

    /// 1. 第一次進入 index
    /// 2. 改變進階查詢
    /// 3. 改變筆數
    /// 4. 改變排序
    /// 都需要重新向後端拿資料, 所以合成一個 function 處理

    // advancedSearchDataList: 進階查詢資料
    // sort: 排序
    const fetchData = (
        advancedSearchDataList = null,
        pageSize = 20,
        sort = sortBy,
        pageNumber = 1
    ) => {
        //const url = `api/Logistics/LgsPurchaseOrderGoodsInvoice/GetList`;
        const url = `api/Logistics/LgsSalesOrderPreparing/getUpdateList`;
        //console.log(advancedSearchDataList);

        // 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
        if (advancedSearchDataList) {
            // only "one" search data
            advancedSearchDataList[0].searchLogic = '';
        }

        axiosIndexData(
            {
                url,
                method: 'post',
                data: {
                    sort,
                    pageSize,
                    page: pageNumber,
                    advancedSearchDataList,
                },
            },
            (res) => {
                const { indexData, permission, numberOfData, totalPage } = res;

                // 分頁資訊
                setPaginationData({
                    ...paginationData,
                    numberOfData: numberOfData,
                    totalPage: totalPage,
                    pageNumber: pageNumber,
                });

                // table 資料
                setFetchedData({
                    indexData,
                    permission,
                    paginationData,
                });

                function isArrayFn(obj) {
                    if (typeof Array.isArray === 'function') {
                        return Array.isArray(obj); // 如果瀏覽器支援就用 isArray() 方法
                    } else {
                        // 否則就使用 toString 方法
                        return (
                            Object.prototype.toString.call(obj) ===
                            '[object Array]'
                        );
                    }
                }

                /// 這和 api 回傳的 result 無關
                // 如果有 advancedSearchDataList, 且是 array
                // console.log('original', advancedSearchDataList);
                if (isArrayFn(advancedSearchDataList)) {
                    // 因為後端給的 select list 名稱不一定 和 後端在做進階查詢時的欄位名稱一樣
                    // 所以要將進階查詢時的欄位(searchFilter) 轉換為 tableColumn
                    advancedSearchDataList.map((el) => {
                        searchContentList.map((item) => {
                            // console.log(item);
                            if (el.searchFilter === item.tableColumn) {
                                el.searchFilter = item.searchFromDataBase;
                            }
                        });
                    });

                    // console.log('after edit', advancedSearchDataList);

                    setDisplayList(advancedSearchDataList);
                    setAdvancedSearchDataList(advancedSearchDataList);
                }

                handleAdvancedSearchModalClose();
                localStorage.setItem('Permission_LgsPurchaseOrder', permission);
            }
        );
    };

    const getLocalStorageData = useCallback(() => {
        const gettingData = async () => {
            const data = await (JSON.parse(
                localStorage.getItem(`${guidName}DisplayColumns`)
            ) || bodyTitleList);

            setDisplayColumns(data);

            return data;
        };

        return gettingData();
    }, []);

    const handleOpenAdvancedSearch = () => {
        console.log('handleOpenAdvancedSearch');
    };

    const handleAdvancedSearchModalClose = () => setAdvancedSearchShow(false);
    const handleAdvancedSearchModalShow = () => {
        setAdvancedSearchShow(true);
    };

    const deleteAllFilters = () => {
        setDisplayList([]);
        setAdvancedSearchDataList([]);
        fetchData();
    };

    const deleteFilter = (targetIndex) => {
        console.log('deleteFilter index', targetIndex);
        console.log(advancedSearchDataList[targetIndex]);
        console.log(displayList[targetIndex]);

        const newAdvancedSearchDataList = advancedSearchDataList.filter(
            (_, index) => index !== targetIndex
        );
        const newDisplayList = displayList.filter(
            (_, index) => index !== targetIndex
        );
        setDisplayList(newDisplayList);
        setAdvancedSearchDataList(newAdvancedSearchDataList);
        fetchData(
            newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null
        );
    };

    useEffect(() => {
        // fetchIndexInfo();
        getLocalStorageData();
        fetchData();

        return () => {
            // cleanup
        };
    }, [getLocalStorageData]);

    // console.log(advancedSearchDataList);

    return (
        <>
            <AdvancedSearchResultTag
                advancedSearchDataList={advancedSearchDataList}
                deleteAllFilters={deleteAllFilters}
                deleteFilter={deleteFilter}
                ns="lgsSalesOrderPreparing"
            />
            <div id="indexView">
                <div className="position-relative firstRowMinHeight bg-white d-flex flex-column p-4 position-relative overflow-auto">
                    {fetchedData?.indexData && (
                        <Page0Partial
                            guidName={guidName}
                            sortBy={sortBy}
                            headTitleList={headTitleList}
                            bodyTitleList={bodyTitleList}
                            indexData={fetchedData?.indexData}
                            displayColumns={displayColumns}
                            setDisplayColumns={setDisplayColumns}
                            paginationData={paginationData}
                            setPaginationData={setPaginationData}
                            fetchData={fetchData}
                            fetchedData={fetchedData}
                            tableStyles={tableStyles}
                            columnMustShow={columnMustShow}
                            rowClick={true}
                            advancedSearchDataList={advancedSearchDataList}
                            clickSelection={1}
                            singleChecked={singleChecked}
                            setSingleChecked={setSingleChecked}
                        />
                    )}
                </div>
            </div>

            {advancedSearchShow && (
                <AdvancedSearchModal
                    isOpen={advancedSearchShow}
                    handleClose={handleAdvancedSearchModalClose}
                    headTitleList={headTitleList}
                    bodyTitleList={bodyTitleList}
                    advancedSearchDataList={advancedSearchDataList}
                    setAdvancedSearchDataList={setAdvancedSearchDataList}
                    advancedSearchDataTemp={advancedSearchDataTemp}
                    fetchData={fetchData}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    setFetchedData={setFetchedData}
                    displayList={
                        displayList.length > 0
                            ? displayList
                            : [{ ...advancedSearchDataTemp }]
                    }
                    setDisplayList={setDisplayList}
                    searchContentList={searchContentList}
                    ns="lgsSalesOrderPreparing"
                />
            )}
        </>
    );
}

export default Page0Table;
