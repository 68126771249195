import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import PageTitleBar from '../../../components/PageTitleBar/PageTitleBar';

import { DialogConfirm } from './Pages/DialogConfirm';
import Page0 from './Pages/Page0/Page0';
import Page1 from './Pages/Page1/Page1';
import Page2 from './Pages/Page2/Page2';
import Page3 from './Pages/Page3/Page3';

// Author: YL
/**
 * LgsSalesOrderGoodsInvoice_Edit 組件，用於管理銷售訂單支付相關的頁面流程。
 *
 * 主要功能：
 * 1. 管理頁面間數據流轉，包括父組件數據、當前頁面數等。
 * 2. 處理上一步和下一步按鈕的點擊事件。
 * 3. 包含三個子頁面 Page0、Page1、Page2 和 Page3，分別處理不同的頁面內容。
 * 4. 使用 react-i18next 實現多語言支持，提供 i18n 和 t 函數。
 * 5. 使用 react-cookie 管理 cookies，獲取公司貨幣相關信息。
 *
 * @returns {JSX.Element} LgsSalesOrderGoodsInvoice_Edit 組件的 JSX 元素
 */

export default function LgsSalesOrderGoodsReturn_Edit() {
    const emptyReceiveData = {
        isEverEnterPage: {
            page1: false,
            page2: false,
        },
        salesOrder: {},
        receive: {},
    };

    const { t, i18n } = useTranslation(['lgsSalesOrderGoodsReturn', 'common']);
    const [parentData, setParentData] = useState(emptyReceiveData);
    const [cookies, setCookie] = useCookies();
    const CurrencyCode = cookies._company_currency_code;
    const CurrencyName = cookies._company_currency_name;
    const DecimalPlaceTl = cookies._company_currency_decimalplace_tl;

    const [page, setPage] = useState(0);
    const pageTitle = t('pageTitle_edit');

    //#region page1
    const [copySalesOrderData, setCopySalesOrderData] = useState({});
    const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [warningMessage, setWarningMessage] = useState('');

    const processSteps = [t('steps.step0'), t('steps.step1'), t('steps.step2')];

    //#endregion

    const handlePrevPage = () => {
        setPage(page - 1);
    };

    const handleNextPage = () => {
        setPage(page + 1);
    };

    /**
     * DialogConfirm 返回btn確認dialog
     **/
    const [dialogConfirmData, setDialogConfirmData] = useState({
        btnName: '',
        isOpen: false,
        goTo: '',
        title: '',
    });
    const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);

    // DialogConfirm Modal open
    const handleDialogConfirm = () => {
        const newData = {
            btnName: 'goToRoot',
            isOpen: true,
            goTo: 'prePage',
            title: t('steps.confirmLeavePaymentPage'),
        };
        setDialogConfirmData(newData);
    };

    return (
        <>
            <div id="detailsView">
                <PageTitleBar
                    pageTitle={pageTitle}
                    previousPageButton={true}
                    goTo={-1}
                    isDialogConfirm={true}
                    handleDialogConfirm={handleDialogConfirm}
                />

                {/* DialogConfirm element */}
                <DialogConfirm
                    dialogConfirmData={dialogConfirmData}
                    setDialogConfirmData={setDialogConfirmData}
                    page={page}
                    setPage={setPage}
                    setIsConfirmBtnClicked={setIsConfirmBtnClicked}
                />

                <div>
                    {page === 0 && (
                        <Page0
                            pageAction="UPDATE"
                            parentData={parentData}
                            setParentData={setParentData}
                            page={page}
                            setPage={setPage}
                            processSteps={processSteps}
                            handleNextPage={handleNextPage}
                            emptyReceiveData={emptyReceiveData}
                            t={t}
                        />
                    )}
                    {page === 1 && (
                        <Page1
                            pageAction="UPDATE"
                            dialogConfirmData={dialogConfirmData}
                            setDialogConfirmData={setDialogConfirmData}
                            parentData={parentData}
                            setParentData={setParentData}
                            page={page}
                            setPage={setPage}
                            processSteps={processSteps}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                            copySalesOrderData={copySalesOrderData}
                            setCopySalesOrderData={setCopySalesOrderData}
                            dialogBtnKeyName={dialogBtnKeyName}
                            setDialogBtnKeyName={setDialogBtnKeyName}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            modalData={modalData}
                            setModalData={setModalData}
                            warningMessage={warningMessage}
                            setWarningMessage={setWarningMessage}
                            CurrencyCode={CurrencyCode}
                            CurrencyName={CurrencyName}
                            DecimalPlaceTl={DecimalPlaceTl}
                            t={t}
                        />
                    )}
                    {page === 2 && (
                        <Page2
                            pageAction="UPDATE"
                            parentData={parentData}
                            setParentData={setParentData}
                            page={page}
                            setPage={setPage}
                            processSteps={processSteps}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                            copySalesOrderData={copySalesOrderData}
                            setCopySalesOrderData={setCopySalesOrderData}
                            dialogConfirmData={dialogConfirmData}
                            setDialogConfirmData={setDialogConfirmData}
                            dialogBtnKeyName={dialogBtnKeyName}
                            setDialogBtnKeyName={setDialogBtnKeyName}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            modalData={modalData}
                            setModalData={setModalData}
                            warningMessage={warningMessage}
                            setWarningMessage={setWarningMessage}
                            CurrencyCode={CurrencyCode}
                            CurrencyName={CurrencyName}
                            DecimalPlaceTl={DecimalPlaceTl}
                            t={t}
                        />
                    )}
                    {page === 3 && (
                        <Page3
                            pageAction="UPDATE"
                            parentData={parentData}
                            setParentData={setParentData}
                            page={page}
                            setPage={setPage}
                            processSteps={processSteps}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                            salesOrderData={copySalesOrderData}
                            setCopySalesOrderData={setCopySalesOrderData}
                            dialogConfirmData={dialogConfirmData}
                            setDialogConfirmData={setDialogConfirmData}
                            dialogBtnKeyName={dialogBtnKeyName}
                            setDialogBtnKeyName={setDialogBtnKeyName}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            modalData={modalData}
                            setModalData={setModalData}
                            warningMessage={warningMessage}
                            setWarningMessage={setWarningMessage}
                            emptyReceiveData={emptyReceiveData}
                            t={t}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
