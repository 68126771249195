import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Icn_deleted } from '../../../../assets/images/icn_DeleteCross.svg';
import { ReactComponent as CreateIcon } from '../../../../assets/images/icn_Create.svg';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../assets/images/icn_inputDialog.svg';
import { BtnNormal } from '../../../../components/Buttons/BtnNormal';
import { DialogCol } from '../Dialog/DialogCol';

// #region styled-components

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	background-color: var(--grey1) !important;
`;

const NextToLast = styled(TH)`
	border-right: none !important;
`;

const LastTH = styled(TH)`
	border-left: none !important;
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
`;

const Input = styled.input`
	border: 1px solid #e3e8ee;
	border-radius: 4px;
	min-width: 24px;
	width: 100%;
	outline: none;
`;

const Select = styled.select`
	border: 1px solid #e3e8ee;
	border-radius: 4px;
	min-width: 24px;
	width: 100%;
	outline: none;
`;

// -----Dialog------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	height: 25px;
	--bs-gutter-x: none;
	background: #fff;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 25px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: -1px;
	right: 24px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

//#endregion
export default function SalesDetails({
	// currencyList,
	data,
	setData,
	error,
	baseInfoList,
	isDetailRoute,
	isEditRoute,
}) {
	const { t } = useTranslation(['common', 'lgsSalesKit']);
	const [editingRowIndex, setEditingRowIndex] = useState(-1);

	const [indexDialog, setIndexDialog] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState([]);
	const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');
	const [tableBodyData, setTableBodyData] = useState([]);

	console.log(error);
	//#region variable initiation

	const handleAddServiceEstimatedPriceList = () => {
		const newNumber =
			data.salesDetailsList.filter((el) => el.action !== 'delete')
				.length + 1;

		const newItem = {
			id: _uuid(),
			kitSetGuid: data.salesKitSet.kitSetGuid ?? '',
			action: 'insert',
			displayOrder: newNumber,
			salesCategoryCode: 'P', // 預設為P
			salesCategoryName: t('lgsSalesKit:material'), // 預設為物料
		};

		const oldList = [...data.salesDetailsList];

		// displayOrder排序必須去除delete與reallyDelete
		const notIncludeAnyDelete = oldList
			.filter((item) => item.action !== 'delete')
			.map((item) => ({
				...item,
			}));

		// 不能顯示但必須保留給後端的delete資料
		const actionDelete = oldList.filter((item) => item.action === 'delete');

		// 結合新的資料，將delete放到最後，才能讓修改資料時index對應到
		const newList = [...notIncludeAnyDelete, newItem, ...actionDelete];

		setData({
			...data,
			salesDetailsList: newList,
		});
	};

	const handleDeleteEstimatedPrice = (e, id) => {
		e.stopPropagation();

		const oldList = [...data.salesDetailsList];

		console.log(oldList, id);

		// action === 'update'或'native' 的資料只能標記不能刪除
		let deletedItem = oldList.find((item) => item.id === id);
		console.log(deletedItem);
		if (
			deletedItem.action === 'update' ||
			deletedItem.action === 'native'
		) {
			deletedItem.action = 'delete';
			deletedItem.displayOrder = '0'; // 由於必須給予displayOrder，所以預設為0
		} else if (deletedItem.action === 'insert') {
			deletedItem.action = 'reallyDelete';
			deletedItem.displayOrder = '0'; // 由於必須給予displayOrder，所以預設為0
		}

		// 不能顯示但必須保留給後端的delete資料
		const actionDelete = oldList.filter((item) => item.action === 'delete');

		// displayOrder排序必須去除delete與reallyDelete
		const notIncludeAnyDelete = oldList
			.filter(
				(item) =>
					item.action !== 'delete' && item.action !== 'reallyDelete'
			)
			.map((item, index) => ({
				...item,
				displayOrder: index + 1,
			}));

		// 排序後，結合除了reallyDelete的資料
		const newList = [...notIncludeAnyDelete, ...actionDelete];

		console.log(newList);

		setData({ ...data, salesDetailsList: newList });

		console.log(oldList);
	};

	const handleEditTable = (e, rowIndex, el) => {
		// console.log(e.target, rowIndex, el);

		const targetIndex = rowIndex;
		setEditingRowIndex(targetIndex);
	};

	console.log(editingRowIndex);

	const handleInputChange = (e, index) => {
		const value = e.target.value;
		const name = e.target.name;

		const newData = { ...data };
		const salesDetailsList = [...data.salesDetailsList];
		salesDetailsList[index][name] = value;

		// 在編輯頁面且不是新增時，標記為update
		if (isEditRoute && salesDetailsList[index]['action'] !== 'insert')
			salesDetailsList[index]['action'] = 'update';

		// 型態
		if (name === 'salesCategoryCode') {
			// 清空 物料&服務
			newData.salesDetailsList[index].materialGuid = '';
			newData.salesDetailsList[index].materialName = '';
			newData.salesDetailsList[index].serviceGuid = '';
			newData.salesDetailsList[index].serviceName = '';

			if (value === 'P') {
				salesDetailsList[index]['salesCategoryName'] = t(
					'lgsSalesKit:material'
				); // 物料
			} else if (value === 'S') {
				salesDetailsList[index]['salesCategoryName'] = t(
					'lgsSalesKit:service'
				); // 服務
			}
		}

		setData({ ...data, salesDetailsList });
	};

	function _uuid() {
		var d = Date.now();
		if (
			typeof performance !== 'undefined' &&
			typeof performance.now === 'function'
		) {
			d += performance.now(); //use high-precision timer if available
		}
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
			/[xy]/g,
			function (c) {
				var r = (d + Math.random() * 16) % 16 | 0;
				d = Math.floor(d / 16);
				return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
			}
		);
	}

	// DialogCol input 黃色按鈕取消
	const handleCancelClick = (rowIndex) => {
		const newData = { ...data };
		// 物料
		newData.salesDetailsList[rowIndex].materialGuid = '';
		newData.salesDetailsList[rowIndex].materialName = '';
		// 服務
		newData.salesDetailsList[rowIndex].serviceGuid = '';
		newData.salesDetailsList[rowIndex].serviceName = '';
		console.log(newData);

		setData(newData);
	};

	const handleOpen = (rowIndex, btnKeyName) => {
		if (btnKeyName === 'material') {
			setDialogBtnKeyName('material');
			setModalData(baseInfoList.materialMasterList);
		} else if ('service') {
			setDialogBtnKeyName('service');
			setModalData(baseInfoList.serviceMasterList);
		}

		setIndexDialog(rowIndex); // 獲取真實的index，傳給DialogCol
		setIsOpen(true);
	};

	useEffect(() => {
		setEditingRowIndex(-1);
	}, [isDetailRoute, isEditRoute]); // 換頁後取消可編輯狀態
	return (
		<>
			{/* DialogCol element */}
			<DialogCol
				indexDialog={indexDialog}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				modalData={modalData}
				setModalData={setModalData}
				btnKeyName={dialogBtnKeyName}
				pageData={data}
				setPageData={setData}
				tableBodyData={tableBodyData}
				setTableBodyData={setTableBodyData}
				isEditRoute={isEditRoute}
			/>
			<div className="position-relative">
				<div className="bg-white d-flex flex-column ps-1 pe-0 position-relative">
					<div className="px-0 py-3 d-flex align-item-center">
						{!isDetailRoute && (
							<BtnNormal
								className={'btn btn-primary text-white'}
								key={'ActivateBtnNormal'}
								onClick={handleAddServiceEstimatedPriceList}
							>
								<CreateIcon />
								{t('lgsSalesKit:add')}
								{/* 新增 */}
							</BtnNormal>
						)}
					</div>
					<IndexTable className="table">
						<THead className="table-head">
							<TR>
								<TH
									style={{
										width: '48px',
										textAlign: 'center',
									}}
								>
									#
								</TH>
								<TH
									style={{
										width: '128px',
										textAlign: 'center',
									}}
								>
									{t('lgsSalesKit:type')}
									{/* 型態 */}
								</TH>
								<TH
									style={{
										width: '128px',
										textAlign: 'center',
									}}
								>
									{t('lgsSalesKit:materialServiceCode')}
									{/* 物料/服務 代碼 */}
								</TH>
								<TH
									style={{
										width: '160px',
										textAlign: 'center',
									}}
								>
									{t('lgsSalesKit:quantity')}
									{/* 數量 */}
								</TH>
								<TH
									style={{
										width: '160px',
										textAlign: 'center',
									}}
								>
									{t('lgsSalesKit:priceSplitPercentage')}
									{/* 價格拆分比例% */}
								</TH>
								<NextToLast
									style={{
										width: '232px',
										textAlign: 'center',
									}}
								>
									{t('lgsSalesKit:remarks')}
									{/* 備註 */}
								</NextToLast>
								<LastTH
									style={{
										width: '48px',
										textAlign: 'center',
									}}
								></LastTH>
							</TR>
						</THead>
						<TBody className="">
							{data?.salesDetailsList
								?.filter((item) => item.action !== 'delete')
								?.map((el, rowIndex) =>
									editingRowIndex !== rowIndex ? (
										<TR
											key={el?.itemGuid || el?.id}
											id={el?.id}
											onClick={(e) =>
												!isDetailRoute &&
												handleEditTable(e, rowIndex, el)
											}
										>
											<TD className="text-center">
												{el?.displayOrder}
											</TD>
											<TD className="text-left">
												{el.salesCategoryCode +
													' ' +
													el.salesCategoryName}
												<div className="mt-xl-1 text-danger text-start">
													{error?.salesDetailsList &&
														error?.salesDetailsList[
															el.id
														]?.salesCategoryCode
															?.message}
												</div>
											</TD>
											{el.salesCategoryCode === 'P' ? (
												<TD className="text-start">
													{el?.materialName}
													<div className="mt-xl-1 text-danger text-start">
														{error?.salesDetailsList &&
															error
																?.salesDetailsList[
																el.id
															]?.materialGuid
																?.message}
													</div>
												</TD>
											) : el.salesCategoryCode === 'S' ? (
												<TD className="text-start">
													{el?.serviceName}
													<div className="mt-xl-1 text-danger text-start">
														{error?.salesDetailsList &&
															error
																?.salesDetailsList[
																el.id
															]?.serviceGuid
																?.message}
													</div>
												</TD>
											) : (
												<></>
											)}

											<TD className="text-end">
												{el?.quantity}
												<div className="mt-xl-1 text-danger text-start">
													{error?.salesDetailsList &&
														error?.salesDetailsList[
															el.id
														]?.quantity?.message}
												</div>
											</TD>
											<TD className="text-end">
												{el?.percentage}
												<div className="mt-xl-1 text-danger text-start">
													{error?.salesDetailsList &&
														error?.salesDetailsList[
															el.id
														]?.percentage?.message}
												</div>
											</TD>
											<TD className="text-left">
												{el?.note}
											</TD>
											<TD className="text-left"></TD>
										</TR>
									) : (
										<TR
											key={
												el?.priceGuid || el.displayOrder
											}
											onClick={(e) =>
												!isDetailRoute &&
												handleEditTable(e, rowIndex, el)
											}
										>
											<TD className="text-center bg-grey1">
												{el?.displayOrder}
											</TD>
											<TD className="text-start bg-grey1">
												<Select
													id="salesCategoryCode"
													name="salesCategoryCode"
													key="salesCategoryCode"
													type="select"
													value={
														el?.salesCategoryCode
													}
													defaultValue={'P'} // 設置預設值
													onChange={(e) => {
														handleInputChange(
															e,
															rowIndex
														);
													}}
												>
													<option value={'P'}>
														P 物料
													</option>

													<option value={'S'}>
														S 服務
													</option>
												</Select>
												<div className="mt-xl-1 text-danger text-start">
													{error?.salesDetailsList &&
														error?.salesDetailsList[
															el.id
														]?.salesCategoryCode
															?.message}
												</div>
											</TD>

											{/* 物料 or 服務 */}
											{el.salesCategoryCode === 'P' ? (
												<TD className="text-start bg-grey1">
													<DialogWrapper className="row flex-nowrap">
														<InputDialogElementData
															type="text"
															value={
																el.materialGuid ||
																''
															}
															onChange={() => {}}
														/>
														<InputDialogElementView
															style={{
																minWidth:
																	'85px',
																cursor: 'auto',
															}}
															value={
																el.materialName ||
																''
															}
															onChange={() => {}}
														/>
														<CancelInputBtn
															onClick={() => {
																handleCancelClick(
																	rowIndex
																);
															}}
															isValueExist={
																el.materialGuid
															}
														>
															<Icn_InputYellowCancel />
														</CancelInputBtn>

														<DialogBtn
															style={{
																cursor: 'pointer',
															}}
															onClick={() =>
																handleOpen(
																	rowIndex,
																	'material'
																)
															}
														>
															<Icn_InputDialog />
														</DialogBtn>
													</DialogWrapper>
													<div className="mt-xl-1 text-danger text-start">
														{error?.salesDetailsList &&
															error
																?.salesDetailsList[
																el.id
															]?.materialGuid
																?.message}
													</div>
												</TD>
											) : el.salesCategoryCode === 'S' ? (
												<TD className="text-start bg-grey1">
													<DialogWrapper className="row flex-nowrap">
														<InputDialogElementData
															type="text"
															value={
																el.serviceGuid ||
																''
															}
															onChange={() => {}}
														/>
														<InputDialogElementView
															style={{
																minWidth:
																	'85px',
																cursor: 'auto',
															}}
															value={
																el.serviceName ||
																''
															}
															onChange={() => {}}
														/>
														<CancelInputBtn
															onClick={() => {
																handleCancelClick(
																	rowIndex
																);
															}}
															isValueExist={
																el.serviceGuid
															}
														>
															<Icn_InputYellowCancel />
														</CancelInputBtn>

														<DialogBtn
															style={{
																cursor: 'pointer',
															}}
															onClick={() =>
																handleOpen(
																	rowIndex,
																	'service'
																)
															}
														>
															<Icn_InputDialog />
														</DialogBtn>
													</DialogWrapper>

													<div className="mt-xl-1 text-danger text-start">
														{error?.salesDetailsList &&
															error
																?.salesDetailsList[
																el.id
															]?.serviceGuid
																?.message}
													</div>
												</TD>
											) : (
												<></>
											)}

											<TD className="text-start bg-grey1">
												<Input
													name="quantity"
													value={el?.quantity}
													active={el?.active}
													type="number"
													min="0"
													onChange={(e) => {
														handleInputChange(
															e,
															rowIndex
														);
													}}
												/>
												<div className="mt-xl-1 text-danger text-start">
													{error?.salesDetailsList &&
														error?.salesDetailsList[
															el.id
														]?.quantity?.message}
												</div>
											</TD>
											<TD className="text-start bg-grey1">
												<Input
													name="percentage"
													value={el?.percentage}
													active={el?.active}
													type="number"
													min="0"
													onChange={(e) => {
														handleInputChange(
															e,
															rowIndex
														);
													}}
												/>
												<div className="mt-xl-1 text-danger text-start">
													{error?.salesDetailsList &&
														error?.salesDetailsList[
															el.id
														]?.percentage?.message}
												</div>
											</TD>

											<TD className="text-left bg-grey1">
												<Input
													name="note"
													value={el?.note}
													active={el?.active}
													type="text"
													onChange={(e) => {
														handleInputChange(
															e,
															rowIndex
														);
													}}
												/>
											</TD>
											<TD className="text-left position-relative bg-grey1">
												<div
													className="position-absolute top-50 start-50 translate-middle cursor"
													role="button"
													onClick={(e) =>
														handleDeleteEstimatedPrice(
															e,
															el.id
														)
													}
												>
													<Icn_deleted />
												</div>
											</TD>
										</TR>
									)
								)}
						</TBody>
					</IndexTable>
				</div>
			</div>
		</>
	);
}
