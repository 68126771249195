import { useState, useEffect } from 'react';
import useAxios from '../../../../../../../hooks/useAxios';
import { useLocation } from 'react-router-dom';
import { Table } from '../../../../../../../components/exports';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

function LgsPurchaseOrder_PaymentTerm({ salesOrderPaymentTermList, foreignCurrency, t }) {
	//#region state initiation
	const [tableData, setTableData] = useState([]);

	const [tableFooter, setTableFooter] = useState([]);
	const { isLoading, error, sendRequest: axiosPaymentTermList } = useAxios();

	console.log(salesOrderPaymentTermList)

	//#endregion

	//#region variable initiation

	const guid = useLocation().pathname.split('/').pop();

	const headTitleList = [
		'#',
		t('lgsSalesOrderPayment:page2.paymentTerms.paymentDateBasis'),
		t('lgsSalesOrderPayment:page2.paymentTerms.paymentDays'),
		t('lgsSalesOrderPayment:page2.paymentTerms.paymentDate'),
		t('lgsSalesOrderPayment:page2.paymentTerms.paymentAmount'),
		t('lgsSalesOrderPayment:page2.paymentTerms.paymentPercentage'),
		t('lgsSalesOrderPayment:page2.paymentTerms.annotation'),
	];

	const bodyTitleList = [
		'displayOrder',
		'baseOnName',
		'days',
		'payDate',
		'amount',
		'percentage',
		'note',
	];

	const tableHeadStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			textAlign: 'center',
		},
		{
			width: '304px',
			textAlign: 'center',
		},
	];

	const tableBodyStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'right',
		},
		{
			textAlign: 'right',
		},
		{
			width: '304px',
			textAlign: 'left',
		},
	];

	const baseOnObj = {
		0: '約定付款日',
		1: '採購日',
		2: '交貨日',
		3: '收貨日',
		4: '驗收日',
	};

	//#endregion

	useEffect(() => {
		console.log(salesOrderPaymentTermList)
		let totalAmount = 0
		let totalPercentage = 0

		const newSalesPaymentTermList = [...salesOrderPaymentTermList];

		salesOrderPaymentTermList?.forEach((el, idx) => {
			const {baseOn, payDate, amount, foreignAmount, percentage} = el;
			if (foreignCurrency) {
				totalAmount += Number(foreignAmount)
				newSalesPaymentTermList[idx].amount = foreignAmount
				newSalesPaymentTermList[idx].baseOnName = baseOnObj[baseOn];
				newSalesPaymentTermList[idx].payDate = dayjs(payDate).format('YYYY-MM-DD')
			} else {
				totalAmount += Number(amount)
				newSalesPaymentTermList[idx].baseOnName = baseOnObj[baseOn];
				newSalesPaymentTermList[idx].payDate = dayjs(payDate).format('YYYY-MM-DD')
			}

			totalPercentage = totalPercentage + Number(percentage)
		})

		setTableData(newSalesPaymentTermList);
		setTableFooter([
			'',
			'',
			'',
			t('lgsSalesOrderPayment:page2.totalAmount'),
			totalAmount,
			totalPercentage,
			'',
		]);
	}, []);

	if (error) {
		console.log('error !', error);
	}

	console.log('tableData', tableData)

	return (
		<>
			{tableData && (
				<Table
					guidName="paymentTermGuid"
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					tableData={tableData}
					tableFooter={tableFooter}
				/>
			)}
		</>
	);
}

export default LgsPurchaseOrder_PaymentTerm;
