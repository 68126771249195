import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Icn_Cancel } from "../../../../../assets/images/icn_Cancel.svg";
import { ReactComponent as Icn_InputYellowCancel } from "../../../../../assets/images/icn_InputYellowCancel.svg";
import { ReactComponent as Icn_InputDialog } from "../../../../../assets/images/icn_inputDialog.svg";
import { useCookies } from "react-cookie";
import { DialogCol } from "./DialogCol";
import { useTranslation } from "react-i18next";

// #region styled-components
const ModalWrapper = styled.div`
  position: fixed;
  /* top: 0;
	left: 0; */
  z-index: 1000;
  /* width: 100%; */
  /* height: 100%; */
`;

const ModalDialog = styled.div`
  max-width: 1000px !important;
  min-height: calc(100% - 3.5rem);
`;

const LabelNormal = styled.label`
  font-size: 12px;
  color: var(--grey4);
  margin-bottom: 6px;
  text-align: left;
`;

const InputElement = styled.input`
  border: ${(props) =>
    props.isDetailView ? "none" : "1px solid var(--grey2)"};
  border-bottom: ${(props) =>
    props.isDetailView ? "1px solid var(--grey1)" : "1px solid var(--grey2)"};
  border-radius: ${(props) => (props.isDetailView ? "none" : "4px")};
  padding: 6px 8px;
  height: 32px;
  &:focus-visible {
    outline: 0;
  }
  &:disabled {
    background-color: ${(props) =>
      props.isDetailView ? "transparent" : "#eff2f5"};
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const SelectElement = styled.select`
  border: ${(props) =>
    props.isDetailView ? "none" : "1px solid var(--grey2)"};
  border-bottom: ${(props) =>
    props.isDetailView ? "1px solid var(--grey1)" : "1px solid var(--grey2)"};
  border-radius: ${(props) => (props.isDetailView ? "none" : "4px")};

  padding: 6px 8px;
  height: 32px;
  &:focus-visible {
    outline: 0;
  }
  &:disabled {
    background-color: ${(props) =>
      props.isDetailView ? "transparent" : "#eff2f5"};
  }

  /* 下拉箭頭圖案 */
  background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
    linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
  background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
  background-size: ${(props) =>
    props.isDetailView ? "0px" : "6px 6px, 6px 6px"};
  background-repeat: no-repeat;
  -webkit-appearance: none;
`;

// -----Dialog------
const DialogWrapper = styled.div`
  position: relative;
  align-items: stretch;
  width: 100%;
  height: 25px;
  --bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
  display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
  flex: 1 1 auto;
  border: ${(props) =>
    props.isDetailView ? "1px solid var(--grey1)" : "1px solid var(--grey2)"};
  border-right: ${(props) =>
    props.isDetailView ? "1px solid var(--grey1)" : 0};
  border-radius: ${(props) => (props.isDetailView ? "4px" : "4px 0 0 4px")};
  padding: 6px 8px;
  height: 25px;
  &:focus-visible {
    outline: 0;
  }
  pointer-events: none;
  background-color: ${(props) =>
    props.isDetailView ? "#eff2f5" : "transparent"};
`; // input顯示欄位

const CancelInputBtn = styled.span`
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 3px;
  right: 24px;
  width: 24px;
  text-align: center;
  display: ${(props) => (props.isValueExist ? "inline" : "none")};
  cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  border: 1px solid var(--grey2);
  border-radius: 0 4px 4px 0;
  border-left: 0;
  padding-left: 16px;
  padding-right: 8px;
  cursor: pointer;
`; // dialog按鈕

// #endregion

export const DialogInvoice = ({
  isOpen,
  setIsOpen,
  modalData,
  setModalData,
  firstDialogName,
  backEndData,
  pageData,
  setPageData,
  invoiceDialogData,
  setInvoiceDialogData,
  tableBodyData,
  setTableBodyData,
  evlAllAmount,
  decimalPlaceTl,
  isDetailView,
}) => {
  //#region state initialization

  const { t } = useTranslation(["common", "lgsPurchaseOrderGoodsInvoice"]); // i18n

  // prop
  const {
    invoiceFormatTypeList,
    invoiceTaxRateTypeList,
    purchaserList,
    salesList,
  } = backEndData;
  //console.log(backEndData);
  //console.log("tableBodyData", tableBodyData);

  // state
  const [isSecondDialogOpen, setIsSecondDialogOpen] = useState(false);

  // cookies
  const [cookies] = useCookies();
  let countryCode = cookies._company_country_code;
  let companyName = cookies._company_name;
  let taxID = cookies._company_tax_id;
  //let countryCode = 'CN';

  //#endregion

  //#region function
  // 轉換浮點數，並四捨五入到小數第decimalPlaces位
  function convertToFloat(inputStr, decimalPlace) {
    console.log(inputStr);

    //
    if (inputStr === "") return "";

    // 1.當數字的最後一位為"."，並且同時只能有一個"."時，直接return現在的輸入
    inputStr = inputStr.toString();
    if (
      inputStr.endsWith(".") &&
      inputStr.indexOf(".") === inputStr.lastIndexOf(".")
    )
      return inputStr;

    // 2.其後的浮點數需才需透過以下修正
    let floatNum = parseFloat(inputStr);

    if (!isNaN(floatNum)) {
      floatNum =
        Math.round(floatNum * Math.pow(10, decimalPlace)) /
        Math.pow(10, decimalPlace);
    }
    return floatNum;
  }
  //#endregion

  //#region dialogInvoice
  // Modal儲存
  function modalSave(createEditDetail) {
    const newPageData = JSON.parse(JSON.stringify(pageData));
    let falseCount = 0;

    // 1.驗證
    if (createEditDetail === "create" || createEditDetail === "edit") {
      let newInvoiceDialogData = { ...invoiceDialogData };
      let requiredInput = ["invoiceDate", "netAmount", "taxAmount"];
      console.log(newInvoiceDialogData);

      requiredInput.forEach((inputName) => {
        if (
          newInvoiceDialogData[inputName] === null ||
          newInvoiceDialogData[inputName] === ""
        ) {
          newInvoiceDialogData[`${inputName}Warning`] = t(
            "lgsPurchaseOrderGoodsInvoice:fillData"
          );
          falseCount++;
        }
      });
      setInvoiceDialogData(newInvoiceDialogData);
    }
    console.log(falseCount);

    // 2.存資料
    if (falseCount === 0) {
      if (createEditDetail === "edit") {
        // edit
        // 1.pageData
        const invoiceItemList = newPageData.invoiceItemList;
        const newInvoiceItemList = invoiceItemList.map((item) => {
          if (item.invoiceTempGuid === invoiceDialogData.invoiceTempGuid) {
            let newInvoiceDialogData = { ...invoiceDialogData };

            // vatInvoiceFormatViewName如果是其他憑證，直接用vatInvoiceFormat名字
            if (firstDialogName === "govInvoiceBtn") {
              newInvoiceDialogData.vatInvoiceFormatViewName =
                invoiceDialogData.vatInvoiceFormatViewName;
            } else {
              newInvoiceDialogData.vatInvoiceFormatViewName =
                invoiceDialogData.vatInvoiceFormat;
            }

            return newInvoiceDialogData;
          } else {
            return item;
          }
        });

        newPageData.invoiceItemList = newInvoiceItemList;
        setPageData(newPageData);
        evlAllAmount(newPageData);

        // 2.tableBodyData
        const newTableBodyData = [...tableBodyData];

        newTableBodyData.forEach((item, index) => {
          if (item.invoiceTempGuid === invoiceDialogData.invoiceTempGuid) {
            newTableBodyData[index] = {
              invoiceTempGuid: invoiceDialogData.invoiceTempGuid,
              displayOrder: "",
              governmentInvoice: invoiceDialogData.governmentInvoice,
              vatInvoiceFormatViewName:
                firstDialogName === "govInvoiceBtn"
                  ? invoiceDialogData.vatInvoiceFormatViewName
                  : invoiceDialogData.vatInvoiceFormat,
              invoiceReturn: invoiceDialogData.invoiceReturn,
              invoiceDate: invoiceDialogData.invoiceDate,
              invoiceNo: invoiceDialogData.invoiceNo,
              vatRate: invoiceDialogData.vatRate,
              totalAmount: invoiceDialogData.totalAmount,
              netAmount: invoiceDialogData.netAmount,
              taxAmount: invoiceDialogData.taxAmount,
            };
          }
        });
        setTableBodyData(newTableBodyData);
        handleClose();
      } else if (createEditDetail === "create") {
        // create
        // 1.pageData
        let newInvoiceDialogData = { ...invoiceDialogData };
        // vatInvoiceFormatViewName如果是其他憑證，直接用vatInvoiceFormat名字
        if (firstDialogName === "govInvoiceBtn") {
          newInvoiceDialogData.vatInvoiceFormatViewName =
            invoiceDialogData.vatInvoiceFormatViewName;
        } else {
          newInvoiceDialogData.vatInvoiceFormatViewName =
            invoiceDialogData.vatInvoiceFormat;
        }
        newPageData.invoiceItemList.push(newInvoiceDialogData);
        setPageData(newPageData);
        evlAllAmount(newPageData);
        // 2.tableBodyData
        const newTableBodyData = [...tableBodyData];
        newTableBodyData.push({
          invoiceTempGuid: invoiceDialogData.invoiceTempGuid,
          displayOrder: "",
          governmentInvoice: invoiceDialogData.governmentInvoice,
          vatInvoiceFormatViewName:
            firstDialogName === "govInvoiceBtn"
              ? invoiceDialogData.vatInvoiceFormatViewName
              : invoiceDialogData.vatInvoiceFormat,
          invoiceReturn: invoiceDialogData.invoiceReturn,
          invoiceDate: invoiceDialogData.invoiceDate,
          invoiceNo: invoiceDialogData.invoiceNo,
          vatRate: invoiceDialogData.vatRate,
          totalAmount: invoiceDialogData.totalAmount,
          netAmount: invoiceDialogData.netAmount,
          taxAmount: invoiceDialogData.taxAmount,
        });
        setTableBodyData(newTableBodyData);
        handleClose();
      } else if (createEditDetail === "detail") {
        handleClose();
      }
    }
  }

  // Modal取消(關閉)
  const handleClose = () => {
    // clear dialog data
    setInvoiceDialogData({});
    setIsOpen(false);
  };

  // secondDialog cancel yellow btn
  const handleCancelClick = (btnKeyName) => {
    if (btnKeyName === "vendor") {
      setInvoiceDialogData({
        ...invoiceDialogData,
        salesVendorViewName: "",
        salesVendorGuid: "",
        salesTaxID: "",
        salesTaxName: "",
      });
    }

    if (btnKeyName === "customer") {
      setInvoiceDialogData({
        ...invoiceDialogData,
        purchaserCustomerViewName: "",
        purchaserCustomerGuid: "",
        purchaserTaxID: "",
        purchaserTaxName: "",
      });
    }
  };

  //#endregion

  //#region dialogCol(second dialog)

  const [dialogBtnKeyName, setDialogBtnKeyName] = useState("");
  //console.log('invoiceDialogData', invoiceDialogData);

  // Second Dialog Modal open
  const handleOpen = (secondBtnKeyName) => {
    console.log(backEndData.purchaserList);
    setDialogBtnKeyName(secondBtnKeyName);
    setModalData(
      secondBtnKeyName === "customer"
        ? backEndData.purchaserList
        : secondBtnKeyName === "vendor"
        ? backEndData.salesList
        : []
    );
    setIsSecondDialogOpen(true);
  };

  //#endregion

  return (
    <>
      {/* DialogCol element */}
      <DialogCol
        // indexDialog={indexDialog}
        isOpen={isSecondDialogOpen}
        setIsOpen={setIsSecondDialogOpen}
        modalData={modalData}
        setModalData={setModalData}
        btnKeyName={dialogBtnKeyName}
        pageData={pageData}
        setPageData={setPageData}
        invoiceDialogData={invoiceDialogData}
        setInvoiceDialogData={setInvoiceDialogData}
      />

      {isOpen && (
        <ModalWrapper onClick={handleClose}>
          <ModalDialog
            className="modal-dialog d-flex align-items-center m-0"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="modal-content border-0 bg-white"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: "12px",
              }}
            >
              <div
                className="modal-header px-4 pt-4 pb-3"
                style={{
                  borderBottom: "1px solid var(--grey2)",
                }}
              >
                <h5 className="text-primary fw-bold fontSize125Rem mb-0">
                  {firstDialogName === "govInvoiceBtn"
                    ? t("lgsPurchaseOrderGoodsInvoice:accountingVoucherInvoice")
                    : firstDialogName === "otherInvoiceBtn"
                    ? t("lgsPurchaseOrderGoodsInvoice:otherCertificate")
                    : ""}
                </h5>
                <button
                  style={{ top: "12px", right: "12px" }}
                  type="button"
                  className="close position-absolute btn"
                  onClick={handleClose}
                >
                  <Icn_Cancel />
                </button>
              </div>

              <div className="modal-body text-center pt-4 px-6">
                <section>
                  <div className="row mb-3">
                    <div className="col-lg-3">
                      <div className="d-flex flex-column">
                        <LabelNormal htmlFor={`vatControl`}>
                          {t("common:invoiceDialog.vatControl")}
                        </LabelNormal>
                        <SelectElement
                          id={"vatControl"}
                          value={invoiceDialogData.vatControl}
                          isDetailView={isDetailView}
                          disabled
                          onChange={() => {
                            setInvoiceDialogData({
                              ...invoiceDialogData,
                              vatControl: event.target.value,
                            });
                          }}
                        >
                          <option value="1">
                            {t("common:invoiceDialog.input")}
                          </option>
                          <option value="2">
                            {t("common:invoiceDialog.output")}
                          </option>
                        </SelectElement>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="d-flex flex-column">
                        <LabelNormal htmlFor={`invoiceDate`}>
                          {firstDialogName === "govInvoiceBtn"
                            ? t("common:invoiceDialog.invoiceDate")
                            : t("common:invoiceDialog.invoiceDate2")}
                        </LabelNormal>
                        <InputElement
                          id={"invoiceDate"}
                          type="date"
                          value={invoiceDialogData.invoiceDate}
                          isDetailView={isDetailView}
                          disabled={isDetailView}
                          onChange={() => {
                            setInvoiceDialogData({
                              ...invoiceDialogData,
                              invoiceDate: event.target.value,
                              invoiceDateWarning: "",
                            });
                          }}
                        ></InputElement>
                        <span className="text-danger text-start ms-1 my-1">
                          {invoiceDialogData.invoiceDateWarning}
                        </span>
                      </div>
                    </div>

                    {countryCode === "CN" && (
                      <div className="col-lg-3">
                        <div className="d-flex flex-column">
                          <LabelNormal htmlFor={`invoiceCode`}>
                            {firstDialogName === "govInvoiceBtn"
                              ? t("common:invoiceDialog.invoiceCode")
                              : t("common:invoiceDialog.invoiceCode2")}
                          </LabelNormal>
                          <InputElement
                            id={"invoiceCode"}
                            value={invoiceDialogData.invoiceCode}
                            isDetailView={isDetailView}
                            disabled={isDetailView}
                            onChange={() => {
                              setInvoiceDialogData({
                                ...invoiceDialogData,
                                invoiceCode: event.target.value,
                              });
                            }}
                          ></InputElement>
                        </div>
                      </div>
                    )}

                    <div className="col-lg-3">
                      <div className="d-flex flex-column">
                        <LabelNormal htmlFor={`invoiceNo`}>
                          {firstDialogName === "govInvoiceBtn"
                            ? t("common:invoiceDialog.invoiceNo")
                            : t("common:invoiceDialog.invoiceNo2")}
                        </LabelNormal>
                        <InputElement
                          id={"invoiceNo"}
                          value={invoiceDialogData.invoiceNo}
                          isDetailView={isDetailView}
                          disabled={isDetailView}
                          onChange={() => {
                            setInvoiceDialogData({
                              ...invoiceDialogData,
                              invoiceNo: event.target.value,
                            });
                          }}
                        ></InputElement>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-3">
                      <div className="d-flex flex-column">
                        {firstDialogName === "govInvoiceBtn" ? (
                          <>
                            <LabelNormal htmlFor={`vatInvoiceFormat`}>
                              {t("common:invoiceDialog.vatInvoiceFormat")}
                            </LabelNormal>
                            <SelectElement
                              id={"vatInvoiceFormat"}
                              value={invoiceDialogData.vatInvoiceFormat}
                              isDetailView={isDetailView}
                              disabled={isDetailView}
                              onChange={() => {
                                const vatInvoiceFormatViewName =
                                  invoiceFormatTypeList.find(
                                    (el) =>
                                      el.formatTypeGuid === event.target.value
                                  ).name01;

                                setInvoiceDialogData({
                                  ...invoiceDialogData,
                                  vatInvoiceFormat: event.target.value,

                                  vatInvoiceFormatViewName:
                                    vatInvoiceFormatViewName,
                                });
                              }}
                            >
                              {invoiceFormatTypeList.map((el) => {
                                return (
                                  <option
                                    key={el.formatTypeGuid}
                                    value={el.formatTypeGuid}
                                  >
                                    {el.name01}
                                  </option>
                                );
                              })}
                            </SelectElement>
                          </>
                        ) : firstDialogName === "otherInvoiceBtn" ? (
                          <>
                            <LabelNormal htmlFor={`vatInvoiceFormat`}>
                              {t("common:invoiceDialog.vatInvoiceFormat2")}
                            </LabelNormal>
                            <InputElement
                              id={"vatInvoiceFormat"}
                              value={invoiceDialogData.vatInvoiceFormat}
                              isDetailView={isDetailView}
                              disabled={isDetailView}
                              onChange={() => {
                                setInvoiceDialogData({
                                  ...invoiceDialogData,
                                  vatInvoiceFormat: event.target.value,
                                });
                              }}
                            ></InputElement>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="row">
                        <div className="col-7">
                          <div className="d-flex flex-column">
                            <LabelNormal htmlFor={`vatInvoiceTaxType`}>
                              {t("common:invoiceDialog.vatInvoiceTaxType")}
                            </LabelNormal>
                            <SelectElement
                              id={"vatInvoiceTaxType"}
                              value={invoiceDialogData.vatInvoiceTaxType}
                              isDetailView={isDetailView}
                              disabled={isDetailView}
                              onChange={() => {
                                let organizedVatRate = convertToFloat(
                                  invoiceTaxRateTypeList.find(
                                    (el) =>
                                      el.rateTypeGuid === event.target.value
                                  ).taxRate,
                                  decimalPlaceTl
                                );

                                let organizedTaxAmount = convertToFloat(
                                  (Number(invoiceDialogData.netAmount) *
                                    Number(organizedVatRate)) /
                                    100,
                                  decimalPlaceTl
                                );

                                let organizedTotalAmount = convertToFloat(
                                  Number(invoiceDialogData.netAmount) +
                                    Number(organizedTaxAmount),
                                  decimalPlaceTl
                                );

                                setInvoiceDialogData({
                                  ...invoiceDialogData,
                                  vatInvoiceTaxType: event.target.value,
                                  vatRate: organizedVatRate,
                                  taxAmount: organizedTaxAmount,
                                  totalAmount: organizedTotalAmount,
                                });
                              }}
                            >
                              {invoiceTaxRateTypeList.map((el) => {
                                return (
                                  <option
                                    key={el.rateTypeGuid}
                                    value={el.rateTypeGuid}
                                  >
                                    {el.name01}
                                  </option>
                                );
                              })}
                            </SelectElement>
                          </div>
                        </div>
                        <div className="col-4 px-0 d-flex">
                          <InputElement
                            id={"vatRate"}
                            className="w-100 mt-4"
                            value={
                              Number.isInteger(
                                invoiceTaxRateTypeList.find(
                                  (el) =>
                                    el.rateTypeGuid ===
                                    invoiceDialogData.vatInvoiceTaxType
                                ).taxRate
                              )
                                ? invoiceTaxRateTypeList
                                    .find(
                                      (el) =>
                                        el.rateTypeGuid ===
                                        invoiceDialogData.vatInvoiceTaxType
                                    )
                                    .taxRate.toFixed(0)
                                : invoiceTaxRateTypeList
                                    .find(
                                      (el) =>
                                        el.rateTypeGuid ===
                                        invoiceDialogData.vatInvoiceTaxType
                                    )
                                    .taxRate.toFixed(decimalPlaceTl)
                            }
                            isDetailView={isDetailView}
                            disabled
                          ></InputElement>
                          <span className="ps-2 mt-5">%</span>
                        </div>
                      </div>
                    </div>
                    {countryCode === "TW" && (
                      <div className="col-lg-3">
                        <div className="d-flex flex-column">
                          <LabelNormal htmlFor={`vatInputTaxable`}>
                            {t("common:invoiceDialog.vatInputTaxable")}
                          </LabelNormal>
                          <SelectElement
                            id={"vatInputTaxable"}
                            value={invoiceDialogData.vatInputTaxable}
                            isDetailView={isDetailView}
                            disabled={isDetailView}
                            onChange={() => {
                              setInvoiceDialogData({
                                ...invoiceDialogData,
                                vatInputTaxable: event.target.value,
                              });
                            }}
                          >
                            <option value="0">
                              {t("common:invoiceDialog.nonDeductible")}
                            </option>
                            <option value="1">
                              {t("common:invoiceDialog.deductible")}
                            </option>
                          </SelectElement>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="d-flex flex-column">
                        <LabelNormal htmlFor={`netAmount`}>
                          {t("common:invoiceDialog.netAmount")}
                        </LabelNormal>
                        <InputElement
                          id={"netAmount"}
                          type="number"
                          value={invoiceDialogData.netAmount}
                          isDetailView={isDetailView}
                          disabled={isDetailView}
                          onChange={() => {
                            let organizedNetAmount = convertToFloat(
                              event.target.value,
                              decimalPlaceTl
                            );

                            let organizedTaxAmount = convertToFloat(
                              (Number(event.target.value) *
                                Number(invoiceDialogData.vatRate)) /
                                100,
                              decimalPlaceTl
                            );

                            let organizedTotalAmount = convertToFloat(
                              Number(event.target.value) +
                                (Number(event.target.value) *
                                  Number(invoiceDialogData.vatRate)) /
                                  100,
                              decimalPlaceTl
                            );

                            setInvoiceDialogData({
                              ...invoiceDialogData,
                              netAmount: organizedNetAmount,
                              netAmountWarning: "",
                              taxAmountWarning: "",
                              taxAmount: organizedTaxAmount,
                              totalAmount: organizedTotalAmount,
                            });
                          }}
                          onBlur={() => {
                            setInvoiceDialogData({
                              ...invoiceDialogData,
                              netAmount: Number(
                                invoiceDialogData.netAmount
                              ).toFixed(decimalPlaceTl),
                              taxAmount: Number(
                                invoiceDialogData.taxAmount
                              ).toFixed(decimalPlaceTl),
                              totalAmount: Number(
                                invoiceDialogData.totalAmount
                              ).toFixed(decimalPlaceTl),
                            });
                          }}
                        ></InputElement>
                        <span className="text-danger text-start ms-1 my-1">
                          {invoiceDialogData.netAmountWarning}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="d-flex flex-column">
                        <LabelNormal htmlFor={`taxAmount`}>
                          {t("common:invoiceDialog.taxAmount")}
                        </LabelNormal>
                        <InputElement
                          id={"taxAmount"}
                          type="number"
                          value={invoiceDialogData.taxAmount}
                          isDetailView={isDetailView}
                          disabled={isDetailView}
                          onChange={() => {
                            let organizedTaxAmount = convertToFloat(
                              event.target.value,
                              decimalPlaceTl
                            );

                            let organizedTotalAmount = convertToFloat(
                              Number(event.target.value) +
                                Number(invoiceDialogData.netAmount),
                              decimalPlaceTl
                            );

                            setInvoiceDialogData({
                              ...invoiceDialogData,
                              taxAmount: organizedTaxAmount,
                              netAmountWarning: "",
                              taxAmountWarning: "",
                              totalAmount: organizedTotalAmount,
                            });
                          }}
                          onBlur={() => {
                            setInvoiceDialogData({
                              ...invoiceDialogData,
                              netAmount: Number(
                                invoiceDialogData.netAmount
                              ).toFixed(decimalPlaceTl),
                              taxAmount: Number(
                                invoiceDialogData.taxAmount
                              ).toFixed(decimalPlaceTl),
                              totalAmount: Number(
                                invoiceDialogData.totalAmount
                              ).toFixed(decimalPlaceTl),
                            });
                          }}
                        ></InputElement>
                        <span className="text-danger text-start ms-1 my-1">
                          {invoiceDialogData.taxAmountWarning}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="d-flex flex-column">
                        <LabelNormal htmlFor={`totalAmount`}>
                          {t("common:invoiceDialog.totalAmount")}
                        </LabelNormal>
                        <InputElement
                          id={"totalAmount"}
                          value={invoiceDialogData.totalAmount}
                          isDetailView={isDetailView}
                          disabled
                          onChange={() => {}}
                        ></InputElement>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="d-flex flex-column">
                        <LabelNormal htmlFor={`currencyName`}>
                          {t("common:invoiceDialog.currencyName")}
                        </LabelNormal>
                        <InputElement
                          id={"currencyName"}
                          value={invoiceDialogData.currencyName}
                          isDetailView={isDetailView}
                          disabled
                        ></InputElement>
                      </div>
                    </div>
                  </div>
                </section>
                <hr
                  className="mt-5 mb-4"
                  style={{
                    border: `1px solid var(--grey2)`,
                  }}
                />
                <section className="row">
                  <div className="col-lg-6">
                    <div className="row mb-3">
                      <div className="col-lg-3">
                        <h6 className="my-2 fw-bold text-start fontSize875Rem">
                          {t("common:invoiceDialog.purchaser")}
                        </h6>
                      </div>

                      <div className="col-lg-9">
                        <div className="d-flex flex-column">
                          <InputElement
                            id={"companyName"}
                            value={companyName}
                            isDetailView={isDetailView}
                            disabled
                          ></InputElement>
                        </div>

                        {/* <DialogWrapper className="h-100 row flex-nowrap">
													<InputDialogElementData
														id="purchaserCustomerGuid"
														type="text"
														value={
															invoiceDialogData.purchaserCustomerGuid
														}
														onChange={() => {}}
													/>
													<InputDialogElementView
														id="purchaserCustomerViewName"
														className="h-100"
														style={{
															minWidth: '85px',
															cursor: 'auto',
														}}
														value={
															invoiceDialogData.purchaserCustomerViewName
														}
														isDetailView={
															isDetailView
														}
														onChange={() => {}}
													/>
													{isDetailView ? (
														<></>
													) : (
														<>
															<CancelInputBtn
																onClick={() => {
																	handleCancelClick(
																		'customer'
																	);
																}}
																isValueExist={
																	invoiceDialogData.purchaserCustomerViewName &&
																	true
																}
															>
																<Icn_InputYellowCancel />
															</CancelInputBtn>

															<DialogBtn
																style={{
																	cursor: 'pointer',
																}}
																onClick={() =>
																	handleOpen(
																		'customer'
																	)
																}
															>
																<Icn_InputDialog />
															</DialogBtn>
														</>
													)}
												</DialogWrapper> */}
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-5">
                        <div className="d-flex flex-column">
                          <LabelNormal htmlFor={`purchaserTaxID`}>
                            {countryCode == "TW"
                              ? t("common:invoiceDialog.UBN")
                              : countryCode == "CN"
                              ? t("common:invoiceDialog.TIN")
                              : ""}
                          </LabelNormal>
                          <InputElement
                            id={"purchaserTaxID"}
                            value={taxID}
                            isDetailView={isDetailView}
                            disabled
                            onChange={() => {
                              setInvoiceDialogData({
                                ...invoiceDialogData,
                                purchaserTaxID: event.target.value,
                              });
                            }}
                          ></InputElement>
                        </div>
                      </div>
                      <div className="form-group col-lg-7">
                        <div className="d-flex flex-column">
                          <LabelNormal htmlFor={`purchaserTaxName`}>
                            {t("common:invoiceDialog.name")}
                          </LabelNormal>
                          <InputElement
                            id={"purchaserTaxName"}
                            value={companyName}
                            isDetailView={isDetailView}
                            disabled
                            onChange={() => {
                              setInvoiceDialogData({
                                ...invoiceDialogData,
                                purchaserTaxName: event.target.value,
                              });
                            }}
                          ></InputElement>
                        </div>
                      </div>
                    </div>
                    {countryCode === "CN" && (
                      <>
                        <div className="row my-3">
                          <div className="d-flex flex-column">
                            <LabelNormal htmlFor={`purchaserAddTel`}>
                              {t("common:invoiceDialog.addressAndPhone")}
                            </LabelNormal>
                            <InputElement
                              id={"purchaserAddTel"}
                              value={invoiceDialogData.purchaserAddTel}
                              isDetailView={isDetailView}
                              disabled={isDetailView}
                              onChange={() => {
                                setInvoiceDialogData({
                                  ...invoiceDialogData,
                                  purchaserAddTel: event.target.value,
                                });
                              }}
                            ></InputElement>
                          </div>
                        </div>
                        <div className="row">
                          <div className="d-flex flex-column">
                            <LabelNormal htmlFor={`purchaserBankAccount`}>
                              {t("common:invoiceDialog.bankAndAccount")}
                            </LabelNormal>
                            <InputElement
                              id={"purchaserBankAccount"}
                              value={invoiceDialogData.purchaserBankAccount}
                              isDetailView={isDetailView}
                              disabled={isDetailView}
                              onChange={() => {
                                setInvoiceDialogData({
                                  ...invoiceDialogData,
                                  purchaserBankAccount: event.target.value,
                                });
                              }}
                            ></InputElement>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <div className="row mb-3">
                      <div className="col-lg-3">
                        <h6 className="my-2 fw-bold text-start fontSize875Rem">
                          {t("common:invoiceDialog.seller")}
                        </h6>
                      </div>
                      <div className="col-lg-9">
                        <DialogWrapper className="h-100 row flex-nowrap">
                          <InputDialogElementData
                            id="salesVendorGuid"
                            type="text"
                            value={invoiceDialogData.salesVendorGuid}
                            onChange={() => {}}
                          />
                          <InputDialogElementView
                            id="salesVendorViewName"
                            className="h-100"
                            style={{
                              minWidth: "85px",
                              cursor: "auto",
                            }}
                            value={invoiceDialogData.salesVendorViewName}
                            isDetailView={isDetailView}
                            onChange={() => {}}
                          />
                          {isDetailView ? (
                            <></>
                          ) : (
                            <>
                              <CancelInputBtn
                                onClick={() => {
                                  handleCancelClick("vendor");
                                }}
                                isValueExist={
                                  invoiceDialogData.salesVendorViewName && true
                                }
                              >
                                <Icn_InputYellowCancel />
                              </CancelInputBtn>

                              <DialogBtn
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => handleOpen("vendor")}
                              >
                                <Icn_InputDialog />
                              </DialogBtn>
                            </>
                          )}
                        </DialogWrapper>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-5">
                        <div className="d-flex flex-column">
                          <LabelNormal htmlFor={`salesTaxID`}>
                            {countryCode == "TW"
                              ? t("common:invoiceDialog.UBN")
                              : countryCode == "CN"
                              ? t("common:invoiceDialog.TIN")
                              : ""}
                          </LabelNormal>
                          <InputElement
                            id={"salesTaxID"}
                            value={invoiceDialogData.salesTaxID}
                            isDetailView={isDetailView}
                            disabled={isDetailView}
                            onChange={() => {
                              setInvoiceDialogData({
                                ...invoiceDialogData,
                                salesTaxID: event.target.value,
                              });
                            }}
                          ></InputElement>
                        </div>
                      </div>
                      <div className="form-group col-lg-7">
                        <div className="d-flex flex-column">
                          <LabelNormal htmlFor={`salesTaxName`}>
                            {t("common:invoiceDialog.name")}
                          </LabelNormal>
                          <InputElement
                            id={"salesTaxName"}
                            value={invoiceDialogData.salesTaxName}
                            isDetailView={isDetailView}
                            disabled={isDetailView}
                            onChange={() => {
                              setInvoiceDialogData({
                                ...invoiceDialogData,
                                salesTaxName: event.target.value,
                              });
                            }}
                          ></InputElement>
                        </div>
                      </div>
                    </div>

                    {countryCode === "CN" && (
                      <>
                        <div className="row my-3">
                          <div className="d-flex flex-column">
                            <LabelNormal htmlFor={`salesAddTel`}>
                              {t("common:invoiceDialog.addressAndPhone")}
                            </LabelNormal>
                            <InputElement
                              id={"salesAddTel"}
                              value={invoiceDialogData.salesAddTel}
                              isDetailView={isDetailView}
                              disabled={isDetailView}
                              onChange={() => {
                                setInvoiceDialogData({
                                  ...invoiceDialogData,
                                  salesAddTel: event.target.value,
                                });
                              }}
                            ></InputElement>
                          </div>
                        </div>
                        <div className="row">
                          <div className="d-flex flex-column">
                            <LabelNormal htmlFor={`salesBankAccount`}>
                              {t("common:invoiceDialog.bankAndAccount")}
                            </LabelNormal>
                            <InputElement
                              id={"salesBankAccount"}
                              value={invoiceDialogData.salesBankAccount}
                              isDetailView={isDetailView}
                              disabled={isDetailView}
                              onChange={() => {
                                setInvoiceDialogData({
                                  ...invoiceDialogData,
                                  salesBankAccount: event.target.value,
                                });
                              }}
                            ></InputElement>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </section>
              </div>

              <hr
                className="mx-4 mt-3 mb-0"
                style={{
                  border: `1px solid var(--grey2)`,
                }}
              />

              {/* 確定btn */}
              <div className="modal-footer justify-content-center border-0 pt-4 pb-5">
                <button
                  type="button"
                  className="btn btn-primary mediumBtn mx-1"
                  style={{
                    margin: "0 20px",
                    width: "120px",
                  }}
                  onClick={() => modalSave(invoiceDialogData.createEditDetail)}
                >
                  {t("common:button.confirmButton")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primarylllt mediumBtn mx-1"
                  onClick={handleClose}
                >
                  {t("common:button.cancelButton")}
                </button>
              </div>
            </div>
          </ModalDialog>
        </ModalWrapper>
      )}
    </>
  );
};
