import { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import FormControlInput from '../../../../../components/Inputs/FormControlInput';
import { SubmitConfirm } from '../SubmitConfirm';
import { useTranslation } from 'react-i18next';

// #region styled-components
const Wrapper = styled.div`
	border-radius: 0 0 0.75rem 0.75rem;
`;

const NumberingRuleArea = styled.div`
	height: 610px;
	border-radius: 0;
	border: 1px solid var(--grey1);
`;
//#endregion

function DialogListStatus_RightCol_Create(props) {
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	const {
		leftActive,
		handleLeftColBtnClick,
		setMiddleIndexList,
		middleOriginIndexList,
		setRightActive,
		langList,
		getSpecList,
		salesKitGuid,
	} = props;

	const [data, setData] = useState({
		name01: '',
		name02: '',
		name03: '',
		displayOrder: '',
	});

	const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
		useState(false);
	const [err, setErr] = useState({ name01: false, displayOrder: false });

	const [tempCreateData, setTempCreateData] = useState({
		specGuid: '00000000-0000-0000-0000-000000000000',
		status: '0', // 預設可銷售 "0"
	});

	const handleSubmit = (status) => {
		const { name01, name02, name03, displayOrder, rowStamp } = data;

		axios
			.post(`api/Logistics/LgsSalesKitSpec/insert`, {
				salesKitGuid: salesKitGuid,
				feature: leftActive.split('feature')[1],
				name01,
				name02,
				name03,
				displayOrder,
				status,
				rowStamp,
			})
			.then((res) => {
				if (res.status === 200) {
					handleLeftColBtnClick(leftActive);
					setIsSubmitConfirmDialogOpen(false);

					// 清空回復最初的值
					setData({
						name01: '',
						name02: '',
						name03: '',
						displayOrder: '',
					});

					// 更新specList
					getSpecList();
				} else {
					alert(res.status);
				}
			});
	};

	const [twoOrThreeBtn, setTwoOrThreeBtn] = useState(''); // 開啟兩按鈕或三按鈕的dialog

	const checkInputs = (twoOrThreeBtn) => {
		// 開啟兩按鈕或三按鈕的dialog
		setTwoOrThreeBtn(twoOrThreeBtn);

		// 檢查必填
		let errMsgTemp = { ...err };
		console.log(errMsgTemp);

		if (!data.name01) {
			errMsgTemp = { ...errMsgTemp, name01: true };
		}

		if (!data.displayOrder) {
			errMsgTemp = { ...errMsgTemp, displayOrder: true };
		}

		setErr(errMsgTemp);

		if (errMsgTemp.name01 === true || errMsgTemp.displayOrder === true) {
			return;
		} else {
			return setIsSubmitConfirmDialogOpen(true);
		}
	};

	const handleCancel = () => {
		setRightActive('');
		handleLeftColBtnClick(leftActive);
	};

	return (
		<>
			<div className="h-100 bg-white">
				<div
					className="authTitleWrapper d-flex justify-content-center bg-grey1"
					style={{
						borderRadius: '.75rem .75rem 0 0',
					}}
				>
					<h5
						className="fw-bold text-left text-black m-0 "
						style={{
							fontSize: '14px',
							lineHeight: '40px',
						}}
					>
						{t('lgsSalesKit:sellable')}
						{/* 可銷售 */}
					</h5>
				</div>
				<NumberingRuleArea>
					<SubmitConfirm
						t={t}
						isOpen={isSubmitConfirmDialogOpen}
						setIsOpen={setIsSubmitConfirmDialogOpen}
						handleClickInactive={() => handleSubmit('1')}
						handleClickActive={() => handleSubmit('0')}
						message={t('dialog.saveConfirm')}
						twoOrThreeBtn={twoOrThreeBtn}
					/>
					<div className="position-relative">
						<Wrapper className="bg-whit d-flex flex-column px-3 py-4 position-relative bg-white">
							<div className="col-12 mb-2">
								<FormControlInput
									id="displayOrder"
									labelText={'序號'}
									value={data.displayOrder}
									type="number"
									onChange={(e) => {
										setData({
											...data,
											displayOrder: e.target.value,
										});

										// 暫存的新增資料
										setTempCreateData({
											...tempCreateData,
											displayOrder: e.target.value,
										});

										setMiddleIndexList([
											...middleOriginIndexList,
											{
												...tempCreateData,
												displayOrder: e.target.value,
											},
										]);

										setErr({
											...err,
											displayOrder:
												e.target.value === ''
													? true
													: false,
										});
									}}
									disabled={false}
									disabledCssType={0}
									isErr={err.displayOrder}
									errMsg={t('errMsg.mustFill')}
								/>
							</div>
							<div className="col-12 mb-2">
								<FormControlInput
									id="name01"
									labelText={t('lgsSalesKit:name')}
									//名稱
									value={data.name01}
									onChange={(e) => {
										setData({
											...data,
											name01: e.target.value,
										});

										// 暫存的新增資料
										setTempCreateData({
											...tempCreateData,
											name01: e.target.value,
										});

										setMiddleIndexList([
											...middleOriginIndexList,
											{
												...tempCreateData,
												name01: e.target.value,
											},
										]);

										setErr({
											...err,
											name01:
												e.target.value === ''
													? true
													: false,
										});
									}}
									disabled={false}
									disabledCssType={0}
									isErr={err.name01}
									errMsg={t('errMsg.mustFill')}
								/>
							</div>
							{langList[1] !== '' && (
								<div className="col-12 mb-2">
									<FormControlInput
										id="name02"
										labelText={`${t('lgsSalesKit:name')}( ${
											langList[1]
										} )`}
										value={data.name02}
										onChange={(e) => {
											setData({
												...data,
												name02: e.target.value,
											});

											// 暫存的新增資料
											setTempCreateData({
												...tempCreateData,
												name02: e.target.value,
											});

											setMiddleIndexList([
												...middleOriginIndexList,
												{
													...tempCreateData,
													name02: e.target.value,
												},
											]);
										}}
										disabled={false}
										disabledCssType={0}
									/>
								</div>
							)}
							{langList[2] !== '' && (
								<div className="col-12 mb-2">
									<FormControlInput
										id="name03"
										labelText={`${t('lgsSalesKit:name')}( ${
											langList[2]
										} )`}
										value={data.name03}
										onChange={(e) => {
											setData({
												...data,
												name03: e.target.value,
											});

											// 暫存的新增資料
											setTempCreateData({
												...tempCreateData,
												name03: e.target.value,
											});

											setMiddleIndexList([
												...middleOriginIndexList,
												{
													...tempCreateData,
													name03: e.target.value,
												},
											]);
										}}
										disabled={false}
										disabledCssType={0}
									/>
								</div>
							)}

							<div className="col-12 d-flex flex-column">
								{/* <BtnNormal2
									className="btn-primary me-3 w-100 mb-2 py-1"
									handleClick={() => checkInputs(true)}
									word="啟用"
									children={<ActivateIcon className="me-2" />}
									elementStyle={{ width: '88px' }}
								></BtnNormal2> */}

								<div className="d-flex">
									<BtnNormal2
										className="btn-primary mediumBtn me-2"
										handleClick={() => checkInputs('2')}
									>
										{t('button.saveButton')}
									</BtnNormal2>
									<BtnNormal2
										className="btn-outline-primary mediumBtn me-0"
										handleClick={() => handleCancel()}
									>
										{t('button.cancelButton')}
									</BtnNormal2>
								</div>
							</div>
						</Wrapper>
					</div>
				</NumberingRuleArea>
			</div>
		</>
	);
}

export default DialogListStatus_RightCol_Create;
