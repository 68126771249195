import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_Cancel } from '../../../../assets/images/icn_Cancel.svg';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// #region styled-components
const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	width: 100%;
	height: 100%;
`;

const ModalDialog = styled.div`
	max-width: 448px !important;
	min-height: calc(100% - 10.5rem);
`;

// #endregion

export const DialogFeatureDeleteConfirm = ({
	dialogFeatureDeleteData,
	setDialogFeatureDeleteData,
	handleFinalSubmit,
	pageData,
	setPageData,
	specSelectedList,
	setSpecSelectedList,
}) => {
	const { t } = useTranslation(['common', 'lgsSalesKit']);
	const [isDialogFeatureDeleteClicked, setIsDialogFeatureDeleteClicked] =
		useState(false);

	console.log(dialogFeatureDeleteData);

	/**
	 * Modal儲存
	 **/
	function modalSave() {
		let updateData = {};
		if (dialogFeatureDeleteData.index === 0) {
			updateData = {
				feature1Name01: '',
				feature1Name02: '',
				feature1Name03: '',
			};

			setSpecSelectedList([
				null,
				specSelectedList[1],
				specSelectedList[2],
			]);
		} else if (dialogFeatureDeleteData.index === 1) {
			updateData = {
				feature2Name01: '',
				feature2Name02: '',
				feature2Name03: '',
			};
			setSpecSelectedList([
				specSelectedList[0],
				null,
				specSelectedList[2],
			]);
		} else if (dialogFeatureDeleteData.index === 2) {
			updateData = {
				feature3Name01: '',
				feature3Name02: '',
				feature3Name03: '',
			};
			setSpecSelectedList([
				specSelectedList[0],
				specSelectedList[1],
				null,
			]);
		}

		setPageData({
			...pageData,
			goodsItemInfo: {
				...pageData.goodsItemInfo,
				...updateData,
			},
		});

		setIsDialogFeatureDeleteClicked(true);
		handleClose();
	}

	/**
	 * Modal cancel close
	 **/
	const handleClose = () => {
		setDialogFeatureDeleteData({
			...dialogFeatureDeleteData,
			isOpen: false,
		});
	};

	useEffect(() => {
		if (isDialogFeatureDeleteClicked) {
			handleFinalSubmit(false, 'notChange', true); // (isCopyItemSave, statusAction, isFeatureItemSave)

			// TODO:刪除相關的spec
			// axios
			// 	.put(`api/Logistics/LgsSalesKitSpec/Delete`, {
			// 		feature: dialogFeatureDeleteData.index + 1,
			// 		specGuid:"XXXXX"
			// 	})
			// 	.then((res) => {
			// 		console.log(res.data);
			// 	});

			setIsDialogFeatureDeleteClicked(false);
		}
	}, [isDialogFeatureDeleteClicked]);

	return (
		<>
			{dialogFeatureDeleteData.isOpen && (
				<ModalWrapper onClick={handleClose}>
					<ModalDialog
						className="modal-dialog d-flex align-items-center"
						onClick={(e) => e.stopPropagation()}
					>
						<div
							className="modal-content border-0 bg-white"
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
								borderRadius: '12px',
							}}
						>
							<div className="modal-header border-0 px-4 pt-5 pb-4">
								<h5 className="w-100 text-center text-danger fw-bold fontSize1Rem mb-0">
									{dialogFeatureDeleteData.title}
								</h5>
								<button
									style={{ top: '12px', right: '12px' }}
									type="button"
									className="btn close position-absolute "
									data-dismiss="modal"
									aria-label="Close"
									onClick={handleClose}
								>
									<Icn_Cancel />
								</button>
							</div>

							<div className="modal-body text-center px-11 py-0">
								<h6 className="mb-2 text-danger fontSize875Rem">
									將連同所有"此特徵"的資料一併刪除，包含"照片"、"銷售明細"、"價格條件"
								</h6>
								<h6 className="mb-0 fontSize875Rem">
									確定要全部刪除嗎?
								</h6>
							</div>

							<div className="modal-footer justify-content-center border-0 py-5">
								<button
									type="button"
									className="btn btn-outline-primarylllt mediumBtn mx-2"
									onClick={handleClose}
								>
									{t('common:button.cancelButton')}
								</button>
								<button
									type="button"
									className="btn btn-primary mediumBtn mx-2"
									style={{
										margin: '0 20px',
										width: '120px',
									}}
									onClick={modalSave}
								>
									{t('common:button.confirmButton')}
								</button>
							</div>
						</div>
					</ModalDialog>
				</ModalWrapper>
			)}
		</>
	);
};
