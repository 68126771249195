import { useState } from "react";
import PageTitleBar from "../../../../components/PageTitleBar/PageTitleBar";
import Page0 from "./Pages/Page0/Page0";
import Page1 from "./Pages/Page1/Page1";
import Page2 from "./Pages/Page2/Page2";
import Page3 from "./Pages/Page3/Page3";
import { DialogConfirm } from "./Pages/DialogConfirm";
import { useTranslation } from "react-i18next";

function LgsSalesOrderPreparing_Create() {
  //#region state initialization
  // i18n
  const { t } = useTranslation(["lgsSalesOrderPreparing", "common"]);

  // state

  // YL 2024-03-05 09:18
  // 因為 page1 就已經包括所有資料更改, 所以 parentData 內不需要分成 page1, page2, 故直接命名為 updateData
  // originalData 為 page1 call api 而得的原資料
  // 以免增加不必要的 re-render, originalData 會在 page1 submit -> page2 前, 才會連同 updateData 一同更新到 parentData
  // 故一開始的 parentData 不需要任何預設內容 -> null
  const [parentData, setParentData] = useState(null);
  const [page, setPage] = useState(0);

  const pageTitle = t("lgsSalesOrderPreparing");
  const processSteps = [
    t("selectOrder"),
    t("goodsDetails"),
    // t("invoiceDetails"),
    t("confirmation"),
  ];

  //#endregion

  //#region handlePage
  const handlePrevPage = () => {
    setPage(page - 1);
  };

  const handleNextPage = () => {
    //console.log("handleNextPage");
    setPage(page + 1);
  };
  //#endregion

  //#region DialogConfirm

  //  返回btn確認dialog
  const [dialogConfirmData, setDialogConfirmData] = useState({
    btnName: "",
    isOpen: false,
    goTo: "",
    title: "",
  });
  const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);
  const [tableBodyData, setTableBodyData] = useState([]);

  // DialogConfirm Modal open
  const handleDialogConfirm = () => {
    const newData = {
      btnName: "goToRoot",
      isOpen: true,
      goTo: "toRoot",
      title: t("confirmLeavePurchaseOrderPage") + "?",
    };
    setDialogConfirmData(newData);
  };

  //#endregion

  //console.log(parentData);

  return (
    <>
      <div id="detailsView">
        <PageTitleBar
          pageTitle={pageTitle}
          previousPageButton={true}
          goTo={-1}
          isDialogConfirm={true}
          handleDialogConfirm={handleDialogConfirm}
          t={t}
        />

        {/* DialogConfirm element */}
        <DialogConfirm
          dialogConfirmData={dialogConfirmData}
          setDialogConfirmData={setDialogConfirmData}
          page={page}
          setPage={setPage}
          setIsConfirmBtnClicked={setIsConfirmBtnClicked}
          t={t}
        />

        <div>
          {page === 0 && (
            <Page0
              parentData={parentData}
              setParentData={setParentData}
              page={page}
              processSteps={processSteps}
              handleNextPage={handleNextPage}
              t={t}
            />
          )}
          {page === 1 && (
            <Page1
              setDialogConfirmData={setDialogConfirmData}
              parentData={parentData}
              setParentData={setParentData}
              tableBodyData={tableBodyData}
              setTableBodyData={setTableBodyData}
              page={page}
              processSteps={processSteps}
              handleNextPage={handleNextPage}
              t={t}
            />
          )}
          {page === 2 && (
            <Page2
              parentData={parentData}
              setParentData={setParentData}
              tableBodyData={tableBodyData}
              setTableBodyData={setTableBodyData}
              page={page}
              setPage={setPage}
              processSteps={processSteps}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              t={t}
            />
          )}
          {page === 3 && (
            <Page3
              parentData={parentData}
              setParentData={setParentData}
              tableBodyData={tableBodyData}
              setTableBodyData={setTableBodyData}
              page={page}
              setPage={setPage}
              processSteps={processSteps}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              t={t}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default LgsSalesOrderPreparing_Create;
