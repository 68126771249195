import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Icn_Search2 } from "../../../../../assets/images/icn_Search2.svg";
import { ReactComponent as Icn_Cancel } from "../../../../../assets/images/icn_Cancel.svg";
import { ReactComponent as Icn_InputYellowCancel } from "../../../../../assets/images/icn_InputYellowCancel.svg";
import useSortableData from "../../../../../hooks/useSortableData";
import { useTranslation } from "react-i18next";

// #region styled-components
const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
`;

const ModalDialog = styled.div`
  max-width: 600px !important;
  min-height: calc(100% - 3.5rem);
`;

const ModalThead = styled.thead`
  padding: 0 0.75rem;
  cursor: pointer !important;
`;
const ModalTh = styled.th`
  padding: 0 0.75rem;
  cursor: pointer !important;
  border: none !important;
`;
const ModalLabel = styled.label`
  padding: 0 0.75rem;
  cursor: pointer !important;
`;
const ModalInput = styled.input`
  padding: 0 0.75rem;
  cursor: pointer !important;
`;

// #endregion

export const DialogCol = ({
  targetRow,
  indexDialog,
  isOpen,
  setIsOpen,
  modalData,
  setModalData,
  btnKeyName,
  pageData,
  setPageData,
  invoiceDialogData,
  setInvoiceDialogData,
  tableBodyData,
  setTableBodyData,
}) => {
  //#region state initialization
// console.log({
//   targetRow,
//   btnKeyName,
//   tableBodyData,
// })

  const { t } = useTranslation(["common", "lgsPurchaseOrderGoodsInvoice"]); // i18n

  // console.log(targetRow);

  // state
  const [isCancelSearchModalBtn, setIsCancelSearchModalBtn] = useState(false); // cancel search btn
  const [oneCheck, setOneCheck] = useState("");

  // ref
  const searchModalInput = useRef("");

  //console.log(modalData);
  //#endregion

  //#region Modal儲存
  function modalSave() {
    // 選取哪些checkbox
    const handleSelectedData = () => {
      const selectedId = [];
      const newPageData = { ...pageData };

      document
        .querySelectorAll(".modal-content [type=checkbox]")
        .forEach((el) => {
          el.checked && selectedId.push(el.id);
        });
      // 此功能頁面共四種共用
      // 1.employee
      if (btnKeyName === "employee") {
        newPageData.goodsItemInfo.employeeGuid = selectedId[0];

        let item = modalData.find((el) => {
          return el[`employeeGuid`] === selectedId[0];
        });

        newPageData.goodsItemInfo.employeeCode = item.employeeCode;
        newPageData.goodsItemInfo.employeeName = item.fullname;
      }

      // 2.cube
      if (btnKeyName === "cube") {

        console.log(targetRow[0])

        const targetItem = newPageData.updateData.salesOrderShipmentItemList.find(
          (el) => {
            return (el.shipmentItemGuid === targetRow[0].shipmentItemGuid)
          }
        );

        const targetIdx = newPageData.updateData.salesOrderShipmentItemList.findIndex(
          (el) => el.shipmentItemGuid === targetRow[0].shipmentItemGuid
        );

        let item = modalData.find((el) => {
          return el.cubeGuid === selectedId[0];
        });

        targetItem.cubeGuid = selectedId[0];
        targetItem.cubeName = item.name01;

        // console.log(newPageData.salesOrderKitItemList[indexDialog]);
        // newPageData.salesOrderKitItemList[indexDialog].cubeGuid = selectedId[0];
        // targetRow[newData[inputIndex]] = value;
        // newPageData.salesOrderKitItemList[indexDialog].cubeName = item.name01;

        console.log(tableBodyData);

        // const newTableBodyData = [...tableBodyData, tableBodyData[targetIdx] = targetItem]
        const newTableBodyData = [...tableBodyData];
        newTableBodyData[targetIdx] = targetItem;

        // 清除錯誤提示
        // const newTableBodyData = [...tableBodyData];
        // newTableBodyData.forEach((el) => {
        //   if (
        //     newPageData.salesOrderKitItemList[indexDialog]
        //       .purchaseOrderItemGuid === el.purchaseOrderItemGuid
        //   ) {
        //     el.cubeGuidWarning = "";
        //   }
        // });
        setTableBodyData(newTableBodyData);
      }

      // 3.vendor
      if (btnKeyName === "vendor") {
        console.log(selectedId[0]);

        let item = modalData.find((el) => {
          return el[`vendorGuid`] === selectedId[0];
        });

        setInvoiceDialogData({
          ...invoiceDialogData,
          salesVendorViewName: item.name01,
          salesVendorGuid: selectedId[0],
          salesTaxID: item.vendorTaxId,
          salesTaxName: item.name01 || "",
        });
      }

      // 4.customer
      if (btnKeyName === "customer") {
        console.log(selectedId[0]);

        let item = modalData.find((el) => {
          return el[`customerGuid`] === selectedId[0];
        });

        setInvoiceDialogData({
          ...invoiceDialogData,
          purchaserCustomerViewName: item.name01,
          purchaserCustomerGuid: selectedId[0],
          purchaserTaxID: item.customerTaxId,
          purchaserTaxName: item.name01 || "",
        });
      }

      setPageData(newPageData);
    };

    oneCheck.length !== 0 && handleSelectedData();

    handleClose();
  }
  //#endregion

  // Modal取消(關閉)
  const handleClose = () => {
    setIsOpen(false);
  };

  //#region Modal搜尋

  // 搜尋
  const searchModalBtn = () => {
    //取得搜尋文字
    let searchModalInputText = searchModalInput.current
      ? searchModalInput.current.value.toUpperCase()
      : searchModal2Input.current.value.toUpperCase();

    let modalTrList = document.querySelectorAll(".modalTr");

    if (searchModalInputText !== "") {
      //隱藏全部欄位
      modalTrList.forEach((item) => {
        item.classList.add("d-none");
      });

      // 搜尋每個Tr中的每個Td欄位
      modalTrList.forEach((item) => {
        // console.log(item.children);

        [...item.children].forEach((td) => {
          if (td.textContent.toUpperCase().includes(searchModalInputText)) {
            td.parentElement.classList.remove("d-none");
          }
        });
      });

      //顯示取消搜尋btn
      setIsCancelSearchModalBtn(true);
    }
  };

  // 取消搜尋
  const cancelSearchModalBtn = (i) => {
    // 顯示全部欄位
    document.querySelectorAll(".modalTr").forEach((item) => {
      item.classList.remove("d-none");
    });

    // 清空搜尋欄位
    if (searchModalInput.current) searchModalInput.current.value = "";

    // 隱藏取消搜尋btn
    setIsCancelSearchModalBtn(false);
  };

  //#endregion

  //#region Modal clickTableRow(只能選一個)

  function clickTableRow(e, guid) {
    // console.log(guid);
    setOneCheck(guid);

    //點選tr 使checkbox被選取
    let majorCheck = e.target.parentElement.children[0].querySelector("input");

    //避免input無法直接點擊
    if (e.nodeName != "INPUT" && e.nodeName != "LABEL") {
      majorCheck.checked = !majorCheck.checked;
    }
  }

  //#endregion

  //#region Modal 排序

  let headTitleList = [];
  let bodyTitleList = [];

  if (btnKeyName === "employee") {
    headTitleList = [
      t("lgsPurchaseOrderGoodsInvoice:dialogCol.number"),
      t("lgsPurchaseOrderGoodsInvoice:dialogCol.name"),
    ];
    bodyTitleList = ["employeeCode", "fullname"];
  } else if (btnKeyName === "cube") {
    headTitleList = [
      t("lgsPurchaseOrderGoodsInvoice:dialogCol.number"),
      t("lgsPurchaseOrderGoodsInvoice:dialogCol.name"),
    ];
    bodyTitleList = ["cubeCode", "name01"];
  } else if (btnKeyName === "vendor") {
    headTitleList = [
      t("lgsPurchaseOrderGoodsInvoice:dialogCol.number"),
      t("lgsPurchaseOrderGoodsInvoice:dialogCol.name"),
      t("lgsPurchaseOrderGoodsInvoice:dialogCol.UBN"),
    ];
    bodyTitleList = ["vendorCode", "name01", "vendorTaxId"];
  } else if (btnKeyName === "customer") {
    headTitleList = [
      t("lgsPurchaseOrderGoodsInvoice:dialogCol.number"),
      t("lgsPurchaseOrderGoodsInvoice:dialogCol.name"),
      t("lgsPurchaseOrderGoodsInvoice:dialogCol.phoneNumber"),
    ];
    bodyTitleList = ["customerCode", "name01", "purchaserAddTel"];
  }

  useEffect(() => {
    setUISort(
      btnKeyName === "employee"
        ? "employeeCode"
        : btnKeyName === "cube"
        ? "cubeCode"
        : btnKeyName === "vendor"
        ? "vendorCode"
        : btnKeyName === "customer"
        ? "customerCode"
        : ""
    );
  }, [btnKeyName]);

  const [orderBy, setOrderBy] = useState("asc"); // 排序方式
  const [UISort, setUISort] = useState(""); // 排序欄位
  const {
    items: items,
    requestSort: requestSort,
    sortConfig: sortConfig,
  } = useSortableData(modalData, bodyTitleList[0], "ascending"); // 排序

  // console.log(modalData);

  const handleSortChange = (newTarget) => {
    const oldTarget = UISort;
    setUISort(newTarget);

    if (oldTarget === newTarget) {
      setOrderBy(orderBy === "desc" ? "asc" : "desc");
    } else {
      setOrderBy("asc");
    }

    setModalData(items);
  };

  //#endregion

  // 每次打開dialog，清空所選擇的項目
  useEffect(() => {
    setOneCheck("");
  }, [isOpen]);

  // console.log(tableBodyData)

  return (
    <>
      {isOpen && (
        <ModalWrapper onClick={handleClose}>
          <ModalDialog
            className="modal-dialog d-flex align-items-center"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="modal-content border-0 bg-white"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: "12px",
              }}
            >
              <div className="modal-header px-4 pt-4 pb-3">
                <h5 className="text-primary fw-bold fontSize125Rem mb-0">
                  {btnKeyName === "employee"
                    ? t("lgsPurchaseOrderGoodsInvoice:dialogCol.receiver")
                    : btnKeyName === "cube"
                    ? t("lgsPurchaseOrderGoodsInvoice:dialogCol.number")
                    : btnKeyName === "vendor"
                    ? t(
                        "lgsPurchaseOrderGoodsInvoice:dialogCol.materialLocation"
                      )
                    : btnKeyName === "customer"
                    ? t("lgsPurchaseOrderGoodsInvoice:dialogCol.customer")
                    : ""}
                </h5>
                <button
                  style={{ top: "12px", right: "12px" }}
                  type="button"
                  className="close position-absolute btn"
                  onClick={handleClose}
                >
                  <Icn_Cancel />
                </button>
              </div>

              <div className="modal-body text-center px-7 py-0">
                {/* 搜尋 */}
                <div className="row">
                  <div className="position-relative col-12 col-md-6 p-0 pr-3 mt-6 mb-3">
                    <input
                      type="text"
                      id="uomSearchInput"
                      name="searchModalInput"
                      className="form-control searchModalInput fontSize875Rem"
                      ref={searchModalInput}
                      placeholder={t(
                        "lgsPurchaseOrderGoodsInvoice:dialogCol.quickSearch"
                      )}
                      style={{
                        height: "32px",
                        textTransform: "uppercase",
                      }}
                    />
                    <button
                      type="button"
                      className="searchModalBtn position-absolute border-0 btn btn-primaryllt px-3 py-0"
                      onClick={() => searchModalBtn()}
                      style={{
                        top: "1px",
                        right: "1px",
                        height: "30px",
                        borderRadius: "0 0.25rem 0.25rem 0",
                      }}
                    >
                      <Icn_Search2 />
                    </button>
                    {isCancelSearchModalBtn && (
                      <a
                        id="cancelSearchUomModal"
                        className={`cancelSearchModalBtn position-absolute ${
                          searchModalInput.current?.value ? "" : "d-none"
                        }`}
                        onClick={() => cancelSearchModalBtn()}
                        style={{
                          top: "3px",
                          right: "-24px",
                          cursor: "pointer",
                        }}
                      >
                        <Icn_InputYellowCancel />
                      </a>
                    )}
                  </div>
                </div>

                <div
                  className="form-group pe-0 mb-0 row"
                  style={{
                    height: "500px",
                    overflowY: "scroll",
                  }}
                >
                  <table
                    className="table tableModal"
                    style={{ height: "fit-content" }}
                  >
                    <ModalThead
                      className="table-head position-sticky"
                      style={{ top: "0" }}
                      items={items}
                      requestSort={requestSort}
                      sortConfig={sortConfig}
                    >
                      <tr>
                        <ModalTh className="p-0">
                          <ModalLabel className="m-0 w-100 text-center">
                            <ModalInput
                              className="totalCheckBtn d-none"
                              type="checkbox"
                            />
                            <span></span>
                          </ModalLabel>
                        </ModalTh>
                        {headTitleList?.map((el, index) => {
                          return (
                            <th
                              key={bodyTitleList[index] + index}
                              className="text-start"
                              onClick={() => {
                                requestSort(
                                  bodyTitleList[headTitleList.indexOf(el)]
                                );
                                handleSortChange(
                                  bodyTitleList[headTitleList.indexOf(el)]
                                );
                              }}
                            >
                              {el}
                              {bodyTitleList[headTitleList.indexOf(el)] ===
                                UISort &&
                                (orderBy === "desc" ? (
                                  <span className="ml-1">▼</span>
                                ) : (
                                  <span className="ml-1">▲</span>
                                ))}
                            </th>
                          );
                        })}
                      </tr>
                    </ModalThead>
                    <tbody>
                      {items.map((item) => {
                        return (
                          <tr
                            key={item[`${btnKeyName}Guid`]}
                            className="modalTr list-item table-item tableData"
                            onClick={(e) =>
                              clickTableRow(e, item[`${btnKeyName}Guid`])
                            }
                          >
                            <td className="text-center p-0">
                              <label className="m-0 w-100 px-2">
                                <input
                                  type="checkbox"
                                  id={item[`${btnKeyName}Guid`]}
                                  value={item[`${btnKeyName}Guid`]}
                                  className="mt-2"
                                  checked={
                                    oneCheck === item[`${btnKeyName}Guid`]
                                  }
                                  onChange={() => {}}
                                />
                              </label>
                            </td>
                            <td className="text-start">
                              {item[`${btnKeyName}Code`]}
                            </td>

                            <td className="text-start">
                              {btnKeyName === "employee"
                                ? item.fullname
                                : btnKeyName === "cube" ||
                                  "vendor" ||
                                  "customer"
                                ? item.name01
                                : ""}
                            </td>

                            {(btnKeyName === "vendor" ||
                              btnKeyName === "customer") && (
                              <td className="text-start">
                                {btnKeyName === "vendor"
                                  ? item.vendorTaxId
                                  : btnKeyName === "customer"
                                  ? item.purchaserAddTel
                                  : ""}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <hr
                className="mx-4 mt-3 mb-0"
                style={{
                  border: `1px solid var(--grey2)`,
                }}
              />

              {/* 確定btn */}
              <div className="modal-footer justify-content-center border-0 pt-4 pb-5">
                <button
                  type="button"
                  className="btn btn-primary mediumBtn mx-1"
                  style={{
                    margin: "0 20px",
                    width: "120px",
                  }}
                  onClick={modalSave}
                >
                  {t("common:button.confirmButton")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primarylllt mediumBtn mx-1"
                  onClick={handleClose}
                >
                  {t("common:button.cancelButton")}
                </button>
              </div>
            </div>
          </ModalDialog>
        </ModalWrapper>
      )}
    </>
  );
};
